import React, { useState, useEffect } from "react"
import { Row, Col, Card, CardBody, Progress, CardTitle } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { ReactApexChart } from "react-apexcharts"

const FeedbackChart = props => {
  const [stats, setStats] = useState({ feedbacks: 0 })
  const [ratingData, setRatingData] = useState({ series: [], labels: [] })

  const clientCount =
    props.data && props.data.list
      ? new Set(props.data.list.map(item => item.client_name)).size
      : 0

  useEffect(() => {
    if (props.data && Array.isArray(props.data.list)) {
      const totalFeedbacks = props.data.list.length
      setStats({ feedbacks: totalFeedbacks })

      const counts = { 0: 0, 1: 0, 2: 0 }
      props.data.list.forEach(item => {
        if (item.rating === 0 || item.rating === 1 || item.rating === 2) {
          counts[item.rating]++
        }
      })

      setRatingData({
        series: [counts[0], counts[1], counts[2]],
      })
    }
  }, [props.data])

  return (
    <div id="feedback-chart">
      <Row className="mt-0 mb-2">
        <div className="d-flex flex-wrap">
          <span>
            <h5 className="mt-0">{props.t("Video call feedback2")}</h5>
          </span>
        </div>
      </Row>
      <Row>
        <Col lg={6} xl={3}>
          <Card style={{ height: "127px" }}>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-md mt-2 ms-0">
                  <div className="avatar-title bg-light rounded-circle text-primary h1">
                    <i className="mdi mdi-account-voice"></i>
                  </div>
                </div>
                <div className="ms-4 mt-2">
                  <p className="text-muted font-size-14 mb-2">
                    {props.t("Total feedbacks")}
                  </p>
                  <h2 className="mb-0">{stats.feedbacks}</h2>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6} xl={3}>
          <Card style={{ height: "127px" }}>
            <CardBody>
              <div className="d-flex">
                <div className="avatar-md ms-0">
                  <div className="avatar-title bg-light rounded-circle text-primary h1">
                    <i className="bx bx-group"></i>
                  </div>
                </div>
                <div className="ms-4 mt-2">
                  <p className="text-muted font-size-14 mb-2">
                    {props.t("Customers that")} <br />
                    {props.t("sent feedback")}
                  </p>
                  <h2 className="mb-0">{clientCount}</h2>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6}>
          <Card style={{ height: "127px" }}>
            <CardBody>
              {/* <CardTitle>{props.t("Palaute")}</CardTitle> */}
              <div className="mt-4 mb-1">
                {(() => {
                  const total = ratingData.series.reduce(
                    (acc, val) => acc + val,
                    0
                  )
                  return (
                    <Progress
                      multi
                      className="progress-xl progress font-size-12"
                    >
                      <Progress
                        bar
                        style={{ backgroundColor: "#E57373" }}
                        className="progress-xl"
                        value={(ratingData.series[0] / total) * 100}
                      >
                        <span>
                          {Math.round((ratingData.series[0] / total) * 100)}%
                        </span>
                      </Progress>
                      <Progress
                        bar
                        style={{ backgroundColor: "#F3B562" }}
                        className="progress-xl font-size-12"
                        value={(ratingData.series[1] / total) * 100}
                      >
                        <span>
                          {Math.round((ratingData.series[1] / total) * 100)}%
                        </span>
                      </Progress>
                      <Progress
                        bar
                        style={{ backgroundColor: "#50A684" }}
                        className="progress-xl font-size-12"
                        value={(ratingData.series[2] / total) * 100}
                      >
                        <span>
                          {Math.round((ratingData.series[2] / total) * 100)}%
                        </span>
                      </Progress>
                    </Progress>
                  )
                })()}
                <Row className="mt-3 font-size-13">
                  <Col xs="4">
                    <i
                      className="fas fa-circle"
                      style={{ color: "#E57373" }}
                    ></i>
                    <span className="text-muted ms-1">
                      {props.t("Improvable")} {ratingData.series[0]}
                    </span>
                  </Col>
                  <Col xs="4">
                    <i
                      className="fas fa-circle ms-3"
                      style={{ color: "#F3B562" }}
                    ></i>
                    <span className="text-muted ms-1">
                      {props.t("Satisfied")} {ratingData.series[1]}
                    </span>
                  </Col>
                  <Col xs="4">
                    <i
                      className="fas fa-circle ms-3"
                      style={{ color: "#50A684" }}
                    ></i>
                    <span className="text-muted ms-1">
                      {props.t("Excellent")} {ratingData.series[2]}
                    </span>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

FeedbackChart.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(FeedbackChart)
