import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"

// Locale
import { withTranslation } from "react-i18next"

// Importing components
import FeedbackTable from "./components/feedback-table"
import FeedbackChart from "./components/feedback-chart"

const CallFeedbackTabReporting = props => {
  const [feedbackData, setFeedbackData] = useState([])

  // Filter view related states
  const [activeTab] = useState("1")

  useEffect(() => {
    if (props.activeTab == 10) {
      //console.log("feedbackData changed: " + JSON.stringify(props.data))
      setFeedbackData(props.data)
    }
  }, [props.data, props.activeTab])

  return (
    <>
      <Row>
        <Col>
          {activeTab == "1" && props.data !== undefined && (
            <div className="mt-2 mb-1">
              <Row>
                <FeedbackChart data={feedbackData} />
              </Row>
              <Row>
                <FeedbackTable
                  minRows={0}
                  selectedType={props.selectedType}
                  data={feedbackData}
                />
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

CallFeedbackTabReporting.propTypes = {
  data: PropTypes.any,
  activeTab: PropTypes.any,
  selectedType: PropTypes.any,
}

export default withTranslation()(CallFeedbackTabReporting)
