import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getOrders } from "../../helpers/backend_helper"
import { Select, ConfigProvider } from "antd"
import { Col, Row } from "reactstrap"
const { Option } = Select

const SelectOrder = props => {
  const [loading, setLoading] = useState(false)
  const [customize] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState(null)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    updateOrders()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.selection) setSelectedValue(props.selection)
    else setSelectedValue(null)
  }, [props.selection])

  useEffect(() => {
    if (props.updateCounter > 0) {
      updateOrders()
    }
  }, [props.updateCounter])

  // Get orders
  const updateOrders = () => {
    if (!loading) {
      setLoading(true)
      getOrders(abortRef.current)
        .then(result => {
          if (result.statusCode == 200) {
            let opts = []
            result.entries.forEach(entry => {
              let temp = new Object()
              temp.label = entry.orderNumber + " - " + entry.customer
              temp.value = entry.orderNumber
              temp.customer = entry.customer
              temp.billingCustomer = entry.billingCustomer
              temp.additionalInfo = entry.additionalInfo
              temp.status = entry.status
              temp.statusChanged = entry.statusChanged

              switch (entry.status) {
                case "1":
                  temp.statusName = "Processing"
                  break
                case "2":
                  temp.statusName = "Sent"
                  break
                default:
                  temp.statusName = "Created"
                  break
              }

              opts.push(temp)
            })
            
            // Descending order except items starting with a number should be before those starting with a letter
            //opts.sort((a, b) => (a.value < b.value ? 1 : -1)) // Old
            opts.sort((a, b) => {
              if ((/^\d/.test(a.value) && /^\d/.test(b.value))) {
                //return (a.value < b.value ? 1 : -1)
                return b.value-a.value
              }
              else if ((!/^\d/.test(a.value) && !/^\d/.test(b.value))) {
                return (a.value < b.value ? 1 : -1)
              }
              else {
                return /^\d/.test(a.value) ? -1 : 1
              }
            })
            setOptions(opts)
            if (props.datasetChanged) props.datasetChanged(opts)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const handleChange = newSelection => {
    setSelectedValue(newSelection)
    if (props.setSelectedOrder != null) {
      props.setSelectedOrder(newSelection)
    }
  }

  // Get styling for status badge
  const getStatusStyling = status => {
    switch (status) {
      case "1":
        return "badge bg-primary font-size-12 px-2 py-1"
      case "2":
        return "badge bg-success font-size-12 px-2 py-1"
      default:
        return "badge bg-gray font-size-12 px-2 py-1"
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      {loading ? (
        <p>{props.t("Loading")}</p>
      ) : (
        <p>{props.t("No entries found")}</p>
      )}
    </div>
  )

  return (
    <div>
      <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
        <div className="config-provider">
          <Select
            //disabled={loading}
            showSearch
            loading={loading}
            placeholder={props.t("Select")}
            style={{ width: 100 + "%" }}
            value={selectedValue}
            onChange={handleChange}
            allowClear={true}
            filterOption={(inputValue, option) => {
              return (
                option.label.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0 ||
                option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                  0 ||
                option.customer
                  ?.toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ||
                option.billingCustomer
                  ?.toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0 ||
                option.additionalInfo
                  ?.toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              )
            }}
          >
            {options.map((item, index) => {
              return (
                <Option
                  key={item.value}
                  value={item.value}
                  label={item.label}
                  customer={item.customer}
                  billingCustomer={item.billingCustomer}
                  additionalInfo={item.additionalInfo}
                >
                  <Row type="flex" justify="start">
                    <Col>{item.label}</Col>
                    {props.showStatus && (
                      <Col className="col-auto">
                        <span
                          style={{ borderRadius: "20px" }}
                          className={"" + getStatusStyling(item.status)}
                        >
                          {props.t(item.statusName)}
                        </span>
                      </Col>
                    )}
                  </Row>
                </Option>
              )
            })}
          </Select>
        </div>
      </ConfigProvider>
    </div>
  )
}

SelectOrder.propTypes = {
  t: PropTypes.any,
  selection: PropTypes.string,
  setSelectedOrder: PropTypes.func,
  updateCounter: PropTypes.any,
  showStatus: PropTypes.bool,
  datasetChanged: PropTypes.any,
}

export default withTranslation()(SelectOrder)
