import axios from "axios"
import * as url from "./url_helper"

const axiosApi = axios.create({})

axiosApi.defaults.headers.common["Content-Type"] = "application/json";
axiosApi.defaults.headers.common["Accept"] = "application/json";
axiosApi.defaults.headers.common["AuthKey"] = "ad28e382-8485-4633-92e7-f29fbb0523f1";
axiosApi.defaults.withCredentials = true;

// Set the AUTH token for any request
axiosApi.interceptors.request.use(function (config) {
  const token = localStorage.getItem('api_token');
  if (token) {
    config.headers.AccessToken = token;
  }
  return config;
});

/**
 * PROFILE
 */
export async function kcUpdateProfile(controller, firstName, lastName, gsm) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "UpdateProfile", firstName: firstName, lastName: lastName, gsm: gsm }, { signal: controller.signal })
    .then(response => response.data)
}

export async function kcUpdatePassword(controller) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "UpdatePassword" }, { signal: controller.signal })
    .then(response => response.data)
}

/**
 * GROUPS
 */
export async function createNewUserGroup(controller, groupName) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "CreateGroup", groupName: groupName }, { signal: controller.signal })
    .then(response => response.data)
}

export async function deleteUserGroup(controller, groupName) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "DeleteGroup", groupName: groupName }, { signal: controller.signal })
    .then(response => response.data)
}

export async function removeUsersFromGroup(controller, groupName, users) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "RemoveFromGroup", groupName: groupName, users: users }, { signal: controller.signal })
    .then(response => response.data)
}

export async function addUsersToGroup(controller, groupName, users) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "AddToGroup", groupName: groupName, users: users }, { signal: controller.signal })
    .then(response => response.data)
}

export async function addGroupsToUser(controller, userId, groups) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "AddGroupsToUser", userId: userId, groups: groups }, { signal: controller.signal })
    .then(response => response.data)
}

export async function removeGroupsFromUser(controller, userId, groups) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "RemoveGroupsFromUser", userId: userId, groups: groups }, { signal: controller.signal })
    .then(response => response.data)
}

/**
 * USERS
 */

export async function getUserList(controller, groupName, listAllUsers=false, listUsersGroups=false) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "ListUsers", groupName: groupName, listAllUsers: listAllUsers, listUsersGroups: listUsersGroups }, { signal: controller.signal })
    .then(response => response.data)
}

export async function getKeycloakUser(controller, userId) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "GetUser", userId: userId }, { signal: controller.signal })
    .then(response => response.data)
}

export async function createNewUser(controller, username, groupName) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "CreateUser", username: username, groupName: groupName }, { signal: controller.signal })
    .then(response => response.data)
}

export async function setTemporaryUserPassword(controller, userId, newPassword) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "SetTemporaryPassword", userId: userId, newPassword: newPassword }, { signal: controller.signal })
    .then(response => response.data)
}

export async function deleteKeycloakUser(controller, userId) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "DeleteUser", userId: userId }, { signal: controller.signal })
    .then(response => response.data)
}

export async function disableKeycloakUser(controller, userId) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "DisableUser", userId: userId }, { signal: controller.signal })
    .then(response => response.data)
}

export async function updateKeycloakUser(controller, userId, values) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "UpdateUser", userId: userId, values: values }, { signal: controller.signal })
    .then(response => response.data)
}

export async function listUsersGroups(controller, userId) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "ListUsersGroups", userId: userId }, { signal: controller.signal })
    .then(response => response.data)
}

export async function getUsersEvents(controller, userId) {
  return axiosApi
    .post(url.KEYCLOAK_ADMIN_API_URL, { type: "GetUserEvents", userId: userId }, { signal: controller.signal })
    .then(response => response.data)
}
