import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { getUsersTickets } from "helpers/database_helper"
import XLSX from "xlsx"
import {
  dateFormatter,
  dateSorter,
  defaultPageSizeOptions,
  numberSorter,
  showToast,
  stringSorter,
} from "utils/general"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"

const SupportTicketsReport = props => {
  const [tickets, setTickets] = useState([])
  const [filteredTickets, setFilteredTickets] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(7, "days"),
    dayjs(),
  ])
  const [userId, setUserId] = useState("149")
  const [maxItems, setMaxItems] = useState("500")

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    document.title = props.t("Support tickets") + " SLA | Suvanto Care"
    return () => abortRef.current.abort()
  }, [])

  // Get tickets
  const getTickets = () => {
    if (!ongoingSearch && userId && dateRange) {
      setOngoingSearch(true)
      //let rows = isNaN(maxItems) == false ? parseInt(maxItems, 10) : 500
      let from = dayjs(dateRange[0]).startOf("day").valueOf()
      let to = dayjs(dateRange[1]).add(1, "days").startOf("day").valueOf()

      getUsersTickets(abortRef.current, userId, from, to)
        .then(result => {
          //console.log("Tickets: ", result)
          result.forEach(element => {
            element.createdAt = element.created
              ? dayjs(element.created).valueOf()
              : 0
            element.closedAt = element.closed
              ? dayjs(element.closed).valueOf()
              : 0
            element.processingAt = element.processing_started
              ? dayjs(element.processing_started).valueOf()
              : 0
          })
          setTickets(result)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, tickets])

  // Handle search function
  const handleSearch = () => {
    let result = tickets.filter(
      data =>
        data.ticket_number?.toLowerCase().includes(searchText) ||
        data.title?.toLowerCase().includes(searchText) ||
        data.sender_email?.toLowerCase().includes(searchText) ||
        data.topic?.toLowerCase().includes(searchText)
    )
    setFilteredTickets(result)
  }

  const columns = [
    {
      dataIndex: "ticket_number",
      title: props.t("Ticket"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "ticket_number", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "createdAt",
      title: props.t("Created"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["createdAt"], b["createdAt"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "topic",
      title: props.t("Topic"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "topic", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "title",
      title: props.t("Title"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "title", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "sender_email",
      title: props.t("Email"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "sender_email", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "current_status",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "current_status", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "processingAt",
      title: props.t("Processing started"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["processingAt"], b["processingAt"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "closedAt",
      title: props.t("Closed at"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["closedAt"], b["closedAt"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "count",
      title: props.t("Attachments"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["count"], b["count"], sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
  ]

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let title = props.t("Tickets")
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Ticket"),
        props.t("Created"),
        props.t("Topic"),
        props.t("Title"),
        props.t("Email"),
        props.t("Status"),
        props.t("Processing started"),
        props.t("Closed at"),
        props.t("Attachments"),
        //props.t("Source"),
      ],
    ]
    const data = filteredTickets.map(elt => [
      elt.ticket_number,
      elt.created,
      elt.topic,
      elt.title,
      elt.sender_email,
      elt.current_status,
      elt.processing_started,
      elt.closed,
      elt.count,
      //elt.source,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Support tickets") + " SLA"} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  {tickets != null && (
                    <React.Fragment>
                      <Row className="mb-1">
                        {" "}
                        <Col className="col-auto pe-0">
                          <Form
                            className="mt-sm-0 d-flex align-items-center"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            <div className="search-box me-2 mb-2 d-inline-block float-end">
                              <div className="position-relative">
                                <Input
                                  type="text"
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  placeholder={props.t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Form>
                        </Col>
                        <Col className="col-auto">
                          <Input
                            type="text"
                            name="userId"
                            value={userId}
                            placeholder="User ID"
                            onChange={v => {
                              setUserId(v.target.value)
                            }}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Input
                            style={{ width: "80px" }}
                            type="number"
                            name="maxItems"
                            value={maxItems}
                            onChange={v => {
                              setMaxItems(v.target.value)
                            }}
                          />
                        </Col>
                        <Col>
                          <CustomRangePicker
                            value={dateRange}
                            rangeChanged={onDateSelection}
                            showTime={false}
                            allowClear={false}
                            format={"DD.MM.YYYY"}
                          />
                        </Col>
                        <Col className="col-auto">
                          <Button
                            //className="square-icon-button-sm"
                            id="search-button"
                            color="primary"
                            disabled={ongoingSearch}
                            onClick={() => {
                              //setDateRange([dateRange[0], dayjs()])
                              getTickets()
                            }}
                          >
                            <i className="mdi mdi-database-search me-2" />
                            {props.t("Search")}
                          </Button>
                          <Button
                            id="export-button"
                            className="ms-3"
                            color="primary"
                            outline
                            disabled={ongoingSearch || tickets.length == 0}
                            onClick={() => {
                              downloadExcel()
                            }}
                          >
                            <i className="mdi mdi-download me-2" />
                            {props.t("Download") + " .xlsx"}
                          </Button>
                        </Col>
                        <Col>
                          <div className="mt-2 float-end">
                            <p>
                              {filteredTickets.length}{" "}
                              {props.t("results found")}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xl="12">
                          {ongoingSearch ? (
                            <div className="py-5 text-center">
                              <p className="text-primary">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                {props.t("Loading")}
                              </p>
                            </div>
                          ) : (
                            <AntdTable
                              className="colored-header narrow-header"
                              bordered
                              size="small"
                              sticky
                              rowKey={"ticket_number"}
                              columns={columns}
                              dataSource={filteredTickets}
                              pagination={{
                                showSizeChanger: true,
                                defaultPageSize: 50,
                                pageSizeOptions: defaultPageSizeOptions,
                              }}
                              tableLayout="auto"
                              scroll={{
                                x: "max-content",
                                y: tableDefaultHeight,
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

SupportTicketsReport.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SupportTicketsReport)
