import React, { useCallback, useContext, useEffect, useState } from "react";
import Keycloak from "keycloak-js";
import GlobalState from "./GlobalState";
import PropTypes from "prop-types";

/**
 * Keycloak instance
 */
const keycloak = new Keycloak({
	url: process.env.REACT_APP_KEYCLOAK_URL,
	realm: process.env.REACT_APP_KEYCLOAK_REALM,
	clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

/**
 * Authentication provider component.
 * 
 * The component initializes authentication and refreshes token when it expires.
 */
const KeycloakAuthProvider = props => {
	const [state, setState] = useContext(GlobalState)
	const [auth, setAuth] = useState(undefined);
	const [userProfile, setUserProfile] = useState(undefined);

	  // Listen for logout event
	  useEffect(() => {
		const handleLocalStorageLogout = event => {
		  if (event.key === "logout") {
			if (state.logout) {
			  state.logout()
			}
		  }
		}

		window.addEventListener("storage", handleLocalStorageLogout)
		return () => window.removeEventListener("storage", handleLocalStorageLogout)
	  }, [state.logout])

	/**
	 * Updates authentication data.
	 * Method is called when user logs in or when token is refreshed.
	 */
	const updateAuthData = useCallback(() => {
		//console.log("Keycloak update auth data", keycloak.token)
		if (!(keycloak.tokenParsed && keycloak.token)) return;

		localStorage.setItem("api_token", keycloak.token);
		setState(state => ({ ...state, logout: () => keycloak.logout({redirectUri: `${window.location.origin}` + '/' }) }))

		setAuth({
			token: keycloak.tokenParsed,
			tokenRaw: keycloak.token,
			logout: () => keycloak.logout({ redirectUri: `${window.location.origin}` }),
		});

		setUserProfile(keycloak.profile);
	}, [setAuth, setUserProfile]);

	/**
	 * Clears authentication data.
	 * Method is called when user logs out.
	 */
	const clearAuthData = useCallback(() => {
		console.log("Keycloak clear auth data")
		setAuth(undefined);
		setUserProfile(undefined);
		localStorage.setItem("api_token", "");
	}, [setAuth, setUserProfile]);

	/**
   * Initializes authentication.
   * Method is called when component mounts.
   */
	const initAuth = useCallback(async () => {
		//console.log("Keycloak init auth")
		const keycloakLoginOptions = {
			redirectUri: `${window.location.origin}/main`
		}

		try {
			keycloak.onTokenExpired = () => {
				console.log("Keycloak onTokenExpired")
				keycloak.updateToken(5);
			}

			keycloak.onAuthRefreshError = () => {
				console.log("Keycloak onAuthRefreshError")
				keycloak.login(keycloakLoginOptions);
			}
			keycloak.onAuthRefreshSuccess = () => {
				console.log("Keycloak onAuthRefreshSuccess");
				updateAuthData();
			}

			keycloak.onAuthError = (error) => console.error(error);
			keycloak.onAuthSuccess = async () => {
				console.log("Keycloak on auth success")
				try {
					await keycloak.loadUserProfile();
				} catch (error) {
					console.error("Could not load user profile", error);
				}

				updateAuthData();
			};

			// Triggers only with status iframe enabled 
			keycloak.onAuthLogout = () => {
				console.log("Keycloak on logout")
				clearAuthData();
				keycloak.login(keycloakLoginOptions);
			};

			await keycloak.init({
				onLoad: "login-required",
				checkLoginIframe: false,
				scope: "openid profile email",
				locale: "fi"
			});
		} catch (error) {
			console.error(error);
		}
	}, [clearAuthData, updateAuthData]);

	/**
	 * Initializes authentication when component mounts.
	 */
	useEffect(() => {
		if (keycloak.authenticated === undefined) initAuth();
	}, []);

	if (!auth) {
		//console.log("Auth null")
		return (
            <div id="preloader">
              <div id="status">
                <div className="spinner-chase">
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                  <div className="chase-dot" />
                </div>
              </div>
            </div>
          )
	}
	//else console.log("Auth not null return children")
	return props.children;
}

KeycloakAuthProvider.propTypes = {
    children: PropTypes.any,
  }

export default KeycloakAuthProvider;