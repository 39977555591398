/*********************************
 * CLIENT SELECT
 * USED IN: CustomerProfile (page)
 *********************************/

import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { getClientList } from "../../helpers/backend_helper"
import { Select, ConfigProvider } from "antd"
const { Option, OptGroup } = Select
import reactStringReplace from "react-string-replace"

const SelectClientManagement = props => {
  const [customize] = useState(true)
  const [options, setOptions] = useState([])
  const [selectedName, setSelectedName] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [placement] = useState("bottomLeft")
  const [searchString, setSearchString] = useState("")

  const [filteredOptions, setFilteredOptions] = useState([])
  const includeVideoIMEIRef = useRef(props.includeVideoIMEI)

  // Update includeVideoIMEI Ref to ensure correct values
  useEffect(() => {
    includeVideoIMEIRef.current = props.includeVideoIMEI
  }, [props.includeVideoIMEI])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    getClients()
    return () => {
      abortRef.current.abort()
    }
  }, [])
  useEffect(() => {
    if (
      props.selectionName !== null &&
      props.selectionName !== undefined &&
      props.selectionName !== ""
    ) {
      setSelectedName(props.selectionName)
    } else {
      setSelectedName(null)
    }
  }, [props.selectionName])

  useEffect(() => {
    // console.log(props.updateCounter)
    if (props.updateCounter && props.updateCounter > 0) {
      getClients()
    }
  }, [props.updateCounter])

  // Istekki TODO: set socket
  // Update the list every 30sec
  useEffect(() => {
    const interval = setInterval(() => {
      getClients("polling")
    }, 30000)
    return () => clearInterval(interval)
  }, [])

  // Customer info changed, update list
  useEffect(() => {
    if (props.refresh) getClients()
  }, [props.refresh])

  // includeVideoIMEI switched on
  useEffect(() => {
    //console.log("includeVideoIMEI", props.includeVideoIMEI)
    if (props.includeVideoIMEI) {
      getClients() // Fetch data with IMEI
      // Clear and disable search until new data is fetched
      setDisabled(true)
      setOptions([])
    }
  }, [props.includeVideoIMEI])

  // Filter list by selected tags
  useEffect(() => {
    if (Array.isArray(props.selectedTags) && props.selectedTags.length > 0) {
      // Filter the options based on selected tags
      const filtered = options
        .map(group => {
          const filteredClients = group.options.filter(client => {
            // Check if any of the tags in the client match the selected tags
            const clientTagKeys = client.tags?.map(tag => tag.key) || []
            return clientTagKeys.some(key => props.selectedTags.includes(key))
          })

          // Return a new group if it has any clients that match
          if (filteredClients.length > 0) {
            return {
              ...group,
              options: filteredClients,
            }
          }

          return null // Exclude groups without matching clients
        })
        .filter(group => group !== null)

      setFilteredOptions(filtered)
    } else {
      // No tags selected, show all options
      setFilteredOptions(options)
    }
  }, [props.selectedTags, options])

  // Get clients list
  const getClients = t => {
    const apiDurationStart = performance.now()
    //console.log("API call innitiated")

    //const selectedId = props.params?.clientId ?? null
    //console.log("SELECTED ID: ", selectedId)

    getClientList(abortRef.current, includeVideoIMEIRef.current)
      .then(data => {
        //console.log("all data", data)
        if (data.array != null) {
          props.clientArray(data.array)
          //let selection = null
          let opts = []
          let counter = 1

          data.array.forEach(group => {
            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.label = group.name
            groupObject.value = group.name
            groupObject.gsm = ""
            groupObject.ssn = ""
            groupObject.address = ""

            groupObject.displayName = ""
            groupObject.appInfo = ""
            groupObject.info = ""
            groupObject.contacts = ""

            groupObject.options = []

            group.clients.sort((a, b) => (a.name > b.name ? 1 : -1))
            group.clients.forEach(client => {
              let clientObject = new Object()
              clientObject.label = client.name
              clientObject.value = client.id
              clientObject.gsm = client.gsm ? client.gsm : ""
              clientObject.ssn = client.ssn ? client.ssn : ""
              clientObject.address = client.address ? client.address : ""

              clientObject.displayName = client.displayName
                ? client.displayName
                : ""
              clientObject.appInfo = client.appInfo ? client.appInfo : ""
              clientObject.info = client.info ? client.info : ""
              clientObject.contacts = client.contacts ? client.contacts : ""
              clientObject.videoSerial = client.videoSerial
                ? client.videoSerial
                : ""
              clientObject.videoImei = client.videoImei ? client.videoImei : ""

              clientObject.tags = group.tags ? group.tags : []

              /*if (selectedId != null && selectedId === client.id) {
                selection = client.id
              }*/
              groupObject.options.push(clientObject)
            })
            opts.push(groupObject)
          })
          setOptions(opts)
          /*if (t !== "polling") {
            handleChange(selection)
          }*/
          const apiDurationEnd = performance.now()
          const durationInSeconds = (
            (apiDurationEnd - apiDurationStart) /
            1000
          ).toFixed(3)
          console.log(`API Call Duration: ${durationInSeconds} seconds`)
          setDisabled(false)
        }
      })
      .catch(e => {
        console.log(e)
        setDisabled(false)
      })
  }

  // Selection changed
  const handleChange = (id, item) => {
    setSearchString("")
    if (props.onChange != null) {
      props.onChange(id)
    }
    if (item != undefined) {
      setSelectedName(item.label)
    }
  }

  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => {
    if (disabled) {
      return (
        <div className="mt-3" style={{ textAlign: "center" }}>
          <p>{props.t("Loading")}...</p>
          {props.includeVideoIMEI && (
            <p>{props.t("Searching with video device IMEI number")}</p>
          )}
        </div>
      )
    } else {
      return (
        <div className="mt-3" style={{ textAlign: "center" }}>
          <p>{props.t("No customers found")}</p>
        </div>
      )
    }
  }

  return (
    <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
      <div id="heavy" className="config-provider ">
        <Select
          size="large"
          loading={disabled}
          showSearch
          variant="unstyled"
          allowClear={true}
          placement={placement}
          popupMatchSelectWidth={true}
          placeholder={props.t("Select client")}
          value={selectedName}
          style={{
            minWidth: 350 + "px",
            width: "100%",
            // minWidth: 250 + "px",
          }}
          onChange={handleChange}
          listHeight={400}
          onSearch={s => {
            setSearchString(s.toLowerCase())
          }}
          filterOption={(inputValue, option) => {
            if (
              Array.isArray(props.selectedTags) &&
              props.selectedTags.length > 0
            ) {
              if (
                Array.isArray(props.selectedTags) &&
                props.selectedTags.length > 0
              ) {
                // Check if the tags in the client match the selected tags
                const clientTagKeys = option.tags?.map(tag => tag.key) || []
                const tagMatch = clientTagKeys.some(key =>
                  props.selectedTags.includes(key)
                )
                if (!tagMatch) {
                  return false // If no matching tag, filter out
                }
              }
            }

            return (
              option.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0 ||
              option.value?.toLowerCase() == inputValue ||
              option.gsm?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0 ||
              option.ssn?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0 ||
              option.address?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0 ||
              option.displayName
                ?.toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0 ||
              option.contacts
                ?.toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0 ||
              //option.appInfo?.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
              option.info?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
                0 ||
              option.videoSerial
                ?.toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0 ||
              option.videoImei
                ?.toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
            )
          }}
        >
          {/* {options.map((item, index) => { 
              return (
                <OptGroup key={item.value} value={item.value} label={item.label}>
                  {item.options.map((client, index2) => { 
                    return (
                      <Option key={client.value} value={client.value} label={client.label} gsm={client.gsm} address={client.address} ssn={client.ssn} contacts={client.contacts} displayName={client.displayName} info={client.info}>
                        <Row type="flex" justify="start">
                          <Col className="col-auto">{client.label}</Col>
                          {searchString && client.gsm?.toLowerCase().indexOf(searchString) >= 0 && 
                            <Col className="col-auto font-size-12"><strong className="">{props.t("GSM")}: </strong>{client.gsm}</Col>
                          }
                          {searchString && client.address?.toLowerCase().indexOf(searchString) >= 0 && 
                            <Col className="col-auto font-size-12"><strong className="">{props.t("Address")}: </strong>{client.address}</Col>
                          }
                          {searchString && client.ssn?.toLowerCase().indexOf(searchString) >= 0 && 
                            <Col className="col-auto font-size-12"><strong className="">{props.t("SSN")}: </strong>{client.ssn}</Col>
                          }
                          {searchString && client.contacts?.toLowerCase().indexOf(searchString) >= 0 && 
                            <Col className="col-auto font-size-12"><strong className="">{props.t("Contacts")}: </strong>{client.contacts}</Col>
                          }
                          {searchString && client.displayName !== client.label && client.displayName?.toLowerCase().indexOf(searchString) >= 0 && 
                            <Col className="col-auto font-size-12"><strong className="">{props.t("App display name")}: </strong>{client.displayName}</Col>
                          }
                          {searchString && client.info?.toLowerCase().indexOf(searchString) >= 0 && 
                            <Col className="col-auto font-size-12"><strong className="">{props.t("Info")}: </strong>{client.info}</Col>
                          }
                        </Row>
                      </Option>
                    )
                  })}
                </OptGroup>
              )
            })} */}

          {filteredOptions.map((item, index) => {
            return (
              <OptGroup key={item.value} value={item.value} label={item.label}>
                {item.options.map((client, index2) => {
                  return (
                    <Option
                      key={client.value}
                      value={client.value}
                      label={client.label}
                      tags={client.tags}
                      displayName={
                        searchString.length >= 3 ? client.displayName : ""
                      }
                      gsm={searchString.length >= 3 ? client.gsm : ""}
                      address={searchString.length >= 3 ? client.address : ""}
                      contacts={searchString.length >= 3 ? client.contacts : ""}
                      info={searchString.length >= 3 ? client.info : ""}
                      ssn={searchString.length >= 6 ? client.ssn : ""}
                      videoSerial={
                        searchString.length >= 3 ? client.videoSerial : ""
                      }
                      videoImei={
                        searchString.length >= 3 ? client.videoImei : ""
                      }
                    >
                      <div className="col-auto mb-0">{client.label}</div>
                      <div className="sub-label-select">
                        <small>
                          <em>
                            {searchString &&
                              client.gsm?.toLowerCase().indexOf(searchString) >=
                                0 && (
                                <div>
                                  {props.t("GSM")}:{" "}
                                  {reactStringReplace(
                                    client.gsm,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_gsm"}>{match}</strong>
                                    )
                                  )}
                                </div>
                              )}
                            {searchString &&
                              client.address
                                ?.toLowerCase()
                                .indexOf(searchString) >= 0 && (
                                <div>
                                  {props.t("Address")}:{" "}
                                  {reactStringReplace(
                                    client.address,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_address"}>
                                        {match}
                                      </strong>
                                    )
                                  )}
                                </div>
                              )}
                            {searchString &&
                              client.ssn?.toLowerCase().indexOf(searchString) >=
                                0 && <div>{props.t("SSN")}: ******-****</div>}
                            {searchString &&
                              client.contacts
                                ?.toLowerCase()
                                .indexOf(searchString) >= 0 && (
                                <div>
                                  {props.t("Contacts")}:{" "}
                                  {reactStringReplace(
                                    client.contacts,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_contacts"}>
                                        {match}
                                      </strong>
                                    )
                                  )}
                                </div>
                              )}
                            {searchString &&
                              client.displayName !== client.label &&
                              client.displayName
                                ?.toLowerCase()
                                .indexOf(searchString) >= 0 && (
                                <div>
                                  {props.t("App display name")}:{" "}
                                  {reactStringReplace(
                                    client.displayName,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_displayName"}>
                                        {match}
                                      </strong>
                                    )
                                  )}
                                </div>
                              )}
                            {searchString &&
                              client.info
                                ?.toLowerCase()
                                .indexOf(searchString) >= 0 && (
                                <div>
                                  {props.t("Info")}:{" "}
                                  {reactStringReplace(
                                    client.info,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_info"}>{match}</strong>
                                    )
                                  )}
                                </div>
                              )}
                            {searchString &&
                              client.videoSerial
                                ?.toLowerCase()
                                .indexOf(searchString) >= 0 && (
                                <div>
                                  {props.t("Video device serial")}:{" "}
                                  {reactStringReplace(
                                    client.videoSerial,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_vserial"}>
                                        {match}
                                      </strong>
                                    )
                                  )}
                                </div>
                              )}
                            {searchString &&
                              client.videoImei
                                ?.toLowerCase()
                                .indexOf(searchString) >= 0 && (
                                <div>
                                  {props.t("Video device IMEI")}:{" "}
                                  {reactStringReplace(
                                    client.videoImei,
                                    searchString,
                                    (match, i) => (
                                      <strong key={i + "_vimei"}>
                                        {match}
                                      </strong>
                                    )
                                  )}
                                </div>
                              )}
                          </em>
                        </small>
                      </div>
                      {/* )} */}
                    </Option>
                  )
                })}
              </OptGroup>
            )
          })}
        </Select>
      </div>
    </ConfigProvider>
  )
}

SelectClientManagement.propTypes = {
  t: PropTypes.any,
  onChange: PropTypes.func,
  //params: PropTypes.any,
  updateCounter: PropTypes.any,
  clientArray: PropTypes.any,
  selectionName: PropTypes.string,
  refresh: PropTypes.bool,
  includeVideoIMEI: PropTypes.bool,
  selectedTags: PropTypes.any,
}

export default withTranslation()(SelectClientManagement)
