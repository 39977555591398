import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  UncontrolledTooltip,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import {
  createGateway,
  getGatewayDebugData,
  getGateways,
  importGateways,
  setGatewayInstallationSerials,
} from "helpers/backend_helper"
import GatewayDrawer from "./GatewayDrawer"
import {
  downloadExcel,
  showToast,
  isTrue,
  stringSorter,
  dateSorter,
  numberSorter,
  defaultPageSizeOptions,
  dateFormatter,
  getOperatorByIccid,
  operatorFormatter,
  searchToURL,
} from "utils/general"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Table as AntdTable, Popconfirm } from "antd"
import { tableDefaultHeight } from "constants/layout"
import { clientLinksFormatter } from "utils/tables"

const GatewayConfigs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [gateways, setGateways] = useState([])
  const [filteredGateways, setFilteredGateways] = useState([])
  const [createConfirm, setCreateConfirm] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingCreate, setOngoingCreate] = useState(false)
  const [configFilter, setConfigFilter] = useState("0")
  const [connectionFilter, setConnectionFilter] = useState("0")
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [debugSearchText, setDebugSearchText] = useState("")
  const [installationSerials, setInstallationSerials] = useState("")
  const [statistics, setStatistics] = useState({ total: 0 })
  const [importModal, setImportModal] = useState(false)
  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [debugModal, setDebugModal] = useState(false)
  const [debugDataLoading, setDebugDataLoading] = useState(false)
  const [debugData, setDebugData] = useState([])
  const [filteredDebugData, setFilteredDebugData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedMessage, setSelectedMessage] = useState(null)

  const [title] = useState(props.t("Gateways"))

  const search = useLocation().search
  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"

    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    abortRef.current = new AbortController()
    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let now = moment()
    let temp = new Object()
    temp.total = gateways.length
    temp.linked = gateways.filter(data => data.links.length > 0).length
    temp.configured = gateways.filter(data => data.configured).length
    temp.unconfigured = gateways.filter(data => !data.configured).length

    temp.online = gateways.filter(
      data =>
        data.configured &&
        data.lastConnected &&
        now.diff(data.lastConnected, "minutes") <= 30
    ).length
    temp.offline = gateways.filter(
      data =>
        data.configured &&
        (!data.lastConnected || now.diff(data.lastConnected, "minutes") > 30)
    ).length
    temp.offlineRecent = gateways.filter(
      data =>
        data.configured &&
        data.lastConnected &&
        now.diff(data.lastConnected, "minutes") > 30 &&
        now.diff(data.lastConnected, "minutes") < 1440
    ).length

    setStatistics(temp)
  }, [gateways])

  // Check duplicate values
  useEffect(() => {
    if (gateways) {
      // GSM, IMSI, ICCID, IMEI
      let dups = []
      for (let i = 0; i < gateways.length - 1; i++) {
        for (let j = i + 1; j < gateways.length; j++) {
          if (gateways[i].iccid && gateways[i].iccid === gateways[j].iccid) {
            dups.push({
              key: gateways[i].iccid,
              name: "ICCID:" + gateways[i].iccid,
            })
          }
          if (gateways[i].gsm && gateways[i].gsm === gateways[j].gsm) {
            dups.push({ key: gateways[i].gsm, name: "GSM:" + gateways[i].gsm })
          }
          if (gateways[i].imei && gateways[i].imei === gateways[j].imei) {
            dups.push({
              key: gateways[i].imei,
              name: "IMEI:" + gateways[i].imei,
            })
          }
          if (gateways[i].imsi && gateways[i].imsi === gateways[j].imsi) {
            dups.push({
              key: gateways[i].imsi,
              name: "IMSI:" + gateways[i].imsi,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [gateways])

  // Check multiple links
  useEffect(() => {
    if (gateways) {
      let multis = []
      gateways.forEach(element => {
        if (
          element.links.length > 1 &&
          element.key !== "1bc604c9-ca3f-4ffe-9045-93d9eb6454b0"
        ) {
          multis.push({ key: element.key, name: element.description })
        }
      })
      setMultilinks(multis)
    }
  }, [gateways])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen("")
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, gateways, configFilter, connectionFilter])

  useEffect(() => {
    handleDebugSearch()
  }, [debugSearchText, debugData])

  // Clear or fetch alarm logs
  useEffect(() => {
    setDebugData([])
    if (debugModal) {
      getDebugData()
    } else {
      setDebugSearchText("")
    }
  }, [debugModal])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = gateways.filter(
      data =>
        (configFilter === "0" ||
          (configFilter === "1" && data.configured) ||
          (configFilter === "2" && !data.configured)) &&
        (connectionFilter === "0" ||
          (connectionFilter === "1" && data.lastConnectedDiff <= 30) ||
          (connectionFilter === "2" && data.lastConnectedDiff > 30) ||
          (connectionFilter === "3" &&
            data.lastConnectedDiff > 30 &&
            data.lastConnectedDiff < 1440)) &&
        (data.key?.toLowerCase() === searchTextLowercase ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.gsm?.toLowerCase().includes(searchTextLowercase) ||
          data.imsi?.toLowerCase().includes(searchTextLowercase) ||
          data.imei?.toLowerCase().includes(searchTextLowercase) ||
          data.iccid?.toLowerCase().includes(searchTextLowercase) ||
          data.operator?.toLowerCase().includes(searchTextLowercase) ||
          data.description?.toLowerCase().includes(searchTextLowercase) ||
          data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
          data.groupTags?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.toLowerCase().includes(searchTextLowercase))
    )
    setFilteredGateways(result)
  }

  // Handle debug data search
  const handleDebugSearch = () => {
    let searchTextLowercase = debugSearchText.toLowerCase()
    let result = debugData.filter(data =>
      data.data?.toLowerCase().includes(searchTextLowercase)
    )
    setFilteredDebugData(result)
  }

  // Get gateways
  const getDevices = () => {
    setOngoingSearch(true)
    getGateways(abortRef.current)
      .then(result => {
        console.log("result.entries:", result.entries)
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          let now = moment()
          result.entries.forEach(element => {
            element.linkedNames = getLinkedNames(element.links)

            if (element.lastConnected) {
              element.lastConnectedDiff = now.diff(
                moment(element.lastConnected),
                "minutes"
              )
            } else {
              element.lastConnectedDiff = 99999
            }

            element.operator = getOperatorByIccid(element.iccid) || ""
          })
          setGateways(result.entries)

          // Update selected row
          if (selectedRow?.key) {
            let foundRow = result.entries.find(
              item => item.key == selectedRow.key
            )
            setSelectedRow(foundRow)
          }

          if (result.installationSerials) {
            setInstallationSerials(result.installationSerials)
          }
        } else {
          setGateways([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setGateways([])
        setOngoingSearch(false)
      })
  }

  // Get linked names in proper format
  const getLinkedNames = links => {
    let linkedNames = []
    if (links) {
      links.forEach(link => {
        linkedNames.push(link.name)
      })
    }
    return linkedNames.join()
  }

  // Create gateway
  const createGW = () => {
    setOngoingCreate(true)
    createGateway(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
        setOngoingCreate(false)
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setOngoingCreate(false)
      })
  }

  // Update serials
  const updateInstallationSerials = () => {
    setGatewayInstallationSerials(abortRef.current, installationSerials)
      .then(result => {
        if (result.statusCode === 200) {
          showToast(props.t("Updated"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
  }

  // Get GW debug data for table
  const getDebugData = () => {
    if (!debugDataLoading && selectedRow?.key) {
      setDebugDataLoading(true)
      getGatewayDebugData(abortRef.current, selectedRow.key)
        .then(result => {
          if (result.statusCode === 200 && result.data) {
            setDebugData(result.data)
          } else {
            showToast(props.t("Error"), "error")
            setDebugData([])
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
          setDebugData([])
        })
        .finally(() => {
          setDebugDataLoading(false)
        })
    }
  }

  // Import validation
  const importValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      json: "",
    },
    validationSchema: Yup.object({
      json: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (!ongoingCreate) {
        setOngoingCreate(true)
        importGateways(abortRef.current, values.json)
          .then(result => {
            if (result.statusCode == 200) {
              let msg =
                props.t("Added") +
                ": " +
                result.added +
                ", " +
                props.t("Updated") +
                ": " +
                result.updated
              showToast(msg, "success")
              setImportModal(false)
              importValidation.resetForm()
              getDevices()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingCreate(false)
          })
      }
    },
  })

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "lastConnected",
      title: props.t("Last connection"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastConnected"], b["lastConnected"], sortOrder)
      },
      render: (cell, row) => {
        return row.lastConnectedDiff == null || row.lastConnectedDiff > 30 ? (
          <span style={{ color: "#f46a6a" }}>{dateFormatter(cell)}</span>
        ) : (
          dateFormatter(cell)
        )
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "online",
      title: props.t("Online"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.online - b.online
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "description",
      title: props.t("Description"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "description", sortOrder)
      },
      width: "280px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      defaultSortOrder: "ascend",
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["serial"], b["serial"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "sshId",
      title: props.t("SSH ID"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["sshId"], b["sshId"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "groupTags",
      title: props.t("Tags"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groupTags", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: clientLinksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "imsi",
      title: props.t("IMSI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imsi", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "iccid",
      title: props.t("ICCID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "iccid", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "operator",
      title: props.t("Operator"),
      render: (text, record) => {
        return operatorFormatter(record.operator)
      },
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "operator", sortOrder)
      },
      width: "110px",
      ellipsis: true,
      align: "center",
    },
    {
      dataIndex: "zigbeeEpId",
      title: props.t("Zigbee EPID"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["zigbeeEpId"], b["zigbeeEpId"], sortOrder)
      },
      width: "120px",
      ellipsis: true,
      align: "center",
    },
    {
      dataIndex: "sshConnection",
      title: props.t("SSH connection"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.sshConnection - b.sshConnection
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "remoteCommand",
      title: props.t("Remote command"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.remoteCommand - b.remoteCommand
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "remoteCommandRan",
      title: props.t("Remote command ran"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(
          a["remoteCommandRan"],
          b["remoteCommandRan"],
          sortOrder
        )
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "onlineSince",
      title: props.t("Online since"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["onlineSince"], b["onlineSince"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "coordinatorUpdate",
      title: props.t("Coordinator update"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.coordinatorUpdate - b.coordinatorUpdate
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "coordinatorUpdated",
      title: props.t("Coordinator updated"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(
          a["coordinatorUpdated"],
          b["coordinatorUpdated"],
          sortOrder
        )
      },
      render: dateFormatter,
      width: "200px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryOperate",
      title: props.t("Battery operated"),
      align: "center",
      sorter: (a, b) =>
        a.batteryOperate === b.batteryOperate ? 0 : a.batteryOperate ? 1 : -1,
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "135px",
      ellipsis: true,
    },
    {
      dataIndex: "imei",
      title: props.t("IMEI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imei", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "ipAddress",
      title: props.t("IP address"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "ipAddress", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "virtual",
      title: props.t("Virtual"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.virtual - b.virtual
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "hostKey",
      title: props.t("Host"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "hostKey", sortOrder)
      },
      width: "280px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "simStatus",
      title: props.t("SIM status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "simStatus", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "networkType",
      title: props.t("Network type"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "networkType", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "wifi",
      title: props.t("WiFi"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "wifi", sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "rssi",
      title: props.t("RSSI"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["rssi"], b["rssi"], sortOrder)
      },
      width: "80px",
      ellipsis: true,
    },
    {
      dataIndex: "configured",
      title: props.t("Configured"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.configured - b.configured
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "debugging",
      title: props.t("Debugging"),
      align: "center",
      sorter: (a, b, sortOrder) => {
        return a.debugging - b.debugging
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "key",
      title: props.t("GUID"),
      sorter: (a, b) => {
        return stringSorter(a, b, "key")
      },
      width: "280px",
      ellipsis: true,
    },
    AntdTable.SELECTION_COLUMN,
  ]

  const debugDataColumns = [
    {
      dataIndex: "received",
      title: props.t("Time"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["received"], b["received"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "data",
      title: props.t("Data"),
      sorter: (a, b) => {
        return stringSorter(a, b, "data")
      },
      width: "800px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
        {row.debugging == true && (
          <Button
            className="ms-2"
            color="primary"
            outline
            size="sm"
            onClick={() => {
              setDebugModal(true)
              setSelectedRow(row)
            }}
          >
            <i className="mdi mdi-database" />
          </Button>
        )}
      </>
    )
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Last connection"),
        props.t("Online"),
        props.t("Description"),
        props.t("Serial"),
        props.t("SSH ID"),
        props.t("Tags"),
        props.t("Links"),
        props.t("GSM"),
        props.t("IMSI"),
        props.t("ICCID"),
        props.t("Operator"),
        props.t("Zigbee EPID"),
        props.t("SSH connection"),
        props.t("Remote command"),
        props.t("Remote command ran"),
        props.t("Coordinator update"),
        props.t("Coordinator updated"),
        props.t("Battery operated"),
        props.t("IMEI"),
        props.t("IP address"),
        props.t("Virtual"),
        props.t("Host"),
        props.t("Order number"),
        props.t("SIM status"),
        props.t("Network type"),
        props.t("WiFi"),
        props.t("RSSI"),
        props.t("Configured"),
        props.t("GUID"),
      ],
    ]
    const data = filteredGateways.map(elt => [
      dateFormatter(elt.lastConnected),
      isTrue(elt.online),
      elt.description,
      elt.serial,
      elt.sshId,
      elt.groupTags,
      elt.linkedNames,
      elt.gsm,
      elt.imsi,
      elt.iccid,
      elt.operator,
      elt.zigbeeEpId,
      isTrue(elt.sshConnection),
      isTrue(elt.remoteCommand),
      dateFormatter(elt.remoteCommandRan),
      dateFormatter(elt.onlineSince),
      isTrue(elt.coordinatorUpdate),
      dateFormatter(elt.coordinatorUpdated),
      isTrue(elt.batteryOperate),
      elt.imei,
      elt.ipAddress,
      elt.virtual,
      elt.hostKey,
      elt.orderNumber,
      elt.simStatus,
      elt.networkType,
      elt.wifi,
      elt.rssi,
      isTrue(elt.configured),
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Table selection changed
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  // Expanded table row
  const expandedRowRender = record => {
    return (
      <>
        <Row className="mb-1">
          <Col className="col-auto">
            <Button
              size="sm"
              color="transparent"
              onClick={() => {
                setSelectedMessage(record.data)
              }}
            >
              {props.t("Message")}
            </Button>
          </Col>
          <Col className="col-auto">
            <Button
              size="sm"
              color="transparent"
              onClick={() => {
                try {
                  if (record.data?.length > 0) {
                    let rec = JSON.parse(record.data)
                    setSelectedMessage(JSON.stringify(rec, undefined, 4))
                  }
                } catch (err) {}
              }}
            >
              {"JSON"}
            </Button>
          </Col>
        </Row>
        <Input
          type="textarea"
          style={{ width: "75vw", maxWidth: "1000px" }}
          rows={15}
          disabled
          value={selectedMessage}
        />
      </>
    )
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Gateways")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "3"}
                      onClick={() => {
                        setActiveTab("3")
                      }}
                    >
                      {props.t("Other")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Accordion
                    flush
                    open={alertsOpen}
                    toggle={id => {
                      id === alertsOpen ? setAlertsOpen("") : setAlertsOpen(id)
                    }}
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="accordion-zeropadding"
                      >
                        <Alert color="danger">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Duplicate values")}
                            </h5>
                            {duplicates.map((entry, index) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={"" + index}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Multiple links")}
                            </h5>
                            {multilinks.map((entry, index) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={"" + index}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                        </Alert>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <Row>
                    <Col>
                      {gateways != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      value={searchText}
                                      onBlur={() =>
                                        // Apply search word to URL
                                        searchToURL(
                                          searchText,
                                          location,
                                          navigate
                                        )
                                      }
                                      onChange={event =>
                                        setSearchText(event.target.value)
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                className="round-icon-button"
                                id="filter-button"
                                outline
                                onClick={() => {
                                  filtersOpen.length > 0
                                    ? setFiltersOpen("")
                                    : setFiltersOpen("1")
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="filter-button"
                                >
                                  {props.t("Filters")}
                                </UncontrolledTooltip>
                                {filtersOpen ? (
                                  <i className="mdi mdi-filter-minus"></i>
                                ) : (
                                  <i className="mdi mdi-filter-menu"></i>
                                )}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getDevices()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>

                              <Popconfirm
                                title={props.t("Confirm action")}
                                open={createConfirm}
                                okType="primary"
                                okText={props.t("Create")}
                                cancelText={props.t("Cancel")}
                                onConfirm={() => {
                                  createGW()
                                  setCreateConfirm(false)
                                }}
                                onCancel={() => {
                                  setCreateConfirm(false)
                                }}
                              >
                                <Button
                                  className="ms-3"
                                  disabled={ongoingCreate}
                                  style={{ borderRadius: "100px" }}
                                  color="success"
                                  onClick={() => {
                                    setCreateConfirm(true)
                                  }}
                                >
                                  <i className="mdi mdi-plus me-2" />
                                  {props.t("Create new")}
                                </Button>
                              </Popconfirm>

                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="success"
                                onClick={() => {
                                  setImportModal(true)
                                }}
                              >
                                <i className="mdi mdi-import me-2" />
                                {props.t("Import")}
                              </Button>

                              {(duplicates.length > 0 ||
                                multilinks.length > 0) && (
                                <Button
                                  color="danger"
                                  className="round-icon-button ms-3"
                                  id="alerts-button"
                                  onClick={() => {
                                    alertsOpen.length > 0
                                      ? setAlertsOpen("")
                                      : setAlertsOpen("1")
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="alerts-button"
                                  >
                                    {props.t("Notices")}
                                  </UncontrolledTooltip>
                                  {alertsOpen ? (
                                    <i className="mdi mdi-alert-minus"></i>
                                  ) : (
                                    <i className="mdi mdi-alert-plus"></i>
                                  )}
                                </Button>
                              )}
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredGateways.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                className="mb-3"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row xs={1} sm={2} md={3} xl={4}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Status")}</Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="configFilter"
                                        value={configFilter}
                                        onChange={v => {
                                          setConfigFilter(v.target.value)
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {props.t("Configured")}
                                        </option>
                                        <option value="2">
                                          {props.t("Unconfigured")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                  <Col>
                                    <FormGroup>
                                      <Label>
                                        {props.t("Connection status")}
                                      </Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="connectionFilter"
                                        value={connectionFilter}
                                        onChange={v => {
                                          setConnectionFilter(v.target.value)
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {props.t("Online")}
                                        </option>
                                        <option value="2">
                                          {props.t("Offline")}
                                        </option>
                                        <option value="3">
                                          {props.t("Offline recently")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>
                          <Row className="mt-2">
                            <Col xl="12">
                              <AntdTable
                                className="colored-header narrow-header"
                                bordered
                                size="small"
                                sticky
                                rowKey={"key"}
                                columns={columns}
                                dataSource={filteredGateways}
                                rowSelection={{
                                  type: "radio",
                                  fixed: false,
                                  selectedRowKeys: selectedRowKeys,
                                  onChange: onSelectChange,
                                  columnWidth: 0,
                                  renderCell: () => "",
                                }}
                                onRow={(record, rowIndex) => {
                                  return {
                                    onClick: event => {
                                      if (
                                        selectedRowKeys.includes(record.key)
                                      ) {
                                        setSelectedRowKeys([])
                                      } else {
                                        setSelectedRowKeys([record.key])
                                      }
                                    },
                                  }
                                }}
                                pagination={{
                                  showSizeChanger: true,
                                  defaultPageSize: 50,
                                  pageSizeOptions: defaultPageSizeOptions,
                                }}
                                loading={ongoingSearch}
                                tableLayout="auto"
                                scroll={{
                                  x: "max-content",
                                  y: tableDefaultHeight,
                                }}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Configured")}</th>
                        <td>{statistics.configured}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Unconfigured")}</th>
                        <td>{statistics.unconfigured}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Online")}</th>
                        <td>{statistics.online}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Offline")}</th>
                        <td>{statistics.offline}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          {props.t("Offline recently") + " (24h)"}
                        </th>
                        <td>{statistics.offlineRecent}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>

                <TabPane className="show" tabId="3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Installation serials")}</Label>
                        <Input
                          bsSize="sm"
                          name="installationSerials"
                          type="text"
                          value={installationSerials}
                          onChange={v => {
                            setInstallationSerials(v.target.value)
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col className="col-auto">
                      <div className="d-flex" style={{ height: "100%" }}>
                        <Button
                          className="d-inline-block mb-3"
                          style={{ minWidth: "80px", alignSelf: "flex-end" }}
                          color="primary"
                          size="sm"
                          disabled={ongoingSearch}
                          onClick={() => {
                            updateInstallationSerials()
                          }}
                        >
                          {props.t("Set")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={6}></Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <GatewayDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            getGateways={getDevices}
            gatewayKey={selectedRow?.key}
          />

          <Modal
            isOpen={importModal}
            toggle={() => {
              setImportModal(!importModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Import")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("JSON")}</Label>
                  <Input
                    name="json"
                    type="textarea"
                    rows={8}
                    onChange={importValidation.handleChange}
                    onBlur={importValidation.handleBlur}
                    placeholder="[{'SerialNumber': '123', 'MSISDN': '358401234567', 'ICCID': '456', 'IMSI': '789'}]"
                    value={importValidation.values.json || ""}
                    invalid={
                      !!(
                        importValidation.touched.json &&
                        importValidation.errors.json
                      )
                    }
                  ></Input>
                  {importValidation.touched.json &&
                  importValidation.errors.json ? (
                    <FormFeedback type="invalid">
                      {importValidation.errors.json}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setImportModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingCreate}
                  onClick={() => {
                    importValidation.submitForm()
                  }}
                >
                  {props.t("Import")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={debugModal}
            toggle={() => {
              setDebugModal(!debugModal)
            }}
            centered
            size="xl"
          >
            <ModalHeader
              toggle={() => {
                setDebugModal(!debugModal)
              }}
            >
              {props.t("Data logs")}
            </ModalHeader>
            <ModalBody>
              <Row className="mb-2">
                <Col className="col-auto pe-0">
                  <Form
                    className="mt-sm-0 d-flex align-items-center"
                    onSubmit={e => {
                      e.preventDefault()
                      return false
                    }}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                      <div className="position-relative">
                        <Input
                          type="text"
                          value={debugSearchText}
                          onChange={event =>
                            setDebugSearchText(event.target.value)
                          }
                          placeholder={props.t("Search")}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Form>
                </Col>
                <Col className="col-auto">
                  <Button
                    //size="sm"
                    style={{ borderRadius: "100px" }}
                    color="primary"
                    disabled={debugDataLoading}
                    onClick={() => {
                      getDebugData()
                    }}
                  >
                    <i className="mdi mdi-refresh me-2" />
                    {props.t("Refresh data")}
                  </Button>
                </Col>
                <Col className="d-flex">
                  {debugDataLoading && (
                    <span
                      className="text-primary"
                      style={{ alignSelf: "center" }}
                    >
                      <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                      {props.t("Loading")}
                    </span>
                  )}
                </Col>
                <Col></Col>
              </Row>

              <AntdTable
                className="clear-header"
                size="small"
                rowKey={"id"}
                columns={debugDataColumns}
                dataSource={filteredDebugData}
                expandable={{
                  expandedRowKeys: expandedKeys,
                  expandedRowRender: expandedRowRender,
                  onExpand: (expanded, record) => {
                    setSelectedMessage(record.data)

                    let keys = []
                    if (expanded) {
                      keys.push(record.id)
                    }
                    setExpandedKeys(keys)
                  },
                  showExpandColumn: false,
                  expandRowByClick: true,
                }}
                pagination={{
                  showSizeChanger: true,
                  defaultPageSize: 100,
                  pageSizeOptions: defaultPageSizeOptions,
                }}
                tableLayout="auto"
                scroll={{
                  x: "max-content",
                  y: tableDefaultHeight,
                }}
              />
            </ModalBody>
          </Modal>
        </Container>
      </div>
    </>
  )
}

GatewayConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(GatewayConfigs)
