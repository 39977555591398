import React, { useState, useRef, useEffect } from "react"
import { Modal, ModalBody, Button } from "reactstrap"
import { Popconfirm } from "antd"
import PropTypes from "prop-types"
import {
  GetGroupCallFilters,
  AddGroupCallFilter,
  EditGroupCallFilter,
  DeleteGroupCallFilter,
} from "helpers/backend_helper"
import { showToast } from "utils/general"
import SelectCustomersTree from "components/DropdownList/SelectCustomersTree"

const GroupCallFilters = props => {
  const [editModal, setEditModal] = useState(false)
  const [collapseFilters, setCollapseFilters] = useState(true)
  const [newFilterName, setNewFilterName] = useState("")
  const [filterList, setFilterList] = useState([])
  const [action, setAction] = useState(false)
  const [checkedKeys, setCheckedKeys] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    getFilters()
    return () => {
      abortRef.current.abort()
    }
  }, [])

  // If filter list has items not in the parent components list, notify user of not all filter items being shown
  useEffect(() => {
    if (props.selectedFilterKey && checkedKeys) {
      const flattenedKeys = flattenTree(props.treeCustomers)
      const notAllShown = checkedKeys.some(key => !flattenedKeys.includes(key))
      props.setNotAllShown(notAllShown)
    } else {
      props.setNotAllShown(false)
    }
  }, [props.selectedFilterKey, checkedKeys, props.treeCustomers])

  const flattenTree = nodes =>
    nodes.reduce((acc, node) => {
      acc.push(node.key)
      if (node.children) {
        acc.push(...flattenTree(node.children))
      }
      return acc
    }, [])

  // Open edit modal
  const openEditModal = () => {
    setEditModal(true)
  }
  // Close edit modal
  const closeEditModal = () => {
    setEditModal(false)
  }

  // Handle filter selection
  const handleFilterSelect = filter => {
    clearValues()
    if (props.selectedFilterKey !== filter.key) {
      props.setSelectedFilterKey(filter.key) // Select the clicked filter
      setCheckedKeys(filter.selections) // Set existing filters selections to trees checked keys
    } else {
      // Deselect if the same filter is clicked again
      props.setSelectedFilterKey(null)
      setCheckedKeys(null)
    }

    // Filter list by selected filter
    const updatedGuests = applyFilter(props.treeCustomers, filter.selections)
    props.setFilteredTree(updatedGuests)
  }

  // Handle filter selection
  const clearValues = () => {
    props.setTreeSelections(null)
    props.setFinalSelection([])
    setNewFilterName("")
  }

  // Edit filter
  const getFilters = () => {
    setAction(true)
    props.setSelectedFilterKey(null)

    GetGroupCallFilters(abortRef.current)
      .then(result => {
        if (result.statusCode == 200) {
          console.log("result:", result)

          // Transform the result to correct structure
          const filters = result.entries
            .map(entry => ({
              key: entry.key,
              name: entry.name,
              selections: JSON.parse(entry.selections),
            }))
            .sort((a, b) => a.name.localeCompare(b.name))

          setFilterList(filters)
        } else {
          showToast(props.t("Error") + ": " + result.reason, "error")
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setAction(false)
      })
  }

  // Add a new filter
  const addFilter = () => {
    if (!action) {
      setAction(true)
      closeEditModal()

      const selections = JSON.stringify(checkedKeys)

      AddGroupCallFilter(abortRef.current, newFilterName, selections)
        .then(result => {
          if (result.statusCode == 200) {
            getFilters()
            showToast(props.t("Created"), "success")
          } else {
            showToast(props.t("Error") + ": " + result.reason, "error")
          }
        })
        .catch(err => {
          console.log(err)
          setNewFilterName("")
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Edit filter
  const editFilter = () => {
    if (props.selectedFilterKey && !action) {
      setAction(true)
      closeEditModal()

      const selections = JSON.stringify(checkedKeys)

      EditGroupCallFilter(
        abortRef.current,
        newFilterName,
        selections,
        props.selectedFilterKey
      )
        .then(result => {
          if (result.statusCode == 200) {
            getFilters()
            showToast(props.t("Updated"), "success")
          } else {
            showToast(props.t("Error") + ": " + result.reason, "error")
          }
        })
        .catch(err => {
          console.log(err)
          setNewFilterName("")
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Delete filter
  const deleteFilter = () => {
    if (props.selectedFilterKey && !action) {
      setAction(true)
      closeEditModal()

      DeleteGroupCallFilter(abortRef.current, props.selectedFilterKey)
        .then(result => {
          if (result.statusCode == 200) {
            getFilters()
            showToast(props.t("Deleted"), "success")
          } else {
            showToast(props.t("Error") + ": " + result.reason, "error")
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  return (
    <>
      <div
        className="filter-buttons d-flex align-items-center flex-wrap"
        style={{
          maxWidth: "calc(100% - 160px)",
          gap: "5px",
        }}
      >
        {/* Filter menu button */}
        <Button
          color="primary"
          outline={collapseFilters}
          style={{
            borderRadius: "15px",
            width: "50px",
            height: "25px",
            padding: "0",
            fontSize: "12px",
            fontWeight: "bold",
            borderWidth: "2px",
          }}
          onClick={() => {
            setCollapseFilters(!collapseFilters)
            clearValues()
            props.setSelectedFilterKey(null)
            setCheckedKeys(null)
          }}
        >
          <i className="mdi mdi-filter-menu"></i>
        </Button>

        {/* Filter items */}
        {!collapseFilters &&
          filterList.map(filter => (
            <Button
              key={filter.key}
              color="primary"
              outline={props.selectedFilterKey !== filter.key}
              style={{
                borderRadius: "15px",
                width: "auto",
                height: "25px",
                padding: "0 10px",
                fontSize: "12px",
                // fontWeight: "500",
                borderWidth: "2px",
              }}
              onClick={() => {
                handleFilterSelect(filter)
              }}
            >
              {filter.name}
            </Button>
          ))}

        {/* Add filter button */}
        {!collapseFilters && filterList.length < 30 && (
          <Button
            color="success"
            outline
            style={{
              borderRadius: "15px",
              width: "30px",
              height: "25px",
              padding: "0",
              fontSize: "20px",
              fontWeight: "500",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderWidth: "2px",
            }}
            onClick={() => {
              openEditModal()
              props.setSelectedFilterKey(null)
              clearValues()
              setCheckedKeys(null)
            }}
          >
            +
          </Button>
        )}

        {/* Edit filter button */}
        {!collapseFilters && props.selectedFilterKey && (
          <Button
            color="tertiary"
            outline
            style={{
              borderRadius: "15px",
              borderWidth: "2px",
              width: "50px",
              height: "25px",
              padding: "0",
              fontSize: "12px",
              fontWeight: "bold",
            }}
            onClick={() => {
              openEditModal()
              clearValues()
            }}
          >
            <i className="mdi mdi-pencil"></i>
          </Button>
        )}
      </div>

      {/* Add/Edit/Delete modal */}
      <Modal isOpen={editModal} toggle={closeEditModal}>
        <ModalBody style={{ position: "relative" }}>
          <h4 style={{ fontSize: "1.25rem", marginBottom: "1rem" }}>
            {!props.selectedFilterKey
              ? props.t("Add Filter")
              : props.t("Edit Filter")}
          </h4>

          {/* Name Input */}
          <div style={{ marginBottom: "1rem" }}>
            <input
              id="filter-name"
              type="text"
              className="form-control"
              value={newFilterName}
              onChange={e => setNewFilterName(e.target.value)}
              maxLength={20}
              placeholder={
                props.selectedFilterKey?.name || props.t("New filter name")
              }
              style={{
                borderColor: newFilterName.length === 20 ? "red" : "",
                color: newFilterName.length === 20 ? "red" : "",
              }} // Highlight when limit reached
            />
            {newFilterName.length === 20 && (
              <small style={{ color: "red" }}>
                {props.t("Character limit reached")}
              </small>
            )}
          </div>

          <SelectCustomersTree
            checkedKeys={checkedKeys}
            setCheckedKeys={setCheckedKeys}
            expandAll
            data={props.groupGuests}
          />

          {/* Save and Cancel Buttons */}
          <div
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Delete Confirmation using Popconfirm */}
            {props.selectedFilterKey && (
              <Popconfirm
                title={props.t("DeleteFilterConfirmation")}
                onConfirm={() => {
                  deleteFilter()
                  closeEditModal()
                  setCheckedKeys(null)
                  setNewFilterName("")
                }}
                okText={props.t("Delete")}
                cancelText={props.t("Cancel")}
                okType="danger"
                cancelButtonProps={{
                  style: { color: "primary" },
                }}
              >
                <button type="button" className="btn btn-danger">
                  {props.t("Delete")}
                </button>
              </Popconfirm>
            )}

            {/* Save and Cancel Buttons */}
            <div style={{ display: "flex", gap: "10px" }}>
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={closeEditModal}
              >
                {props.t("Cancel")}
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  if (!props.selectedFilterKey && !newFilterName.trim()) {
                    showToast(props.t("Please enter a name"), "error")
                    return
                  }
                  !props.selectedFilterKey ? addFilter() : editFilter()
                }}
              >
                {props.t("Save")}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

GroupCallFilters.propTypes = {
  t: PropTypes.any,
  treeCustomers: PropTypes.any,
  groupGuests: PropTypes.any,
  selectedFilterKey: PropTypes.any,
  setSelectedFilterKey: PropTypes.any,
  setFilteredTree: PropTypes.any,
  setFinalSelection: PropTypes.any,
  setTreeSelections: PropTypes.func,
  setNotAllShown: PropTypes.any,
}

export default GroupCallFilters

export const applyFilter = (guests, filter) => {
  if (!filter || filter.length === 0) {
    return guests // Return all guests if no filter is applied
  }

  // Recursive function to filter the tree structure
  const filterTree = nodes => {
    return nodes
      .map(node => {
        const matchesFilter = filter.includes(node.key) // Check if the node matches
        const filteredChildren = node.children ? filterTree(node.children) : []

        // Include node if it matches or has filtered children
        if (matchesFilter || filteredChildren.length > 0) {
          return {
            ...node,
            children: filteredChildren,
          }
        }

        return null // Exclude node if it doesn't match or have filtered children
      })
      .filter(node => node !== null) // Remove null values
  }

  return filterTree(guests)
}
