import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer, Switch } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import {
  generateStrongPassword,
  getLottieLoadingOptions,
  showToast,
} from "utils/general"
import {
  sendLoginCredentials,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import withRouter from "components/Common/withRouter"
import { disableKeycloakUser, getKeycloakUser, setTemporaryUserPassword, updateKeycloakUser } from "helpers/keycloak_api_helper"

const OrganizationUserDrawer = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [userData, setUserData] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [ongoingRequest, setOngoingRequest] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.selectedRow) {
      setNewPassword("")
      getData()
    }
  }, [props.selectedRow])

  useEffect(() => {
    if (!props.showDrawer) {
      setActiveTab("1")
    }
  }, [props.showDrawer])

  // Get admins data
  const getData = () => {
    setUserData(null)
    if (props.selectedRow?.id) {
      setLoading(true)
      getKeycloakUser(abortRef.current, props.selectedRow.id)
        .then(result => {
          console.log(result)
          if (result.id) {
            if (result.attributes) {
              result.description = result.attributes.description ? result.attributes.description[0] : ""
              result.gsm = result.attributes.gsm ? result.attributes.gsm[0] : ""
              result.videoRoomId = result.attributes.videoRoomId ? result.attributes.videoRoomId[0] : ""
              result.videoRoomDemoId = result.attributes.videoRoomDemoId ? result.attributes.videoRoomDemoId[0] : ""
              result.videoNickname = result.attributes.videoNickname ? result.attributes.videoNickname[0] : ""
              result.alarmCenterType = result.attributes.alarmCenterType ? result.attributes.alarmCenterType[0] : ""
              result.supportType = result.attributes.supportType ? result.attributes.supportType[0] : ""
              result.unit = result.attributes.unit ? result.attributes.unit[0] : ""
            }
            setUserData(result)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: userData?.description || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      gsm: userData?.gsm || "",
      email: userData?.email || "",
      emailVerified: userData?.emailVerified || false,
      videoNickname: userData?.videoNickname || "",
      basicUser: userData?.basicUser || false,
      unit: userData?.unit || "",
      //enabled: userData?.enabled || false,
    },
    validationSchema: Yup.object({
      videoNickname: Yup.string().matches(
        /^[a-zA-Z0-9\s\-()!?&.,+#äöåøæÄÖÅÆØ]*$/,
        props.t("ContainsNotAllowedCharacters")
      ),
    }),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setSuccess("")
      setError("")
      if (userData?.id && !update) {
        let temp = JSON.parse(JSON.stringify(values))
        setUpdate(true)
        updateKeycloakUser(abortRef.current, userData.id, temp)
          .then(result => {
            if (result == "OK") {
              setSuccess("Updated")
              getData()
              props.getAdmins()
            } else {
              setError("Error")
            }
          })
          .catch(err => {
            console.log(err)
            setError("Error")
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  // Disable user
  const disableUser = () => {
    if (userData?.id && !ongoingRequest && !loading) {
      setOngoingRequest(true)
      disableKeycloakUser(abortRef.current, userData.id)
        .then(result => {
          if (result == "OK") {
            showToast(props.t("Success"), "success")
            setDeleteModal(false)
            props.getAdmins()
            props.setShowDrawer(false)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingRequest(false)
        })
    }
  }

  // Send credentials
  // Update Keycloak user first, then send data to TW
  const sendCredentials = () => {
    if (userData?.username && userData?.id && newPassword?.length >= 14 && !update) {
      setUpdate(true)
      setTemporaryUserPassword(abortRef.current, userData.id, newPassword)
        .then(result => {
          if (result == "OK") {
            sendLoginCredentials(
              abortRef.current,
              userData.username,
              newPassword,
              userData.email,
              userData.gsm
            )
              .then(result => {
                if (result.statusCode == 200) {
                  showToast(props.t("Sent"), "success")
                } else {
                  showToast(props.t("Error"), "error")
                }
              })
              .catch(err => {
                console.log(err)
                showToast(props.t("Error"), "error")
              })
          }
          else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  return (
    <>
      <Drawer
        title={props.selectedRow?.username}
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
      >

        <TabContent activeTab={activeTab} className="">
          <TabPane className="show" tabId="1">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Information")}
                    </CardTitle>
                    {loading && (
                      <Lottie
                        options={getLottieLoadingOptions()}
                        height={100}
                        width={100}
                      ></Lottie>
                    )}
                    {!loading && (
                      <Form
                        autoComplete="nope"
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">{props.t(error)}</Alert>
                        ) : null}
                        {success ? (
                          <Alert color="success">{props.t(success)}</Alert>
                        ) : null}
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Description")}</Label>
                              <Input
                                bsSize="sm"
                                name="description"
                                type="text"
                                value={validation.values.description}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row xs={1} xl={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("First name")}</Label>
                              <Input
                                bsSize="sm"
                                name="firstName"
                                type="text"
                                value={validation.values.firstName}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Last name")}</Label>
                              <Input
                                bsSize="sm"
                                name="lastName"
                                type="text"
                                value={validation.values.lastName}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("GSM")}</Label>
                              <Input
                                bsSize="sm"
                                name="gsm"
                                type="text"
                                value={validation.values.gsm}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Email")}</Label>
                              <Input
                                bsSize="sm"
                                name="email"
                                type="text"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Unit info")}</Label>
                              <Input
                                bsSize="sm"
                                name="unit"
                                type="text"
                                value={validation.values.unit}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                {props.t("Video call display name")}
                              </Label>
                              <Input
                                bsSize="sm"
                                name="videoNickname"
                                type="text"
                                value={validation.values.videoNickname}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  !!(
                                    validation.touched.videoNickname &&
                                    validation.errors.videoNickname
                                  )
                                }
                              ></Input>
                              {validation.touched.videoNickname &&
                                validation.errors.videoNickname ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.videoNickname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row xs={1} xl={2}>
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="basicUser"
                                checked={validation.values.basicUser}
                                onChange={v => {
                                  validation.setFieldValue("basicUser", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Professional")}</Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="emailVerified"
                                checked={validation.values.emailVerified}
                                onChange={v => {
                                  validation.setFieldValue("emailVerified", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Email verified")}</Label>
                            </FormGroup>
                          </Col>
                          {/** 
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="enabled"
                                checked={validation.values.enabled}
                                onChange={v => {
                                  validation.setFieldValue("enabled", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Enabled")}</Label>
                            </FormGroup>
                          </Col>*/}
                        </Row>

                        <div className="mt-2 mb-3 d-flex justify-content-center">
                          <Button
                            color="primary"
                            disabled={update || !userData?.enabled}
                            style={{ minWidth: "150px" }}
                            onClick={() => {
                              validation.submitForm()
                            }}
                          >
                            {props.t("Save")}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Send login credentials")}</h6>
                              <span className="text-muted">
                                {props.t("Email & GSM required")}
                              </span>
                              <Row className="mt-2">
                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="newPassword"
                                    type="text"
                                    placeholder={props.t("Set new password")}
                                    value={newPassword}
                                    onChange={v => {
                                      setNewPassword(v.target.value)
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    id="generatePasswordButtonOne"
                                    className="p-0"
                                    color="transparent"
                                    outline
                                    size="sm"
                                    onClick={() => {
                                      let pw = generateStrongPassword(14)
                                      setNewPassword(pw)
                                    }}
                                  >
                                    <i className="mdi mdi-shield-refresh text-primary font-size-18"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="primary"
                              style={{ minWidth: "100px" }}
                              disabled={
                                update ||
                                !userData?.email ||
                                !userData?.gsm ||
                                newPassword.length < 14
                              }
                              onClick={() => {
                                sendCredentials()
                              }}
                            >
                              {props.t("Send")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Set temporary password")}</h6>
                              <span className="text-muted">
                                {props.t("SetTemporaryPasswordDescription")}
                              </span>
                              <Row className="mt-2">
                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="newPassword"
                                    type="text"
                                    placeholder={props.t("Set new password")}
                                    value={newPassword}
                                    onChange={v => {
                                      setNewPassword(v.target.value)
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    id="generatePasswordButtonOne"
                                    className="p-0"
                                    color="transparent"
                                    outline
                                    size="sm"
                                    onClick={() => {
                                      let pw = generateStrongPassword(14)
                                      setNewPassword(pw)
                                    }}
                                  >
                                    <i className="mdi mdi-shield-refresh text-primary font-size-18"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="primary"
                              style={{ minWidth: "100px" }}
                              disabled={
                                update || loading || !userData?.id ||
                                newPassword.length < 14
                              }
                              onClick={() => {
                                if (!update && userData?.id && newPassword?.length >= 14) {
                                  setUpdate(true)
                                  setTemporaryUserPassword(abortRef.current, userData.id, newPassword)
                                    .then(result => {
                                      if (result == "OK") {
                                        showToast(props.t("Password set"), "success")
                                      }
                                      else {
                                        showToast(props.t("Error"), "error")
                                      }
                                    })
                                    .catch(err => {
                                      console.log(err)
                                      showToast(props.t("Error"), "error")
                                    })
                                    .finally(() => {
                                      setUpdate(false)
                                    })
                                }
                              }}
                            >
                              {props.t("Set")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("User deletion")}</h6>
                              <span className="text-muted">
                                {props.t("UserDeletionDescription")}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="danger"
                              style={{ minWidth: "100px" }}
                              disabled={ongoingRequest || loading || !userData?.id || !userData?.enabled}
                              onClick={() => {
                                setDeleteModal(true)
                              }}
                            >
                              {props.t("Delete")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("User deletion")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              userData?.username +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingRequest}
            color="danger"
            onClick={() => {
              disableUser()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

OrganizationUserDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getAdmins: PropTypes.func,
}

export default withRouter(withTranslation()(OrganizationUserDrawer))