import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Modal,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import RegisterVideoRoomModal from "./RegisterVideoRoomModal"
import VideoPasswordModal from "./VideoPasswordModal"
import VideoRoomDrawer from "./VideoRoomDrawer"
import { getGroupLicenseStats, getVideoRooms } from "helpers/backend_helper"
import { Table as AntdTable } from "antd"
import {
  showToast,
  stringSorter,
  downloadExcel,
  isTrue,
  dateFormatter,
  numberSorter,
  dateSorter,
  defaultPageSizeOptions,
  searchToURL,
} from "utils/general"
import SelectGroupFilters from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import jsPDF from "jspdf"
import { tableDefaultHeight } from "constants/layout"
import { clientLinksFormatter } from "utils/tables"

const VideoRooms = props => {
  const [rooms, setRooms] = useState([])
  const [filteredRooms, setFilteredRooms] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [createModal, setCreateModal] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [hostModal, setHostModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)
  const [selectedRoom, setSelectedRoom] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")

  const [platformFilter, setPlatformFilter] = useState("0")
  const [statusFilter, setStatusFilter] = useState("")
  const [roleFilter, setRoleFilter] = useState("")

  const [newRooms, setNewRooms] = useState(null)
  const [multilinks, setMultilinks] = useState([])
  const [statistics, setStatistics] = useState({
    total: 0,
  })
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [licenseMenu, setLicenseMenu] = useState(false) //Dropdown button state
  const [title] = useState(props.t("Video numbers"))
  const [selectedTags, setSelectedTags] = useState([])
  const [groups, setGroups] = useState([])
  const [loadingLicenses, setLoadingLicenses] = useState(false)
  const [licenseData, setLicenseData] = useState({
    guests: 0,
    hosts: 0,
    entries: [],
  })

  const search = useLocation().search
  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()

    // Get search text from URL param
    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    getRooms()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let source = rooms
    let demoRooms = source.filter(data => data.roomName.indexOf("7299") == 0)
    let prodRooms = source.filter(data => data.roomName.indexOf("7254") == 0)

    let temp = new Object()
    temp.total = source.length
    temp.demo = demoRooms.length
    temp.production = prodRooms.length

    temp.linked = source.filter(data => data.links.length > 0).length
    temp.linked_prod = prodRooms.filter(data => data.links.length > 0).length
    temp.linked_demo = demoRooms.filter(data => data.links.length > 0).length

    temp.callback = source.filter(data => data.callbackEnabled).length
    temp.callback_prod = prodRooms.filter(data => data.callbackEnabled).length
    temp.callback_demo = demoRooms.filter(data => data.callbackEnabled).length
    temp.guests = source.filter(data => data.groupCallGuest).length
    temp.guests_prod = prodRooms.filter(data => data.groupCallGuest).length
    temp.guests_demo = demoRooms.filter(data => data.groupCallGuest).length
    temp.hosts = source.filter(data => data.groupCallHost).length
    temp.hosts_prod = prodRooms.filter(data => data.groupCallHost).length
    temp.hosts_demo = demoRooms.filter(data => data.groupCallHost).length

    temp.offline = source.filter(
      data => data.statusDescription.indexOf("Offline") == 0
    ).length
    temp.offline_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Offline") == 0
    ).length
    temp.offline_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Offline") == 0
    ).length
    temp.online = source.filter(
      data => data.statusDescription.indexOf("Online") == 0
    ).length
    temp.online_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Online") == 0
    ).length
    temp.online_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Online") == 0
    ).length
    temp.call = source.filter(
      data => data.statusDescription.indexOf("Call") == 0
    ).length
    temp.call_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Call") == 0
    ).length
    temp.call_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Call") == 0
    ).length
    temp.groupcall = source.filter(
      data => data.statusDescription.indexOf("Group") == 0
    ).length
    temp.groupcall_prod = prodRooms.filter(
      data => data.statusDescription.indexOf("Group") == 0
    ).length
    temp.groupcall_demo = demoRooms.filter(
      data => data.statusDescription.indexOf("Group") == 0
    ).length

    temp.prodVideos = 0
    temp.prodGroups = 0
    temp.prodHealth = 0

    setStatistics(temp)
  }, [rooms])

  // Check multiple links
  useEffect(() => {
    if (rooms) {
      let multis = []
      rooms.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.roomName, name: element.roomName })
        }
      })
      setMultilinks(multis)
    }
  }, [rooms])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0) setAlertsOpen("")
  }, [multilinks])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, platformFilter, statusFilter, roleFilter, rooms])

  // Handle search function
  const handleSearch = () => {
    let plat = ""
    if (platformFilter == "1") plat = "7254"
    else if (platformFilter == "2") plat = "7299"

    let stat = ""
    if (statusFilter == "1") stat = "Offline"
    else if (statusFilter == "2") stat = "Online"
    else if (statusFilter == "3") stat = "Call"
    else if (statusFilter == "4") stat = "Group"

    let searchTextLowercase = searchText.toLowerCase()
    let result = rooms.filter(
      data =>
        data.roomName?.indexOf(plat) == 0 &&
        data.statusDescription?.indexOf(stat) == 0 &&
        (roleFilter === "" ||
          (roleFilter === "host" && data.groupCallHost) ||
          (roleFilter === "guest" && data.groupCallGuest) ||
          (roleFilter === "neither" &&
            !data.groupCallHost &&
            !data.groupCallGuest)) &&
        (data.roomName?.toLowerCase().includes(searchTextLowercase) ||
          data.clientName?.includes(searchText) ||
          data.clientEmail?.toLowerCase().includes(searchTextLowercase) ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.includes(searchText) ||
          data.groupTags?.toLowerCase().includes(searchTextLowercase) ||
          data.hostName?.includes(searchText) ||
          data.hostGroups?.includes(searchText))
    )
    setFilteredRooms(result)
    //console.log(result)
  }

  // Get video rooms list
  const getRooms = () => {
    const apiDurationStart = performance.now() // Time the api call
    setOngoingSearch(true)
    getVideoRooms(abortRef.current)
      .then(result => {
        if (result.statusCode === 200 && result.entries != null) {
          //console.log(result, "rooms")
          result.entries.forEach(element => {
            element.linkedNames = getLinkedNames(element.links)

            // Room status description
            switch(element.roomStatus) {
              case 0: element.statusDescription = "Offline"; break;
              case 1: element.statusDescription = "Online"; break;
              case 2: element.statusDescription = "Call"; break;
              case 3: element.statusDescription = "Group"; break;
              default: element.statusDescription = ""; break;
            }
          })
          //console.log(result.entries, "rooms")
          setRooms(result.entries)
        } else {
          setRooms([])
        }
        setOngoingSearch(false)
        const apiDurationEnd = performance.now()
        const durationInSeconds = (
          (apiDurationEnd - apiDurationStart) /
          1000
        ).toFixed(3)
        console.log(`API Call Duration: ${durationInSeconds} seconds`)
      })
      .catch(err => {
        console.log(err)
        setRooms([])
        setOngoingSearch(false)
      })
  }

  // Get linked names in proper format
  const getLinkedNames = links => {
    let linkedNames = []
    if (links) {
      links.forEach(link => {
        linkedNames.push(link.name)
      })
    }
    return linkedNames.join()
  }

  // Get group licenses for selected groups
  const getLicenses = () => {
    if (!loadingLicenses) {
      setLoadingLicenses(true)
      getGroupLicenseStats(abortRef.current, groups, selectedTags.toString())
        .then(result => {
          if (result.statusCode === 200 && result.entries != null) {
            setLicenseData(result)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingLicenses(false)
        })
    }
  }

  const roomsCreated = arr => {
    setNewRooms(arr)
  }

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "roomName",
      title: props.t("Number"),
      render: roomFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "roomName", sortOrder)
      },
      fixed: "left",
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "serial", sortOrder)
      },
      width: "165px",
      ellipsis: true,
    },
    {
      dataIndex: "statusDescription",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "statusDescription", sortOrder)
      },
      render: cell => {
        return cell == "Offline" ? (
          <span style={{ color: "#f46a6a" }}>{cell}</span>
        ) : (
          cell
        )
      },
      width: "90px",
      ellipsis: true,
    },

    {
      dataIndex: "clientName",
      title: props.t("Name"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "clientName", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "groupTags",
      title: props.t("Tags"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groupTags", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: clientLinksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "clientEmail",
      title: props.t("Email"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "clientEmail", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "clientPassword",
      title: props.t("Password"),
      align: "center",
      render: passwordFormatter,
      sorter: (a, b) => {
        return a.clientPassword - b.clientPassword
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "groupCallHost",
      title: props.t("Group host"),
      align: "center",
      sorter: (a, b) => {
        return a.groupCallHost - b.groupCallHost
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "groupCallGuest",
      title: props.t("Group guest"),
      align: "center",
      sorter: (a, b) => {
        return a.groupCallGuest - b.groupCallGuest
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "callbackEnabled",
      title: props.t("Callback"),
      align: "center",
      sorter: (a, b) => {
        return a.callbackEnabled - b.callbackEnabled
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "135px",
      ellipsis: true,
    },
    {
      dataIndex: "feedbackEnabled",
      title: props.t("Feedback"),
      align: "center",
      sorter: (a, b) => {
        return a.feedbackEnabled - b.feedbackEnabled
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "135px",
      ellipsis: true,
    },
    {
      dataIndex: "hostName",
      title: props.t("Host user"),
      render: hostFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "hostName", sortOrder)
      },
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "idleDisplayMode",
      title: props.t("Idle display"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(
          a["idleDisplayMode"],
          b["idleDisplayMode"],
          sortOrder
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "callRequestTimeout",
      title: props.t("Timeout"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(
          a["callRequestTimeout"],
          b["callRequestTimeout"],
          sortOrder
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "primaryGroupId",
      title: props.t("Group"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["primaryGroupId"], b["primaryGroupId"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "statusTime",
      title: props.t("Status updated"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["statusTime"], b["statusTime"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "lastOnline",
      title: props.t("Last online"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastOnline"], b["lastOnline"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "lastOffline",
      title: props.t("Last offline"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastOffline"], b["lastOffline"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    AntdTable.SELECTION_COLUMN,
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function passwordFormatter(cell, row) {
    if (row.clientPassword) {
      return (
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setPasswordModal(true)
            setSelectedRoom(row)
          }}
        >
          <i className="mdi mdi-qrcode-scan" />
        </Button>
      )
    } else {
      return <></>
    }
  }

  function roomFormatter(cell, row) {
    return (
      <>
        <span>{row.roomName}</span>
        {row.groupCallGuest && (
          <i className="ms-2 mdi mdi-account-group text-primary" />
        )}
        {row.groupCallHost && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function hostFormatter(cell, row) {
    if (row.hostName && row.hostName.length > 0) {
      return (
        <>
          <Button
            className="me-2"
            color="primary"
            outline
            size="sm"
            onClick={() => {
              setHostModal(true)
              setSelectedRow(row)
            }}
          >
            <i className="mdi mdi-account-group" />
          </Button>
          <span>{row.hostName}</span>
        </>
      )
    } else {
      return <></>
    }
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Number"),
        props.t("Serial"),
        props.t("Status"),
        //props.t("Ticket"),
        props.t("Name"),
        props.t("Tags"),
        props.t("Links"),
        props.t("Email"),
        props.t("Group host"),
        props.t("Group guest"),
        props.t("Callback"),
        props.t("Idle display"),
        props.t("Timeout"),
        props.t("Host user"),
        //props.t("Client status"),
        //props.t("Client count"),
        props.t("Status updated"),
        props.t("Last online"),
        props.t("Last offline"),
      ],
    ]
    const data = filteredRooms.map(elt => [
      elt.roomName,
      elt.serial,
      elt.statusDescription,
      //elt.offlineTicketNumber,
      elt.clientName,
      elt.groupTags,
      elt.linkedNames,
      elt.clientEmail,
      isTrue(elt.groupCallHost),
      isTrue(elt.groupCallGuest),
      isTrue(elt.callbackEnabled),
      elt.idleDisplayMode,
      elt.callRequestTimeout,
      elt.hostName,
      //elt.clientStatus,
      //elt.activeClientCount,
      dateFormatter(elt.statusTime),
      dateFormatter(elt.lastOnline),
      dateFormatter(elt.lastOffline),
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Generate licenses excel
  const generateLicenseExcel = () => {
    const heading = [
      [
        props.t("Group"),
        props.t("Group hosts"),
        props.t("Group hosts"),
        props.t("Group guests"),
        props.t("Group guests"),
      ],
    ]

    let data = licenseData.entries?.map(entry => [
      entry.group,
      entry.hosts,
      entry.hostAccounts.join(", "),
      entry.guests,
      entry.guestClients.join(", "),
    ])
    if (licenseData?.entries.length > 1) {
      data.unshift([
        props.t("All"),
        licenseData?.hosts,
        licenseData?.hostAccounts?.join(", "),
        licenseData?.guests,
        licenseData?.guestClients?.join(", "),
      ])
    }
    downloadExcel(props.t("Group call licenses"), heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Generate licenses PDF
  const exportLicensesPDF = () => {
    const doc = new jsPDF("landscape", "pt", "A4", "grid")
    doc.setFontSize(14)

    let data = licenseData.entries?.map(entry => [
      entry.group,
      entry.hosts,
      entry.hostAccounts.join(", "),
      entry.guests,
      entry.guestClients.join(", "),
    ])
    if (licenseData?.entries.length > 1) {
      data.unshift([
        props.t("All"),
        licenseData?.hosts,
        licenseData?.hostAccounts?.join(", "),
        licenseData?.guests,
        licenseData?.guestClients?.join(", "),
      ])
    }

    let content = {
      startY: 50,
      head: [
        [
          props.t("Group"),
          props.t("Group hosts"),
          props.t("Group hosts"),
          props.t("Group guests"),
          props.t("Group guests"),
        ],
      ],
      body: data,
    }

    doc.text(props.t("Group call licenses"), 40, 40)
    doc.autoTable(content)
    doc.save(props.t("Group call licenses") + ".pdf")
    //Show toast
    showToast(props.t("PDF file exported succesfully!"))
  }

  const tagsChanged = newTag => {
    if (!newTag) setSelectedTags([])
    else setSelectedTags([newTag])
  }

  // Table selection changed
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Video numbers")} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-flex flex-wrap">
                    <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                      <NavItem>
                        <NavLink
                          to="#"
                          active={activeTab === "1"}
                          onClick={() => {
                            setActiveTab("1")
                          }}
                        >
                          {props.t("Table")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "2"}
                          onClick={() => {
                            setActiveTab("2")
                          }}
                        >
                          {props.t("Statistics")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "3"}
                          onClick={() => {
                            setActiveTab("3")
                          }}
                        >
                          {props.t("Group call licenses")}
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                  {/** 
                  <Link to="/config/video-devices" className="float-end">devs</Link>
                  */}
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane className="show" tabId="1">
                      {newRooms && newRooms.length > 0 && (
                        <Alert color="info">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Registered numbers")}
                            </h5>
                            <span>{newRooms}</span>
                          </div>
                        </Alert>
                      )}

                      <Accordion
                        flush
                        open={alertsOpen}
                        toggle={id => {
                          id === alertsOpen
                            ? setAlertsOpen("")
                            : setAlertsOpen(id)
                        }}
                      >
                        <AccordionItem>
                          <AccordionBody
                            accordionId="1"
                            className="accordion-zeropadding"
                          >
                            <Alert color="danger">
                              <div>
                                <h5 className="alert-heading">
                                  {props.t("Multiple links")}
                                </h5>
                                {multilinks.map((entry, index) => (
                                  <Button
                                    size="sm"
                                    color="danger"
                                    outline
                                    key={"" + index}
                                    className="me-2 mb-2"
                                    onClick={() => {
                                      setSearchText(entry.key)
                                    }}
                                  >
                                    <span>{entry.name}</span>
                                  </Button>
                                ))}
                              </div>
                            </Alert>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      <Row>
                        <Col>
                          {rooms != null && (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          value={searchText}
                                          onBlur={() =>
                                            // Apply search word to URL
                                            searchToURL(
                                              searchText,
                                              location,
                                              navigate
                                            )
                                          }
                                          onChange={event =>
                                            setSearchText(event.target.value)
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    color="primary"
                                    className="round-icon-button"
                                    id="filter-button"
                                    outline
                                    onClick={() => {
                                      filtersOpen.length > 0
                                        ? setFiltersOpen("")
                                        : setFiltersOpen("1")
                                    }}
                                  >
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="filter-button"
                                    >
                                      {props.t("Filters")}
                                    </UncontrolledTooltip>
                                    {filtersOpen ? (
                                      <i className="mdi mdi-filter-minus"></i>
                                    ) : (
                                      <i className="mdi mdi-filter-menu"></i>
                                    )}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      getRooms()
                                    }}
                                  >
                                    <i className="mdi mdi-refresh me-2" />
                                    {props.t("Refresh data")}
                                  </Button>
                                  <Button
                                    className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="success"
                                    onClick={() => {
                                      setCreateModal(true)
                                    }}
                                  >
                                    <i className="mdi mdi-plus me-2" />
                                    {props.t("Register numbers")}
                                  </Button>
                                </Col>
                                {multilinks.length > 0 && (
                                  <Button
                                    color="danger"
                                    className="round-icon-button ms-3"
                                    id="alerts-button"
                                    onClick={() => {
                                      alertsOpen.length > 0
                                        ? setAlertsOpen("")
                                        : setAlertsOpen("1")
                                    }}
                                  >
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="alerts-button"
                                    >
                                      {props.t("Notices")}
                                    </UncontrolledTooltip>
                                    {alertsOpen ? (
                                      <i className="mdi mdi-alert-minus"></i>
                                    ) : (
                                      <i className="mdi mdi-alert-plus"></i>
                                    )}
                                  </Button>
                                )}
                                <Col>
                                  <span className="float-end ms-2">
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="downloadstatstable"
                                    >
                                      {props.t("Download statistics")}
                                    </UncontrolledTooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => generateExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredRooms.length}{" "}
                                      {props.t("found results")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Accordion
                                flush
                                open={filtersOpen}
                                toggle={id => {
                                  id === filtersOpen
                                    ? setFiltersOpen("")
                                    : setFiltersOpen(id)
                                }}
                              >
                                <AccordionItem>
                                  <AccordionBody
                                    accordionId="1"
                                    className="mb-3"
                                    style={{
                                      backgroundColor: "#e8f0fe",
                                      border: "1px solid #ced4da",
                                    }}
                                  >
                                    <Row xs={1} sm={2} md={3} xl={4}>
                                      <Col>
                                        <FormGroup className="mb-0">
                                          <Label>{props.t("Platform")}</Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="platformFilter"
                                            value={platformFilter}
                                            onChange={v => {
                                              setPlatformFilter(v.target.value)
                                            }}
                                          >
                                            <option value="0">
                                              {props.t("All")}
                                            </option>
                                            <option value="1">
                                              {props.t("Production")}
                                            </option>
                                            <option value="2">
                                              {props.t("Demo")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                      <Col>
                                        <FormGroup>
                                          <Label>{props.t("Status")}</Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="statusFilter"
                                            value={statusFilter}
                                            onChange={v => {
                                              setStatusFilter(v.target.value)
                                            }}
                                          >
                                            <option value="0">
                                              {props.t("All")}
                                            </option>
                                            <option value="1">
                                              {props.t("Offline")}
                                            </option>
                                            <option value="2">
                                              {props.t("Online")}
                                            </option>
                                            <option value="3">
                                              {props.t("Video call")}
                                            </option>
                                            <option value="4">
                                              {props.t("Group call")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>

                                      <Col>
                                        <FormGroup>
                                          <Label>
                                            {props.t("Group call role")}
                                          </Label>
                                          <Input
                                            type="select"
                                            bsSize={"sm"}
                                            name="roleFilter"
                                            value={roleFilter}
                                            onChange={v => {
                                              setRoleFilter(v.target.value)
                                            }}
                                          >
                                            <option value="">
                                              {props.t("Undefined")}
                                            </option>
                                            <option value="host">
                                              {props.t("Group host")}
                                            </option>
                                            <option value="guest">
                                              {props.t("Group guest")}
                                            </option>
                                            <option value="neither">
                                              {props.t("Neither")}
                                            </option>
                                          </Input>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </AccordionBody>
                                </AccordionItem>
                              </Accordion>

                              <Row className="mt-2">
                                <Col xl="12">
                                  <AntdTable
                                    className="colored-header narrow-header"
                                    bordered
                                    size="small"
                                    sticky
                                    rowKey={"roomName"}
                                    columns={columns}
                                    dataSource={filteredRooms}
                                    rowSelection={{
                                      type: "radio",
                                      fixed: false,
                                      selectedRowKeys: selectedRowKeys,
                                      onChange: onSelectChange,
                                      columnWidth: 0,
                                      renderCell: () => "",
                                    }}
                                    onRow={(record, rowIndex) => {
                                      return {
                                        onClick: event => {
                                          if (
                                            selectedRowKeys.includes(
                                              record.roomName
                                            )
                                          ) {
                                            setSelectedRowKeys([])
                                          } else {
                                            setSelectedRowKeys([
                                              record.roomName,
                                            ])
                                          }
                                        },
                                      }
                                    }}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 100,
                                      pageSizeOptions: defaultPageSizeOptions,
                                    }}
                                    loading={ongoingSearch}
                                    tableLayout="auto"
                                    scroll={{
                                      x: "max-content",
                                      y: tableDefaultHeight,
                                    }}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane className="show" tabId="2">
                      <Table
                        style={{ tableLayout: "fixed" }}
                        className="table"
                        bordered={false}
                        striped={false}
                        hover={true}
                      >
                        <thead>
                          <tr>
                            <th></th>
                            <th>{props.t("Total")}</th>
                            <th>{props.t("Production platform")}</th>
                            <th>{props.t("Demo platform")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row">{props.t("Video numbers")}</th>
                            <td>{statistics.total}</td>
                            <td>{statistics.production}</td>
                            <td>{statistics.demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Linked")}</th>
                            <td>{statistics.linked}</td>
                            <td>{statistics.linked_prod}</td>
                            <td>{statistics.linked_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Callback enabled")}</th>
                            <td>{statistics.callback}</td>
                            <td>{statistics.callback_prod}</td>
                            <td>{statistics.callback_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Group hosts")}</th>
                            <td>{statistics.hosts}</td>
                            <td>{statistics.hosts_prod}</td>
                            <td>{statistics.hosts_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Group guests")}</th>
                            <td>{statistics.guests}</td>
                            <td>{statistics.guests_prod}</td>
                            <td>{statistics.guests_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Offline")}</th>
                            <td>{statistics.offline}</td>
                            <td>{statistics.offline_prod}</td>
                            <td>{statistics.offline_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("Online")}</th>
                            <td>{statistics.online}</td>
                            <td>{statistics.online_prod}</td>
                            <td>{statistics.online_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("In call")}</th>
                            <td>{statistics.call}</td>
                            <td>{statistics.call_prod}</td>
                            <td>{statistics.call_demo}</td>
                          </tr>
                          <tr>
                            <th scope="row">{props.t("In group call")}</th>
                            <td>{statistics.groupcall}</td>
                            <td>{statistics.groupcall_prod}</td>
                            <td>{statistics.groupcall_demo}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPane>

                    <TabPane className="show" tabId="3">
                      <Row className="mb-4">
                        <Col xl={2} sm="12" className="mt-2">
                          <div
                            className="d-inline-block"
                            style={{ width: "100%" }}
                          >
                            <TagsTree
                              loadData={true}
                              expanded={true}
                              multiple={false}
                              selectionChanged={tagsChanged}
                            />
                          </div>
                        </Col>
                        <Col xl={6} sm="12" className="mt-2">
                          <SelectGroupFilters
                            onChange={setGroups}
                            multiple={true}
                            selectedTags={selectedTags}
                          />
                        </Col>
                        <Col className="mt-1 col-auto">
                          <div className="d-flex gap-1 flex-wrap">
                            <div className="align-self-end placeholder-glow">
                              <Button
                                type="button"
                                color="primary"
                                disabled={loadingLicenses}
                                style={{ padding: "9px" }}
                                onClick={() => {
                                  getLicenses()
                                }}
                              >
                                <span className="me-3 ms-3">
                                  {props.t("Search")}
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col className="mt-2 col-auto">
                          <span className="float-end">
                            <UncontrolledTooltip
                              placement="top"
                              target="exportLicensesDropdown"
                            >
                              {props.t("Download statistics")}
                            </UncontrolledTooltip>
                            <Dropdown
                              id="exportLicensesDropdown"
                              isOpen={licenseMenu}
                              toggle={() => setLicenseMenu(!licenseMenu)}
                              className="download-btn text-center"
                              type="button"
                            >
                              <DropdownToggle
                                id="exportLicensesDropdown"
                                className="text-muted"
                                tag="i"
                              >
                                <i className="mdi mdi-download" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  disabled={!licenseData?.entries}
                                  onClick={() => generateLicenseExcel()}
                                >
                                  {props.t("Download .xlsx")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => exportLicensesPDF()}
                                >
                                  {props.t("Download .pdf")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </span>
                        </Col>
                      </Row>
                      <Table
                        style={{ tableLayout: "fixed" }}
                        className="table"
                        bordered={false}
                        striped={false}
                        hover={true}
                      >
                        <thead>
                          <tr>
                            <th>{props.t("Group")}</th>
                            <th>{props.t("Group hosts")}</th>
                            <th>{props.t("Group guests")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">{props.t("All")}</td>
                            <td>{licenseData?.hosts}</td>
                            <td>{licenseData?.guests}</td>
                          </tr>
                        </tbody>
                        {licenseData.entries?.map((item, index) => {
                          return (
                            <tbody key={"" + index}>
                              <tr>
                                <td scope="row">{item.group}</td>
                                <td>{item.hosts}</td>
                                <td>{item.guests}</td>
                              </tr>
                            </tbody>
                          )
                        })}
                      </Table>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={hostModal}
            toggle={() => {
              setHostModal(!hostModal)
            }}
            centered
          >
            <ModalHeader>{selectedRow?.hostName}</ModalHeader>
            <ModalBody>
              <p>{selectedRow?.hostGroups}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => {
                  setHostModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          <VideoRoomDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getRooms={getRooms}
          />
          <RegisterVideoRoomModal
            createModal={createModal}
            setCreateModal={setCreateModal}
            getRooms={getRooms}
            roomsCreated={roomsCreated}
          />
          <VideoPasswordModal
            roomName={selectedRoom?.roomName}
            clientEmail={selectedRoom?.clientEmail}
            passwordModal={passwordModal}
            setPasswordModal={setPasswordModal}
          />
        </Container>
      </div>
    </>
  )
}

VideoRooms.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(VideoRooms)
