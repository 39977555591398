import React, { useEffect, useRef, useState, Fragment } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  CardTitle,
  NavLink,
  Table,
  Button,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "components/Common/Breadcrumb"
import classnames from "classnames"
import SelectGroupFilters from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import { getClientServiceCounts } from "helpers/backend_helper"

//Chart import
import ReactApexChart from "react-apexcharts"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

// Toast
import { showToast } from "utils/general"

const ServiceMonitoring = props => {
  const [loading, setLoading] = useState(false)
  const [selectedGroups, setSelectedGroups] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [selectedTags, setSelectedTags] = useState([])

  //Totals
  const [total, setTotal] = useState(0)
  const [totalInactive, setTotalInactive] = useState(0)
  const [groups, setGroups] = useState(0)

  // Clients
  const [clients, setClients] = useState(0)

  // Device states
  const [home, setHome] = useState([])
  const [tracker, setTracker] = useState([])
  const [health, setHealth] = useState([])
  const [dose, setDose] = useState([])
  const [smartlock, setSmartlock] = useState([])
  const [video, setVideo] = useState([])
  const [carephone, setCarephone] = useState([])

  // Inactive device states
  const [homeInactive, setHomeInactive] = useState([])
  const [trackerInactive, setTrackerInactive] = useState([])
  const [healthInactive, setHealthInactive] = useState([])
  const [doseInactive, setDoseInactive] = useState([])
  const [smartlockInactive, setSmartlockInactive] = useState([])
  const [videoInactive, setVideoInactive] = useState([])
  const [carephoneInactive, setCarephoneInactive] = useState([])

  //Dropdown button state
  const [menu, setMenu] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customers and services") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Fetch data from server
  const fetchData = () => {
    if (
      (selectedGroups != null && selectedGroups.length > 0) ||
      (selectedTags != null && selectedTags.length > 0)
    ) {
      setLoading(true)
      getClientServiceCounts(
        abortRef.current,
        selectedGroups,
        selectedTags.toString()
      )
        .then(result => {
          //console.log("DATA: ", result)
          setGroups(result.entries.length)

          //counting totals
          let entries = result.entries

          let customersTotal = 0
          entries.forEach(value => {
            customersTotal += value.clients
          })
          setClients(customersTotal)

          let sumTotal = 0
          entries.forEach(value => {
            sumTotal +=
              value.carephone +
              value.tracker +
              value.dose +
              value.home +
              value.smartlock +
              value.video +
              value.health
          })
          setTotal(sumTotal)

          let sumInactiveTotal = 0
          entries.forEach(value => {
            sumInactiveTotal +=
              value.carephone_inactive +
              value.tracker_inactive +
              value.dose_inactive +
              value.home_inactivate +
              value.smartlock_inactive +
              value.video_inactive +
              value.health_inactive
          })
          setTotalInactive(sumInactiveTotal)

          let homer = 0
          let homeInac = 0
          let trackeri = 0
          let trackerInac = 0
          let carephone = 0
          let carephoneInac = 0
          let smartlock = 0
          let smartlockInac = 0
          let health = 0
          let healthInac = 0
          let video = 0
          let videoInac = 0
          let dose = 0
          let doseInac = 0

          // Home values
          entries.forEach(value => {
            homer += value.home
          })
          setHome(homer)
          entries.forEach(value => {
            homeInac += value.home_inactivate
          })
          setHomeInactive(homeInac)

          // Tracker values
          entries.forEach(value => {
            trackeri += value.tracker
          })
          setTracker(trackeri)
          entries.forEach(value => {
            trackerInac += value.tracker_inactive
          })
          setTrackerInactive(trackerInac)

          // Carephone values
          entries.forEach(value => {
            carephone += value.carephone
          })
          setCarephone(carephone)
          entries.forEach(value => {
            carephoneInac += value.carephone_inactive
          })
          setCarephoneInactive(carephoneInac)

          // Smartlock values
          entries.forEach(value => {
            smartlock += value.smartlock
          })
          setSmartlock(smartlock)
          entries.forEach(value => {
            smartlockInac += value.smartlock_inactive
          })
          setSmartlockInactive(smartlockInac)

          // Health values
          entries.forEach(value => {
            health += value.health
          })
          setHealth(health)
          entries.forEach(value => {
            healthInac += value.health_inactive
          })
          setHealthInactive(healthInac)

          // Video values
          entries.forEach(value => {
            video += value.video
          })
          setVideo(video)
          entries.forEach(value => {
            videoInac += value.video_inactive
          })
          setVideoInactive(videoInac)

          // Dose values
          entries.forEach(value => {
            dose += value.dose
          })
          setDose(dose)
          entries.forEach(value => {
            doseInac += value.dose_inactive
          })
          setDoseInactive(doseInac)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      clearData()
    }
  }

  const clearData = () => {
    setGroups(0)
    setClients(0)
    setTotal(0)
    setTotalInactive(0)
    setHome(0)
    setHomeInactive(0)
    setTracker(0)
    setTrackerInactive(0)
    setCarephone(0)
    setCarephoneInactive(0)
    setSmartlock(0)
    setSmartlockInactive(0)
    setHealth(0)
    setHealthInactive(0)
    setVideo(0)
    setVideoInactive(0)
    setDose(0)
    setDoseInactive(0)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // Stat card values
  const cardValues = [
    {
      id: 0,
      value: home,
      inactive: homeInactive,
      text: "Gateways",
      icon: "bx bx-home",
      color: "warning",
      series: toPercentage(home),
    },
    {
      id: 1,
      value: tracker,
      inactive: trackerInactive,
      text: "Trackers",
      icon: "mdi mdi-phone-ring-outline",
      color: "info",
      series: toPercentage(tracker),
    },
    {
      id: 2,
      value: dose,
      inactive: doseInactive,
      text: "Medication reminders",
      icon: "mdi mdi-cellphone-sound",
      color: "primary",
      series: toPercentage(dose),
    },
    {
      id: 3,
      value: health,
      inactive: healthInactive,
      text: "Measuring devices",
      color: "danger",
      icon: "mdi mdi-cellphone-link",
      series: toPercentage(health),
    },
    {
      id: 4,
      value: smartlock,
      inactive: smartlockInactive,
      text: "Smartlocks",
      icon: "bx bx-stats",
      color: "success",
      series: toPercentage(smartlock),
    },
    {
      id: 5,
      value: video,
      inactive: videoInactive,
      text: "Videos",
      icon: "mdi mdi-medical-bag",
      color: "pink",
      series: toPercentage(video),
    },
    {
      id: 6,
      value: carephone,
      inactive: carephoneInactive,
      text: "Safety phones",
      icon: "mdi mdi-shield-lock-outline",
      color: "secondary",
      series: toPercentage(carephone),
    },
  ]

  // For radial bars
  function toPercentage(value) {
    if (value != 0) {
      let result = Math.round((value / total) * 100)
      return [result]
    }
    if (value == 0) {
      return [0]
    }
  }

  // Chart options
  const getChartOptions = index => {
    let options = {
      chart: { sparkline: { enabled: !0 } },
      dataLabels: { enabled: !1 },
      colors: ["#556ee6"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 0,
            size: "50%",
          },

          dataLabels: {
            showOn: "always",
            name: {
              offsetY: 0,
              show: false,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              offsetY: 4,
              color: "#111",
              fontSize: "16px",
              show: true,
            },
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: [""],
    }
    switch (index) {
      case 0:
        options["colors"][0] = "#1c3763" // color: loading-blue-dots
        break
      case 1:
        options["colors"][0] = "#005f73" //color: suvanto-green
        break
      case 2:
        options["colors"][0] = "#0a9396" //color: suvanto-blue-3-1
        break
      case 3:
        options["colors"][0] = "#94d2bd" //color: danger
        break
      case 4:
        options["colors"][0] = "#ee9b00" //color: success
        break
      case 5:
        options["colors"][0] = "#ca6702" //color: suvanto-orange
        break
      case 6:
        options["colors"][0] = "#bb3e03" //color: secondary
        break
      default:
        break
    }

    return options
  }

  //Excel and pdf name
  const title = props.t("Customers and services")

  // Export PDF table NOT IN USE AT THE MOMENT
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = series.map(elt => [
      elt.group,
      elt.name,
      statusFormatterExport(elt.home),
      statusFormatterExport(elt.tracker),
      statusFormatterExport(elt.carephone),
      statusFormatterExport(elt.video),
      statusFormatterExport(elt.health),
      statusFormatterExport(elt.dose),
      statusFormatterExport(elt.smartlock),
    ])
    console.log(data, ".pdf function")

    let content = {
      startY: 50,
      head: [
        [
          props.t("Sale." + "Group"),
          props.t("Sale." + "Name"),
          props.t("Sale." + "Home"),
          props.t("Sale." + "On the Go"),
          props.t("Sale." + "Safety phone"),
          props.t("Sale." + "Video"),
          props.t("Sale." + "Measurements"),
          props.t("Sale." + "Medication reminder"),
          props.t("Sale." + "Smartlock"),
        ],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    // TODO: Include comments and message ( problem with mapping undefined object )
    const Heading = [
      [
        props.t("Statistics"),
        props.t("Amount"),
        // props.t("Percentage"),
        props.t("Inactive"),
      ],
    ]
    const total1 = [props.t("Sale." + "Devices total"), total]
    const inactive1 = [props.t("Sale." + "Inactive devices"), totalInactive]
    const groups1 = [props.t("Sale." + "Groups"), groups]
    const customers1 = [props.t("Sale." + "Clients"), clients]
    const data = cardValues.map(elt => [
      props.t("Sale." + elt.text),
      elt.value,
      elt.inactive,
    ])
    data.push(total1, inactive1, groups1, customers1)
    console.log(data)
    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  const tagsChanged = newTag => {
    if (!newTag) setSelectedTags([])
    else setSelectedTags([newTag])
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Customers and services")}
            className="breadcrumb m-0 justify-content-center"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Col lg="12">
                    <Row>
                      <Col md={3} sm="12" className="mt-2">
                        <TagsTree
                          loadData={true}
                          expanded={true}
                          multiple={false}
                          selectionChanged={tagsChanged}
                          onSelect={() => setSelectedGroups([])}
                        />
                      </Col>
                      <Col md={6} sm="12" className="mt-2">
                        <SelectGroupFilters
                          selection={selectedGroups}
                          onChange={setSelectedGroups}
                          multiple={true}
                          selectedTags={selectedTags}
                        />
                      </Col>
                      <Col md={3} className="mt-1">
                        <span className="d-flex">
                          <Button
                            disabled={loading}
                            color="primary"
                            style={{ minWidth: "100px", padding: "9px" }}
                            onClick={() => {
                              fetchData()
                            }}
                          >
                            {props.t("Search")}
                          </Button>
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {total != 0 && (
            <>
              <Row>
                <Col>
                  <div className="d-flex flex-wrap">
                    <span>
                      <h5 className="mt-1 me-2">{props.t("Statistics")}</h5>
                    </span>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="downloadstatstable"
                    >
                      {props.t("Download statistics")}
                    </UncontrolledTooltip>
                    <Dropdown
                      id="printButtonServicesStats"
                      isOpen={menu}
                      toggle={() => setMenu(!menu)}
                      className="download-btn text-center"
                      type="button"
                    >
                      <DropdownToggle
                        id="downloadstatstable"
                        className="text-muted"
                        tag="i"
                      >
                        <i className="mdi mdi-download " />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => downloadExcel()}>
                          {props.t("Download .xlsx")}
                        </DropdownItem>
                        {/* <DropdownItem onClick={() => exportPDF()}>
                              {props.t("Download .pdf")}
                            </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col>
                <Col>
                  <div className=" float-end">
                    <Form>
                      <Nav className="product-view-nav" pills>
                        <NavItem>
                          {/* Card view */}
                          <UncontrolledTooltip
                            placement="bottom"
                            target="TTnavcardservice"
                          >
                            {props.t("Cards")}
                          </UncontrolledTooltip>
                          <NavLink
                            id="TTnavcardservice"
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                            }}
                          >
                            <i
                              style={{ padding: "10px" }}
                              className="bx bx-grid-alt text-sixe-16"
                            />
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          {/* Taulukko view */}
                          <UncontrolledTooltip
                            target="TTnavtableservice"
                            placement="bottom"
                          >
                            {props.t("Table")}
                          </UncontrolledTooltip>
                          <NavLink
                            id="TTnavtableservice"
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                            }}
                          >
                            <i
                              style={{ padding: "10px" }}
                              className="bx bx-list-ul text-sixe-16"
                            />
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <TabContent activeTab={activeTab} className="py-2">
                    <TabPane className="show" tabId="1">
                      <Row>
                        <Col lg="3">
                          <Card>
                            <CardBody>
                              <div className="d-flex">
                                <Col xs={6} className="mt-3 ms-0">
                                  <span className="text-muted font-size-14 mb-2">
                                    {props.t("Sale." + "Devices")}
                                  </span>
                                  <h3 className="mt-2">{total}</h3>
                                </Col>
                                <Col xs={6} className="mt-3 ms-0 mb-2">
                                  <span className="text-muted font-size-14 mb-2 text-truncate">
                                    {props.t("Sale." + "Inactive devices")}
                                    <i
                                      className="bx bx-info-circle ms-1 cursor-pointer"
                                      id="info-inactive"
                                    />
                                    <UncontrolledTooltip
                                      placement="right"
                                      target="info-inactive"
                                    >
                                      {props.t(
                                        "Sale." +
                                          "The device has not been used for 2 weeks"
                                      )}
                                    </UncontrolledTooltip>
                                  </span>
                                  <h3 className="text-danger mt-2">
                                    {totalInactive}
                                  </h3>
                                </Col>
                              </div>
                              <div className="d-flex">
                                <Col xs={6} className="mt-3 ms-0">
                                  <span className="text-muted font-size-14 mb-2">
                                    {props.t("Sale." + "Groups")}
                                  </span>
                                  <h3 className="mt-2">{groups}</h3>
                                </Col>
                                <Col xs={6} className="mt-3 ms-0">
                                  <span className="text-muted font-size-14 mb-2">
                                    {props.t("Sale." + "Clients")}
                                  </span>
                                  <h3 className="mt-2">{clients}</h3>
                                </Col>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {cardValues.map((item, index) => {
                          const options2 = getChartOptions(item.id)
                          return (
                            <Fragment key={"" + index}>
                              {item.value != 0 ? (
                                <Col sm="3">
                                  <Card>
                                    <CardBody>
                                      <CardTitle>
                                        {props.t("Sale." + item.text)}
                                      </CardTitle>
                                      <Row>
                                        <Col xs="4">
                                          <div className="text-center mt-2 ms-3">
                                            <span className="mt-4 h2 float-end">
                                              {item.value}
                                            </span>
                                            {/*{" "} <span className="">kpl</span> */}
                                          </div>
                                        </Col>
                                        <Col id={"col_" + item.id} xs="8">
                                          <div id="chart-devices-sales">
                                            <ReactApexChart
                                              options={options2}
                                              series={item.series}
                                              type="radialBar"
                                              height={100}
                                              // width={95}
                                              className="apex-charts"
                                            ></ReactApexChart>
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col xs="12">
                                          <div className="text-center mt-4">
                                            {item.inactive != 0 && (
                                              <span className="badge badge-soft-danger font-size-12">
                                                {item.inactive}{" "}
                                                {props.t(
                                                  "Sale." + "inactive devices"
                                                )}
                                              </span>
                                            )}
                                            {item.inactive == 0 && (
                                              <span className="badge badge-soft-success font-size-12">
                                                {props.t(
                                                  "Sale." +
                                                    "All devices are in use"
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Card>
                                </Col>
                              ) : null}
                            </Fragment>
                          )
                        })}
                      </Row>
                    </TabPane>
                    <TabPane className="show" tabId="2">
                      <Row>
                        <Col lg={4}>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">
                                {props.t("Sale." + "General")}
                              </h4>
                              <div className="table-responsive">
                                <Table className="table mt-2 mb-2">
                                  <tbody>
                                    <tr>
                                      <th scope="row">
                                        {props.t("Sale." + "Devices total")}
                                      </th>
                                      <td>{total}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        {props.t("Sale." + "Inactive devices")}
                                      </th>
                                      <td>{totalInactive}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        {props.t("Sale." + "Groups")}
                                      </th>
                                      <td>{groups}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">
                                        {props.t("Sale." + "Clients")}
                                      </th>
                                      <td>{clients}</td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg={8}>
                          <Card>
                            <CardBody>
                              <h4 className="card-title">
                                {props.t("Devices")}
                              </h4>
                              <div className="table-responsive">
                                <Table className="table mt-2 mb-2">
                                  <thead>
                                    <tr>
                                      <th>{props.t("Sale." + "Device")}</th>
                                      <th>
                                        {props.t("Sale." + "Total amount")}
                                      </th>
                                      <th>
                                        {props.t("Sale." + "Percentage of all")}
                                      </th>
                                      <th>
                                        {props.t("Sale." + "Inactive device")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {cardValues.map((item, index) => {
                                      return (
                                        <tr key={"" + index}>
                                          {item.value != 0 && (
                                            <>
                                              <th scope="row">
                                                {props.t("Sale." + item.text)}
                                              </th>
                                              <td>{item.value}</td>
                                              <td>{item.series}%</td>
                                              <td>{item.inactive}</td>
                                            </>
                                          )}
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </>
  )
}

ServiceMonitoring.propTypes = {
  t: PropTypes.any,
}
export default withTranslation()(ServiceMonitoring)
