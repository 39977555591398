import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalFooter, Button, Form, Row, Card, Col } from "reactstrap"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { showToast } from "utils/general"

const FileUploadModal = props => {
  const [uploadMessage, setUploadMessage] = useState("")
  const [loadingFile, setLoadingFile] = useState(false)

  useEffect(() => {
    if (props.selectedFile.length == 0) {
      setUploadMessage("")
    }
  }, [props.selectedFile])

  // Clear the modal if its closed
  useEffect(() => {
    if (!props.isOpen) {
      props.setSelectedFile([])
      props.setSelectedDataBase64("")
      setUploadMessage("")
      setLoadingFile(false)
    }
  }, [props.isOpen])

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    console.log(
      "size ",
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    )
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  // Dropzone
  function handleAcceptedFiles(files) {
    setUploadMessage("")
    setLoadingFile(true)
    console.log(files, "files")

    files.forEach(file => {
      const reader = new FileReader()

      if (props.parent == "journal") {
        let maxSizeInBytes = 0
        // console.log(file.type, "filetype")
        if (file.type.includes("video")) {
          maxSizeInBytes = 20 * 1024 * 1024 // 20 Mt
        } else {
          maxSizeInBytes = 5 * 1024 * 1024 // 5 Mt
        }
        if (file.size > maxSizeInBytes) {
          setUploadMessage(props.t("File is too large"))
        }
      }

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        let dataUrl = reader.result
        //console.log(dataUrl)
        let index = dataUrl.indexOf("base64,")
        if (index >= 0) {
          dataUrl = dataUrl.substring(index + 7)
        }
        props.setSelectedDataBase64(dataUrl)
      }
      reader.readAsDataURL(file)
    })

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    props.setSelectedFile(files)
    setLoadingFile(false)
  }

  return (
    <Modal centered isOpen={props.isOpen} toggle={props.toggle}>
      <div className="modal-header">
        <h5 className="modal-title mt-0 mb-0">
          {props.t("Add an attachment")}
        </h5>

        <button
          type="button"
          onClick={props.toggle}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Row className="mt-2">
        <div className="px-5 pt-2">
          <Form>
            <Dropzone
              multiple={false}
              maxFiles={1}
              accept={props.allowedTypes}
              maxSize={props.maxSize} // Custom handling in handleAcceptedFiles
              onDropAccepted={handleAcceptedFiles}
              onDropRejected={fileRejections => {
                let errMessage = fileRejections[0].errors[0].code
                if (errMessage === "file-too-large") {
                  errMessage = "File is too large"
                }
                showToast(props.t(errMessage), "error")
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  {props.selectedFile.length === 0 && (
                    <div className="dropzone">
                      <div
                        className="dz-message needsclick"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                          </div>
                          <h5>
                            {props.t("Drop file here or click to upload")}
                          </h5>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Dropzone>

            {loadingFile && (
              <div className="p-4">
                <div className="spinner-border text-primary">
                  <span className="sr-only">{props.t("Loading...")}</span>
                </div>
              </div>
            )}
            <div className="dropzone-previews py-1" id="file-previews">
              {props.selectedFile.length > 0 && (
                <span className="float-end p-2">
                  <Button
                    className="p-2 mt-2"
                    color="transparent"
                    onClick={() => {
                      props.setSelectedFile([])
                    }}
                  >
                    <i className="bx bx-trash font-size-16 text-danger" />
                  </Button>
                </span>
              )}
              {uploadMessage !== "" && (
                <span className="text-danger h6">{props.t(uploadMessage)}</span>
              )}
              {props.selectedFile?.map((f, i) => {
                return (
                  <Card
                    outline
                    color={uploadMessage === "" ? "" : "danger"}
                    className={
                      "py-1 mb-2 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    }
                    key={i + "-file"}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          {/* Icons based on file type */}
                          {f.type.includes("video") && (
                            <i className="mdi mdi-file-video display-7 text-muted ps-2"></i>
                          )}
                          {f.type.includes("application/vnd.ms-excel") ||
                          f.type.includes(
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ) ? (
                            <i className="mdi mdi-file-excel display-7 text-muted ps-2"></i>
                          ) : null}
                          {f.type.includes("audio") && (
                            <i className="mdi mdi-file-music display-7 text-muted ps-2"></i>
                          )}
                          {f.type.includes("application/msword") ||
                          f.type.includes(
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                          ) ? (
                            <i className="bx bxs-file-doc display-7 text-muted ps-2"></i>
                          ) : null}
                          {f.type.includes("image") && (
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              alt={f.name}
                              src={f.preview}
                            />
                          )}
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {f.name}
                          </Link>
                          <p className="mb-0">
                            <strong>{f.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              })}
            </div>
            <p
              className={`mt-0 pt-0 ${
                props.selectedFile.length > 0 && uploadMessage !== ""
                  ? "text-danger"
                  : "text-muted"
              }`}
            >
              {props.description}.
            </p>
          </Form>
        </div>
      </Row>
      <ModalFooter className="mt-2">
        <span className="float-end">
          <button
            type="button"
            onClick={props.toggle}
            className="btn btn-outline-primary"
          >
            {props.t("Cancel")}
          </button>

          <button
            disabled={props.selectedFile.length === 0 || uploadMessage !== ""}
            className="btn btn-primary ms-2"
            onClick={() => {
              props.onUpload(props.selectedFile[0])
            }}
          >
            {props.t("Save")}
          </button>
        </span>
      </ModalFooter>
    </Modal>
  )
}

FileUploadModal.propTypes = {
  t: PropTypes.any,
  toggle: PropTypes.any,
  isOpen: PropTypes.any,
  onUpload: PropTypes.any,
  maxSize: PropTypes.any,
  selectedFile: PropTypes.any,
  setSelectedFile: PropTypes.any,
  setSelectedDataBase64: PropTypes.any,
  allowedTypes: PropTypes.any,
  description: PropTypes.any,
}

export default FileUploadModal
