// Landing page -content
import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"

const AppointmentsManual = props => {
  return (
    <div className="instructions">
      <div className="px-1">
        <h5 className="font-size-15">{props.t("Appointments")}</h5>
        <p className="pt-2">{props.t("CallAppointments." + "0")}</p>
        <p className="pt-2">{props.t("CallAppointments." + "1")}</p>
      </div>{" "}
      <Collapse
        bordered={false}
        className="px-0"
        accordion
        expandIcon={props.ExpandIcon}
        style={{ backgroundColor: "transparent" }}
        items={[
          {
            key: "0",
            label: props.t("CallAppointmentsTitle1"),
            children: (
              <>
                <p>{props.t("CallAppointments." + "CreateAppointment1")}</p>
                <p>{props.t("CallAppointments." + "CreateAppointment2")}</p>
                <ul className="">
                  <li className="text-muted">
                    {props.t("CallAppointments." + "CreateAppointment3")}
                  </li>
                  <li className="">
                    {props.t("CallAppointments." + "CreateAppointment4")}
                  </li>
                </ul>
                <p>{props.t("CallAppointments." + "CreateAppointment5")}</p>
                <p>{props.t("CallAppointments." + "CreateAppointment6")}</p>
              </>
            ),
          },
          {
            key: "1",
            label: props.t("CallAppointmentsTitle2"),
            children: (
              <>
                <p>{props.t("CallAppointments." + "FilterAppointment1")}</p>
                <p>{props.t("CallAppointments." + "FilterAppointment2")}</p>
              </>
            ),
          },
          {
            key: "2",
            label: props.t("CallAppointmentsTitle3"),
            children: (
              <>
                <p>{props.t("CallAppointments." + "MoveAppointment1")}</p>
                <p>{props.t("CallAppointments." + "MoveAppointment2")}</p>
              </>
            ),
          },
          {
            key: "3",
            label: props.t("CallAppointmentsTitle4"),
            children: (
              <>
                <p>{props.t("CallAppointments." + "DeleteAppointment1")}</p>
              </>
            ),
          },
          {
            key: "4",
            label: props.t("CallAppointmentsTitle5"),
            children: (
              <>
                <p className="">
                  {props.t("CallAppointments." + "NavigateTimeline0")}
                </p>
                {/* TIMELINE CAN BE ADJUSTED */}
                <h6 className="text-dark">
                  {props.t("CallAppointments." + "NavigateTimelineTitle1")}
                </h6>
                <p className="">
                  {props.t("CallAppointments." + "NavigateTimeline1")}
                </p>
                <ul className="">
                  <li className="">
                    {props.t("CallAppointments." + "NavigateTimeline3")}
                  </li>
                  <li className="">
                    {props.t("CallAppointments." + "NavigateTimeline2")}
                  </li>
                </ul>
                <p className="">
                  {props.t("CallAppointments." + "NavigateTimeline4")}
                </p>
                <h6 className="text-dark">
                  {props.t("CallAppointments." + "NavigateTimelineTitle2")}
                </h6>
                <p className="">
                  {props.t("CallAppointments." + "NavigateTimeline5")}
                </p>
                <ul className="">
                  <li className="">
                    {props.t("CallAppointments." + "NavigateTimeline6")}
                  </li>
                  <li className="">
                    {props.t("CallAppointments." + "NavigateTimeline7")}
                  </li>
                </ul>
              </>
            ),
          },
        ]}
      ></Collapse>
    </div>
  )
}
AppointmentsManual.propTypes = {
  t: PropTypes.any,
  ExpandIcon: PropTypes.func,
}

export default withTranslation()(AppointmentsManual)
