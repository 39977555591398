import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { Tabs } from "antd"
import { getNewsletterSubscribers } from "helpers/database_helper"
import ReleaseNotesModal from "./release-notes/release-notes-modal"
import ReleaseNotesIndex from "./release-notes/release-notes-index"
import ErrorBulletinsIndex from "./error-bulletins/error-bulletins-index"
import BulletinLogsIndex from "./logs/bulletin-logs-index"
import RecipientLists from "./recipient-lists/recipient-lists"
import ErrorBulletinsModal from "./error-bulletins/error-bulletins-modal"

const EmailReleasesPage = props => {
  const [releaseNotes, setReleaseNotes] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selection, setSelection] = useState(null)
  const [listActiveTab, setListActiveTab] = useState("1")
  const [bulletinsActiveTab, setBulletinsActiveTab] = useState("1")
  const [subs, setSubs] = useState([])
  const [productionDatabase, setProductionDatabase] = useState(true)
  const [newReleaseModal, setNewReleaseModal] = useState(false)
  const [modalType, setModalType] = useState(0)
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [count, setCount] = useState(0)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Email bulletins") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // TEST -> Should be deleted?
  useEffect(() => {
    fetchSubs()
  }, [])

  useEffect(() => {
    // console.log(selection, "selection email-releases")
    if (selection) {
      setNewReleaseModal(true)
    } else {
      setNewReleaseModal(false)
    }
  }, [selection])

  // DB selection changed, clear everything
  useEffect(() => {
    setSelection(null)
    setReleaseNotes([])
    setSelectedIndex(null)
  }, [productionDatabase])

  // Get newsletter subscribers
  const fetchSubs = () => {
    // Set filters
    let filters = new Object()
    setOngoingSearch(true)
    getNewsletterSubscribers(abortRef.current, false, filters)
      .then(subs => {
        console.log("Subscribers: ", subs)
        setSubs(subs)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setOngoingSearch(false)
      })
  }
  // TABS
  const onChange = key => {
    console.log(key)
    setListActiveTab(key)
  }

  // Update fetching
  const update = v => {
    setNewReleaseModal(false)
    setCount(count + v)
  }
  //  tabpane 1 // Bulletins
  const noticeJSX = (
    <TabPane tabId={1} className="">
      <Row>
        <div className="email-bulletins-col-1">
          <Button
            color="success"
            style={{ width: "100%" }}
            className="mb-3 mt-0 py-2"
            onClick={() => {
              setNewReleaseModal(true)
            }}
            disabled={bulletinsActiveTab == 3}
          >
            <i className="mdi mdi-pencil-plus font-size-14 me-2"></i>
            {props.t("New bulletin")}
          </Button>
          <Nav
            pills
            className="flex-column custom-nav-pills mt-1"
          >
            <NavItem>
              <NavLink
                to="#"
                active={bulletinsActiveTab === "1"}
                onClick={() => {
                  setBulletinsActiveTab("1")
                  setModalType(0)
                  setCount(0)
                }}
                className="mb-1"
              >
                {props.t("Release notes")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                // disabled={true}
                active={bulletinsActiveTab === "2"}
                onClick={() => {
                  setBulletinsActiveTab("2")
                  setModalType(1)
                  setCount(0)
                }}
                className="mb-1"
              >
                {props.t("Error bulletins")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                active={bulletinsActiveTab === "3"}
                onClick={() => {
                  setBulletinsActiveTab("3")
                  setCount(0)
                }}
                className="mb-1"
              >
                {props.t("Logs")}
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {bulletinsActiveTab === "1" && (
          <ReleaseNotesIndex
            fetchReleases={props.fetchReleases}
            setSelection={setSelection}
            subscribers={subs}
            update={count}
          ></ReleaseNotesIndex>
        )}
        {bulletinsActiveTab === "2" && (
          <ErrorBulletinsIndex
            fetchReleases={props.fetchReleases}
            setSelection={setSelection}
            subscribers={subs}
            update={count}
          ></ErrorBulletinsIndex>
        )}
        {bulletinsActiveTab === "3" && (
          <BulletinLogsIndex subscribers={subs}></BulletinLogsIndex>
        )}
      </Row>
    </TabPane>
  )
  const recipientJSX = (
    <TabPane tabId={2} className="">
      <Row>
        <RecipientLists
          subscribers={subs}
          updatingSubs={setSubs}
        ></RecipientLists>
      </Row>
    </TabPane>
  )

  const itemsTabs = [
    {
      key: "1",
      label: props.t("Bulletins"),
      children: noticeJSX,
    },
    {
      key: "3",
      label: props.t("Mailing list"),
      children: recipientJSX,
    },
  ]

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Email bulletins")} />
          <Row>
            <Col lg={12} xxl={12}>
              <Card>
                <CardBody className="pt-1">
                  <Tabs
                    defaultActiveKey="1"
                    items={itemsTabs}
                    onChange={onChange}
                  />
                </CardBody>
              </Card>
            </Col>
            {newReleaseModal && (
              <>
                {modalType === 0 ? (
                  <ReleaseNotesModal
                    selection={selection}
                    setSelection={setSelection}
                    modal={setNewReleaseModal}
                    fetchReleases={props.fetchReleases}
                    subscribers={subs}
                    // TODO: Now always true, set from release-notes-index
                    productionDatabase={productionDatabase}
                    setCount={update}
                  ></ReleaseNotesModal>
                ) : (
                  <ErrorBulletinsModal
                    selection={selection}
                    setSelection={setSelection}
                    modal={setNewReleaseModal}
                    fetchReleases={props.fetchReleases}
                    subscribers={subs}
                    // TODO: Now always true, set from release-notes-index
                    productionDatabase={productionDatabase}
                    setCount={update}
                  ></ErrorBulletinsModal>
                )}
              </>
            )}
          </Row>
        </Container>
      </div>
    </>
  )
}

EmailReleasesPage.propTypes = {
  t: PropTypes.any,
  fetchReleases: PropTypes.any,
}

export default withTranslation()(EmailReleasesPage)
