import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  FormGroup,
  Button,
  Input,
  CardTitle,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
  UncontrolledAccordion,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Switch } from "antd"
import { useFormik } from "formik"
import * as Yup from "yup"
import { withTranslation } from "react-i18next"
import {
  getClientData,
  transferCarephone,
  transferGateway,
  transferSmartlock,
  transferTracker,
  transferVideos,
  updateClientInfo,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import SelectTracker from "./tracker-dropdown"
import SelectCarephone from "./carephone-dropdown"
import SelectPindora from "./pindora-dropdown"
import SelectGateway from "./gateway-dropdown"
import SelectVideoDevice from "./videodevice-dropdown"
import SelectVideoRoom from "./videoroom-dropdown"
import SelectGroup from "./group-dropdown"
import SelectClient from "./client-dropdown"
import { getLottieLoadingOptions, getLottieNothingFound, showToast } from "utils/general"
import PubSub from "pubsub-js"
import GatewayDrawer from "pages/Developer/Devices/Gateways/GatewayDrawer"

const ClientIntegrations = props => {
  const [clientData, setClientData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [transferType, setTransferType] = useState("existing")
  const [transferGroup, setTransferGroup] = useState(null)
  const [transferClient, setTransferClient] = useState(null)
  const [newClientName, setNewClientName] = useState("")

  const [showGatewayDropdown, setShowGatewayDropdown] = useState(false)
  const [showGatewayDrawer, setShowGatewayDrawer] = useState(false)
  const [showTrackerDropdown, setShowTrackerDropdown] = useState(false)
  const [showCarephoneDropdown, setShowCarephoneDropdown] = useState(false)
  const [showPindoraDropdown, setShowPindoraDropdown] = useState(false)

  const [showVideoRoomDropdown, setShowVideoRoomDropdown] = useState(false)
  const [showVideoDeviceDropdown, setShowVideoDeviceDropdown] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    //document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.suvantoKey) {
      getData()
    }
  }, [props.suvantoKey])

  // Get client data
  const getData = () => {
    if (props.suvantoKey && !loading) {
      setLoading(true)
      getClientData(abortRef.current, props.suvantoKey, false)
        .then(result => {
          if (result.key) {
            setClientData(result)
          }
          setLoading(false)
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  // Update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ssnNumber: clientData?.ssn || "",
      alarmCenter: clientData?.alarmCenterType || "",
      alarmCenterId: clientData?.alarmCenterId || "",
      gillieEnabled:
        clientData?.gillieEnabled != null ? clientData.gillieEnabled : false,
      gillieIntegration: clientData?.gillieIntegration != null ? clientData.gillieIntegration : "",
      hilkkaEnabled:
        clientData?.hilkkaEnabled != null ? clientData.hilkkaEnabled : false,
      hilkkaCustomerId: clientData?.hilkkaCustomerId || "",
      healthMeasurements:
        clientData?.measurements != null ? clientData.measurements : false,
      journalAllowHealthEntries:
        clientData?.journalAllowHealthEntries != null
          ? clientData.journalAllowHealthEntries
          : false,
      allowMeasureTemperature:
        clientData?.allowMeasureTemperature != null
          ? clientData.allowMeasureTemperature
          : false,
      allowMeasureWeight:
        clientData?.allowMeasureWeight != null
          ? clientData.allowMeasureWeight
          : false,
      allowMeasureBloodPressure:
        clientData?.allowMeasureBloodPressure != null
          ? clientData.allowMeasureBloodPressure
          : false,
      allowMeasureOxygen:
        clientData?.allowMeasureOxygen != null
          ? clientData.allowMeasureOxygen
          : false,
      allowMeasureSpirometer:
        clientData?.allowMeasureSpirometer != null
          ? clientData.allowMeasureSpirometer
          : false,
      allowMeasureBloodSugar:
        clientData?.allowMeasureBloodSugar != null
          ? clientData.allowMeasureBloodSugar
          : false,
      gatewayKey: clientData?.gatewayKey || null,
      trackerKey: clientData?.trackerKey || null,
      carephoneKey: clientData?.carephoneKey || null,
      pindoraId: clientData?.pindoraId || null,
      videoCallRoomName: clientData?.videoCallRoomName || null,
      videoSerialNumber: clientData?.videoSerialNumber || null,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit: ", values)

      let temp = new Object()
      temp.trackerKey = values.trackerKey != null ? values.trackerKey : ""
      temp.carephoneKey = values.carephoneKey != null ? values.carephoneKey : ""
      temp.pindoraId = values.pindoraId != null ? values.pindoraId : ""
      temp.gatewayKey = values.gatewayKey != null ? values.gatewayKey : ""
      temp.videoSerialNumber =
        values.videoSerialNumber != null ? values.videoSerialNumber : ""
      temp.videoCallRoomName =
        values.videoCallRoomName != null ? values.videoCallRoomName : ""

      temp.gillieIntegration =
        values.gillieIntegration != null ? values.gillieIntegration : ""
      temp.gillieEnabled = values.gillieEnabled
      temp.hilkkaEnabled = values.hilkkaEnabled
      temp.hilkkaCustomerId = values.hilkkaCustomerId
      temp.ssnNumber = values.ssnNumber != null ? values.ssnNumber : ""

      temp.healthMeasurements = values.healthMeasurements
      temp.journalAllowHealthEntries = values.journalAllowHealthEntries
      temp.allowMeasureTemperature = values.allowMeasureTemperature
      temp.allowMeasureWeight = values.allowMeasureWeight
      temp.allowMeasureBloodPressure = values.allowMeasureBloodPressure
      temp.allowMeasureOxygen = values.allowMeasureOxygen
      temp.allowMeasureSpirometer = values.allowMeasureSpirometer
      temp.allowMeasureBloodSugar = values.allowMeasureBloodSugar

      console.log("Final submit: ", temp)

      if (props.suvantoKey) {
        setUpdating(true)
        updateClientInfo(abortRef.current, props.suvantoKey, temp)
          .then(result => {
            if (result.statusCode == 200) {
              if (props.refreshClient) props.refreshClient()
              getData()
              showToast(props.t("Information updated"), "success")
            } else {
              showToast(props.t("Failed to update information"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Failed to update information"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      }
    },
  })

  // Move gw to another client
  const moveGateway = () => {
    if (props.suvantoKey) {
      if (
        (transferType == "new" && transferGroup && newClientName) ||
        (transferType == "existing" && transferClient)
      ) {
        setUpdating(true)
        transferGateway(
          abortRef.current,
          transferType,
          props.suvantoKey,
          transferClient,
          transferGroup,
          newClientName
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Success"), "success")
              if (props.refreshClient) props.refreshClient()
              getData()
              setNewClientName("")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      } else {
        showToast(props.t("Invalid form values"), "error")
      }
    }
  }

  // Move tracker to another client
  const moveTracker = () => {
    if (props.suvantoKey) {
      if (
        (transferType == "new" && transferGroup && newClientName) ||
        (transferType == "existing" && transferClient)
      ) {
        setUpdating(true)
        transferTracker(
          abortRef.current,
          transferType,
          props.suvantoKey,
          transferClient,
          transferGroup,
          newClientName
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Success"), "success")
              if (props.refreshClient) props.refreshClient()
              getData()
              setNewClientName("")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      } else {
        showToast(props.t("Invalid form values"), "error")
      }
    }
  }

  // Move carephone to another client
  const moveCarephone = () => {
    if (props.suvantoKey) {
      if (
        (transferType == "new" && transferGroup && newClientName) ||
        (transferType == "existing" && transferClient)
      ) {
        setUpdating(true)
        transferCarephone(
          abortRef.current,
          transferType,
          props.suvantoKey,
          transferClient,
          transferGroup,
          newClientName
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Success"), "success")
              if (props.refreshClient) props.refreshClient()
              getData()
              setNewClientName("")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      } else {
        showToast(props.t("Invalid form values"), "error")
      }
    }
  }

  // Move Pindora to another client
  const movePindora = () => {
    if (props.suvantoKey) {
      if (
        (transferType == "new" && transferGroup && newClientName) ||
        (transferType == "existing" && transferClient)
      ) {
        setUpdating(true)
        transferSmartlock(
          abortRef.current,
          transferType,
          props.suvantoKey,
          transferClient,
          transferGroup,
          newClientName
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Success"), "success")
              if (props.refreshClient) props.refreshClient()
              getData()
              setNewClientName("")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      } else {
        showToast(props.t("Invalid form values"), "error")
      }
    }
  }

  // Move video device + room to another client
  const moveVideos = () => {
    if (props.suvantoKey) {
      if (
        (transferType == "new" && transferGroup && newClientName) ||
        (transferType == "existing" && transferClient)
      ) {
        setUpdating(true)
        transferVideos(
          abortRef.current,
          transferType,
          props.suvantoKey,
          transferClient,
          transferGroup,
          newClientName
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Success"), "success")
              if (props.refreshClient) props.refreshClient()
              getData()
              setNewClientName("")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setUpdating(false)
          })
      } else {
        showToast(props.t("Invalid form values"), "error")
      }
    }
  }

  // Gateway selection changed
  const gatewayChanged = gatewayKey => {
    validation.setFieldValue("gatewayKey", gatewayKey)
  }

  // Tracker selection changed
  const trackerChanged = trackerKey => {
    validation.setFieldValue("trackerKey", trackerKey)
  }

  // Carephone selection changed
  const carephoneChanged = carephoneKey => {
    validation.setFieldValue("carephoneKey", carephoneKey)
  }

  // Pindora selection changed
  const pindoraChanged = pindoraId => {
    validation.setFieldValue("pindoraId", pindoraId)
  }

  // Video device changed
  const videoDeviceChanged = serial => {
    validation.setFieldValue("videoSerialNumber", serial)
  }

  // Video room changed
  const videoRoomChanged = room => {
    validation.setFieldValue("videoCallRoomName", room)
  }

  // Group selection changed
  const groupChanged = group => {
    setTransferGroup(group)
  }

  // Client selection changed
  const clientChanged = client => {
    setTransferClient(client)
  }

  // Transfer bits
  const renderTransfer = () => {
    return (
      <>
        <FormGroup>
          <Label>{props.t("Transfer device to")}</Label>
          <Input
            type="select"
            value={transferType}
            onChange={v => {
              setTransferType(v.target.value)
            }}
          >
            <option value="existing">{props.t("An existing client")}</option>
            <option value="new">{props.t("A new client")}</option>
          </Input>
        </FormGroup>
        {transferType == "existing" && (
          <>
            <FormGroup>
              <Label>{props.t("Client")}</Label>
              <SelectClient
                onChange={clientChanged}
                selection={transferClient}
              />
            </FormGroup>
          </>
        )}
        {transferType == "new" && (
          <>
            <FormGroup>
              <Label>{props.t("Group")}</Label>
              <SelectGroup
                onChange={groupChanged}
                multiple={false}
                clearable={false}
                selection={transferGroup}
              />
            </FormGroup>
            <FormGroup>
              <Label>{props.t("Name")}</Label>
              <Input
                type="text"
                value={newClientName}
                placeholder={props.t("New client name")}
                onChange={v => {
                  setNewClientName(v.target.value)
                }}
              ></Input>
            </FormGroup>
          </>
        )}
      </>
    )
  }

  return (
    <>
      <span>
        <span className="h4">
          <Row>
            <Col></Col>
            <Col className="col-auto">
              <Button
                color="success"
                disabled={loading || updating}
                onClick={() => {
                  validation.submitForm()
                }}
              >
                <i className="bx bxs-save me-2" />
                {props.t("Save changes")}
              </Button>
            </Col>
          </Row>
        </span>

        {loading && (
          <Lottie
            options={getLottieLoadingOptions()}
            height={100}
            width={100}
          />
        )}

        {!loading && !clientData && (
          <Card>
            <CardBody>
              <div className="text-center mb-4">
                <Lottie
                  options={getLottieNothingFound()}
                  height={150}
                  width={150}
                />
                <h5 className="text-muted mt-2">
                  {props.t("Something went wrong")}
                </h5>
              </div>
            </CardBody>
          </Card>
        )}

        {!loading && clientData && (
          <Row xs={1} xl={2} className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Gateway")}</CardTitle>
                  <Row>
                    <Col>
                      <SelectGateway
                        onChange={gatewayChanged}
                        selection={validation.values.gatewayKey}
                        multiple={false}
                        clearable={true}
                      />
                    </Col>
                    <Col className="col-auto">
                      <ButtonDropdown
                        isOpen={showGatewayDropdown}
                        toggle={() => {
                          setShowGatewayDropdown(!showGatewayDropdown)
                        }}
                      >
                        <DropdownToggle
                          color="btn btn-light square-icon-button-sm"
                          style={{ borderRadius: "4px" }}
                        >
                          <i className="mdi mdi-chevron-down text-muted" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              window.open(
                                "/config/gateways?search=" +
                                  validation.values.gatewayKey,
                                "_blank",
                                "noreferrer"
                              )
                            }}
                          >
                            {props.t("View in device list")}
                          </DropdownItem>
                          <DropdownItem
                            disabled={!validation.values.gatewayKey}
                            onClick={() => {
                              setShowGatewayDrawer(true)
                            }}
                          >
                            {props.t("Open settings")}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  </Row>
                  <GatewayDrawer
                    showDrawer={showGatewayDrawer}
                    setShowDrawer={setShowGatewayDrawer}
                    gatewayKey={validation.values.gatewayKey}
                  />
                </CardBody>
                {clientData?.gatewayKey && (
                  <UncontrolledAccordion
                    flush
                    defaultOpen={[]}
                    stayOpen
                    className="border-top"
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="pt-0"
                        style={{ paddingTop: "0px" }}
                      >
                        {renderTransfer()}
                        <div className="justify-content-center d-flex">
                          <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            disabled={updating}
                            onClick={() => {
                              moveGateway()
                            }}
                          >
                            {props.t("Transfer")}
                          </Button>
                        </div>
                      </AccordionBody>
                      <AccordionHeader targetId="1">
                        {props.t("Device transfer")}
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                )}
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody className="mb-2">
                  <CardTitle className="mb-4">{props.t("Tracker")}</CardTitle>
                  <Row>
                    <Col>
                      <SelectTracker
                        onChange={trackerChanged}
                        selection={validation.values.trackerKey}
                        multiple={false}
                        clearable={true}
                      />
                    </Col>
                    <Col className="col-auto">
                      <ButtonDropdown
                        isOpen={showTrackerDropdown}
                        toggle={() => {
                          setShowTrackerDropdown(!showTrackerDropdown)
                        }}
                      >
                        <DropdownToggle
                          color="btn btn-light square-icon-button-sm"
                          style={{ borderRadius: "4px" }}
                        >
                          <i className="mdi mdi-chevron-down text-muted" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              window.open(
                                "/config/trackers?search=" +
                                  validation.values.trackerKey,
                                "_blank",
                                "noreferrer"
                              )
                            }}
                          >
                            {props.t("View in device list")}
                          </DropdownItem>
                          <DropdownItem
                            disabled
                            onClick={() => {}}
                          >
                            {props.t("Open settings")}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  </Row>
                </CardBody>

                {clientData?.trackerKey && (
                  <UncontrolledAccordion
                    flush
                    defaultOpen={[]}
                    stayOpen
                    className="border-top"
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="pt-0"
                        style={{ paddingTop: "0px" }}
                      >
                        {renderTransfer()}
                        <div className="justify-content-center d-flex">
                          <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            disabled={updating}
                            onClick={() => {
                              moveTracker()
                            }}
                          >
                            {props.t("Transfer")}
                          </Button>
                        </div>
                      </AccordionBody>
                      <AccordionHeader targetId="1">
                        {props.t("Device transfer")}
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                )}
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody className="mb-2">
                  <CardTitle className="mb-4">{props.t("Carephone")}</CardTitle>
                  <Row>
                    <Col>
                      <SelectCarephone
                        onChange={carephoneChanged}
                        selection={validation.values.carephoneKey}
                        multiple={false}
                        clearable={true}
                      />
                    </Col>
                    <Col className="col-auto">
                      <ButtonDropdown
                        isOpen={showCarephoneDropdown}
                        toggle={() => {
                          setShowCarephoneDropdown(!showCarephoneDropdown)
                        }}
                      >
                        <DropdownToggle
                          color="btn btn-light square-icon-button-sm"
                          style={{ borderRadius: "4px" }}
                        >
                          <i className="mdi mdi-chevron-down text-muted" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              window.open(
                                "/config/carephones?search=" +
                                  validation.values.carephoneKey,
                                "_blank",
                                "noreferrer"
                              )
                            }}
                          >
                            {props.t("View in device list")}
                          </DropdownItem>
                          <DropdownItem
                            disabled
                            onClick={() => {}}
                          >
                            {props.t("Open settings")}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  </Row>
                </CardBody>

                {clientData?.carephoneKey && (
                  <UncontrolledAccordion
                    flush
                    defaultOpen={[]}
                    stayOpen
                    className="border-top"
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="pt-0"
                        style={{ paddingTop: "0px" }}
                      >
                        {renderTransfer()}
                        <div className="justify-content-center d-flex">
                          <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            disabled={updating}
                            onClick={() => {
                              moveCarephone()
                            }}
                          >
                            {props.t("Transfer")}
                          </Button>
                        </div>
                      </AccordionBody>
                      <AccordionHeader targetId="1">
                        {props.t("Device transfer")}
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                )}
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody className="mb-2">
                  <CardTitle className="mb-4">{"Pindora"}</CardTitle>
                  <Row>
                    <Col>
                      <SelectPindora
                        onChange={pindoraChanged}
                        selection={validation.values.pindoraId}
                        multiple={false}
                        clearable={true}
                      />
                    </Col>
                    <Col className="col-auto">
                      <ButtonDropdown
                        isOpen={showPindoraDropdown}
                        toggle={() => {
                          setShowPindoraDropdown(!showPindoraDropdown)
                        }}
                      >
                        <DropdownToggle
                          color="btn btn-light square-icon-button-sm"
                          style={{ borderRadius: "4px" }}
                        >
                          <i className="mdi mdi-chevron-down text-muted" />
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              window.open(
                                "/config/pindoras?search=" +
                                  validation.values.pindoraId,
                                "_blank",
                                "noreferrer"
                              )
                            }}
                          >
                            {props.t("View in device list")}
                          </DropdownItem>
                          <DropdownItem
                            disabled
                            onClick={() => {}}
                          >
                            {props.t("Open settings")}
                          </DropdownItem>
                        </DropdownMenu>
                      </ButtonDropdown>
                    </Col>
                  </Row>
                </CardBody>

                {clientData?.pindoraId && (
                  <UncontrolledAccordion
                    flush
                    defaultOpen={[]}
                    stayOpen
                    className="border-top"
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="pt-0"
                        style={{ paddingTop: "0px" }}
                      >
                        {renderTransfer()}
                        <div className="justify-content-center d-flex">
                          <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            disabled={updating}
                            onClick={() => {
                              movePindora()
                            }}
                          >
                            {props.t("Transfer")}
                          </Button>
                        </div>
                      </AccordionBody>
                      <AccordionHeader targetId="1">
                        {props.t("Device transfer")}
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                )}
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">{props.t("Video")}</CardTitle>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <SelectVideoDevice
                            onChange={videoDeviceChanged}
                            selection={validation.values.videoSerialNumber}
                            multiple={false}
                            clearable={true}
                          />
                        </Col>
                        <Col className="col-auto">
                          <ButtonDropdown
                            isOpen={showVideoDeviceDropdown}
                            toggle={() => {
                              setShowVideoDeviceDropdown(
                                !showVideoDeviceDropdown
                              )
                            }}
                          >
                            <DropdownToggle
                              color="btn btn-light square-icon-button-sm"
                              style={{ borderRadius: "4px" }}
                            >
                              <i className="mdi mdi-chevron-down text-muted" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  window.open(
                                    "/config/video-devices?search=" +
                                      validation.values.videoSerialNumber,
                                    "_blank",
                                    "noreferrer"
                                  )
                                }}
                              >
                                {props.t("View in device list")}
                              </DropdownItem>
                              <DropdownItem
                                disabled
                                onClick={() => {}}
                              >
                                {props.t("Open settings")}
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Col>
                      </Row>
                      <Button
                        size="sm"
                        color="link"
                        className="mt-1"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            validation.values.videoSerialNumber
                          )
                          showToast(props.t("Copied to clipboard"), "info")
                        }}
                      >
                        {props.t("Copy to clipboard")}
                      </Button>
                    </Col>
                    <Col>
                      <Row>
                        <Col>
                          <SelectVideoRoom
                            onChange={videoRoomChanged}
                            selection={validation.values.videoCallRoomName}
                            multiple={false}
                            clearable={true}
                          />
                        </Col>
                        <Col className="col-auto">
                          <ButtonDropdown
                            isOpen={showVideoRoomDropdown}
                            toggle={() => {
                              setShowVideoRoomDropdown(!showVideoRoomDropdown)
                            }}
                          >
                            <DropdownToggle
                              color="btn btn-light square-icon-button-sm"
                              style={{ borderRadius: "4px" }}
                            >
                              <i className="mdi mdi-chevron-down text-muted" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  window.open(
                                    "/config/video-numbers?search=" +
                                      validation.values.videoCallRoomName,
                                    "_blank",
                                    "noreferrer"
                                  )
                                }}
                              >
                                {props.t("View in device list")}
                              </DropdownItem>
                              <DropdownItem
                                disabled
                                onClick={() => {}}
                              >
                                {props.t("Open settings")}
                              </DropdownItem>
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
                {clientData?.videoSerialNumber && (
                  <UncontrolledAccordion
                    flush
                    defaultOpen={[]}
                    stayOpen
                    className="border-top"
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="pt-0"
                        style={{ paddingTop: "0px" }}
                      >
                        {renderTransfer()}
                        <div className="justify-content-center d-flex">
                          <Button
                            style={{ minWidth: "150px" }}
                            color="primary"
                            disabled={updating}
                            onClick={() => {
                              moveVideos()
                            }}
                          >
                            {props.t("Transfer")}
                          </Button>
                        </div>
                      </AccordionBody>
                      <AccordionHeader targetId="1">
                        {props.t("Device transfer")}
                      </AccordionHeader>
                    </AccordionItem>
                  </UncontrolledAccordion>
                )}
              </Card>
            </Col>

            <Col></Col>

            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Measurements")}
                  </CardTitle>

                  <Row xs={1} sm={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Enabled")}</Label>
                        <Switch
                          className="d-block"
                          name="healthMeasurements"
                          checked={validation.values.healthMeasurements}
                          onChange={v => {
                            validation.setFieldValue("healthMeasurements", v)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Show in journal")}</Label>
                        <Switch
                          className="d-block"
                          name="journalAllowHealthEntries"
                          checked={validation.values.journalAllowHealthEntries}
                          onChange={v => {
                            validation.setFieldValue(
                              "journalAllowHealthEntries",
                              v
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Temperature")}</Label>
                        <Switch
                          className="d-block"
                          name="allowMeasureTemperature"
                          checked={validation.values.allowMeasureTemperature}
                          onChange={v => {
                            validation.setFieldValue(
                              "allowMeasureTemperature",
                              v
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Weight")}</Label>
                        <Switch
                          className="d-block"
                          name="allowMeasureWeight"
                          checked={validation.values.allowMeasureWeight}
                          onChange={v => {
                            validation.setFieldValue("allowMeasureWeight", v)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Blood pressure")}</Label>
                        <Switch
                          className="d-block"
                          name="allowMeasureBloodPressure"
                          checked={validation.values.allowMeasureBloodPressure}
                          onChange={v => {
                            validation.setFieldValue(
                              "allowMeasureBloodPressure",
                              v
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Oxygen saturation")}</Label>
                        <Switch
                          className="d-block"
                          name="allowMeasureOxygen"
                          checked={validation.values.allowMeasureOxygen}
                          onChange={v => {
                            validation.setFieldValue("allowMeasureOxygen", v)
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Blood sugar")}</Label>
                        <Switch
                          className="d-block"
                          name="allowMeasureBloodSugar"
                          checked={validation.values.allowMeasureBloodSugar}
                          onChange={v => {
                            validation.setFieldValue(
                              "allowMeasureBloodSugar",
                              v
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("PEF")}</Label>
                        <Switch
                          className="d-block"
                          name="allowMeasureSpirometer"
                          checked={validation.values.allowMeasureSpirometer}
                          onChange={v => {
                            validation.setFieldValue(
                              "allowMeasureSpirometer",
                              v
                            )
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("External systems")}
                  </CardTitle>
                  <Form
                    className="form-horizontal"
                    onSubmit={e => {
                      e.preventDefault()
                      return false
                    }}
                  >
                    <Row xs={1} sm={2} className="mt-3">
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Gillie")}</Label>
                          <Switch
                            className="d-block"
                            name="gillieEnabled"
                            checked={validation.values.gillieEnabled}
                            onChange={v => {
                              validation.setFieldValue("gillieEnabled", v)
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{props.t("Gillie integration")}</Label>
                          <Input
                            bsSize="sm"
                            name="gillieIntegration"
                            type="select"
                            value={validation.values.gillieIntegration}
                            onChange={validation.handleChange}
                          >
                            <option value="">{props.t("Undefined")}</option>
                            <option value="eloisa">{"Eloisa"}</option>
                            <option value="hyvaep">{"Hyvaep"}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr />
                    <Row xs={1} sm={2} className="mt-3">
                      <Col>
                        <FormGroup>
                          <Label>{"Hilkka"}</Label>
                          <Switch
                            className="d-block"
                            name="hilkkaEnabled"
                            checked={validation.values.hilkkaEnabled}
                            onChange={v => {
                              validation.setFieldValue("hilkkaEnabled", v)
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{"Hilkka " + props.t("customer ID")}</Label>
                          <Input
                            bsSize={"sm"}
                            name="hilkkaCustomerId"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.hilkkaCustomerId}
                          ></Input>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label>{props.t("SSN")}</Label>
                          <Input
                            bsSize={"sm"}
                            name="ssnNumber"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.ssnNumber}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </span>
    </>
  )
}

ClientIntegrations.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.any,
  refreshClient: PropTypes.func,
}

export default withTranslation()(ClientIntegrations)
