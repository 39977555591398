// Landing page -content
import React, { useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"

const CustomerManagementManual = props => {
  const [activeTab] = useState(props.tab)
  return (
    <div className="instructions">
      <div className="px-1">
        <h5 className="font-size-15">{props.t("Customer management")}</h5>
        <p className="pt-2">{props.t("CustomerManagement." + "0")}</p>
      </div>
      <Collapse
        bordered={false}
        className="px-0"
        accordion
        expandIcon={props.ExpandIcon}
        defaultActiveKey={[activeTab]}
        style={{ backgroundColor: "transparent" }}
        items={[
          {
            key: "null",
            label: props.t("Start"),
            children: (
              <>
                <p>{props.t("CustomerManagement." + "1")}</p>
                <p>{props.t("CustomerManagement." + "2")}</p>
              </>
            ),
          },
          //        <Panel key={1} header={props.t("Navigation" + ".Suvanto Home")}>
          //   <p>{props.t("InstuctionsVideo." + "StartCall")}</p>
          // </Panel>
          // <Panel key={2} header={props.t("Navigation" + ".Suvanto OnTheGo")}>
          //   <p>{props.t("InstuctionsVideo." + "StartCall")}</p>
          // </Panel>
          // <Panel key={3} header={props.t("Navigation" + ".Carephone")}>
          //   <p>{props.t("InstuctionsVideo." + "StartCall")}</p>
          // </Panel>
          {
            key: "4",
            label: props.t("Navigation" + ".Video"),
            children: (
              <>
                <h6 className="">{props.t("Start video call")}</h6>
                <p className="">{props.t("InstuctionsVideo." + "StartCall")}</p>
                <ul className="">
                  <li className="text-muted">
                    {props.t("InstuctionsVideo." + "StartCall1")}
                  </li>
                  <li className="">
                    {props.t("InstuctionsVideo." + "StartCall2")}
                  </li>
                </ul>
                <p className="">
                  {props.t("InstuctionsVideo." + "StartCall3")}
                </p>
                <p className="">
                  {props.t("InstuctionsVideo." + "StartCall4")}
                </p>
                <h6>{props.t("Send a message to video device")}</h6>
                <p className="">
                  {props.t("InstuctionsVideo." + "SendMessage")}
                </p>
              </>
            ),
          },
          // TODO: !! ISTEKKI VIDEO VIDEOLLE LINKKI TARKEMPIIN OHJEISIIN OhjeVideo: suvanto.info/#6
          // <Panel key={5} header={props.t("Navigation" + ".Dose")}>
          //   <p>{props.t("InstuctionsVideo." + "StartCall")}</p>
          // </Panel>
        ]}
      ></Collapse>
    </div>
  )
}
CustomerManagementManual.propTypes = {
  t: PropTypes.any,
  tab: PropTypes.any,
  ExpandIcon: PropTypes.func,
}

export default withTranslation()(CustomerManagementManual)
