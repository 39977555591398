import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Select, ConfigProvider } from "antd"

const EventRepetitionSelect = props => {
  const [options, setOptions] = useState([])
  const [selectedValue, setSelectedValue] = useState("0")

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()

    let opts = [
        {label: props.t("Does not repeat"), value: "0"},
        {label: props.t("Daily"), value: "1"},
        {label: props.t("Weekly"), value: "2"},
        {label: props.t("Every other week"), value: "3"},
        {label: props.t("Monthly"), value: "4"},
        {label: props.t("Weekdays"), value: "5"},
    ]
    setOptions(opts)

    return () => abortRef.current.abort()
  }, [])

  const handleChange = newSelection => {
    setSelectedValue(newSelection)
    if (props.setSelected != null) {
      props.setSelected(newSelection)
    }
  }

  return (
    <div>
      <ConfigProvider>
        <div className="config-provider">
          <Select
            style={{ width: 100 + "%" }}
            value={selectedValue}
            onChange={handleChange}
            allowClear={false}
            options={options}
          />
        </div>
      </ConfigProvider>
    </div>
  )
}

EventRepetitionSelect.propTypes = {
  t: PropTypes.any,
  setSelected: PropTypes.func,
}

export default withTranslation()(EventRepetitionSelect)