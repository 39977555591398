import withRouter from "components/Common/withRouter"
import GlobalState from "contexts/GlobalState"
import { suvantoLogout } from "helpers/backend_helper"
import React, { useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"

const Logout = props => {
  const [state, setState] = useContext(GlobalState)
  const navigate = useNavigate()

  useEffect(() => {
    console.log("Logout")
    try {
      // KEYCLOAK-LOGIN commented block
      /*
      // Send logout info to server
      if (localStorage.getItem("session_id")) {
        suvantoLogout().catch((err) => {});
      }

      setState({})
      localStorage.removeItem("session_id")
      navigate("/login")
      */

      // KEYCLOAK-LOGIN-START added block
      // Send logout info to server
      /*if (localStorage.getItem("api_token")) {
        suvantoLogout().catch((err) => {});
      }*/

      localStorage.removeItem("session_id")
      localStorage.removeItem("api_token")

      // Set logout event flag in localstorage for all tabs to logout
      localStorage.setItem("logout", Date.now())

      // Call Keycloak instances logout function that is set in the GlobalState
      if (state.logout) {
        state.logout()
      }
      setState({})
      
      //navigate("/main") // Not needed?
      // KEYCLOAK-LOGIN-END

    } catch (error) {
      console.log(error)
    }
  }, [])

  return <></>
}

Logout.propTypes = {
  //history: PropTypes.object,
}

export default withRouter(Logout)
