import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"

import { getUserList } from "helpers/keycloak_api_helper"
import { getVideoRoomSettings } from "helpers/backend_helper"
import {
  defaultPageSizeOptions,
  getLottieLoadingOptions,
  showToast,
  stringSorter,
  downloadExcel,
  exportPDF,
  getLottieSearch,
} from "utils/general"

import OrganizationUserDrawer from "./OrganizationUserDrawer"
import { default as SelectGroupFilters } from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Lottie from "react-lottie"
import classnames from "classnames"
import "jspdf-autotable"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"

const OrganizationUserManagement = props => {
  const [group, setGroup] = useState("")
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [updateCounter] = useState(0)
  const [selectedUsers] = useState([])
  const [ttdownloadstatstable, setTtdownloadstatstable] = useState(false) // Tooltip state for download button
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])
  const [title] = useState(props.t("User management"))
  const [colSize] = useState(12)
  const [activeTab, setActiveTab] = useState("1")

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getAdmins()
  }, [group])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // Name tags state update from app-users-selected.js
  useEffect(() => {
    if (selectedUsers.length == 0) {
      start()
    }
  }, [selectedUsers])

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.username?.toLowerCase().includes(searchText) ||
        data.description?.toLowerCase().includes(searchText) ||
        data.groups?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText) ||
        data.fullName?.toLowerCase().includes(searchText)
    )
    setFilteredUsers(result)
  }

  // Get admin users for table
  const getAdmins = () => {
    setUsers([])
    if (!loading && group?.length > 0) {
      setLoading(true)
      getUserList(abortRef.current, group, false, true)
        .then(result => {
          // console.log("userlist:", result)
          if (Array.isArray(result)) {
            result.sort((a, b) => (a.username > b.username ? 1 : -1))
            result.forEach(user => {
              let first = user.firstName ?? ""
              let last = user.lastName ?? ""
              user.fullName = (first + " " + last).trim()
              if (user.attributes) {
                user.description = user.attributes.description
                  ? user.attributes.description[0]
                  : ""
                user.gsm = user.attributes.gsm ? user.attributes.gsm[0] : ""
                user.videoRoomId = user.attributes.videoRoomId
                  ? user.attributes.videoRoomId[0]
                  : ""
              }
            })

            // Get video room settings
            const videoRoomIds = result
              .map(user => user.attributes?.videoRoomId?.[0])
              .filter(id => id) // Remove undefined/null values

            // Combine values
            ;(async () => {
              let groupHostValues = await getRooms(videoRoomIds)
              // console.log("groupHostValues:", groupHostValues)

              const userValues = result.map(room => ({
                ...room,
                groupHost:
                  groupHostValues.find(
                    entry => entry.roomName === room.videoRoomId
                  )?.groupCallHost || null,
              }))
              // console.log("merge", userValues)
              setUsers(userValues)
            })()
          } else {
            setUsers([])
          }
        })
        .catch(err => {
          console.log(err)
          setUsers([])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const getRooms = async videoRoomIds => {
    try {
      const result = await getVideoRoomSettings(
        abortRef.current,
        JSON.stringify(videoRoomIds)
      )
      return result.entries
    } catch (error) {
      return []
    }
  }

  // Empty selected users
  const start = () => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 200)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Username"),
        props.t("Description"),
        props.t("Professional"),
        props.t("Email"),
        props.t("GSM"),
        props.t("Groups"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      elt.username,
      elt.description,
      elt.basicUser,
      elt.email,
      elt.gsm,
      elt.groups,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  const generatePdf = () => {
    const heading = [
      props.t("Username"),
      props.t("Description"),
      props.t("Professional"),
      props.t("Email"),
      props.t("GSM"),
      props.t("Groups"),
    ]
    const data = filteredUsers.map(elt => [
      elt.username,
      elt.description,
      elt.basicUser,
      elt.email,
      elt.gsm,
      elt.groups,
    ])

    exportPDF(title, heading, data)
    showToast(props.t("PDF file exported succesfully!"), "success")
  }

  // Table structure
  const columns = [
    {
      title: props.t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      title: props.t("Username"),
      dataIndex: "username",
      render: usernameFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "username", sortOrder)
      },
      fixed: "left",
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Name"),
      dataIndex: "fullName",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "fullName", sortOrder)
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Description"),
      dataIndex: "description",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "description", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      title: props.t("Professional"),
      dataIndex: "basicUser",
      sorter: (a, b) => a.basicUser - b.basicUser,
      render: basicFormatter,
      align: "center",
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Email"),
      dataIndex: "email",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "email", sortOrder)
      },
      width: "250px",
      ellipsis: true,
    },
    {
      title: props.t("GSM"),
      dataIndex: "gsm",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Groups"),
      dataIndex: "groups",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groups", sortOrder)
      },
      width: "150px",
      ellipsis: true,
    },
  ]

  // Table formatting functions start
  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function usernameFormatter(cell, row) {
    return (
      <>
        <span className={classnames({ "text-danger": !row.enabled })}>
          {row.username}
        </span>
        {!row.enabled && (
          <span className="ms-2 badge bg-danger font-size-12">
            {props.t("Disabled")}
          </span>
        )}
        {row.videoRoomId && !!row.groupHost && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function basicFormatter(cell, row) {
    if (row.basicUser) {
      return <i className="mdi mdi-check-bold text-primary"></i>
    } else return <>{/* <i className="mdi mdi-close text-light"></i> */}</>
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={title} />
          <Row className="mb-4">
            <Col xs="4">
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
            </Col>
            <Col xs="4">
              <SelectGroupFilters
                onChange={setGroup}
                multiple={false}
                disabled={loading}
                selectedTags={selectedTags}
                updateCounter={updateCounter}
              />
            </Col>
          </Row>

          {!group && !loading && (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={getLottieSearch()}
                    height={150}
                    width={180}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          )}

          {!!group && (
            <div>
              <Row>
                <Col sm={colSize}>
                  <Card>
                    <CardHeader className="bg-transparent border-bottom">
                      <div className="d-flex flex-wrap">
                        <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                          <NavItem>
                            <NavLink
                              to="#"
                              active={activeTab === "1"}
                              onClick={() => {
                                setActiveTab("1")
                              }}
                            >
                              {props.t("Users")}
                            </NavLink>
                          </NavItem>
                        </ul>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          {
                            <React.Fragment>
                              <Row>
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value.toLowerCase()
                                            )
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col>
                                  <span className="float-end ms-2">
                                    <Tooltip
                                      placement="bottom"
                                      isOpen={ttdownloadstatstable}
                                      target="downloadstatstable"
                                      toggle={() => {
                                        setTtdownloadstatstable(
                                          !ttdownloadstatstable
                                        )
                                      }}
                                    >
                                      {props.t("Download statistics")}
                                    </Tooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => generateExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => generatePdf()}
                                        >
                                          {props.t("Download .pdf")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className="mt-2 float-end">
                                    <p>
                                      {filteredUsers.length} {props.t("users")}
                                    </p>
                                  </span>
                                </Col>
                              </Row>

                              {!loading && (
                                <>
                                  <div className="mt-2">
                                    <AntdTable
                                      className="striped colored-header"
                                      bordered
                                      size="middle"
                                      sticky
                                      rowKey={"id"}
                                      columns={columns}
                                      dataSource={filteredUsers}
                                      pagination={{
                                        showSizeChanger: true,
                                        defaultPageSize: 100,
                                        pageSizeOptions: defaultPageSizeOptions,
                                      }}
                                      tableLayout="auto"
                                      scroll={{
                                        x: "max-content",
                                        y: tableDefaultHeight,
                                      }}
                                    />
                                  </div>
                                </>
                              )}
                              {loading && (
                                <Lottie
                                  options={getLottieLoadingOptions()}
                                  height={100}
                                  width={100}
                                ></Lottie>
                              )}
                            </React.Fragment>
                          }
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>{" "}
              </Row>
            </div>
          )}

          <OrganizationUserDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getAdmins={getAdmins}
          />
        </Container>
      </div>
    </>
  )
}

OrganizationUserManagement.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OrganizationUserManagement)
