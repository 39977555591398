/**
 * KEYCLOAK-LOGIN different Authmiddleware version for Keycloak
 */

import React, { useContext, useEffect, useRef, useState } from "react"
import { Navigate } from "react-router-dom"
import PropTypes from "prop-types"
import GlobalState from "contexts/GlobalState"
import { getUserInfo } from "helpers/backend_helper"

const Authmiddleware = props => {
  const [state, setState] = useContext(GlobalState)
  const [preloader, setPreloader] = useState(true)
  const abortRef = useRef(null)

  useEffect(() => {
    //console.log(localStorage.getItem("session_id"), "session id")
    abortRef.current = new AbortController()

    //console.log("ROUTE ", localStorage.getItem("api_token"))

    if (localStorage.getItem("api_token")) {
      setPreloader(true)
      getUserInfo(abortRef.current)
        .then(data => {
          if (data.username != null) {
            setState(state => ({ ...state, authUser: JSON.stringify(data) }))
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setPreloader(false)
        })
    } else {
      setPreloader(false)
    }
    return () => abortRef.current.abort()
  }, [])

  /*useEffect(() => {
    console.log("preloader: ", preloader)
    console.log("auth-user: ", state.authUser)
  }, [preloader])*/

  if (preloader) {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    )
  }
  if (state.authUser == null || !localStorage.getItem("api_token")) {
    //console.log("localStorage", localStorage)
    //console.log("auth-user: ", state.authUser)
    return (
      <Navigate to={{ pathname: "/logout", state: { from: props.location } }} />
    )
  }

  if (props.roles != null && props.roles.length > 0) {
    try {
      const user = JSON.parse(state.authUser)
      if (!props.roles.includes(user.role)) {
        return (
          <Navigate
            to={{ pathname: "/main", state: { from: props.location } }}
          />
        )
      }
    } catch (e) {
      console.log("e")
      return (
        <Navigate to={{ pathname: "/main", state: { from: props.location } }} />
      )
    }
  }

  // Special permissions
  if (props.permission != null && props.permission.length > 0) {
    try {
      const obj = JSON.parse(state.authUser)
      if (
        obj.role != "system_admin" &&
        (obj.access_permissions == null ||
          obj.access_permissions[props.permission] != true)
      ) {
        return (
          <Navigate
            to={{ pathname: "/main", state: { from: props.location } }}
          />
        )
      }
    } catch (e) {
      return (
        <Navigate to={{ pathname: "/main", state: { from: props.location } }} />
      )
    }
  }

  //console.log("Route Return: " + props.children)
  return <React.Fragment>{props.children}</React.Fragment>
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  children: PropTypes.any,
  roles: PropTypes.array,
  permission: PropTypes.any,
  location: PropTypes.object,
  globalState: PropTypes.any,
  preloader: PropTypes.bool,
}

export default Authmiddleware