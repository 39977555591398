import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"

const ConcentEventsManual = props => {
  return (
    <div className="instructions">
      <div className="px-1">
        <h5 className="font-size-15">{props.t("Content events")}</h5>
        <p className="pt-2">{props.t("ConcentEventsManual." + "1")}</p>
        <p>{props.t("ConcentEventsManual." + "Groups")}</p>
      </div>
      <Collapse
        bordered={false}
        className="px-0"
        accordion
        expandIcon={props.ExpandIcon}
        style={{ backgroundColor: "transparent" }}
        items={[
          // {
          //   key: "Groups",
          //   label: props.t("Groups"),
          //   children: (
          //     <>
          //       <p>{props.t("ConcentEventsManual." + "Groups")}</p>
          //       {/* <p>{props.t("ConcentEventsManual." + "Groups2")}</p> */}
          //     </>
          //   ),
          // },
          {
            key: "Events",
            label: props.t("Events"),
            children: (
              <>
                <p>{props.t("ConcentEventsManual." + "Events")}</p>
                <p>
                  {props.t("ConcentEventsManual." + "Events2")}{" "}
                  <i className="mdi mdi-repeat font-size-14" />{" "}
                  {props.t("ConcentEventsManual." + "Events3")}
                </p>
                <p>{props.t("ConcentEventsManual." + "Events4")}</p>
              </>
            ),
          },
          {
            key: "Rooms",
            label: props.t("Virtual rooms"),
            children: (
              <>
                <p>{props.t("ConcentEventsManual." + "Rooms")}</p>
              </>
            ),
          },
          {
            key: "Conversations",
            label: props.t("Conversations"),
            children: (
              <>
                <p>{props.t("ConcentEventsManual." + "Conversations")}</p>
              </>
            ),
          },
          {
            key: "Recordings",
            label: props.t("Recordings"),
            children: (
              <>
                <p>{props.t("ConcentEventsManual." + "Recordings")}</p>
              </>
            ),
          },
        ]}
      ></Collapse>
    </div>
  )
}

ConcentEventsManual.propTypes = {
  t: PropTypes.any,
  ExpandIcon: PropTypes.func,
}

export default withTranslation()(ConcentEventsManual)
