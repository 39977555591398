import React, { useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  getNewsletterLogs,
  getReleaseNotes,
  getAdminStatusReports,
} from "helpers/database_helper"
import { Row } from "reactstrap"
import { Table as AntdTable, Tooltip } from "antd"
import {
  dateFormatter,
  dateSorterDateObj,
  defaultPageSizeOptions,
  stringSorter,
} from "utils/general"
import moment from "moment"
import { tableDefaultHeight } from "constants/layout"

const BulletinLogsIndex = props => {
  const abortRef = useRef(null)
  const [ongoingSearch, setOngoingSearch] = useState(true)
  const [logs, setLogs] = useState([])
  const [origLogs, setOrigLogs] = useState([])
  const [subs, setSubs] = useState([])
  const [disturbances, setDisturbances] = useState([])
  const [releases, setReleases] = useState([])
  const [targetFilter] = useState("0")
  const [statusFilter] = useState("0")
  const [rowCount] = useState(100)
  const [productionDatabase] = useState(true)
  const columns = [
    {
      title: props.t("Sent"),
      dataIndex: "sent",
      key: "sent",
      sorter: (a, b, sortOrder) => {
        return dateSorterDateObj(a["sent"], b["sent"], sortOrder)
      },
      render: dateFormatter,
      width: "150px",
    },

    {
      title: props.t("Type"),
      dataIndex: "type",
      key: "type",
      sorter: (a, b) => {
        return stringSorter(a, b, "type")
      },
      width: "150px",
    },
    {
      title: props.t("Notice"),
      dataIndex: "content_link",
      key: "content_link",
      sorter: (a, b) => {
        return stringSorter(a, b, "content_link")
      },
      width: "250px",
    },
    {
      title: props.t("Recipients"),
      dataIndex: "recipients",
      key: "recipients",
      ellipsis: true,
      render: value => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
      sorter: (a, b) => {
        return stringSorter(a, b, "recipients")
      },
      width: "300px",
    },
  ]

  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    setSubs(props.subscribers)
  }, [props.subscribers])

  useEffect(() => {
    fetchReleases()
    fetchBulletins()
    fetchLogs()
  }, [])

  // Match fetched data for content link and recipients for table
  // TODO: Set working before loading stops & more efficient way to update matching
  useEffect(() => {
    if (origLogs.length > 0) {
      let opts = []
      origLogs.forEach(l => {
        let groupObject = new Object()
        groupObject.id = l.id
        groupObject.type = typeFormatter(l.type)
        groupObject.recipients = formatSubs(l.recipients)
        groupObject.sent = l.sent
        groupObject.content_link = formatLinks(l.type, l.content_link)
        opts.push(groupObject)
      })
      setLogs(opts)
    }
  }, [origLogs, releases, disturbances])

  // Get release notes
  const fetchReleases = () => {
    // Set filters
    let filters = new Object()
    filters.include_unpublished = true
    const targetNum = parseInt(targetFilter, 10)
    if (targetNum > 0) filters.target = targetNum
    const statusNum = parseInt(statusFilter, 10)
    if (statusNum > 0) filters.status = statusNum

    setOngoingSearch(true)
    getReleaseNotes(abortRef.current, productionDatabase, rowCount, filters)
      .then(notes => {
        notes.forEach(note => {
          note.label = props.t(getTargetName(note.target)) + " " + note.version
          note.value = note.id
        })
        setReleases(notes)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        // setOngoingSearch(false)
      })
  }

  // Get disturbance bulletins
  const fetchBulletins = () => {
    // Set filters
    let filters = new Object()
    const statusNum = parseInt(statusFilter, 10)
    if (statusNum > 0) filters.current_status = statusNum

    setOngoingSearch(true)
    getAdminStatusReports(
      abortRef.current,
      productionDatabase,
      rowCount,
      filters
    )
      .then(reports => {
        reports.forEach(report => {
          const temp = report.start_time
            ? moment(report.start_time).format("DD.MM.YYYY")
            : ""
          report.label = temp + " " + report.header_fi
          report.value = report.id
        })
        setDisturbances(reports)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        // setOngoingSearch(false)
      })
  }

  // Get newsletter logs
  const fetchLogs = () => {
    // Set filters
    let filters = {}
    // TODO: set filters?
    // filters.type = 1; // 0=both, 1=releasenote, 2=statusreport
    setOngoingSearch(true)
    getNewsletterLogs(abortRef.current, false, filters)
      .then(logs => {
        console.log("Logs: ", logs)
        setOrigLogs(logs)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setOngoingSearch(false)
        // matchingFetched()
      })
  }

  const typeFormatter = type => {
    if (type === 1) return props.t("Release note")
    if (type === 2) return props.t("Disturbance notice")
  }

  // Get matching target name
  const getTargetName = id => {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "ReleaseNoteTypes." + id
      default:
        return "Unknown"
    }
  }

  // formatting subscribers
  const formatSubs = s => {
    let num = s.split(",")
    let numInt = num.map(n => parseInt(n))

    let emailAddresses = numInt.map(id => {
      let found = subs.find(item => item.id === id)
      return found ? found.email : null
    })
    let emailString = emailAddresses.join(", ")
    return emailString
  }

  // formatting content link
  const formatLinks = (t, l) => {
    let link = ""
    if (t === 1) {
      link = releases.find(item => item.id === l)
      return link ? link.label : null
    }
    if (t === 2) {
      link = disturbances.find(item => item.id === l)

      // TODO: set language
      return link ? link.header_fi : null
    }
  }

  return (
    <>
      <div className="email-bulletins-col-2">
        <Row className="">
          {/* <div>Filters</div> */}
          <div style={{ width: "100%" }}>
            <AntdTable
              size="small"
              loading={ongoingSearch}
              dataSource={logs}
              columns={columns}
              rowKey="sent"
              pagination={{
                showSizeChanger: true,
                defaultPageSize: 50,
                pageSizeOptions: defaultPageSizeOptions,
              }}
              tableLayout="auto"
              scroll={{
                x: "max-content",
                y: tableDefaultHeight,
              }}
            />
          </div>
        </Row>
      </div>
    </>
  )
}

BulletinLogsIndex.propTypes = {
  t: PropTypes.any,
  subscribers: PropTypes.any,
}

export default withTranslation()(BulletinLogsIndex)
