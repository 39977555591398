/***********************
 * INDEX TAB6 CONTENT 
 (DEVICE IDENTIFICATIONS)
 ************************/

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Form, Nav, NavItem, NavLink, Tooltip, Row } from "reactstrap"

//Classnames
import classnames from "classnames"

//Translation
import { withTranslation } from "react-i18next"

// Importing components
import TableDI from "./components/di-table"
import ChartDI from "./components/di-chart"

const DeviceIdentificationReporting = props => {
  const [deviceStats, setDeviceStats] = useState([])
  // Tooltip states
  const [tTnavcardtop, setTTnavcardtop] = useState(false)
  const [tTnavtabletop, setTTnavtabletop] = useState(false)

  // Set new state data when props.data changes
  useEffect(() => {
    if (props.activeTab == 7) {
      // console.log("Device data changed: " + JSON.stringify(props.data))
      setDeviceStats(props.data)
    }
  }, [props.data, props.activeTab])

  // Filter view related states
  const [activeTab, setActiveTab] = useState("1")
  const [widgetVisible, setWidgetVisible] = useState(true)
  const [tableVisible, setTableVisible] = useState(false)
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      setWidgetVisible(!widgetVisible)
      setTableVisible(!tableVisible)
    }
  }

  return (
    <React.Fragment>
      {/* Setting filter for view */}
      <Row>
        <Col>
          <div className=" float-end">
            <Form>
              <Nav className="product-view-nav" pills>
                <NavItem>
                  {/* Card view */}
                  <Tooltip
                    placement="bottom"
                    isOpen={tTnavcardtop}
                    target="TTnavcardservice"
                    toggle={() => {
                      setTTnavcardtop(!tTnavcardtop)
                    }}
                  >
                    {props.t("Cards")}
                  </Tooltip>
                  <NavLink
                    id="TTnavcardservice"
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    <i
                      style={{ padding: "10px" }}
                      className="bx bx-grid-alt text-sixe-16"
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* Taulukko view */}
                  <Tooltip
                    isOpen={tTnavtabletop}
                    target="TTnavtableservice"
                    placement="bottom"
                    toggle={() => {
                      setTTnavtabletop(!tTnavtabletop)
                    }}
                  >
                    {props.t("Table")}
                  </Tooltip>
                  <NavLink
                    id="TTnavtableservice"
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    <i
                      style={{ padding: "10px" }}
                      className="bx bx-list-ul text-sixe-16"
                    />
                  </NavLink>
                </NavItem>
              </Nav>
            </Form>
          </div>
          <div className="mt-2 mb-1">
            {widgetVisible && <ChartDI minRows={0} data={deviceStats} />}
            {tableVisible && <TableDI minRows={0} data={deviceStats} />}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

DeviceIdentificationReporting.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  activeTab: PropTypes.any,
}

export default withTranslation()(DeviceIdentificationReporting)
