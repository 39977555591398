import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Collapse } from "antd"
const { Panel } = Collapse

import { withTranslation } from "react-i18next"

import withRouter from "components/Common/withRouter"

import "../custom-styles.scss"

const VideoCallSettings = props => {
  const [browser, setBrowser] = useState("")
  const [copied, setCopied] = useState(false)

  // Copy text to clipboard
  const copyToClipboard = text => {
    navigator.clipboard.writeText(text)
    setCopied(true)
  }

  // Get browser
  useEffect(() => {
    const userAgent = navigator.userAgent
    if (userAgent.includes("Edg/")) {
      setBrowser("Edge")
    } else if (userAgent.includes("Chrome")) {
      setBrowser("Chrome")
    } else {
      setBrowser("Unsupported")
    }
  }, [])

  return (
    <>
      <Modal isOpen={props.settingsModal}>
        <ModalHeader>{props.t("Videocall settings")}</ModalHeader>
        <ModalBody>
          <Collapse
            bordered={false}
            className="px-0"
            accordion
            style={{ backgroundColor: "transparent" }}
            defaultActiveKey={["1"]}
            items={[
              {
                key: "1",
                label: props.t("Automatic gain control"),
                className: "custom-text-dark",
                children: (
                  <>
                    {browser !== "Unsupported" ? (
                      <div className="mb-2">
                        <p>{props.t("stabilizeMicrophoneAudio")}</p>
                        <span className="custom-text-dark">
                          {props.t("disableGainControl1")}
                          <br />
                          {props.t("disableGainControl2")}
                          <br />
                          <span style={{ backgroundColor: "yellow" }}>
                            Allow WebRTC to adjust the input volume.
                          </span>
                          <br />
                          {props.t("disableGainControl3")}
                          <br />
                          <br />
                          {props.t("disableGainControl4")}
                        </span>
                      </div>
                    ) : (
                      <p style={{ color: "#dc3545" }}>
                        {props.t(
                          "Your browser is not supported for this setting."
                        )}
                      </p>
                    )}

                    {browser === "Chrome" && (
                      <div style={{ marginBottom: "1rem" }}>
                        <span
                          className="custom-text-dark"
                          style={{ fontWeight: "bold" }}
                        >
                          Chrome:
                        </span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "0.5rem",
                          }}
                        >
                          <code
                            style={{
                              backgroundColor: "#f8f9fa",
                              padding: "0.25rem 0.5rem",
                              borderRadius: "0.25rem",
                              marginRight: "0.5rem",
                              fontSize: "0.9rem",
                              fontFamily: "monospace",
                            }}
                          >
                            chrome://flags/#enable-webrtc-allow-input-volume-adjustment
                          </code>
                          {!copied ? (
                            <Button
                              color="link"
                              style={{
                                padding: 0,
                                margin: 0,
                                textDecoration: "underline",
                                fontSize: "0.9rem",
                                alignSelf: "flex-start",
                              }}
                              onClick={() =>
                                copyToClipboard(
                                  "chrome://flags/#enable-webrtc-allow-input-volume-adjustment"
                                )
                              }
                            >
                              {props.t("Copy to clipboard")}
                            </Button>
                          ) : (
                            <span
                              className="text-success"
                              style={{
                                marginLeft: "0.5rem",
                                fontSize: "0.85rem",
                                fontWeight: "bold",
                              }}
                            >
                              {props.t("Copied to clipboard")}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {browser === "Edge" && (
                      <div style={{ marginBottom: "1rem" }}>
                        <span
                          className="custom-text-dark"
                          style={{ fontWeight: "bold" }}
                        >
                          Edge:
                        </span>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "0.5rem",
                          }}
                        >
                          <code
                            style={{
                              backgroundColor: "#f8f9fa",
                              padding: "0.25rem 0.5rem",
                              borderRadius: "0.25rem",
                              marginRight: "0.5rem",
                              fontSize: "0.9rem",
                              fontFamily: "monospace",
                            }}
                          >
                            edge://flags/#enable-webrtc-allow-input-volume-adjustment
                          </code>
                          {!copied ? (
                            <Button
                              color="link"
                              style={{
                                padding: 0,
                                margin: 0,
                                textDecoration: "underline",
                                fontSize: "0.9rem",
                                alignSelf: "flex-start",
                              }}
                              onClick={() =>
                                copyToClipboard(
                                  "edge://flags/#enable-webrtc-allow-input-volume-adjustment"
                                )
                              }
                            >
                              {props.t("Copy to clipboard")}
                            </Button>
                          ) : (
                            <span
                              className="text-success"
                              style={{
                                marginLeft: "0.5rem",
                                fontSize: "0.85rem",
                                fontWeight: "bold",
                              }}
                            >
                              {props.t("Copied to clipboard")}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ),
              },
            ]}
          ></Collapse>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props.setSettingsModal(false)
              setCopied(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

VideoCallSettings.propTypes = {
  t: PropTypes.any,
  settingsModal: PropTypes.any,
  setSettingsModal: PropTypes.any,
}

export default withRouter(withTranslation()(VideoCallSettings))
