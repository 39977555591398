// ALARM FILTER TREE

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

//Translation
import { withTranslation } from "react-i18next"

//  antd
import { TreeSelect, ConfigProvider } from "antd"
const { SHOW_PARENT } = TreeSelect

const AlarmFilter = props => {
  const [filteredDataTemp, setFilteredDataTemp] = useState([])
  const [typeFilter, setTypeFilter] = useState(["all"])
  const [customize] = useState(true)
  const [alarmData, setAlarmData] = useState([])

  useEffect(() => {
    // Map props.data to include alarm_type
    const alarmData = props.data.map(item => {
      const alarmType = props.t("AlarmTitles." + item.alarm_id)
      return {
        ...item,
        alarm_type: alarmType,
      }
    })

    setAlarmData(alarmData)

    // console.log("props.data:", props.data)
    console.log("alarmData:", alarmData)
  }, [props.data])

  const treeData = [
    {
      title: props.t("All"),
      value: "all",
      key: "all",
      children: [
        {
          title: props.t("Activity"),
          value: "Activity",
          key: "Activity",
          children: [
            {
              title: props.t("AlarmTitles." + "6"),
              value: props.t("AlarmTitles." + "6"),
              key: props.t("AlarmTitles." + "6"),
            },
            {
              title: props.t("AlarmTitles." + "12"),
              value: props.t("AlarmTitles." + "12"),
              key: props.t("AlarmTitles." + "12"),
            },
            {
              title: props.t("AlarmTitles." + "14"),
              value: props.t("AlarmTitles." + "14"),
              key: props.t("AlarmTitles." + "14"),
            },
            {
              title: props.t("AlarmTitles." + "16"),
              value: props.t("AlarmTitles." + "16"),
              key: props.t("AlarmTitles." + "16"),
            },

            {
              title: props.t("AlarmTitles." + "24"),
              value: props.t("AlarmTitles." + "24"),
              key: props.t("AlarmTitles." + "24"),
            },
            {
              title: props.t("AlarmTitles." + "25"),
              value: props.t("AlarmTitles." + "25"),
              key: props.t("AlarmTitles." + "25"),
            },
          ],
        },
        {
          title: props.t("Abnormal activity"),
          value: "AB",
          key: "AB",
          children: [
            {
              title: props.t("AlarmTitles." + "17"),
              value: props.t("AlarmTitles." + "17"),
              key: props.t("AlarmTitles." + "17"),
            },
            {
              title: props.t("AlarmTitles." + "15"),
              value: props.t("AlarmTitles." + "15"),
              key: props.t("AlarmTitles." + "15"),
            },
            {
              title: props.t("AlarmTitles." + "13"),
              value: props.t("AlarmTitles." + "13"),
              key: props.t("AlarmTitles." + "13"),
            },
          ],
        },

        {
          title: props.t("Health"),
          value: "Health",
          key: "Health",
          children: [
            {
              title: props.t("AlarmTitles." + "30"),
              value: props.t("AlarmTitles." + "30"),
              key: props.t("AlarmTitles." + "30"),
            },
            {
              title: props.t("AlarmTitles." + "27"),
              value: props.t("AlarmTitles." + "27"),
              key: props.t("AlarmTitles." + "27"),
            },
            {
              title: props.t("AlarmTitles." + "28"),
              value: props.t("AlarmTitles." + "28"),
              key: props.t("AlarmTitles." + "28"),
            },
            {
              title: props.t("AlarmTitles." + "31"),
              value: props.t("AlarmTitles." + "31"),
              key: props.t("AlarmTitles." + "31"),
            },
          ],
        },
        {
          title: props.t("Safety"),
          value: "Safety",
          key: "Safety",
          children: [
            {
              title: props.t("AlarmTitles." + "3"),
              value: props.t("AlarmTitles." + "3"),
              key: props.t("AlarmTitles." + "3"),
            },
            {
              title: props.t("AlarmTitles." + "4"),
              value: props.t("AlarmTitles." + "4"),
              key: props.t("AlarmTitles." + "4"),
            },
            {
              title: props.t("AlarmTitles." + "35"),
              value: props.t("AlarmTitles." + "35"),
              key: props.t("AlarmTitles." + "35"),
            },
            {
              title: props.t("AlarmTitles." + "36"),
              value: props.t("AlarmTitles." + "36"),
              key: props.t("AlarmTitles." + "36"),
            },
            {
              title: props.t("AlarmTitles." + "42"),
              value: props.t("AlarmTitles." + "42"),
              key: props.t("AlarmTitles." + "42"),
            },
            {
              title: props.t("AlarmTitles." + "5"),
              value: props.t("AlarmTitles." + "5"),
              key: props.t("AlarmTitles." + "5"),
            },
          ],
        },
        {
          title: props.t("Apartment"),
          value: "Apartment",
          key: "Apartment",
          children: [
            {
              title: props.t("AlarmTitles." + "20"),
              value: props.t("AlarmTitles." + "20"),
              key: props.t("AlarmTitles." + "20"),
            },
            {
              title: props.t("AlarmTitles." + "21"),
              value: props.t("AlarmTitles." + "21"),
              key: props.t("AlarmTitles." + "21"),
            },
            {
              title: props.t("AlarmTitles." + "22"),
              value: props.t("AlarmTitles." + "22"),
              key: props.t("AlarmTitles." + "22"),
            },
            {
              title: props.t("AlarmTitles." + "23"),
              value: props.t("AlarmTitles." + "23"),
              key: props.t("AlarmTitles." + "23"),
            },
            {
              title: props.t("AlarmTitles." + "37"),
              value: props.t("AlarmTitles." + "37"),
              key: props.t("AlarmTitles." + "37"),
            },
            {
              title: props.t("AlarmTitles." + "38"),
              value: props.t("AlarmTitles." + "38"),
              key: props.t("AlarmTitles." + "38"),
            },
            {
              title: props.t("AlarmTitles." + "18"),
              value: props.t("AlarmTitles." + "18"),
              key: props.t("AlarmTitles." + "18"),
            },
            {
              title: props.t("AlarmTitles." + "19"),
              value: props.t("AlarmTitles." + "19"),
              key: props.t("AlarmTitles." + "19"),
            },

            {
              title: props.t("AlarmTitles." + "54"),
              value: props.t("AlarmTitles." + "54"),
              key: props.t("AlarmTitles." + "54"),
            },
          ],
        },
        {
          title: props.t("Devices"),
          value: "Devices",
          key: "Devices",
          children: [
            {
              title: props.t("AlarmTitles." + "1"),
              value: props.t("AlarmTitles." + "1"),
              key: props.t("AlarmTitles." + "1"),
            },
            {
              title: props.t("AlarmTitles." + "2"),
              value: props.t("AlarmTitles." + "2"),
              key: props.t("AlarmTitles." + "2"),
            },
            {
              title: props.t("AlarmTitles." + "10"),
              value: props.t("AlarmTitles." + "10"),
              key: props.t("AlarmTitles." + "10"),
            },
            {
              title: props.t("AlarmTitles." + "11"),
              value: props.t("AlarmTitles." + "11"),
              key: props.t("AlarmTitles." + "11"),
            },
            {
              title: props.t("AlarmTitles." + "26"),
              value: props.t("AlarmTitles." + "26"),
              key: props.t("AlarmTitles." + "26"),
            },
            {
              title: props.t("AlarmTitles." + "29"),
              value: props.t("AlarmTitles." + "29"),
              key: props.t("AlarmTitles." + "29"),
            },
            {
              title: props.t("AlarmTitles." + "32"),
              value: props.t("AlarmTitles." + "32"),
              key: props.t("AlarmTitles." + "32"),
            },
            {
              title: props.t("AlarmTitles." + "33"),
              value: props.t("AlarmTitles." + "33"),
              key: props.t("AlarmTitles." + "33"),
            },
            {
              title: props.t("AlarmTitles." + "44"),
              value: props.t("AlarmTitles." + "44"),
              key: props.t("AlarmTitles." + "44"),
            },
            {
              title: props.t("AlarmTitles." + "45"),
              value: props.t("AlarmTitles." + "45"),
              key: props.t("AlarmTitles." + "45"),
            },
            {
              title: props.t("AlarmTitles." + "46"),
              value: props.t("AlarmTitles." + "46"),
              key: props.t("AlarmTitles." + "46"),
            },
            {
              title: props.t("AlarmTitles." + "47"),
              value: props.t("AlarmTitles." + "47"),
              key: props.t("AlarmTitles." + "47"),
            },
            {
              title: props.t("AlarmTitles." + "48"),
              value: props.t("AlarmTitles." + "48"),
              key: props.t("AlarmTitles." + "48"),
            },
            {
              title: props.t("AlarmTitles." + "51"),
              value: props.t("AlarmTitles." + "51"),
              key: props.t("AlarmTitles." + "51"),
            },
            {
              title: props.t("AlarmTitles." + "52"),
              value: props.t("AlarmTitles." + "52"),
              key: props.t("AlarmTitles." + "52"),
            },
            {
              title: props.t("AlarmTitles." + "53"),
              value: props.t("AlarmTitles." + "53"),
              key: props.t("AlarmTitles." + "53"),
            },
          ],
        },
        {
          title: props.t("Other"),
          value: "Other",
          key: "Other",
          children: [
            {
              title: props.t("AlarmTitles." + "34"),
              value: props.t("AlarmTitles." + "34"),
              key: props.t("AlarmTitles." + "34"),
            },
            {
              title: props.t("AlarmTitles." + "39"),
              value: props.t("AlarmTitles." + "39"),
              key: props.t("AlarmTitles." + "39"),
            },
            {
              title: props.t("AlarmTitles." + "43"),
              value: props.t("AlarmTitles." + "43"),
              key: props.t("AlarmTitles." + "43"),
            },
          ],
        },
      ],
    },
  ]

  // Filter by selected types
  useEffect(() => {
    if (alarmData != undefined && alarmData.length > 0) {
      setFilteredDataTemp([])
      if (typeFilter == "all") {
        setFilteredDataTemp(alarmData)
      }
      if (typeFilter != "all") {
        let alarms = []
        let tempValue = []
        setFilteredDataTemp([])
        // Push datas from alarm name
        alarmData.filter(f => {
          typeFilter.map(v => {
            if (f.alarm_type == v) {
              tempValue.push(f)
            }
          })
        })
        typeFilter.forEach(element => {
          switch (element) {
            case "Activity":
              alarms.push(...[6, 12, 14, 16, 24, 25])
              break
            case "AB":
              alarms.push(...[17, 15, 13])
              break
            case "Health":
              alarms.push(...[30, 27, 28, 31])
              break
            case "Devices":
              alarms.push(
                ...[
                  1, 2, 10, 11, 26, 29, 32, 33, 44, 45, 46, 47, 48, 51, 52, 53,
                ]
              )
              break
            case "Safety":
              alarms.push(...[3, 4, 35, 36, 42, 5])
              break
            case "Apartment":
              alarms.push(...[20, 21, 22, 23, 37, 38, 18, 19])
              break
            case "Other":
              alarms.push(...[34, 39, 43])
              break
            default: {
              let temp = parseInt(element, 10)
              if (!isNaN(temp)) alarms.push(...[temp])
              break
            }
          }
        })
        // Push datas from alarm category
        for (let i = 0; i <= alarms.length; i++) {
          alarmData.filter(data => {
            if (alarmData.alarm_type == props.t("AlarmTitles." + alarms[i])) {
              tempValue.push(data)
            }
          })
        }
        setFilteredDataTemp(tempValue)
      }
    }
  }, [alarmData, typeFilter])

  // Filter by selected types
  useEffect(() => {
    props.setFilteredEntries(filteredDataTemp)
  }, [filteredDataTemp])

  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("Alarm type not found")}</p>
    </div>
  )

  const tProps = {
    treeData,
    value: typeFilter,
    allowClear: true,
    treeDefaultExpandAll: true,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: props.t("Alarm types"),
    style: {
      width: "100%",
    },
  }

  // let types = [
  //   { checked: "true", name: "all", color: "light", type: "all" },
  //   { checked: "false", name: "calls", color: "primary", type: "call" },
  //   { checked: "false", name: "alarms", color: "danger", type: "alarm" },
  //   { checked: "false", name: "dose", color: "chart-color1", type: "dose" },
  //   { checked: "false", name: "files", color: "chart-color2", type: "file" },
  //   {
  //     checked: "false",
  //     name: "measurements",
  //     color: "chart-color3",
  //     type: "measurement",
  //   },
  //   {
  //     checked: "false",
  //     name: "messages",
  //     color: "chart-color4",
  //     type: "message",
  //   },
  //   {
  //     checked: "false",
  //     name: "messages to client",
  //     color: "chart-color5",
  //     type: "message_client",
  //   },
  //   {
  //     checked: "false",
  //     name: "smartlocks",
  //     color: "chart-color6",
  //     type: "smartlock",
  //   },
  //   { checked: "false", name: "visits", color: "chart-color7", type: "visit" },
  // ]

  return (
    <>
      <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
        <TreeSelect
          popupClassName="custom-scrollbar"
          listHeight={"auto"}
          virtual={false}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          style={{ maxHeight: 200 + "px" }}
          onChange={value => {
            setTypeFilter(value)
          }}
          {...tProps}
        />
      </ConfigProvider>
    </>
  )
}
AlarmFilter.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  setFilteredEntries: PropTypes.any,
}

export default withTranslation()(AlarmFilter)
