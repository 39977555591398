/**********************
 * DEMO
 * Appoinments
 * Timeline
 *********************/
import React, { useRef, useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import { getVideoCallToken, getGroupClientConfig } from "helpers/backend_helper"

import { formatDateTime } from "utils/general"

import TimelineCalendar1 from "./TimelineCalendar1"
import SelectGroup from "components/DropdownList/SelectGroup"
import useScrollBlock from "utils/useScrollBlock"
import GlobalState from "contexts/GlobalState"

import { Link } from "react-router-dom"

import { Col, Row, Button, CardBody } from "reactstrap"

import { Collapse, Slider, Tooltip, Select, Popover } from "antd"

import Timeline, {
  DateHeader,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline"

// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css"

import dayjs from "dayjs"
import "dayjs/locale/fi"
import "dayjs/locale/se"
import "dayjs/locale/en-gb"

import { isEqual } from "lodash"

const TimelineCalendar2 = props => {
  const [state] = useContext(GlobalState)
  const [startTime, setStartTime] = useState()
  const [endTime, setEndTime] = useState()
  const abortRef = useRef(null)
  const [rangeTime, setRangeTime] = useState([])
  const [updateCounter, setUpdateCounter] = useState([0, 0])
  const [selectedAssignmentId, setSelectedAssignmentId] = useState()
  const [selectedAssignmentProfId, setSelectedAssignmentProfId] = useState()
  const [tooltipContent, setTooltipContent] = useState(null)
  const [popoverVisible, setPopoverVisible] = useState(true)
  // Custom mouse scroll
  const [timelineHovered, setTimelineHovered] = useState(false)
  //Blocking scroll when handleMouseEnter/handleMouseLeave
  const [blockScroll, allowScroll] = useScrollBlock()
  //   FIXES TIMELINE BUG
  const [display, setDisplay] = useState("block")
  const [isMobile, setIsMobile] = useState(window.innerWidth < 700)

  // Marks
  const marks = {
    0: "00:00",
    8: "08:00",
    12: "12:00",
    16: "16:00",
    20: "20:00",
    24: "23:59",
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 700)
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  useEffect(() => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      let name = obj.username
      // console.log(obj, name, "obj")
      props.setUserRole(role)
      props.setUserKey(name)
    }
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // FIXES TIMELINE BUG
  useEffect(() => {
    window.setTimeout(() => setDisplay({ display: "block" }))
    window.setTimeout(() => window.dispatchEvent(new Event("resize")), 100)
  }, [])

  // Set timeline to current time range
  useEffect(() => {
    const now = dayjs()
    const roundedStartTime = now.startOf("hour")
    const roundedEndTime = roundedStartTime.add(5, "hours")

    setStartTime(roundedStartTime)
    setEndTime(roundedEndTime)

    // Update rangeTime of slider
    setRangeTime([roundedStartTime.hour(), roundedEndTime.hour()])

    props.getAppointments()
  }, [props.startDate])

  useEffect(() => {
    // console.log(props.differences, "diff")
    if (Object.keys(props.differences).length == 0) {
      props.setAssignments(props.assignmentsOrig)
    }
  }, [props.differences])

  useEffect(() => {
    if (!isEqual(props.assignments, props.assignmentsOrig)) {
      // console.log("different", props.assignments, props.assignmentsOrig)
      props.setModify(true)
      const findDifferences = () => {
        const diffs = []

        props.assignmentsOrig.forEach(obj1 => {
          const found = props.assignments.find(obj2 => obj2.key === obj1.key)
          // console.log(found, "found")
          if (found) {
            const newAdmin = found.group
            const newTime = found.start_time
            const diffKeys = Object.keys(obj1).filter(
              key => obj1[key] !== found[key]
            )
            if (diffKeys.length > 0) {
              // console.log(diffKeys, obj1, "pushing")
              //  Dont add if only className is different, needs to change if other changes will be acceptable in the future
              if (
                diffKeys.includes("group") ||
                (diffKeys.includes("start_time") &&
                  diffKeys.includes("end_time"))
              ) {
                diffs.push({
                  key: obj1.key,
                  appointmentType: obj1.type,
                  adminUser: props.findMatch(newAdmin),
                  clientKey: obj1.client_key,
                  startTime: newTime,
                  duration: obj1.duration,
                  appointmentName: obj1.name,
                })
              }
            }
          }
        })
        // console.log(diffs, "props.differences")
        return diffs
      }
      props.setDifferences(findDifferences())
    }
    if (isEqual(props.assignments, props.assignmentsOrig)) {
      // console.log("same", props.assignments, props.assignmentsOrig)
      props.setModify(false)
    }
  }, [props.assignments])

  //  Dragging item
  const handleItemMove = (itemId, dragTime) => {
    const updatedAssignments = props.assignments.map(item => {
      // console.log(
      //   selectedAssignmentId,
      //   selectedAssignmentProfId,
      //   item,
      //   itemId,
      //   dateFormatter(dragTime),
      //   "Moving"
      // )
      if (item.id === selectedAssignmentId) {
        return {
          ...item,
          start_time: dayjs(dragTime),
          end_time: dayjs(dragTime + (item.end_time - item.start_time)),
          group: props.selectedProfs[selectedAssignmentProfId].id,
          className: "moved",
        }
      }
      return item
    })
    if (itemId.itemId == undefined) {
      setTooltipContent(null)
      setPopoverVisible(true)
    }
    props.setAssignments(updatedAssignments)
  }

  const handleItemDrag = (itemId, dragTime, newGroupOrder) => {
    // console.log(
    //   "moving",
    //   itemId,
    //   itemId.itemId,
    //   itemId.dragTime,
    //   itemId.newGroupOrder
    // )
    const item = props.assignments.find(item => item.id === itemId.itemId)
    const group = props.selectedProfs[itemId.newGroupOrder].title
    const time = itemId.time
    setSelectedAssignmentId(itemId.itemId)
    setSelectedAssignmentProfId(itemId.newGroupOrder)
    setTooltipContent({ item, group, time })
    // Sets a bug that item can not be moved after 1 move
    setPopoverVisible(false)
  }

  // Changing time window on timeline
  const changeTimeRange = time => {
    let start = time[0]
    let end = time[1]

    let a = dayjs(props.startDate).add(start, "hours")
    let b = dayjs(props.startDate).add(end, "hours")

    setStartTime(a)
    setEndTime(b)
  }

  // Changing time window on timeline from buttons
  const changeTimeRangeButton = value => {
    let a = dayjs()
    let b = dayjs()
    let newRange1 = rangeTime[0]
    let newRange2 = rangeTime[1]
    if (value == "backward" && rangeTime[0] > 0) {
      a = dayjs(startTime).add(-1, "hours")
      b = dayjs(endTime).add(-1, "hours")

      setStartTime(a)
      setEndTime(b)

      newRange1 = rangeTime[0] - 1
      newRange2 = rangeTime[1] - 1
      setRangeTime([newRange1, newRange2])
    }
    if (value == "forward" && rangeTime[1] < 24) {
      a = dayjs(startTime).add(1, "hours")
      b = dayjs(endTime).add(1, "hours")

      setStartTime(a)
      setEndTime(b)

      newRange1 = rangeTime[0] + 1
      newRange2 = rangeTime[1] + 1
      setRangeTime([newRange1, newRange2])
    } else {
      if (value < 0 && rangeTime[1] > 1 && rangeTime[0] < rangeTime[1] - 1) {
        newRange2 = newRange2 - 1
        a = startTime
        b = dayjs(endTime).add(value, "hours")

        setStartTime(a)
        setEndTime(b)
      }
      if (value > 0 && rangeTime[1] < 24 && rangeTime[0] < rangeTime[1]) {
        newRange2 = newRange2 + 1
        a = startTime
        b = dayjs(endTime).add(value, "hours")

        setStartTime(a)
        setEndTime(b)
      }

      setRangeTime([newRange1, newRange2])
    }
  }

  // Start video call
  const startVideoCall = key => {
    if (key) {
      getVideoCallToken(abortRef.current, key)
        .then(result => {
          if (result.link != null && result.link.length > 0) {
            window.open(result.link, "_blank", "noreferrer")
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // Start call, get config from server
  const startGroupCall = guests => {
    const groupGuests = guests.map(guest => guest.videoNumber)

    // console.log("groupGuests:", groupGuests)
    // setStartError(null)
    const isProduction = false
    getGroupClientConfig(abortRef.current, isProduction, groupGuests)
      .then(result => {
        if (result.statusCode == 200 && result.link != null) {
          window.open(result.link, "_blank", "noreferrer")
        } else if (result.status == "missing_id") {
          setStartError(props.t("No group video permissions"))
        } else {
          setStartError(props.t("Something went wrong"))
        }
      })
      .catch(e => {
        console.log(e)
        // setStartError("Something went wrong")
      })
  }

  // Popover
  const itemRenderDisabledButtons = ({ item, itemContext, getItemProps }) => {
    // console.log(item, itemContext, getItemProps, "itemit")
    // let color = item.className
    const content = (
      <div>
        <div className="pb-2">
          <div></div>
          <div>
            {formatDateTime(item.start_time)} - {formatDateTime(item.end_time)}
          </div>
          <div>{item.duration} min</div>{" "}
          <div>
            {props.t(item.type.charAt(0).toUpperCase() + item.type.slice(1))}
          </div>
        </div>
      </div>
    )
    const title = (
      <div>
        <span>
          {" "}
          {item.name === "undefined" || !item.name
            ? itemContext.title
            : item.name}
        </span>
      </div>
    )
    return (
      <>
        <Popover
          content={popoverVisible ? content : null}
          title={popoverVisible ? title : null}
        >
          <div
            className="item-render-style"
            {...getItemProps({
              style: {
                boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
                       0 2px 2px 0 rgba(0, 0, 0, 0.14),
                       0 3px 1px -2px rgba(0, 0, 0, 0.12)`,
              },
            })}
          >
            <div className="ripple ripple-style">
              <div className=" ripple-title h6 mt-1 mb-0 me-2">
                {item.name === "undefined" || !item.name
                  ? itemContext.title
                  : item.name}
              </div>
              <div className="mt-0 ripple-text">
                {formatDateTime(item.start_time)}-
                {formatDateTime(item.end_time)}
              </div>
            </div>
          </div>
        </Popover>
      </>
    )
  }

  const itemRender = ({ item, itemContext, getItemProps }) => {
    const group = props.selectedProfs.find(prof => prof.id === item.group)

    const content = (
      <div
        onMouseEnter={() => setTimelineHovered(false)}
        onMouseLeave={() => setTimelineHovered(true)}
      >
        <div className="pb-2">
          <div className="mb-1">
            {item.groupCallDetails ? (
              <div>
                <Collapse
                  bordered={false}
                  className="collapse-list"
                  expandIconPosition="end"
                  defaultActiveKey={
                    item.groupCallDetails?.length < 3 ? ["1"] : []
                  }
                  items={[
                    {
                      key: "1",
                      label: props.t("Group call guests"),
                      children: (
                        <div className="collapse-scroll">
                          {item.groupCallDetails?.map((detail, index) => (
                            <div
                              key={1}
                              className={`collapsible-item ${
                                index !== item.groupCallDetails.length - 1
                                  ? "with-border"
                                  : ""
                              }`}
                            >
                              {detail.title}
                            </div>
                          ))}
                        </div>
                      ),
                    },
                  ]}
                ></Collapse>
              </div>
            ) : (
              <div>{item.title}</div>
            )}
          </div>
          <div>
            {formatDateTime(item.start_time)} - {formatDateTime(item.end_time)}
          </div>
          <div>{item.duration} min</div>
          <div>
            {props.t(item.type.charAt(0).toUpperCase() + item.type.slice(1))}
          </div>
          {isMobile && <div className="mt-1"> {group.key}</div>}
        </div>

        <Button
          disabled={!item.client_key}
          color="secondary"
          className="py-1"
          onClick={() => {
            if (item.type === "video_call") startVideoCall(item.client_key)
            if (item.type === "group_call")
              startGroupCall(item.groupCallDetails)
          }}
        >
          <i className="bx bxs-video me-1"></i>{" "}
          {item.type === "video_call" && props.t("Start video call")}
          {item.type === "group_call" && props.t("Start group call")}
        </Button>
      </div>
    )
    const title = (
      <div>
        <span
          id={"edit" + item.key}
          style={{
            visibility: props.userRole.includes("admin") ? "visible" : "hidden",
          }}
          className="float-end"
        >
          <Tooltip
            title={props.t("Edit appointment")}
            placement="right"
            overlayInnerStyle={{ fontSize: "12px" }}
          >
            <Button
              className="p-1"
              color="transparent"
              onClick={() => props.settingSelectedAssignment(item.key)}
            >
              <i className="bx bxs-edit text-primary font-size-14"></i>
            </Button>
          </Tooltip>
        </span>
        <span>
          {" "}
          {item.name === "undefined" || !item.name
            ? itemContext.title
            : item.name}
        </span>
      </div>
    )
    return (
      <>
        <Popover
          content={popoverVisible ? content : null}
          title={popoverVisible ? title : null}
          trigger={["click"]}
          // trigger={["hover"]}
          style={{
            maxHeight: "80vh",
            overflowY: "auto",
            padding: "10px",
          }}
          autoAdjustOverflow={false}
        >
          <div
            className="item-render-style"
            {...getItemProps({
              style: {
                boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
                       0 2px 2px 0 rgba(0, 0, 0, 0.14),
                       0 3px 1px -2px rgba(0, 0, 0, 0.12)`,
              },
            })}
          >
            <div className="ripple ripple-style">
              <div className=" ripple-title h6 mt-1 mb-0 me-2">
                {item.name === "undefined" || !item.name
                  ? itemContext.title
                  : item.name}
              </div>
              <div className="mt-0 ripple-text">
                {formatDateTime(item.start_time)}-
                {formatDateTime(item.end_time)}
              </div>
            </div>
          </div>
        </Popover>
      </>
    )
  }

  const checkRenderer = () => {
    const hasModifiedItems = props.assignments.some(
      a => a.className === "moved"
    )
    return hasModifiedItems ? itemRenderDisabledButtons : itemRender
  }

  const handleMouseEnter = () => {
    setTimelineHovered(true)
    blockScroll()
  }
  const handleMouseLeave = () => {
    setTimelineHovered(false)
    allowScroll()
  }

  // Custom modal does not work now
  // const handleModalResponse = response => {
  //   if (response === "confirm") {
  //     window.removeEventListener("beforeunload", handleBeforeUnload)
  //     window.location.href = "/exit"
  //   } else {
  //     setShowModal(false)
  //   }
  // }

  // Filtering for non-admin
  // const filteringOnlyUser = () => {
  //   let appointments = []
  // }

  return (
    <>
      <div>
        <Row>
          <Col lg="3" className="p-0">
            <Collapse
              className="p-0"
              ghost
              defaultActiveKey={["1"]}
              expandIconPosition="end"
              style={{ background: "transparent" }}
              items={[
                {
                  key: "1",
                  label: (
                    <h5 className="p-0 m-0 font-size-14">
                      <i className="bx bx-calendar text-truncate align-middle me-2"></i>
                      {props.t("Search")}
                    </h5>
                  ),
                  children: (
                    <div className="">
                      <div className="mb-2">
                        <TimelineCalendar1
                          disabled={props.modify}
                          setCalendarStartDate={props.setStartDate}
                          setCalendarEndDate={props.setEndDate}
                          updateCounter={updateCounter}
                        />
                      </div>
                      <SelectGroup
                        disabled={props.modify}
                        onChange={props.setSelectedGroup}
                        multiple={false}
                        selection={props.selectedGroup}
                      />
                      {/* // TODO: Set working: rendering/functions when modifying appointment on "clients" */}
                      {/* <Label className="mt-1 mb-1 pt-1 font-size-12">
            {props.t("Sort by")}
          </Label>
          <div>
            <Radio.Group
              name="radioType"
              className="mt-0 mb-0 pb-0"
              defaultValue={props.findType}
              onChange={e => {
                setprops.findType(e.target.value)
              }}
            >
              <Radio value={"admins"}>{props.t("Professional")}</Radio>
              <Radio value={"clients"}>{props.t("Client")}</Radio>
            </Radio.Group>
          </div> */}
                    </div>
                  ),
                },
                props.selectedGroup != null &&
                  props.selectedGroup != undefined && {
                    key: "2",
                    label: (
                      <h5 className="p-0 m-0 font-size-14">
                        <i className="dripicons-scale text-truncate align-middle me-2"></i>
                        {props.t("Filter")}
                      </h5>
                    ),
                    children: (
                      <div className="">
                        <Select
                          className="mb-2"
                          mode="multiple"
                          allowClear
                          style={{ width: "100%" }}
                          placeholder={props.t("Assignment type")}
                          options={[
                            {
                              title: props.t("Video call"),
                              value: props.t("Video call"),
                              key: "call_video",
                            },
                            {
                              title: props.t("Group call"),
                              value: props.t("Group call"),
                              key: "group_call",
                            },
                          ]}
                        />
                        {props.professionals.length > 0 &&
                          props.findType === "admins" && (
                            <Select
                              className="mb-2"
                              mode="multiple"
                              disabled={props.professionals.length === 0}
                              allowClear
                              style={{ width: "100%" }}
                              placeholder={props.t("Professional")}
                              onChange={props.handleChange}
                              options={props.professionals}
                            />
                          )}
                      </div>
                    ),
                  },
              ].filter(Boolean)} // Filters out undefined entries
            ></Collapse>
            <div className="vl-appointments d-none d-sm-inline-block ms-2"></div>{" "}
          </Col>
          <Col lg={9}>
            <div className="ms-2">
              <div className="mb-3">
                <Col lg={12}>
                  <span className="float-end">
                    {props.userRole.includes("admin") && props.modify && (
                      <>
                        <div className="badge badge-soft-danger p-2 font-size-12 me-3">
                          <span className="">{props.t("Unsaved")}</span>
                        </div>
                        <Button
                          disabled={props.loading}
                          className="mt-0 me-2"
                          color="danger"
                          onClick={() => {
                            props.setDifferences({})
                            props.setAssignments(props.assignmentsOrig)
                          }}
                        >
                          <i className="bx bx-x"></i>
                          <div className="d-none d-lg-inline-block ms-2">
                            {props.t("Cancel changes")}
                          </div>
                        </Button>
                        <Button
                          disabled={props.loading}
                          className="mt-0"
                          color="success"
                          onClick={() => {
                            props.submitMultipleChanges()
                          }}
                        >
                          <i className="bx bx-check "></i>

                          <div className="d-none d-lg-inline-block ms-2">
                            {props.t("Save changes")}
                          </div>
                        </Button>
                      </>
                    )}
                    {/* {props.userRole.includes("admin") && !props.modify && ( */}
                    {!props.modify && (
                      <Button
                        disabled={
                          props.selectedGroup == null ||
                          props.selectedGroup == undefined ||
                          props.modify
                        }
                        className="mt-0"
                        color="success"
                        onClick={() => {
                          props.setNewSelection()
                        }}
                      >
                        <i className="bx bx-calendar-plus "></i>
                        <div className="d-none d-lg-inline-block ms-2">
                          {props.t("Add a new appointment")}
                        </div>
                      </Button>
                    )}
                  </span>
                  <div className="h4 d-block pt-2">
                    <Button
                      disabled={props.modify}
                      onClick={() => {
                        setUpdateCounter([updateCounter - 1, -1])
                      }}
                      // size="sm"
                      color="clear"
                      className="mt-0 py-0"
                      style={{ border: "none" }}
                    >
                      <i className="bx bx-chevron-left mt-1 font-size-22"></i>
                    </Button>
                    {dayjs(props.startDate).format("Do MMMM YYYY")}
                    <Button
                      disabled={props.modify}
                      onClick={() => {
                        setUpdateCounter([updateCounter + 1, 1])
                      }}
                      // size="sm"
                      color="clear"
                      className="mt-0 py-0"
                      style={{ border: "none" }}
                    >
                      <i className="bx bx-chevron-right ms-0 mt-1 font-size-22"></i>
                    </Button>
                    {/* {props.assignments.length > 0 && <span>, {}</span>} */}{" "}
                    {/* <Button
                      onClick={() => {
                        setUpdateCounter(0)
                      }}
                      // size="sm"
                      color="primary"
                      outline
                      className="ms-3 me-2"
                      // style={{ border: "none" }}
                    >
                      {props.t("Today")}
                    </Button> */}
                  </div>
                </Col>
              </div>
              {/* </Row> */}
              {/* </Col> */}
              {props.loading ? (
                <CardBody>
                  <div className="text-center p-5">
                    <Link to="#">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                      {props.t("Loading")}
                    </Link>
                  </div>
                </CardBody>
              ) : (
                <>
                  {props.assignments.length == 0 &&
                    props.selectedProfs.length == 0 && (
                      <CardBody>
                        <div className="text-center p-5">
                          {props.selectedGroup != undefined ||
                          props.selectedGroup != null ? (
                            <>
                              {props.t(
                                "No scheduled appointments for chosen date"
                              )}
                            </>
                          ) : (
                            <>{props.t("No selected group")}</>
                          )}
                        </div>
                      </CardBody>
                    )}
                  {(props.assignments.length > 0 ||
                    props.selectedProfs.length > 0) &&
                    props.selectedGroup != null &&
                    props.selectedGroup != undefined && (
                      <div className="pt-2">
                        <Col xs="12">
                          <div className="mt-2">
                            <div className="mt-3" style={{ display: "flex" }}>
                              <div
                                style={{ flexGrow: "1" }}
                                className="p-0 ps-2"
                              >
                                <Button
                                  onClick={() => {
                                    changeTimeRangeButton("backward")
                                  }}
                                  color="clear"
                                  className="mt-1 py-0 pe-0 "
                                  style={{ border: "none" }}
                                >
                                  <i className="bx bx-chevrons-left mt-1 text-primary font-size-16"></i>
                                </Button>
                              </div>
                              <div
                                // style={{ width: "90%" }}
                                style={{ flexGrow: "40" }}
                                className="d-inline-block mb-0"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                onWheel={e => {
                                  if (timelineHovered && e.shiftKey) {
                                    const delta = e.deltaY > 0 ? -1 : 1
                                    changeTimeRangeButton(delta)
                                  }
                                  if (timelineHovered && !e.shiftKey) {
                                    const delta = e.deltaY
                                    if (delta > 0) {
                                      changeTimeRangeButton("backward")
                                    } else {
                                      changeTimeRangeButton("forward")
                                    }
                                  }
                                }}
                              >
                                <Slider
                                  className="custom-timeline"
                                  onChange={e => {
                                    changeTimeRange(e)
                                    setRangeTime(e)
                                  }}
                                  marks={marks}
                                  range={{
                                    draggableTrack: true,
                                  }}
                                  step={1}
                                  min={0}
                                  max={24}
                                  defaultValue={rangeTime}
                                  value={rangeTime}
                                />
                              </div>
                              <div className="ms-3" style={{ flexGrow: "1" }}>
                                <Button
                                  onClick={() => {
                                    changeTimeRangeButton("forward")
                                  }}
                                  // size="sm"
                                  color="clear"
                                  className="mt-1 py-0 px-0"
                                  style={{ border: "none" }}
                                >
                                  <i className="bx bx-chevrons-right mt-1 text-primary font-size-16"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div
                            className="pb-2 px-3"
                            display={display}
                            // Styles from scss file does not work if timeline disappears
                            style={{
                              height:
                                props.assignments.length == 0 &&
                                props.selectedProfs.length == 0
                                  ? "0px"
                                  : "auto",
                              marginTop: "-20px",
                            }}
                          >
                            <div className="px-3"></div>
                            <div
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              onWheel={e => {
                                if (timelineHovered && e.shiftKey) {
                                  const delta = e.deltaY > 0 ? -1 : 1
                                  changeTimeRangeButton(delta)
                                }
                                if (timelineHovered && !e.shiftKey) {
                                  const delta = e.deltaY
                                  if (delta > 0) {
                                    changeTimeRangeButton("backward")
                                  } else {
                                    changeTimeRangeButton("forward")
                                  }
                                }
                              }}
                              id="timeline-container"
                            >
                              {endTime &&
                                startTime && ( // Prevent errors by rendering timeline after times are set
                                  <Timeline
                                    // Styles from scss file does not work if timeline disappears
                                    style={{
                                      // opacity: props.assignments.length == 0 ? 0 : 1,
                                      opacity:
                                        props.selectedProfs.length == 0 &&
                                        props.assignments.length == 0
                                          ? 0
                                          : 1,
                                    }}
                                    groups={props.selectedProfs}
                                    items={props.assignments}
                                    visibleTimeStart={
                                      startTime ? startTime.valueOf() : 0
                                    }
                                    visibleTimeEnd={
                                      endTime ? endTime.valueOf() : 0
                                    }
                                    lineHeight={50}
                                    onItemDoubleClick={
                                      props.settingSelectedAssignment
                                    }
                                    stackItems
                                    rightSidebarWidth={isMobile ? null : 120}
                                    sidebarWidth={isMobile ? 0 : undefined}
                                    rightSidebarContent={props.t("Time")}
                                    canResize={false}
                                    traditionalZoom={true}
                                    canMove={props.userRole.includes("admin")}
                                    canChangeGroup={true}
                                    // onItemMove={handleMove}
                                    onItemMove={
                                      (e => console.log(e), handleItemMove)
                                    }
                                    onItemDrag={handleItemDrag}
                                    itemRenderer={checkRenderer()}
                                    onCanvasClick={props.handleEmptyAreaClick}
                                    // onCanvasDoubleClick={props.handleEmptyAreaClick}
                                  >
                                    <TimelineHeaders>
                                      {!isMobile && (
                                        <SidebarHeader>
                                          {({ getRootProps, styles }) => {
                                            const customStyles = {
                                              ...styles,
                                              // color: "transparent",
                                              width: "150.25px",
                                              fontWeight: "regular",
                                              fontSize: "13px",
                                              paddingRight: "2px",
                                              padding: "20px 0px 10px 10px",
                                            }
                                            return (
                                              <div
                                                {...getRootProps()}
                                                style={customStyles}
                                              >
                                                {props.findType == "admins"
                                                  ? props.t("Professional")
                                                  : props.t("Customer")}
                                              </div>
                                            )
                                          }}
                                        </SidebarHeader>
                                      )}
                                      {/* <DateHeader
                                    unit="primaryHeader"
                                    className="text-uppercase"
                                    disabled="true"
                                  /> */}
                                      <DateHeader
                                        unit="hour"
                                        labelFormat="HH.mm"
                                      />
                                    </TimelineHeaders>
                                    <TimelineMarkers>
                                      <TodayMarker>
                                        {({ styles }) => {
                                          const customStyles = {
                                            ...styles,
                                            backgroundColor: "#f46a6a",
                                            width: "2px",
                                            border: "1px",
                                            borderRadius: "10px",
                                          }
                                          return <div style={customStyles} />
                                        }}
                                      </TodayMarker>
                                    </TimelineMarkers>
                                  </Timeline>
                                )}
                            </div>
                            {/* Not in use */}
                            <div>
                              {tooltipContent && (
                                <div
                                  style={{
                                    position: "fixed",
                                    left: 100,
                                    bottom: 100,
                                    background: "rgba(0, 0, 0, 0.5)",
                                    color: "white",
                                    padding: 10,
                                    transition: "0.3s",
                                    fontSize: 14,
                                    borderRadius: 5,
                                    zIndex: 85,
                                  }}
                                >
                                  <div>
                                    {props.t("Move")}:{" "}
                                    {tooltipContent.item.title}
                                  </div>
                                  <div>
                                    {props.t("Assign to")}:{" "}
                                    {tooltipContent.group}
                                  </div>
                                  <div>
                                    {props.t("Start time")}:{" "}
                                    {formatDateTime(tooltipContent.time)}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                        <div className="p-3 mt-2">
                          <span className="me-4">
                            {props.t("All appointments")}:{" "}
                            <b>{props.assignmentsOrig.length}</b>
                          </span>
                          <span className="me-4">
                            {props.t("Shown appointments")}:{" "}
                            <b>{props.assignments.length}</b>
                          </span>
                          <span>
                            {props.t("Unassigned appointments")}:{" "}
                            {props.unassigned()}
                          </span>
                        </div>
                      </div>
                    )}
                  {/* TODO exportable lists */}
                  {/* {props.assignments.length > 0 && (
                      <>
                        <Col lg="12">{props.assignments.map(a=>(a.))}</Col>
                      </>
                    )} */}
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

TimelineCalendar2.propTypes = {
  t: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,

  getAppointments: PropTypes.any,
  selectedGroup: PropTypes.any,
  setSelectedGroup: PropTypes.any,
  loading: PropTypes.any,
  findType: PropTypes.any,
  startDate: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  setAssignments: PropTypes.any,
  assignmentsOrig: PropTypes.any,
  assignments: PropTypes.any,
  selectedProfs: PropTypes.any,
  professionals: PropTypes.any,
  setUserKey: PropTypes.any,

  modify: PropTypes.any,
  setModify: PropTypes.any,
  userRole: PropTypes.any,
  setUserRole: PropTypes.any,
  differences: PropTypes.any,

  unassigned: PropTypes.func,
  submitMultipleChanges: PropTypes.func,
  settingSelectedAssignment: PropTypes.func,
  handleChange: PropTypes.func,
  handleEmptyAreaClick: PropTypes.func,
  findMatch: PropTypes.func,
  setDifferences: PropTypes.func,
  setNewSelection: PropTypes.func,
}

export default withTranslation()(TimelineCalendar2)
