import React from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Collapse } from "antd"
const { Panel } = Collapse

const GroupCallManual = props => {
  return (
    <div className="instructions">
      <div className="px-1">
        <h5 className="font-size-15">{props.t("Group video call")}</h5>
        <p className="pt-2">{props.t("GroupVideoCall." + "0")}</p>
      </div>{" "}
      {/* <p className="pt-2">{props.t("GroupVideoCall." + "1")}</p> */}
      <Collapse
        bordered={false}
        className="px-0"
        accordion
        expandIcon={props.ExpandIcon}
        style={{ backgroundColor: "transparent" }}
        items={[
          //  TODO: VIDEOLINKKI ISTEKKI !!
          {
            key: "0",
            label: props.t("GroupVideoCallTitle1"),
            children: (
              <>
                <p>{props.t("GroupVideoCall." + "StartCall1")}</p>
                <p>{props.t("GroupVideoCall." + "StartCall2")}</p>
                <ul className="">
                  <li className="text-muted">
                    {props.t("GroupVideoCall." + "StartCall3")}
                  </li>
                  <li className="">
                    {props.t("GroupVideoCall." + "StartCall4")}
                  </li>
                </ul>
                <p>{props.t("GroupVideoCall." + "StartCall5")}</p>
                <p>{props.t("GroupVideoCall." + "StartCall6")}</p>
              </>
            ),
          },
          {
            key: "1",
            label: props.t("GroupVideoCallTitle2"),
            children: (
              <>
                <p>{props.t("GroupVideoCall." + "SendMessage1")}</p>
                <p>{props.t("GroupVideoCall." + "SendMessage2")}</p>
              </>
            ),
          },
          {
            key: "2",
            label: props.t("GroupVideoCallTitle3"),
            children: (
              <>
                <p>{props.t("GroupVideoCall." + "Events1")}</p>
                <p>{props.t("GroupVideoCall." + "Events2")}</p>
              </>
            ),
          },
          //       <Panel key={3} header={props.t("Navigation" + ".Carephone")}>
          //     <p>{props.t("GroupVideoCall." + "StartCall1")}</p>
          //     <p>{props.t("GroupVideoCall." + "StartCall2")}</p>
          //   </Panel>

          //   <Panel key={4} header={props.t("Navigation" + ".Video")}>
          //     <h6 className="">{props.t("Start video call")}</h6>
          //     <p className="">{props.t("InstuctionsVideo." + "StartCall")}</p>
          //     <ul className="">
          //       <li className="text-muted">
          //         {props.t("InstuctionsVideo." + "StartCall1")}
          //       </li>
          //       <li className="">{props.t("InstuctionsVideo." + "StartCall2")}</li>
          //     </ul>
          //     <p className="">{props.t("InstuctionsVideo." + "StartCall3")}</p>
          //     <p className="">{props.t("InstuctionsVideo." + "StartCall4")}</p>
          //     <h6>{props.t("Send a message to video device")}</h6>{" "}
          //     <p className="">{props.t("InstuctionsVideo." + "SendMessage")}</p>
          //   </Panel>
          //  <Panel key={5} header={props.t("Navigation" + ".Dose")}>
          //     <p>{props.t("InstuctionsVideo." + "StartCall")}</p>
          //   </Panel>
        ]}
      ></Collapse>
    </div>
  )
}
GroupCallManual.propTypes = {
  t: PropTypes.any,
  ExpandIcon: PropTypes.func,
}

export default withTranslation()(GroupCallManual)
