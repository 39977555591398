import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Tooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { Table as AntdTable } from "antd"
import XLSX from "xlsx"
import jsPDF from "jspdf"
import "jspdf-autotable"
import { withTranslation } from "react-i18next"
import {
  defaultPageSizeOptions,
  showToast,
  stringSorter,
  dateSorter,
  formatDateHoursMinutes,
} from "utils/general"

const FeedbackTable = props => {
  const [title] = useState(props.t("Video call feedback"))
  const [tableData, setTableData] = useState([])
  const [tableDataTotal, setTableDataTotal] = useState([])
  const [menu, setMenu] = useState(false)
  const [downloadstatstableTT, setDownloadstatstableTT] = useState(false)

  // Convert individual ratings (0,1,2) to label and color
  const ratingBadgeFormatter = ratingValue => {
    let label,
      style = { fontSize: "10px", fontWeight: "normal", padding: "6px 10px" }
    switch (ratingValue) {
      case 0:
        label = props.t("Improvable")
        style.backgroundColor = "#E57373"
        break
      case 1:
        label = props.t("Satisfied")
        style.backgroundColor = "#F3B562"
        break
      case 2:
        label = props.t("Excellent")
        style.backgroundColor = "#50A684"
        break
      default:
        label = ""
    }
    return (
      <span className="badge font-size-12" style={style}>
        {label}
      </span>
    )
  }

  // Group by client and compute average rating %, latest rating and date
  useEffect(() => {
    if (props.data?.list?.length > 0) {
      const grouped = {}
      props.data.list.forEach(item => {
        const client = item.client_name
        if (!grouped[client]) {
          grouped[client] = []
        }
        grouped[client].push(item)
      })

      const transformed = Object.keys(grouped).map(client => {
        const items = grouped[client]
        // Average rating in %
        const avg = Math.round(
          (items.reduce((sum, cur) => sum + cur.rating, 0) / items.length / 2) *
            100
        )
        // Latest feedback based on date
        const latestItem = items.reduce((latest, cur) =>
          new Date(cur.date) > new Date(latest.date) ? cur : latest
        )
        return {
          group: latestItem.group,
          client_name: client,
          avgRating: avg + "%",
          latestRating: latestItem.rating,
          latestDate: latestItem.date,
        }
      })

      setTableData(transformed)
      setTableDataTotal(transformed)
    } else {
      setTableData([])
    }
  }, [props.data])

  // Table columns
  const columns = [
    {
      dataIndex: "group",
      title: props.t("Group"),
      sorter: (a, b) => stringSorter(a, b, "group"),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "client_name",
      title: props.t("Client name"),
      sorter: (a, b) => stringSorter(a, b, "client_name"),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "avgRating",
      title: props.t("Average rating"),
      sorter: (a, b) => parseInt(a.avgRating) - parseInt(b.avgRating),
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "latestRating",
      title: props.t("Latest rating"),
      render: rating => ratingBadgeFormatter(rating),
      sorter: (a, b) => a.latestRating - b.latestRating,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "latestDate",
      title: props.t("Latest date"),
      render: date => formatDateHoursMinutes(date),
      sorter: (a, b) => dateSorter(a.latestDate, b.latestDate),
      width: "140px",
      ellipsis: true,
    },
  ]

  const exportPDF = () => {
    const message = props.t("PDF file exported succesfully!")
    const doc = new jsPDF("landscape", "pt", "A4")
    doc.setFontSize(14)
    const data = tableDataTotal.map(item => [
      item.group,
      item.client_name,
      item.avgRating,
      ratingBadgeFormatter(item.latestRating).props.children,
      formatDateHoursMinutes(item.latestDate),
    ])
    const content = {
      startY: 50,
      head: [
        [
          props.t("Group"),
          props.t("Client name"),
          props.t("Average rating"),
          props.t("Latest rating"),
          props.t("Latest date"),
        ],
      ],
      body: data,
    }
    doc.text(title, 40, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  const downloadExcel = () => {
    const message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()
    const Heading = [
      [
        props.t("Group"),
        props.t("Client name"),
        props.t("Average rating"),
        props.t("Latest rating"),
        props.t("Latest date"),
      ],
    ]
    const data = tableDataTotal.map(item => [
      item.group,
      item.client_name,
      item.avgRating,
      ratingBadgeFormatter(item.latestRating).props.children,
      formatDateHoursMinutes(item.latestDate),
    ])
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.writeFile(wb, `${title} (${new Date()}).xlsx`)
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  return (
    <div>
      <Row>
        <Col>
          <Card className="mt-2">
            <CardBody>
              <Row>
                <Col className="col-12">
                  <Row className="mb-1">
                    <Col xl="4" xs="6">
                      <div className="mt-2">
                        <p>
                          {tableData.length} {props.t("found results")}
                        </p>
                      </div>
                    </Col>
                    <Col xl="8" xs="6">
                      <span className="float-end mt-1">
                        <Tooltip
                          placement="bottom"
                          isOpen={downloadstatstableTT}
                          target="downloadstatstable"
                          toggle={() =>
                            setDownloadstatstableTT(!downloadstatstableTT)
                          }
                        >
                          {props.t("Download statistics")}
                        </Tooltip>
                        <Dropdown
                          id="printButtonServicesStats"
                          isOpen={menu}
                          toggle={() => setMenu(!menu)}
                          className="download-btn text-center"
                        >
                          <DropdownToggle
                            id="downloadstatstable"
                            className="text-muted"
                            tag="i"
                          >
                            <i className="mdi mdi-download" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={downloadExcel}>
                              {props.t("Download .xlsx")}
                            </DropdownItem>
                            <DropdownItem onClick={exportPDF}>
                              {props.t("Download .pdf")}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </span>
                    </Col>
                  </Row>
                  <div className="extra-padding">
                    <AntdTable
                      bordered={false}
                      size="middle"
                      rowKey={"client_name"}
                      columns={columns}
                      dataSource={tableData}
                      pagination={{
                        showSizeChanger: true,
                        defaultPageSize: 50,
                        pageSizeOptions: defaultPageSizeOptions,
                      }}
                      scroll={{ x: "max-content", y: "70vh" }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

FeedbackTable.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
}

export default withTranslation()(FeedbackTable)
