import React, { useEffect, useState, useContext } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import {
  Offcanvas,
  OffcanvasHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  OffcanvasBody,
} from "reactstrap"

import { Tooltip } from "antd"

import { CaretRightOutlined } from "@ant-design/icons"

import GlobalState from "contexts/GlobalState"
import LandingPageManual from "./Instructions/LandingPageManual"
import CustomerManagementManual from "./Instructions/CustomerManagementManual"
import GroupCallManual from "./Instructions/GroupCallManual"
import AppointmentsManual from "./Instructions/AppointmentsManual"
import ReportingManual from "./Instructions/ReportingManual"
import ConcentEventsManual from "./Instructions/ConcentEventsManual"
import SimpleBar from "simplebar-react"

const ExpandIcon = ({ isActive }) => (
  <CaretRightOutlined rotate={isActive ? 90 : 0} />
)

const Manual = props => {
  const [state] = useContext(GlobalState)
  const [supportType, setSupportType] = useState(null)
  const [manualOpen, setManualOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [tab, setTab] = useState(props.tab)

  useEffect(() => {
    if (state.authUser != null) {
      const user = JSON.parse(state.authUser)
      setSupportType(user.supportType)
    }
  }, [])

  useEffect(() => {
    setTab(props.tab)
    // console.log(props.tab, "tab")

    if (state.authUser != null) {
      const user = JSON.parse(state.authUser)
      setSupportType(user.supportType)
    }
  }, [props.tab])

  // DOWNLOAD PDF
  const onButtonClick = () => {
    fetch("/files/file.pdf").then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob)
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = props.t("Instructions") + ".pdf"
        alink.click()
      })
    })
  }

  const sourcePage = v => {
    // console.log(v, "type")
    switch (v) {
      case "landing-page":
        return <LandingPageManual />
      case "customer-management":
        return <CustomerManagementManual tab={tab} ExpandIcon={ExpandIcon} />
      case "group-video-call":
        return <GroupCallManual ExpandIcon={ExpandIcon} />
      case "content-events":
        return <ConcentEventsManual ExpandIcon={ExpandIcon} />
      case "appointments":
        return <AppointmentsManual ExpandIcon={ExpandIcon} />
      case "reporting":
        return <ReportingManual ExpandIcon={ExpandIcon} />
      default:
        return null
    }
  }

  return (
    <span className="mb-0">
      <span>
        <Tooltip
          title={props.t("Manual")}
          overlayInnerStyle={{
            padding: "6px 11px 0px 11px",
            borderRadius: "4px",
            fontSize: "11px",
          }}
        >
          <Dropdown
            isOpen={manualOpen}
            toggle={() => {
              if (props.source == "landing-page") {
                setManualOpen(!manualOpen)
              } else {
                setIsOpen(!isOpen)
              }
            }}
          >
            <DropdownToggle
              id="info-button"
              className="btn nav-btn d-inline-flex"
              tag="a"
              style={{ padding: "6px 8px" }}
            >
              <i className="bx bx-book-content font-size-16"></i>
            </DropdownToggle>
            {props.source == "landing-page" ? (
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem href="#" onClick={() => setIsOpen(true)}>
                  {props.t("Show side manual")}
                </DropdownItem>
                <DropdownItem href="#" onClick={onButtonClick}>
                  {props.t("Download manual")}
                </DropdownItem>

                {supportType == 6 || supportType == 7 ? (
                  <DropdownItem
                    href={"https://suvanto.help/istekki"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.t("Help portal")}
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    href={"https://suvanto.info"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.t("Instructional videos")}
                  </DropdownItem>
                )}
              </DropdownMenu>
            ) : (
              <></>
            )}
          </Dropdown>
        </Tooltip>
      </span>

      <Offcanvas
        isOpen={isOpen}
        direction="end"
        scrollable
        backdrop={false}
        toggle={() => setIsOpen(!isOpen)}
      >
        <OffcanvasHeader
          toggle={() => {
            setIsOpen(!isOpen)
          }}
        >
          <i className="bx bx-book-content me-2"></i> {props.t("Manual")}
        </OffcanvasHeader>
        <hr className="py-0 my-0"></hr>
        <SimpleBar style={{ maxHeight: "92vh", marginRight: "5px" }}>
          <OffcanvasBody>{sourcePage(props.source)}</OffcanvasBody>
        </SimpleBar>
      </Offcanvas>
    </span>
  )
}

Manual.propTypes = {
  t: PropTypes.any,
  source: PropTypes.any,
  tab: PropTypes.any,
}

ExpandIcon.propTypes = {
  isActive: PropTypes.bool,
}

export default withTranslation()(Manual)
