/**********************
 * DEMO
 * Appoinments
 * Timeline
 *********************/
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

import { getGroupCallClients } from "helpers/backend_helper"

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormFeedback,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap"

import { DatePicker, Select } from "antd"

import dayjs from "dayjs"

import SelectClient from "components/DropdownList/SelectClient"
import SelectCustomersTree from "components/DropdownList/SelectCustomersTree"

const AppoinmentModal = props => {
  const [deleteModal, setDeleteModal] = useState(false)
  const [groupGuests, setGroupGuests] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    getGroupClients()
    return () => {
      abortRef.current.abort()
    }
  }, [])

  // Clear group guest tree on modal close
  useEffect(() => {
    if (!props.modal) {
      props.validation.setFieldValue("selectedCustomers", null)
    }
  }, [props.modal])

  // Update groupguest invite trees initial values
  useEffect(() => {
    if (props.validation.values.type === "group_call") {
      if (typeof props.selectedAssignment?.client_key === "string") {
        props.validation.setFieldValue(
          "selectedCustomers",
          props.selectedAssignment.client_key.split(",")
        )
      } else if (Array.isArray(props.selectedAssignment?.client_key)) {
        props.validation.setFieldValue(
          "selectedCustomers",
          props.selectedAssignment.client_key
        )
      }
    } else {
      props.validation.setFieldValue("selectedCustomers", null)
    }
  }, [props.selectedAssignment, props.validation.values.type])

  // Get list of group guests
  const getGroupClients = () => {
    const isProduction = false
    getGroupCallClients(abortRef.current, isProduction)
      .then(result => {
        if (result.clients) {
          // console.log("Group clients: ", result)
          setGroupGuests(result.clients)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  let types = [
    { label: props.t("Video_call"), value: "video_call" },
    { label: props.t("Group call"), value: "group_call" },
  ]

  return (
    <>
      {/* LEAVE MODAL */}
      {/* <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={() => handleModalResponse("confirm")}
          onCancel={() => handleModalResponse("cancel")}
        >
          Are you sure you want to leave?
        </Modal> */}
      {/** DELETE CLIENT MODAL */}{" "}
      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedAssignment?.title +
              " " +
              props.t("event") +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={props.ongoingDelete}
            color="danger"
            onClick={() => {
              props.deleteAssignment()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
      {props.assignments && !props.loading && !props.modify && (
        <Modal isOpen={props.modal && !props.modify} centered>
          {props.selectedAssignment && !props.addNew ? (
            <>
              <ModalHeader>{props.selectedAssignment.title}</ModalHeader>
            </>
          ) : (
            <ModalHeader>{props.t("Add a new appointment")}</ModalHeader>
          )}
          <ModalBody>
            <Form
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <Row>
                <Col xs="12" className="mb-3">
                  <Label for="appointmentName">
                    {props.t("Appointment name")}
                  </Label>
                  <Input
                    type="text"
                    id="appointmentName"
                    placeholder={
                      props.t("Enter appointment name") +
                      " " +
                      "(" +
                      props.t("Optional") +
                      ")"
                    }
                    value={props.validation.values.appointmentName}
                    onChange={v =>
                      props.validation.setFieldValue(
                        "appointmentName",
                        v.target.value
                      )
                    }
                    onBlur={props.validation.handleBlur}
                    invalid={
                      props.validation.touched.appointmentName &&
                      !!props.validation.errors.appointmentName
                    }
                  />
                  {props.validation.touched.appointmentName &&
                    props.validation.errors.appointmentName && (
                      <FormFeedback type="invalid">
                        {props.validation.errors.appointmentName}
                      </FormFeedback>
                    )}
                </Col>

                {/* Add new appointment */}
                {props.validation.values.type === "video_call" && (
                  <Col xs="12" className="mb-3">
                    <Label>{props.t("Client")}</Label>
                    <SelectClient
                      selectedGroup={props.selectedGroup}
                      value={props.validation.values.selectedCustomers || null}
                      onChange={v => {
                        props.validation.setFieldValue("selectedCustomers", [v])
                      }}
                    />
                  </Col>
                )}

                {props.validation.values.type === "group_call" && (
                  <>
                    <Col xs="12" className="mb-3">
                      <Label>{props.t("Participants")}</Label>
                      <SelectCustomersTree
                        data={groupGuests}
                        checkedKeys={props.validation.values.selectedCustomers}
                        setCheckedKeys={v => {
                          props.validation.setFieldValue("selectedCustomers", v)
                        }}
                      />
                    </Col>
                  </>
                )}

                {!props.emptyClickAssignments && (
                  <Col xs="12" className="mb-3">
                    <Label>{props.t("Assign to")}</Label>
                    <Select
                      showSearch
                      options={props.professionals}
                      onChange={v => {
                        props.validation.setFieldValue("group", v)
                      }}
                      style={{ width: "100%" }}
                    />
                  </Col>
                )}

                {/* ------------------------------------------------------ REPEATING APPOINTMENT --------------------------------------------- */}
                {/* <Col className="mb-3" xs="6">
              <Label>{props.t("Repetition")}</Label>
              {modifyAssignment ? (
                <>
                  <Input
                    name="repetitionType"
                    type="select"
                    value={props.validation.values.repetitionType}
                    onChange={props.validation.handleChange}
                  >
                    <option value={"once"}>{props.t("Once")}</option>
                    <option value={"interval"}>
                      {props.t("Interval")}
                    </option>
                    <option value={"weekdays"}>
                      {props.t("Weekdays")}
                    </option>
                  </Input>
                </>
              ) : (
                <div className="font-size-14">
                  {props.selectedAssignment.repetitionType}
                </div>
              )}
            </Col> 

                   {props.validation.values.repetition === "weekdays" && (
                <Col xs="8">
                  <h6>{props.t("Weekdays")}</h6>
                  <div className="text-uppercase mt-3">
                    <FormGroup>
                      <input
                        type="checkbox"
                        id="mon"
                        className="form-check-input"
                        defaultChecked={props.validation.values.monday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "monday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="mon"
                      >
                        {props.t("Mon")}
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="tue"
                        autoComplete="off"
                        defaultChecked={props.validation.values.tuesday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "tuesday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="tue"
                      >
                        {props.t("Tues")}
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="wed"
                        autoComplete="off"
                        defaultChecked={props.validation.values.wednesday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "wednesday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="wed"
                      >
                        {props.t("Wed")}
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="thu"
                        autoComplete="off"
                        defaultChecked={props.validation.values.thursday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "thursday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="thu"
                      >
                        {props.t("Thurs")}
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="fri"
                        autoComplete="off"
                        defaultChecked={props.validation.values.friday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "friday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="fri"
                      >
                        {props.t("Fri")}
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="sat"
                        autoComplete="off"
                        defaultChecked={props.validation.values.saturday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "saturday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="sat"
                      >
                        {props.t("Sat")}
                      </label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="sun"
                        autoComplete="off"
                        defaultChecked={props.validation.values.sunday}
                        onChange={v => {
                          props.validation.setFieldValue(
                            "sunday",
                            v.target.checked
                          )
                        }}
                      />
                      <label
                        className="form-check-label ms-1 me-2"
                        htmlFor="sun"
                      >
                        {props.t("Sun")}
                      </label>
                    </FormGroup>
                  </div>
                </Col>
              )}
              {props.validation.values.repetition === "interval" && (
                <Col xs="8">
                  <h6>{props.t("Recurrence interval")}</h6>
                  <InputNumber
                    className="mt-1 p-1 me-1"
                    style={{ height: "36.5px" }}
                    min="1"
                    max="365"
                    onChange={v =>
                      props.validation.setFieldValue("interval", v)
                    }
                    value={props.validation.values.interval}
                    invalid={
                      props.validation.touched.interval &&
                      props.validation.errors.interval
                        ? "true"
                        : "false"
                    }
                  ></InputNumber>
                  {props.t("vrk")}
                </Col>
              )}  */}
                {/* -------------------------------------------- REPEATING APPOINTMENT --------------------------------------------- */}

                <Col className="mb-3 mt-2" xs="8">
                  {props.validation.values.repetition === "once" ? (
                    <Label>{props.t("Time of the assignment")}</Label>
                  ) : (
                    <Label>{props.t("Start time")}</Label>
                  )}

                  <DatePicker
                    allowClear={false}
                    showTime={true}
                    value={props.validation.values.start_time || dayjs()}
                    format="DD.MM.YYYY HH:mm"
                    style={{ height: "36.5px", width: "100%" }}
                    onChange={v => {
                      props.validation.setFieldValue("start_time", v)
                    }}
                  />
                </Col>
                {/*REPEATING APPOINTMENT {props.validation.values.repetition !== "once" && (
                  <Col xs="6">
                    <Label>{props.t("End of repetition")}</Label>
                    <DatePicker
                      showTime={true}
                      value={props.validation.values.endDate}
                      format="DD.MM.YYYY HH:mm"
                      style={{ height: "36.5px" }}
                      onChange={v => {
                        props.validation.setFieldValue("endDate", v)
                      }}
                    />
                  </Col>
                )} */}
                <Col className="mb-3 mt-2" xs="4">
                  <Label>{props.t("Duration in minutes")}</Label>

                  <Input
                    name="duration"
                    // placeholder={props.selectedAssignment.duration}
                    type="number"
                    onChange={props.validation.handleChange}
                    onBlur={props.validation.handleBlur}
                    value={props.validation.values.duration}
                    min={0}
                    max={1000}
                    invalid={
                      props.validation.touched.duration &&
                      !!props.validation.errors.duration
                    }
                  />
                  {props.validation.touched.duration &&
                    props.validation.errors.duration && (
                      <FormFeedback type="invalid">
                        {props.validation.errors.duration}
                      </FormFeedback>
                    )}
                </Col>
                <Col className="mb-3 mt-2" xs="6">
                  <Label>{props.t("Type")}</Label>
                  <Select
                    style={{
                      width: "100%",
                    }}
                    value={props.validation.values.type}
                    options={types}
                    onChange={v => {
                      props.validation.setFieldValue("type", v)
                    }}
                  />
                </Col>
                {/* REPEATING APPOINTMENT <Col className="mb-3 text-muted" xs="6">
              <Label>{props.t("Notices")}</Label>
              <div className="font-size-14">
                {props.selectedAssignment.note}
              </div>
            </Col> */}
                <Col xs="12" className="pt-4 pb-3 ">
                  <Row>
                    <Col xs="6">
                      {props.customizeAssignment &&
                        props.userRole.includes("admin") && (
                          <Button
                            className="text-truncate "
                            color="danger"
                            onClick={() => {
                              setDeleteModal(!deleteModal)
                            }}
                          >
                            {props.t("Delete event")}
                          </Button>
                        )}
                    </Col>
                    <Col xs="6">
                      <Button
                        className="float-end"
                        color="primary"
                        onClick={() => {
                          props.validation.submitForm()
                        }}
                      >
                        {props.customizeAssignment
                          ? props.t("Update information")
                          : props.t("Save")}
                      </Button>
                      <Button
                        className="float-end me-2"
                        color="primary"
                        outline
                        onClick={() => {
                          props.setModal(false)
                          props.validation.resetForm()
                        }}
                      >
                        {props.t("Cancel")}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

AppoinmentModal.propTypes = {
  t: PropTypes.any,
  selectedAssignment: PropTypes.any,
  modify: PropTypes.any,
  modal: PropTypes.any,
  setModal: PropTypes.any,
  addNew: PropTypes.any,
  selectedGroup: PropTypes.any,
  validation: PropTypes.any,
  customizeAssignment: PropTypes.any,
  professionals: PropTypes.any,
  userRole: PropTypes.any,
  ongoingDelete: PropTypes.any,
  emptyClickAssignments: PropTypes.any,
  loading: PropTypes.any,
  assignments: PropTypes.any,
  deleteAssignment: PropTypes.func,
}

export default withTranslation()(AppoinmentModal)
