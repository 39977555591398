/***********************
 * CALENDAR COMPONENT (RANGE, CUSTOMER SETTINGS)
 ************************/

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

//Formatting date
import dayjs from "dayjs"

//Translation
import { withTranslation } from "react-i18next"

//DatePicker ANTD
import { DatePicker, ConfigProvider } from "antd"

// Locale/weekday related imports
import "dayjs/locale/fi"
import "dayjs/locale/se"
import "dayjs/locale/en-gb"
import { Col } from "reactstrap"
import { getLocale } from "utils/general"

const TimelineCalendar1 = props => {
  const [defaultValueStart] = useState(props.range)
  const [rangePicker, setRangePicker] = useState(true)
  // const [now, setNow] = useState()
  const [startTime, setStartTime] = useState()
  const [defaultValueEnd] = useState(dayjs())
  const [endTime, setEndTime] = useState()
  const [dateAndTime] = useState(null)
  const [chosenDate, setChosenDate] = useState(dayjs())

  const locale = getLocale()

  useEffect(() => {
    if (dateAndTime == null && defaultValueStart != undefined) {
      let timeStart = new Date(defaultValueStart)
      let timeEnd = new Date(defaultValueEnd)
      let unixStart = timeStart.getTime()
      let unixEnd = timeEnd.getTime()
      props.setCalendarStartDate(unixStart)
      props.setCalendarEndDate(unixEnd)
    }
    if (defaultValueStart == undefined) {
      //   console.log(defaultValueStart)
      onDateSelection(dayjs())
    }
  }, [])

  useEffect(() => {
    if (props.range == undefined) {
      setRangePicker(false)
    }
  }, [props.range])

  useEffect(() => {
    // TODO:set counter => 0 when date is changed
    let newDate = dayjs(chosenDate).add(props.updateCounter[1], "d")
    if (chosenDate !== newDate) {
      onDateSelection(newDate)
    }
  }, [props.updateCounter])

  const onDateSelection = value => {
    setChosenDate(value)
    // console.log(value)
    // setDateAndTime(value)
    if (value != null) {
      let start = new Date(value.startOf("day"))
      let end = new Date(value.endOf("day"))

      //   let startUnix = start.getTime()
      //   let endUnix = end.getTime()
      props.setCalendarStartDate(start)
      props.setCalendarEndDate(end)

      setStartTime(start)
      setEndTime(end)
    }
  }

  return (
    <React.Fragment>
      <div style={{ width: "100%" }}>
        <Col className={` ${props.placeholder ? "placeholder" : ""}`}>
          <ConfigProvider locale={locale}>
            <Col className="px-0">
              {/* <Button
                onClick={() => {
                  settingDate("back")
                }}
                size="sm"
                color="clear"
                className="mt-0 py-0"
                style={{ border: "none" }}
              >
                <i className="bx bx-chevron-left mt-1  text-primary font-size-20"></i>
              </Button> */}
              <DatePicker
                disabled={props.disabled}
                style={{ width: "100%" }}
                className="mt-0"
                defaultValue={dayjs()}
                showToday
                allowClear={false}
                onChange={onDateSelection}
                format="DD.MM.YYYY"
                value={chosenDate}
              />
              {/* <Button
                onClick={() => {
                  settingDate("forward")
                }}
                size="sm"
                color="clear"
                className="mt-0 py-0"
                style={{ border: "none" }}
              >
                <i className="bx bx-chevron-right mt-1 text-primary font-size-20"></i>
              </Button> */}
            </Col>
          </ConfigProvider>
        </Col>
      </div>
    </React.Fragment>
  )
}

TimelineCalendar1.propTypes = {
  placeholder: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
  range: PropTypes.any,
  updateCounter: PropTypes.any,
  disabled: PropTypes.bool,
}
export default withTranslation()(TimelineCalendar1)
