import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation, useNavigate } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { getSmartlocks, syncSmartlocks } from "helpers/backend_helper"
import PindoraDrawer from "./PindoraDrawer"
import {
  showToast,
  downloadExcel,
  isTrue,
  stringSorter,
  numberSorter,
  dateSorter,
  defaultPageSizeOptions,
  dateFormatter,
  getOperatorByIccid,
  operatorFormatter,
  searchToURL,
} from "utils/general"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"
import { clientLinksFormatter } from "utils/tables"

const PindoraConfigs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [pindoras, setPindoras] = useState([])
  const [filteredPindoras, setFilteredPindoras] = useState([])
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [statusFilter, setStatusFilter] = useState("0")
  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [statistics, setStatistics] = useState({
    total: 0,
  })
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [title] = useState(props.t("Smartlocks"))

  const search = useLocation().search
  const navigate = useNavigate()
  const location = useLocation()

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    abortRef.current = new AbortController()
    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = pindoras.length
    temp.linked = pindoras.filter(data => data.links.length > 0).length
    temp.online = pindoras.filter(data => data.status === "online").length
    temp.offline = pindoras.filter(data => data.status === "offline").length
    temp.notAvailable = pindoras.filter(
      data => data.status === "not_available"
    ).length

    setStatistics(temp)
  }, [pindoras])

  // Check duplicate values
  useEffect(() => {
    if (pindoras) {
      // GSM, ICCID, IMEI
      let dups = []
      for (let i = 0; i < pindoras.length - 1; i++) {
        for (let j = i + 1; j < pindoras.length; j++) {
          if (pindoras[i].iccid && pindoras[i].iccid === pindoras[j].iccid) {
            dups.push({
              key: pindoras[i].iccid,
              name: "ICCID:" + pindoras[i].iccid,
            })
          }
          if (pindoras[i].gsm && pindoras[i].gsm === pindoras[j].gsm) {
            dups.push({ key: pindoras[i].gsm, name: "GSM:" + pindoras[i].gsm })
          }
          if (pindoras[i].imei && pindoras[i].imei === pindoras[j].imei) {
            dups.push({
              key: pindoras[i].imei,
              name: "IMEI:" + pindoras[i].imei,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [pindoras])

  // Check multiple links
  useEffect(() => {
    if (pindoras) {
      let multis = []
      pindoras.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.id, name: element.name })
        }
      })
      setMultilinks(multis)
    }
  }, [pindoras])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen("")
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, pindoras, statusFilter])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = pindoras.filter(
      data =>
        (statusFilter === "0" ||
          (statusFilter === "1" && data.status === "online") ||
          (statusFilter === "2" && data.status === "offline") ||
          (statusFilter === "3" && data.status === "not_available")) &&
        (data.name?.toLowerCase().includes(searchTextLowercase) ||
          data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
          data.description?.toLowerCase().includes(searchTextLowercase) ||
          data.gsm?.toLowerCase().includes(searchTextLowercase) ||
          data.imei?.toLowerCase().includes(searchTextLowercase) ||
          data.iccid?.toLowerCase().includes(searchTextLowercase) ||
          data.operator?.toLowerCase().includes(searchTextLowercase) ||
          data.firmware?.toLowerCase().includes(searchTextLowercase) ||
          data.id?.toLowerCase() === searchTextLowercase ||
          data.groupTags?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.toLowerCase().includes(searchTextLowercase))
    )
    setFilteredPindoras(result)
  }

  // Get pindoras
  const getDevices = () => {
    setOngoingSearch(true)
    getSmartlocks(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          result.entries.forEach(element => {
            element.linkedNames = getLinkedNames(element.links)

            element.operator = getOperatorByIccid(element.iccid) || ""
          })
          setPindoras(result.entries)
        } else {
          setPindoras([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setPindoras([])
        setOngoingSearch(false)
      })
  }

  // Get linked names in proper format
  const getLinkedNames = links => {
    let linkedNames = []
    if (links) {
      links.forEach(link => {
        linkedNames.push(link.name)
      })
    }
    return linkedNames.join()
  }

  // Sync all devices
  const syncDeviceList = () => {
    setOngoingSync(true)
    syncSmartlocks(abortRef.current)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "name",
      title: props.t("Serial"),
      defaultSortOrder: "ascend",
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "description",
      title: props.t("Description"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "description", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "groupTags",
      title: props.t("Tags"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "groupTags", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: clientLinksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "status",
      title: props.t("Status"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "status", sortOrder)
      },
      render: cell => {
        return cell == "offline" ? (
          <span style={{ color: "#f46a6a" }}>{cell}</span>
        ) : (
          cell
        )
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "battery",
      title: props.t("Battery"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["battery"], b["battery"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "isCharging",
      title: props.t("Charging"),
      align: "center",
      sorter: (a, b) => {
        return a.isCharging - b.isCharging
      },
      render: cell => {
        return cell ? <i className="mdi mdi-check-bold text-primary" /> : <></>
      },
      width: "135px",
      ellipsis: true,
    },
    {
      dataIndex: "magnetState",
      title: props.t("Magnet state"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["magnetState"], b["magnetState"], sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "wifiSignalStrength",
      title: props.t("Wifi signal strength"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(
          a["wifiSignalStrength"],
          b["wifiSignalStrength"],
          sortOrder
        )
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "lastSeenAt",
      title: props.t("Last seen"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["lastSeenAt"], b["lastSeenAt"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryChanged",
      title: props.t("Battery changed"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["batteryChanged"], b["batteryChanged"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "magnetChanged",
      title: props.t("Magnet changed"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["magnetChanged"], b["magnetChanged"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "wifiChanged",
      title: props.t("WiFi changed"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["wifiChanged"], b["wifiChanged"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "gsm",
      title: props.t("GSM"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "gsm", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "iccid",
      title: props.t("ICCID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "iccid", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "operator",
      title: props.t("Operator"),
      render: (text, record) => {
        return operatorFormatter(record.operator)
      },
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "operator", sortOrder)
      },
      width: "110px",
      ellipsis: true,
      align: "center",
    },
    {
      dataIndex: "imei",
      title: props.t("IMEI"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "imei", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "firmware",
      title: props.t("Firmware"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "firmware", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "id",
      title: props.t("ID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "id", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    AntdTable.SELECTION_COLUMN,
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Serial"),
        props.t("Description"),
        props.t("Tags"),
        props.t("Links"),
        props.t("Status"),
        props.t("Battery"),
        props.t("Charging"),
        props.t("Magnet state"),
        props.t("Wifi signal strength"),
        props.t("Last seen"),
        props.t("Battery changed"),
        props.t("Magnet changed"),
        props.t("WiFi changed"),
        props.t("GSM"),
        props.t("ICCID"),
        props.t("Operator"),
        props.t("IMEI"),
        props.t("Firmware"),
        props.t("Order number"),
        props.t("ID"),
      ],
    ]
    const data = filteredPindoras.map(elt => [
      elt.name,
      elt.description,
      elt.groupTags,
      elt.linkedNames,
      elt.status,
      elt.battery,
      isTrue(elt.isCharging),
      elt.magnetState,
      elt.wifiSignalStrength,
      dateFormatter(elt.lastSeenAt),
      dateFormatter(elt.batteryChanged),
      dateFormatter(elt.magnetChanged),
      dateFormatter(elt.wifiChanged),
      elt.gsm,
      elt.iccid,
      elt.operator,
      elt.imei,
      elt.firmware,
      elt.orderNumber,
      elt.id,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // Table selection changed
  const onSelectChange = (newSelectedRowKeys, newSelectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Smartlocks")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Accordion
                    flush
                    open={alertsOpen}
                    toggle={id => {
                      id === alertsOpen ? setAlertsOpen("") : setAlertsOpen(id)
                    }}
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="accordion-zeropadding"
                      >
                        <Alert color="danger">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Duplicate values")}
                            </h5>
                            {duplicates.map((entry, index) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={"" + index}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Multiple links")}
                            </h5>
                            {multilinks.map((entry, index) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={"" + index}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                        </Alert>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <Row>
                    <Col>
                      {pindoras != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      value={searchText}
                                      onBlur={() =>
                                        // Apply search word to URL
                                        searchToURL(
                                          searchText,
                                          location,
                                          navigate
                                        )
                                      }
                                      onChange={event =>
                                        setSearchText(event.target.value)
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                color="primary"
                                className="round-icon-button"
                                id="filter-button"
                                outline
                                onClick={() => {
                                  filtersOpen.length > 0
                                    ? setFiltersOpen("")
                                    : setFiltersOpen("1")
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="filter-button"
                                >
                                  {props.t("Filters")}
                                </UncontrolledTooltip>
                                {filtersOpen ? (
                                  <i className="mdi mdi-filter-minus"></i>
                                ) : (
                                  <i className="mdi mdi-filter-menu"></i>
                                )}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getDevices()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSync}
                                onClick={() => {
                                  syncDeviceList()
                                }}
                              >
                                <i className="mdi mdi-cloud-sync me-2" />
                                {props.t("Sync devices")}
                              </Button>
                              {(duplicates.length > 0 ||
                                multilinks.length > 0) && (
                                <Button
                                  color="danger"
                                  className="round-icon-button ms-3"
                                  id="alerts-button"
                                  onClick={() => {
                                    alertsOpen.length > 0
                                      ? setAlertsOpen("")
                                      : setAlertsOpen("1")
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target="alerts-button"
                                  >
                                    {props.t("Notices")}
                                  </UncontrolledTooltip>
                                  {alertsOpen ? (
                                    <i className="mdi mdi-alert-minus"></i>
                                  ) : (
                                    <i className="mdi mdi-alert-plus"></i>
                                  )}
                                </Button>
                              )}
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredPindoras.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                className="mb-3"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row xs={1} sm={2} md={3} xl={4}>
                                  <Col>
                                    <FormGroup>
                                      <Label>{props.t("Status")}</Label>
                                      <Input
                                        type="select"
                                        bsSize={"sm"}
                                        name="statusFilter"
                                        value={statusFilter}
                                        onChange={v => {
                                          setStatusFilter(v.target.value)
                                        }}
                                      >
                                        <option value="0">
                                          {props.t("All")}
                                        </option>
                                        <option value="1">
                                          {props.t("Online")}
                                        </option>
                                        <option value="2">
                                          {props.t("Offline")}
                                        </option>
                                        <option value="3">
                                          {props.t("Not available")}
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>

                          <Row className="mt-2">
                            <Col xl="12">
                              <AntdTable
                                className="colored-header narrow-header"
                                bordered
                                size="small"
                                sticky
                                rowKey={"id"}
                                columns={columns}
                                dataSource={filteredPindoras}
                                rowSelection={{
                                  type: "radio",
                                  fixed: false,
                                  selectedRowKeys: selectedRowKeys,
                                  onChange: onSelectChange,
                                  columnWidth: 0,
                                  renderCell: () => "",
                                }}
                                onRow={(record, rowIndex) => {
                                  return {
                                    onClick: event => {
                                      if (selectedRowKeys.includes(record.id)) {
                                        setSelectedRowKeys([])
                                      } else {
                                        setSelectedRowKeys([record.id])
                                      }
                                    },
                                  }
                                }}
                                pagination={{
                                  showSizeChanger: true,
                                  defaultPageSize: 50,
                                  pageSizeOptions: defaultPageSizeOptions,
                                }}
                                loading={ongoingSearch}
                                tableLayout="auto"
                                scroll={{
                                  x: "max-content",
                                  y: tableDefaultHeight,
                                }}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Online")}</th>
                        <td>{statistics.online}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Offline")}</th>
                        <td>{statistics.offline}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Not available")}</th>
                        <td>{statistics.notAvailable}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <PindoraDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getDevices={getDevices}
          />
        </Container>
      </div>
    </>
  )
}

PindoraConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(PindoraConfigs)
