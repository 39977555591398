import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Dropdown,
  DropdownItem,
  Button,
  Col,
  DropdownToggle,
  DropdownMenu,
  Row,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Alert,
  Form,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
//  antd
import { Switch, DatePicker, InputNumber } from "antd"
import { Link } from "react-router-dom"
import {
  addOrUpdateScheduledMessage,
  deleteScheduledMessage,
  getScheduledMessages,
} from "helpers/backend_helper"
import { showToast, getLottieLoadingOptions, formatDate, formatDateHoursMinutes } from "utils/general"
import dayjs from "dayjs"
import Lottie from "react-lottie"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import PubSub from "pubsub-js"

const ScheduledMessagesConfig = props => {
  const [loading, setLoading] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [messages, setMessages] = useState([])
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [messagesDesc, setMessagesDesc] = useState([])
  // New message modal
  const [messageModal, setMessageModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  // Categories
  const [notPausedMessages, setNotPausedMessages] = useState([])
  const [todayMessages, setTodayMessages] = useState([])
  const [pastMessages, setPastMessages] = useState([])
  const [pausedMessages, setPausedMessages] = useState([])
  const [comingMessages, setComingMessages] = useState([])
  // Open details
  const [showDetails, setShowDetails] = useState(false)
  const [showPast, setShowPast] = useState(true)
  // Dropdown toggle
  const [isMenu, setIsMenu] = useState({})

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    getMessages()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getMessages()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Rearrange data by date, check if today
  useEffect(() => {
    if (messages != undefined && messages.length > 0) {
      setMessagesDesc(messages)
      let paused = []
      let notPaused = []
      paused = messages.filter(e => e.paused == true)
      setPausedMessages(paused)
      notPaused = messages.filter(e => e.paused == false)
      setNotPausedMessages(notPaused)
    } else {
      setMessagesDesc([])
      setPausedMessages([])
      setNotPausedMessages([])
    }
  }, [messages])

  // Reset messages when modal closed
  useEffect(() => {
    if (notPausedMessages.length !== 0 && notPausedMessages !== undefined) {
      let expired = []
      let notExpired = []
      let today = []
      expired = notPausedMessages.filter(e => e.expired === true)
      notExpired = notPausedMessages.filter(e => e.expired === false)

      // Setting all coming messages
      setComingMessages(notExpired)

      // Setting all old messages
      setPastMessages(expired)

      if (notExpired.length !== 0) {
        // Setting if today
        notExpired.map(m => {
          // Checking if once is today
          if (m.repetitionType === "once") {
            if (isToday(m.scheduledDate)) {
              today.push(m)
            }
          }
          // Checking if interval is today
          if (m.repetitionType === "interval") {
            if (
              isToday(m.scheduledDate) ||
              isTodayWeekdays(m.repetitionType, m.scheduledDate, m.interval)
            ) {
              today.push(m)
            }
          }
          // Checking if weekday is today
          if (m.repetitionType === "weekdays") {
            if (isTodayWeekdays(m.repetitionType, m.weekdays)) {
              today.push(m)
            }
          }
        })
        setTodayMessages(today)
      }
    } else {
      setComingMessages([])
      setPastMessages([])
      setTodayMessages([])
    }
  }, [notPausedMessages])

  // Reset messages when modal closed
  useEffect(() => {
    setSuccessMessage(null)
    setErrorMessage(null)
  }, [messageModal])

  // Sort messages by date + selected order
  const sortData = (data, order) => {
    if (!order) {
      let y = data.sort(
        (b, a) =>
          dayjs(b.scheduledDate).format("HHmm") -
          dayjs(a.scheduledDate).format("HHmm")
      )
      return y
    }
    if (order) {
      let n = data.sort(
        (a, b) => dayjs(a.scheduledDate).unix() - dayjs(b.scheduledDate).unix()
      )
      // console.log(n, data, "datas")
      return n
    }
  }

  // Get scheduled messages
  const getMessages = () => {
    if (props.suvantoKey) {
      setLoading(true)
      getScheduledMessages(abortRef.current, props.suvantoKey)
        .then(result => {
          if (result.statusCode == 200 && result.entries) {
            console.log("Messages: ", result.entries)
            let arrange = sortData(result.entries, true)
            setMessages(arrange)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Delete selected message
  const deleteMessage = () => {
    if (props.suvantoKey && selectedMessage?.key) {
      setDeleting(true)
      deleteScheduledMessage(
        abortRef.current,
        props.suvantoKey,
        selectedMessage.key
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            getMessages()
            setDeleteModal(false)
            setSelectedMessage(null)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setDeleting(false)
        })
    }
  }

  // Add or update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      key: selectedMessage?.key,
      message: selectedMessage?.message || "",
      repetitionType: selectedMessage?.repetitionType || "once",
      interval: selectedMessage?.interval || 1,
      paused: selectedMessage?.paused || false,
      dialogType: selectedMessage?.dialogType || 1,
      scheduledDate: selectedMessage?.scheduledDate
        ? dayjs(selectedMessage?.scheduledDate)
        : null,
      messageExpiration: selectedMessage?.messageExpiration || 60,
      expirationDate: selectedMessage?.expirationDate
        ? dayjs(selectedMessage?.expirationDate)
        : null,
      expirationAlarm: selectedMessage?.expirationAlarm || false,
      audioAlert: selectedMessage?.audioAlert || true,
      monday: selectedMessage?.monday || false,
      tuesday: selectedMessage?.tuesday || false,
      wednesday: selectedMessage?.wednesday || false,
      thursday: selectedMessage?.thursday || false,
      friday: selectedMessage?.friday || false,
      saturday: selectedMessage?.saturday || false,
      sunday: selectedMessage?.sunday || false,
    },
    validationSchema: Yup.object({
      message: Yup.string()
        .required(props.t("Please enter your message"))
        .min(5, props.t("Message is too short"))
        .max(50, props.t("Message is too long")),
    }),
    onSubmit: values => {
      let temp = JSON.parse(JSON.stringify(values))
      temp.scheduledDate = values.scheduledDate
        ? values.scheduledDate.valueOf()
        : dayjs().add(1, "hour").valueOf()
      /*temp.expirationDate = values.expirationDate
        ? values.expirationDate.valueOf()
        : dayjs().add(1, "hour").valueOf()*/
      temp.expirationDate = values.expirationDate
        ? values.expirationDate.valueOf()
        : 0
      temp.interval = parseInt(values.interval, 10)
      temp.dialogType = parseInt(values.dialogType, 10)
      temp.messageExpiration = parseInt(values.messageExpiration, 10)
      // temp.audioAlert = true

      console.log("SUBMIT: ", temp)

      setUpdating(true)
      addOrUpdateScheduledMessage(abortRef.current, props.suvantoKey, temp)
        .then(result => {
          console.log(result)
          if (result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            getMessages()
            setMessageModal(false)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdating(false)
        })
    },
  })

  const setNewSelection = () => {
    let newEntry = new Object()
    newEntry.key = null
    newEntry.message = ""
    newEntry.repetitionType = "once"
    newEntry.interval = 1
    newEntry.paused = false
    newEntry.dialogType = 1
    newEntry.scheduledDate = dayjs().add(1, "minutes")
    newEntry.messageExpiration = 60
    newEntry.expirationDate = null //dayjs().add(7, "days")
    newEntry.expirationAlarm = false
    newEntry.audioAlert = true
    newEntry.monday = false
    newEntry.tuesday = false
    newEntry.wednesday = false
    newEntry.thursday = false
    newEntry.friday = false
    newEntry.saturday = false
    newEntry.sunday = false
    setSelectedMessage(newEntry)
    setMessageModal(true)
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  // Open modal toggle
  const openMessageModal = () => {
    removeBodyCss()
    setMessageModal(!messageModal)
  }

  const settingTimes = (start, end) => {
    let s = dayjs(end).add(start, "minutes")
    s = dayjs(s).format("HH.mm")
    let e = dayjs(end).format("HH.mm")
    return e + " - " + s
  }

  // Check if the weekday of the value is today
  const isTodayWeekdays = (type, days, length) => {
    if (type == "weekdays") {
      // console.log(days, "days")
      let result = settingWeekdays(days)
      return result
    }
    if (type === "interval") {
      let today = dayjs().startOf("day")
      let messageDate = dayjs(days).startOf("day")
      let difference = messageDate.diff(today, "days")

      if (difference === 0) {
        return true
      }
      if (difference !== 0) {
        if (difference % length == 0) {
          return true
        }
      }
    } else {
      return false
    }
  }
  const isToday = value => {
    const day = dayjs()
    let today = dayjs(value).isSame(day, "day")
    return today
  }
  // Dropdwn toggle
  const toggleMenu = e => {
    setIsMenu(!isMenu)

    if (!isMenu[e]) {
      setIsMenu({ [e]: true })
    } else {
      setIsMenu({})
    }
  }

  // Returning weekday formats by type
  const settingWeekdays = (d, l) => {
    let days = []
    if (l != "list") {
      // console.log(dayjs().format("dddd"), "ddd", props.t("Friday").slice(1))
      if (
        d.monday &&
        dayjs().format("dddd").includes(props.t("Monday").slice(1))
      ) {
        return true
      }
      if (
        d.tuesday &&
        dayjs().format("dddd").includes(props.t("Tuesday").slice(1))
      ) {
        return true
      }
      if (
        d.wednesday &&
        dayjs().format("dddd").includes(props.t("Wednesday").slice(1))
      ) {
        return true
      }
      if (
        d.thursday &&
        dayjs().format("dddd").includes(props.t("Thursday").slice(1))
      ) {
        return true
      }
      if (
        d.friday &&
        dayjs().format("dddd").includes(props.t("Friday").slice(1))
      ) {
        return true
      }
      if (
        d.saturday &&
        dayjs().format("dddd").includes(props.t("Saturday").slice(1))
      ) {
        return true
      }
      if (
        d.sunday &&
        dayjs().format("dddd").includes(props.t("Sunday").slice(1))
      ) {
        return true
      }
      return false
    }
    if (l == "list") {
      if (d.monday) {
        days.push(props.t("Mon") + " ")
      }
      if (d.tuesday) {
        days.push(props.t("Tues") + " ")
      }
      if (d.wednesday) {
        days.push(props.t("Wed") + " ")
      }
      if (d.thursday) {
        days.push(props.t("Thurs") + " ")
      }
      if (d.friday) {
        days.push(props.t("Fri") + " ")
      }
      if (d.saturday) {
        days.push(props.t("Sat") + " ")
      }
      if (d.sunday) {
        days.push(props.t("Sun") + " ")
      }
    }
    return days
  }

  const structure = (item, divkey) => {
    if (item != [] && item != undefined) {
      return (
        <>
          <Card className="mt-0 py-0">
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="">{item.message}</div>
                  <div className="mt-2 text-muted font-size-12">
                    {item.dialogType == 1 ? (
                      <>
                        <div>
                          <i className="mdi mdi-reply me-1"></i>
                          {props.t("Ok")}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <i className="mdi mdi-reply me-1"></i>
                          {props.t("Yes / No")}
                        </div>
                      </>
                    )}
                    {item.expirationAlarm ? (
                      <>
                        <div>
                          <i className="mdi mdi-bell-ring-outline me-1"></i>
                          {props.t("Alarm on")}
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <i className="mdi mdi-bell-off me-1"></i>
                          {props.t("Alarm off")}
                        </div>
                      </>
                    )}
                  </div>
                </Col>
                <Col xs="4">
                  <div className="text-muted">
                    {item.repetitionType == "once" || !item.expirationDate ? (
                      <p className="text-muted ">
                        <i className="mdi mdi-calendar me-1"></i>
                        {formatDateHoursMinutes(item.scheduledDate)}
                      </p>
                    ) : (
                      <p className="text-muted ">
                        <i className="mdi mdi-calendar-start me-1"></i>
                        {formatDateHoursMinutes(item.scheduledDate)} -{" "}
                        <i className="mdi mdi-calendar-end me-1"></i>
                        {formatDateHoursMinutes(item.expirationDate)}
                      </p>
                    )}
                  </div>
                  <div className=" text-muted">
                    <i className="mdi mdi-clock-outline me-1"></i>
                    {settingTimes(item.messageExpiration, item.scheduledDate)}
                  </div>
                </Col>
                <Col xs="4">
                  <div className="float-end">
                    <Dropdown
                      isOpen={isMenu[divkey]}
                      toggle={() => toggleMenu(divkey)}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-comment-edit me-1" />
                        <span className="d-none d-sm-inline-block font-size-12">
                          <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                        <DropdownItem
                          onClick={() => editingMessage(item)}
                        >
                          {props.t("Edit message")}
                        </DropdownItem>

                        <DropdownItem divider />

                        <DropdownItem
                          onClick={() => deletingMessage(item)}
                          className="text-danger"
                        >
                          {props.t("Delete message")}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  {item.repetitionType == "interval" && (
                    <>
                      <div className="badge badge-soft-primary font-size-12">
                        {props.t("Interval")}
                      </div>
                      <div className="mt-2 text-muted font-size-12">
                        <div className="mt-2">
                          <i className="mdi mdi-repeat me-2"></i>
                          {item.interval == 1 ? (
                            <>{props.t("daily")}</>
                          ) : (
                            <>
                              {item.interval} {props.t("vrk")}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {item.repetitionType == "weekdays" && (
                    <>
                      <div className="badge badge-soft-secondary font-size-12">
                        {props.t("Weekdays")}
                      </div>
                      <div className="text-muted">
                        <div className="mt-2 text-uppercase">
                          <i className="mdi mdi-calendar-week me-2"></i>
                          {settingWeekdays(item.weekdays, "list")}
                        </div>
                      </div>
                    </>
                  )}
                  {item.repetitionType == "once" && (
                    <>
                      <div className="badge badge-soft-tertiary font-size-12">
                        {props.t("Once")}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </>
      )
    }
  }

  const messagesJSX = []
  comingMessages.forEach(item => {
    messagesJSX.push(
      <div key={item.key + "_coming"} className="mt-0 py-0" style={{}}>
        <>{structure(item, item.key + "_coming")}</>
      </div>
    )
  })
  const messagesPausedJSX = []
  pausedMessages.forEach(item => {
    messagesPausedJSX.push(
      <div key={item.key + "_paused"} className="mt-0 py-0" style={{}}>
        <>{structure(item, item.key + "_paused")}</>
      </div>
    )
  })
  const outdatedMessagesJSX = []
  pastMessages.forEach(item => {
    outdatedMessagesJSX.push(
      <div key={item.key + "_outdated"} className="mt-0 py-0" style={{}}>
        <>{structure(item, item.key + "_outdated")}</>
      </div>
    )
  })

  const deletingMessage = m => {
    setDeleteModal(!deleteModal)
    setSelectedMessage(m)
  }

  const editingMessage = m => {
    openMessageModal()
    setSelectedMessage(m)
  }

  const [colSize] = useState(4)

  const badgeCheck = item => {
    if (item.repetitionType == "interval") {
      return (
        <>
          <div className="badge badge-soft-primary font-size-12">
            {props.t("Interval")}
          </div>
          <div className="mt-1 text-muted font-size-12">
            <div className="mt-1">
              <i className="mdi mdi-repeat me-2"></i>
              {item.interval == 1 ? (
                <>{props.t("daily")}</>
              ) : (
                <>
                  {item.interval} {props.t("vrk")}
                </>
              )}
              <div>
                <i className="mdi mdi-clock-outline me-2"></i>
                {settingTimes(item.messageExpiration, item.scheduledDate)}
              </div>
            </div>
          </div>
        </>
      )
    }
    if (item.repetitionType == "weekdays") {
      return (
        <>
          <div className="badge badge-soft-secondary font-size-12">
            {props.t("Weekdays")}
          </div>
          <div className="text-muted">
            <div className="mt-2 text-uppercase">
              <i className="mdi mdi-calendar-week me-2"></i>
              {settingWeekdays(item.weekdays, "list")}
            </div>{" "}
            <div>
              <i className="mdi mdi-clock-outline me-2"></i>
              {settingTimes(item.messageExpiration, item.scheduledDate)}
            </div>
          </div>
        </>
      )
    }
    if (item.repetitionType == "once") {
      return (
        <>
          <div className="badge badge-soft-tertiary font-size-12">
            {props.t("Once")}
          </div>{" "}
          <div>
            <i className="mdi mdi-clock-outline me-2"></i>
            {settingTimes(item.messageExpiration, item.scheduledDate)}
          </div>
        </>
      )
    }
  }

  return (
    <>
      {loading && (
        <Lottie options={getLottieLoadingOptions()} height={100} width={100} />
      )}
      {messages && !loading && (
        <div>
          <Modal
            isOpen={messageModal}
            toggle={() => {
              openMessageModal()
            }}
            centered
          >
            {selectedMessage !== null && (
              <>
                {selectedMessage.key !== null ? (
                  <ModalHeader>{props.t("Edit scheduled message")}</ModalHeader>
                ) : (
                  <ModalHeader>{props.t("Add scheduled message")}</ModalHeader>
                )}
              </>
            )}

            <button
              type="button"
              onClick={() => {
                setMessageModal(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <div>
                  <Row>
                    <Col xs="12" className="mt-0 mb-2">
                      <Label style={{ width: "100%" }}>
                        <Row>
                          <Col xs="6">{props.t("Message")}</Col>
                          <Col xs="6">
                            <span className="float-end">
                              <label
                                className="form-check-label font-size-12 text-muted me-2"
                                htmlFor="notification"
                              >
                                {props.t("Notification tone")}
                              </label>
                              <Switch
                                id="notification"
                                size="small"
                                name="notification"
                                checked={validation.values.audioAlert}
                                onChange={v => {
                                  validation.setFieldValue("audioAlert", v)
                                }}
                                checkedChildren={
                                  <i className="bx bxs-bell-ring align-middle font-size-12" />
                                }
                                unCheckedChildren={
                                  <i className="mdi mdi-bell-off-outline align-middle font-size-12" />
                                }
                              ></Switch>{" "}
                              <UncontrolledTooltip
                                placement="right"
                                target="notification"
                              >
                                {props.t("Video device message tone on/off")}
                              </UncontrolledTooltip>
                              {/* <input
                                type="checkbox"
                                className="form-check-input"
                                id="sun"
                                autoComplete="off"
                                defaultChecked={validation.values.sunday}
                                onChange={v => {
                                  validation.setFieldValue(
                                    "sunday",
                                    v.target.checked
                                  )
                                }}
                              /> */}
                            </span>
                          </Col>
                        </Row>
                      </Label>
                      {errorMessage ? (
                        <Alert color="danger">{props.t(errorMessage)}</Alert>
                      ) : null}
                      {successMessage ? (
                        <Alert color="success">{props.t(successMessage)}</Alert>
                      ) : null}
                      <Input
                        type="text"
                        maxLength="50"
                        name="message"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.message || ""}
                        invalid={
                          !!(validation.touched.message &&
                          validation.errors.message)
                        }
                        className="form-control"
                        placeholder={props.t("max. 50 characters")}
                      />
                      {validation.touched.message &&
                      validation.errors.message ? (
                        <FormFeedback type="invalid">
                          {validation.errors.message}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col xs={4} className="mt-3 ">
                      <FormGroup>
                        <Label>{props.t("Response")}</Label>
                        <Input
                          name="dialogType"
                          type="select"
                          value={validation.values.dialogType}
                          onChange={validation.handleChange}
                        >
                          <option value={1}>{props.t("Ok")}</option>
                          <option value={2}>{props.t("Yes / No")}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs={4} className="mt-3">
                      <Label className="">
                        {/* TODO; Check if correct way to describe */}
                        {props.t("Response time")} (min)
                      </Label>
                      <InputNumber
                        style={{ width: "100%", height: "36.5px" }}
                        min="1"
                        max="365"
                        onChange={v =>
                          validation.setFieldValue("messageExpiration", v)
                        }
                        value={validation.values.messageExpiration}
                        invalid={
                          validation.touched.messageExpiration &&
                          validation.errors.messageExpiration
                            ? "true"
                            : "false"
                        }
                      ></InputNumber>
                    </Col>
                    <Col xs={4} className="mt-3">
                      <Label>{props.t("Expiration alarm")}</Label>
                      <div>
                        <Switch
                          className="mt-1"
                          id="switch-bell"
                          name="expirationAlarm"
                          checked={validation.values.expirationAlarm}
                          onChange={v => {
                            validation.setFieldValue("expirationAlarm", v)
                          }}
                          checkedChildren={
                            <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                          }
                          unCheckedChildren={
                            <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                          }
                        ></Switch>
                      </div>
                      <UncontrolledTooltip
                        placement="right"
                        target="switch-bell"
                      >
                        {props.t("Alert an unacknowledged message")}
                      </UncontrolledTooltip>
                    </Col>
                    <Col xs={4} className="mt-2">
                      <FormGroup>
                        <Label>{props.t("Repetition options")}</Label>
                        <Input
                          name="repetitionType"
                          type="select"
                          value={validation.values.repetitionType}
                          onChange={validation.handleChange}
                        >
                          <option value={"once"}>{props.t("Once")}</option>
                          <option value={"interval"}>
                            {props.t("Interval")}
                          </option>
                          <option value={"weekdays"}>
                            {props.t("Weekdays")}
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    {validation.values.repetitionType === "weekdays" && (
                      <Col xs="8" className="mt-2">
                        <h6>{props.t("Weekdays")}</h6>
                        <div className="text-uppercase mt-3">
                          <FormGroup>
                            <input
                              type="checkbox"
                              id="mon"
                              className="form-check-input"
                              defaultChecked={validation.values.monday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "monday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="mon"
                            >
                              {props.t("Mon")}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="tue"
                              autoComplete="off"
                              defaultChecked={validation.values.tuesday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "tuesday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="tue"
                            >
                              {props.t("Tues")}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="wed"
                              autoComplete="off"
                              defaultChecked={validation.values.wednesday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "wednesday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="wed"
                            >
                              {props.t("Wed")}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="thu"
                              autoComplete="off"
                              defaultChecked={validation.values.thursday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "thursday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="thu"
                            >
                              {props.t("Thurs")}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="fri"
                              autoComplete="off"
                              defaultChecked={validation.values.friday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "friday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="fri"
                            >
                              {props.t("Fri")}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="sat"
                              autoComplete="off"
                              defaultChecked={validation.values.saturday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "saturday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="sat"
                            >
                              {props.t("Sat")}
                            </label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="sun"
                              autoComplete="off"
                              defaultChecked={validation.values.sunday}
                              onChange={v => {
                                validation.setFieldValue(
                                  "sunday",
                                  v.target.checked
                                )
                              }}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor="sun"
                            >
                              {props.t("Sun")}
                            </label>
                          </FormGroup>
                        </div>
                      </Col>
                    )}
                    {validation.values.repetitionType === "interval" && (
                      <Col xs="4" className="mt-2">
                        <Label>
                          {props.t("Interval")} ({props.t("vrk")})
                        </Label>
                        <InputNumber
                          style={{ width: "100%", height: "36.5px" }}
                          min="1"
                          max="365"
                          onChange={v =>
                            validation.setFieldValue("interval", v)
                          }
                          value={validation.values.interval}
                          invalid={
                            validation.touched.interval &&
                            validation.errors.interval
                              ? "true"
                              : "false"
                          }
                        ></InputNumber>
                      </Col>
                    )}
                    <Col xs="6" className="mt-2">
                      {validation.values.repetitionType === "once" ? (
                        <Label className="text-muted">
                          {props.t("Time of sending")}
                        </Label>
                      ) : (
                        <Label>{props.t("Start time")}</Label>
                      )}
                      <DatePicker
                        allowClear={false}
                        showTime={true}
                        value={validation.values.scheduledDate}
                        format="DD.MM.YYYY HH:mm"
                        style={{ height: "36.5px", width: "100%" }}
                        onChange={v => {
                          validation.setFieldValue("scheduledDate", v)
                        }}
                      />
                    </Col>
                    {validation.values.repetitionType !== "once" && (
                      <Col xs="6" className="mt-2">
                        <Label>{props.t("End of repetition")}</Label>
                        <DatePicker
                          showTime={true}
                          value={validation.values.expirationDate}
                          format="DD.MM.YYYY HH:mm"
                          style={{ height: "36.5px", width: "100%" }}
                          onChange={v => {
                            validation.setFieldValue("expirationDate", v)
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
                <ModalFooter className="p-2 mt-4">
                  <Col lg={12} className="mt-2 mb-0  text-truncate">
                    <span className="float-end">
                      <button
                        type="button"
                        onClick={() => {
                          openMessageModal()
                        }}
                        className="btn btn-outline-primary "
                        data-dismiss="modal"
                      >
                        {props.t("Cancel")}
                      </button>

                      <button
                        disabled={updating}
                        className="btn btn-primary ms-2"
                        onClick={() => {
                          validation.submitForm()
                        }}
                      >
                        {props.t("Save entry")}
                      </button>
                    </span>
                  </Col>
                </ModalFooter>
              </Form>
            </ModalBody>
          </Modal>
          <Row>
            <Col sm="12">
              {todayMessages != undefined && todayMessages?.length !== 0 && (
                <Card className="mt-2" style={{ overflow: "none" }}>
                  <CardBody className="px-4 ">
                    <CardTitle className="mb-4 ">
                      <span className="text-uppercase">{props.t("Today")}</span>
                    </CardTitle>

                    <div className="hori-timeline mt-2">
                      <div className="owl-carousel owl-theme  navs-carousel events">
                        <Col lg="12">
                          <Row>
                            {todayMessages.length != 0 &&
                              todayMessages.map(item => (
                                <Col
                                  lg={colSize}
                                  key={item.key}
                                  className="mb-4"
                                >
                                  <div className="item event-list me-5">
                                    <div className="event-date">
                                      <div className="text-primary mb-1">
                                        {formatDate(dayjs())}
                                      </div>
                                      <h5 className="mb-3">
                                        {settingTimes(
                                          item.messageExpiration,
                                          item.scheduledDate
                                        )}
                                      </h5>
                                    </div>
                                    <div
                                      style={{ padding: 0, margin: 0 }}
                                      className="event-down-icon"
                                    >
                                      <i className="bx bx-down-arrow-circle h2 text-primary down-arrow-icon" />
                                    </div>

                                    <div className="mt-1 mb-4 py-3">
                                      <p className="font-size-16">
                                        {item.message}
                                      </p>
                                    </div>
                                    <Collapse isOpen={showDetails}>
                                      <span className="float-end me-5">
                                        <Dropdown
                                          className="mt-0"
                                          isOpen={isMenu[item.key]}
                                          toggle={() => toggleMenu(item.key)}
                                        >
                                          <DropdownToggle
                                            type="button"
                                            tag="button"
                                            className="btn btn-light m-2"
                                          >
                                            <i className="mdi mdi-comment-edit me-1" />
                                            <span className="d-none d-sm-inline-block font-size-12">
                                              {props.t("Edit")}{" "}
                                              <i className="mdi mdi-chevron-down" />
                                            </span>
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                                            <DropdownItem
                                              onClick={() =>
                                                editingMessage(item)
                                              }
                                            >
                                              {props.t("Edit message")}
                                            </DropdownItem>

                                            <DropdownItem divider />

                                            <DropdownItem
                                              onClick={() =>
                                                deletingMessage(item)
                                              }
                                              className="text-danger"
                                            >
                                              {props.t("Delete message")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </span>
                                      <CardTitle></CardTitle>
                                      <div className="mt-4 font-size-12">
                                        <h6 className="pt-3">
                                          {props.t("Answer type")}
                                        </h6>
                                        <span className="text-muted ">
                                          {item.dialogType == 1 ? (
                                            <>{props.t("Ok")}</>
                                          ) : (
                                            <>{props.t("Yes / No")}</>
                                          )}
                                          <span className="ms-3">
                                            {item.expirationAlarm ? (
                                              <>
                                                <i className="mdi mdi-bell-ring-outline me-1"></i>
                                              </>
                                            ) : (
                                              <>
                                                <i className="mdi mdi-bell-off me-1"></i>
                                              </>
                                            )}
                                          </span>
                                        </span>
                                      </div>
                                      <div className="mt-3 font-size-12">
                                        <h6>{props.t("Scheduled timing")}</h6>
                                        {item.repetitionType == "once" ||
                                        !item.expirationDate ? (
                                          <p className="text-muted ">
                                            <i className="mdi mdi-calendar me-1"></i>
                                            {formatDateHoursMinutes(
                                              item.scheduledDate
                                            )}
                                          </p>
                                        ) : (
                                          <p className="text-muted ">
                                            <i className="mdi mdi-calendar-start me-1"></i>
                                            {formatDateHoursMinutes(
                                              item.scheduledDate
                                            )}{" "}
                                            -{" "}
                                            <i className="mdi mdi-calendar-end me-1"></i>
                                            {formatDateHoursMinutes(
                                              item.expirationDate
                                            )}
                                          </p>
                                        )}
                                      </div>
                                      <div className="mt-3  font-size-12">
                                        <h6>{props.t("Timing")}</h6>
                                        <p className="text-muted ">
                                          {badgeCheck(item)}
                                        </p>
                                      </div>
                                    </Collapse>
                                  </div>
                                </Col>
                              ))}
                          </Row>
                          <div className="text-center">
                            {showDetails ? (
                              <>
                                <Link
                                  to="#"
                                  onClick={() => setShowDetails(false)}
                                >
                                  {props.t("Hide details")}{" "}
                                  <i className="mdi mdi-chevron-up" />
                                </Link>
                              </>
                            ) : (
                              <>
                                <Link
                                  to="#"
                                  onClick={() => setShowDetails(true)}
                                >
                                  {props.t("Show details and edit")}{" "}
                                  <i className="mdi mdi-chevron-down" />
                                </Link>
                              </>
                            )}
                          </div>
                        </Col>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {/* Empty card */}
          {messages.length === 0 ||
          (comingMessages.length == 0 && todayMessages.length == 0) ? (
            <Card>
              <CardBody>
                <div className="text-center p-4">
                  <Row>
                    <Col lg="6">
                      <h5 className="p-4">
                        {props.t("No scheduled messages")}
                      </h5>
                    </Col>
                    <Col lg="6">
                      <Button
                        type="button"
                        color="success"
                        disabled={updating}
                        onClick={() => {
                          setNewSelection()
                        }}
                        className="btn btn-primary p-4"
                      >
                        <i className="mdi mdi-message-text-clock-outline d-block font-size-20" />
                        <div className="w-sm">
                          {props.t("Add scheduled message")}
                        </div>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          ) : (
            <span className="float-end">
              <Button
                type="button"
                color="success"
                disabled={updating}
                onClick={() => {
                  setNewSelection()
                }}
                className="btn btn-primary mt-0 "
              >
                <i className="mdi mdi-message-text-clock-outline" />
                <span className="d-none d-sm-inline-block ms-2">
                  {props.t("Add scheduled message")}
                </span>
              </Button>
            </span>
          )}

          {comingMessages?.length !== 0 && (
            <>
              <CardTitle className="mt-2">
                <i className="mdi mdi-message-text-clock-outline me-3" />{" "}
                {props.t("Incoming messages")}
              </CardTitle>
              <div className="font-size-12 h6 text-muted ms-3 mt-3 me-3">
                <Row>
                  <Col className="text-uppercase" xs="4">
                    {props.t("Message")}
                  </Col>{" "}
                  <Col className="text-uppercase" xs="4">
                    {props.t("Time")}
                  </Col>
                  <Col className="text-uppercase" xs="4">
                    {props.t("Repetition")}
                  </Col>
                </Row>
              </div>
              {messagesJSX}
            </>
          )}
          {pausedMessages?.length !== 0 && (
            <>
              <CardTitle className=" mt-2">
                <i className="bx bx-calendar-x me-3" />{" "}
                {props.t("Paused messages")}
              </CardTitle>
              <div className="font-size-12 h6 text-muted ms-3 mt-3 me-3">
                <Row>
                  <Col className="text-uppercase" xs="4">
                    {props.t("Message")}
                  </Col>{" "}
                  <Col className="text-uppercase" xs="4">
                    {props.t("Time")}
                  </Col>
                  <Col className="text-uppercase" xs="4">
                    {props.t("Repetition")}
                  </Col>
                </Row>
              </div>
              {messagesPausedJSX}
            </>
          )}
          {pastMessages?.length !== 0 && (
            <>
              <Link
                to="#"
                className="text-muted"
                onClick={() => setShowPast(!showPast)}
              >
                <CardTitle className=" mt-2">
                  <i className="bx bx-calendar-check me-3" />{" "}
                  {props.t("Outdated messages")}{" "}
                  <i
                    className={
                      " mdi mdi-chevron-down menu-arrow-down " +
                      classnames({
                        " mdi-rotate-180": showPast,
                      })
                    }
                  />
                </CardTitle>
              </Link>
              <Collapse isOpen={showPast}>
                <div className="font-size-12 h6 text-muted ms-3 mt-3 me-3">
                  <Row>
                    <Col className="text-uppercase" xs="4">
                      {props.t("Message")}
                    </Col>{" "}
                    <Col className="text-uppercase" xs="4">
                      {props.t("Time")}
                    </Col>
                    <Col className="text-uppercase" xs="4">
                      {props.t("Repetition")}
                    </Col>
                  </Row>
                </div>
                {outdatedMessagesJSX}
              </Collapse>
            </>
          )}
        </div>
      )}
      {/** DELETE MODAL */}
      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>{props.t("Are you sure you want to delete selected message")}?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={deleting}
            color="danger"
            onClick={() => {
              deleteMessage()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

ScheduledMessagesConfig.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
}

export default withTranslation()(ScheduledMessagesConfig)
