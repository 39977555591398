import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  UncontrolledTooltip,
  Form,
  Input,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { getDoses, syncDoses } from "helpers/backend_helper"
import DoseDrawer from "./DoseDrawer"
import { showToast, downloadExcel, isTrue, dateSorter, stringSorter, numberSorter, defaultPageSizeOptions, dateFormatter } from "utils/general"
import { Table as AntdTable } from "antd"
import { tableDefaultHeight } from "constants/layout"
import { clientLinksFormatter } from "utils/tables"

const DoseConfigs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [doses, setDoses] = useState([])
  const [filteredDoses, setFilteredDoses] = useState([])
  const [filtersOpen, setFiltersOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [statistics, setStatistics] = useState({
    total: 0,
  })
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title] = useState(props.t("Medicine reminders"))

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = doses.length
    temp.linked = doses.filter(data => data.links.length > 0).length

    setStatistics(temp)
  }, [doses])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, doses])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = doses.filter(
      data =>
        data.deviceId?.toLowerCase().includes(searchTextLowercase) ||
        data.name?.toLowerCase().includes(searchTextLowercase) ||
        data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
        data.citizenId?.toLowerCase().includes(searchTextLowercase) ||
        data.linkedNames?.toLowerCase().includes(searchTextLowercase)
    )
    setFilteredDoses(result)
  }

  // Get doses
  const getDevices = () => {
    setOngoingSearch(true)
    getDoses(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          result.entries.forEach(element => {
            element.linkedNames = getLinkedNames(element.links)
          })
          setDoses(result.entries)
        } else {
          setDoses([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setDoses([])
        setOngoingSearch(false)
      })
  }

  // Get linked names in proper format
  const getLinkedNames = (links) => {
    let linkedNames = []
    if (links) {
      links.forEach(link => {
        linkedNames.push(link.name)
      })
    }
    return linkedNames.join()
  }

  // Sync all devices
  const syncDeviceList = () => {
    setOngoingSync(true)
    syncDoses(abortRef.current)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Table structure
  const columns = [
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceId",
      title: props.t("Device ID"),
      defaultSortOrder: 'ascend',
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "deviceId", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "name",
      title: props.t("Name"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "name", sortOrder)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "linkedNames",
      title: props.t("Links"),
      render: clientLinksFormatter,
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "linkedNames", sortOrder)
      },
      width: "400px",
      ellipsis: true,
    },
    {
      dataIndex: "syncDate",
      title: props.t("Synced"),
      sorter: (a, b, sortOrder) => {
        return dateSorter(a["syncDate"], b["syncDate"], sortOrder)
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "alias",
      title: props.t("Alias"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "alias", sortOrder)
      },
      width: "80px",
      ellipsis: true,
    },
    {
      dataIndex: "paused",
      title: props.t("Paused"),
      align: "center",
      sorter: (a, b) => {
        return a.paused - b.paused
      },
      render: cell => {
        return cell ? (
          <i className="mdi mdi-check-bold text-primary" />
        ) : (
          <></>
        )
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "inventoryCount",
      title: props.t("Dose count"),
      sorter: (a, b, sortOrder) => {
        return numberSorter(a["inventoryCount"], b["inventoryCount"], sortOrder)
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "orderNumber",
      title: props.t("Order number"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "orderNumber", sortOrder)
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "citizenId",
      title: props.t("Citizen ID"),
      sorter: (a, b, sortOrder) => {
        return stringSorter(a, b, "citizenId", sortOrder)
      },
      width: "80px",
      ellipsis: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Device ID"),
        props.t("Name"),
        props.t("Links"),
        props.t("Synced"),
        props.t("Alias"),
        props.t("Paused"),
        props.t("Dose count"),
        props.t("Order number"),
        props.t("Citizen ID"),
      ],
    ]
    const data = filteredDoses.map(elt => [
      elt.deviceId,
      elt.name,
      elt.linkedNames,
      dateFormatter(elt.syncDate),
      elt.alias,
      isTrue(elt.paused),
      elt.inventoryCount,
      elt.orderNumber,
      elt.citizenId,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Medicine reminders")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Row>
                    <Col>
                      {doses != null && (
                        <React.Fragment>
                          <Row className="mb-1">
                            {" "}
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="col-auto">
                              <Button
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSearch}
                                onClick={() => {
                                  getDevices()
                                }}
                              >
                                <i className="mdi mdi-refresh me-2" />
                                {props.t("Refresh data")}
                              </Button>
                              <Button
                                className="ms-3"
                                style={{ borderRadius: "100px" }}
                                color="primary"
                                disabled={ongoingSync}
                                onClick={() => {
                                  syncDeviceList()
                                }}
                              >
                                <i className="mdi mdi-cloud-sync me-2" />
                                {props.t("Sync devices")}
                              </Button>
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="downloadstatstable"
                                >
                                  {props.t("Download statistics")}
                                </UncontrolledTooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredDoses.length}{" "}
                                  {props.t("found results")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Accordion
                            flush
                            open={filtersOpen}
                            toggle={id => {
                              id === filtersOpen
                                ? setFiltersOpen("")
                                : setFiltersOpen(id)
                            }}
                          >
                            <AccordionItem>
                              <AccordionBody
                                accordionId="1"
                                className="mb-3"
                                style={{
                                  backgroundColor: "#e8f0fe",
                                  border: "1px solid #ced4da",
                                }}
                              >
                                <Row>
                                  <Col></Col>
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          </Accordion>

                          <Row className="mt-2">
                            <Col xl="12">
                              <AntdTable
                                className="colored-header narrow-header"
                                bordered
                                size="small"
                                sticky
                                rowKey={"deviceId"}
                                columns={columns}
                                dataSource={filteredDoses}
                                pagination={{
                                  showSizeChanger: true,
                                  defaultPageSize: 50,
                                  pageSizeOptions: defaultPageSizeOptions,
                                }}
                                loading={ongoingSearch}
                                tableLayout="auto"
                                scroll={{
                                  x: "max-content",
                                  y: tableDefaultHeight,
                                }}
                              />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <DoseDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getDevices={getDevices}
          />
        </Container>
      </div>
    </>
  )
}

DoseConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(DoseConfigs)
