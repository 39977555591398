/**********************
 * DEMO
 * Appoinments
 *********************/
import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { useNavigate, useLocation } from "react-router-dom"

import {
  addOrUpdateAppointment,
  getGroupsAppointments,
  deleteAppointment,
  updateAppointments,
} from "helpers/backend_helper"

import { showToast } from "utils/general"

import GlobalState from "contexts/GlobalState"
import TimelineCalendar2 from "components/Timeline/TimelineCalendar2"
import Manual from "components/Manual/Manual"
import AppointmentModal from "pages/Appointments/AppointmentModal"

import * as Yup from "yup"
import { useFormik } from "formik"

import { Card, CardBody, Col, Container } from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"

import PubSub from "pubsub-js"

import dayjs from "dayjs"
import "dayjs/locale/fi"
import "dayjs/locale/se"
import "dayjs/locale/en-gb"

const Appointments = props => {
  const [title] = useState(props.t("Appointments"))
  const [state] = useContext(GlobalState)
  const [activeTab, setActiveTab] = useState("1")

  const [selectedGroup, setSelectedGroup] = useState(null)
  const [loading, setLoading] = useState(false)
  const [findType] = useState("admins")
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [assignmentsOrig, setAssignmentsOrig] = useState([])
  const [assignments, setAssignments] = useState([])
  const [modifiedAssignments, setModifiedAssignments] = useState([])
  const [selectedProfs, setSelectedProfs] = useState([])
  const [professionals, setProfessionals] = useState([])
  const [userKey, setUserKey] = useState("")
  const [profsSelection, setProfsSelection] = useState([])
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [differences, setDifferences] = useState({})

  const [addNew, setAddNew] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [modify, setModify] = useState(false)
  const [emptyClickAssignments, setEmptyClickAssignments] = useState(false)
  const [customizeAssignment, setCustomizeAssignment] = useState(false)
  const [defaultGroupKey, setDefaultGroupKey] = useState("") // Can not set function to Select defaultValue
  const [userRole, setUserRole] = useState("")

  const navigate = useNavigate()
  const location = useLocation()

  //meta title
  document.title = title + " | Suvanto Care"

  const abortRef = useRef(null)

  // Set group to URL dynamically
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    if (selectedGroup) {
      searchParams.set("group", selectedGroup)
    } else {
      searchParams.delete("group")
    }
    navigate(`?${searchParams}`, { replace: true })
  }, [selectedGroup, navigate])

  // Set group according to URL
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setSelectedGroup(params.get("group") || null)
  }, [])

  useEffect(() => {
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
    })
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
      if (role === "system_admin") {
        // setIsAdmin(true)
        setActiveTab("0")
      }
      if (role === "organisation_admin") {
        setActiveTab("0")
      }
    }
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // Unselect selected when modal is closed
  useEffect(() => {
    if (!modal) {
      setAddNew(false)
      setCustomizeAssignment(false)
      setEmptyClickAssignments(false)
    }
  }, [modal])

  // Fetch appointments when group is picked
  useEffect(() => {
    if (findType == "clients") {
      setProfsSelection([])
    }
    // console.log(group, "new group")
    if (selectedGroup == undefined || selectedGroup == null) {
      setAssignments([])
    }
    if (selectedGroup != undefined) {
      getAppointments()
    }
  }, [selectedGroup, findType])

  // Prevent user from leaving in modify=true
  useEffect(() => {
    window.addEventListener("beforeunload", handlePageExitAttempt)
    return () => {
      window.removeEventListener("beforeunload", handlePageExitAttempt)
    }
  }, [modify])

  // Add or update validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: selectedAssignment?.type || "video_call",
      group: selectedAssignment?.group || null,
      selectedCustomers: selectedAssignment?.client_key || "",
      // selectedCustomers: selectedAssignment?.selectedCustomers || null,
      // repetition: selectedAssignment?.repetition || "once",
      // interval: selectedAssignment?.interval || 1,
      // paused: selectedAssignment?.paused || false,
      start_time: selectedAssignment?.start_time
        ? dayjs(selectedAssignment?.start_time)
        : dayjs(),
      duration: selectedAssignment?.duration || 15,
      key: selectedAssignment?.key || undefined,
      // title: selectedAssignment?.title || null,
      // monday: selectedAssignment?.monday || false,
      // tuesday: selectedAssignment?.tuesday || false,
      // wednesday: selectedAssignment?.wednesday || false,
      // thursday: selectedAssignment?.thursday || false,
      // friday: selectedAssignment?.friday || false,
      // saturday: selectedAssignment?.saturday || false,
      // sunday: selectedAssignment?.sunday || false,
      appointmentName: selectedAssignment?.appointmentName || "",
    },
    validationSchema: Yup.object({
      start_time: Yup.date().required("Please select a start time"),
      duration: Yup.number()
        .required(props.t("Please enter duration"))
        .min(0, props.t("Assignment is too short"))
        .max(1000, props.t("Assignment is too long")),
      appointmentName: Yup.string().matches(
        /^[a-zA-Z0-9\s\-()!?&.,+#äöåøæÄÖÅÆØ]*$/,
        props.t("ContainsNotAllowedCharacters")
      ),
      selectedCustomers: Yup.lazy(value =>
        typeof value === "string"
          ? Yup.string().required(props.t("Please select customer"))
          : Yup.array().min(1, props.t("Please select customer"))
      ),
    }),

    onSubmit: values => {
      let selectedCustomersString = ""

      if ((selectedCustomersString = Array.isArray(values.selectedCustomers))) {
        selectedCustomersString = values.selectedCustomers.join(",")
      } else if (typeof values.selectedCustomers === "string") {
        selectedCustomersString = values.selectedCustomers
      }

      console.log(
        "values:",
        values,
        "selectedCustomersString:",
        selectedCustomersString
      )

      setLoading(true)
      addOrUpdateAppointment(
        abortRef.current,
        values.type,
        values.group,
        selectedCustomersString,
        values.start_time,
        values.duration,
        values.key,
        values.appointmentName
      )
        .then(result => {
          // console.log("result", result)
          if (result.statusCode == 200) {
            showToast(props.t("Information updated"), "success")
            setSelectedAssignment(null)
            validation.resetForm()
            getAppointments()
          } else if (result.errorMessage) {
            showToast(props.t(result.errorMessage), "error")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setModal(false)
          setLoading(false)
        })
    },
  })

  // Get appointments
  const getAppointments = () => {
    if (selectedGroup && !loading) {
      setLoading(true)

      getGroupsAppointments(
        abortRef.current,
        selectedGroup,
        findType,
        startDate,
        endDate
      )
        .then(result => {
          if (result.statusCode == 200 && result) {
            // console.log("getAppointments", result.items)

            if (userRole.includes("admin")) {
              settingSelected(result.groups, result.items)
              settingAssignments(result.items)
            } else {
              let group = result.groups.filter(g => g.key == userKey)
              // console.log(selectedGroup, "gg")

              settingSelected(group, result.items)
              // filteringOnlyUser()

              settingAssignments(result.items, group)
            }
            // console.log(result, result.items)
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Formatting assignments if necessary
  // TODO: update with selected profs
  const settingAssignments = (v, g) => {
    // Non-admin / only user's appointment
    if (g && v != undefined && startDate != undefined) {
      let filtered = v.filter(a => a.group == g[0].id)
      // console.log(filtered, v, g, "filtered")
      let opts = []
      if (filtered.length > 0) {
        filtered.forEach(p => {
          let groupObject = new Object()
          groupObject.id = p.id
          groupObject.key = p.key
          groupObject.client_key = p.client_key
          groupObject.title = p.title
          groupObject.group = p.group
          groupObject.start_time = dayjs(p.start_time)
          groupObject.end_time = dayjs(p.end_time)
          groupObject.duration = p.duration
          // groupObject.className = p.key != null ? "confirm" : "assign"
          groupObject.className = p.group != 0 ? "confirm" : "assign"
          groupObject.type = p.type
          groupObject.name = p.name
          groupObject.groupCallDetails = p.groupCallDetails
          // groupObject.interval = p.interval
          opts.push(groupObject)
        })
        filtered.forEach(p => {
          let groupObject = new Object()
          groupObject.id = p.key
          groupObject.value = p.title
          groupObject.title = p.title
          groupObject.group = p.id
        })
      }
      // console.log(opts, "appointments")
      setAssignmentsOrig(opts)
      setAssignments(opts)
      setModifiedAssignments(opts)

      if (profsSelection.length > 0) {
        handleChange(profsSelection)
      }
    }
    // admin
    else {
      let opts = []
      v.forEach(p => {
        let groupObject = new Object()
        groupObject.id = p.id
        groupObject.key = p.key
        groupObject.client_key = p.client_key
        groupObject.title = p.title
        groupObject.group = p.group
        groupObject.start_time = dayjs(p.start_time)
        groupObject.end_time = dayjs(p.end_time)
        groupObject.duration = p.duration
        // groupObject.className = p.key != null ? "confirm" : "assign"
        groupObject.className = p.group != 0 ? "confirm" : "assign"
        groupObject.type = p.type
        groupObject.name = p.name
        groupObject.groupCallDetails = p.groupCallDetails
        // groupObject.interval = p.interval
        opts.push(groupObject)
      })
      v.forEach(p => {
        let groupObject = new Object()
        groupObject.id = p.key
        groupObject.value = p.title
        groupObject.title = p.title
        groupObject.group = p.id
      })

      // console.log(opts, "appointments")
      setAssignmentsOrig(opts)
      setAssignments(opts)
      setModifiedAssignments(opts)

      if (profsSelection.length > 0) {
        handleChange(profsSelection)
      }
    }
  }

  const deleteAssignment = () => {
    setOngoingDelete(true)
    console.log(abortRef.current, selectedAssignment?.key)
    deleteAppointment(abortRef.current, selectedAssignment.key)
      .then(result => {
        if (result.statusCode == 200) {
          // setDeleteModal(false)
          setSelectedAssignment(null)
          setModal(false)
          // setSelectedAssignment({})
          showToast(props.t("Deleted"), "success")
          getAppointments()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setOngoingDelete(false)
      })
  }

  const submitMultipleChanges = () => {
    setLoading(true)
    // console.log("{ array: differences }:", { array: differences })
    updateAppointments(abortRef.current, { array: differences })
      .then(result => {
        if (result.statusCode == 200) {
          showToast(props.t("Changes saved"), "success")
          getAppointments()
        }
      })
      .catch(err => {
        console.log(err, err.statusCode)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Formatting selected professionals if necessary
  const settingSelected = (prof, app) => {
    if (prof != undefined) {
      let opts = []
      // Counting time for professionals using matching id:s (not permanent solution, only for presentation)
      countingTime(prof, app)

      prof.forEach(p => {
        let groupObject = new Object()
        // groupObject.id = counter++
        groupObject.id = p.id
        groupObject.key = p.key
        groupObject.title =
          p.key != "" ? p.title : props.t("Assignments." + p.title)
        groupObject.label =
          p.key != ""
            ? p.title + " " + "(" + p.key + ")"
            : props.t("Assignments." + p.title)
        groupObject.value = p.key
        groupObject.rightTitle = countingTime(p, app)
        opts.push(groupObject)
      })
      let filteredSelected = opts.filter(o => o.rightTitle !== "00:00")
      setSelectedProfs(filteredSelected)
      setProfessionals(opts)
      // console.log(opts, "professionals")
    }
  }

  // Setting selected from modified list
  const settingSelectedAssignment = item => {
    // console.log(item, !modify, !loading)
    if (!modify && !loading) {
      setCustomizeAssignment(true)
      let select = []
      if (isNaN(item)) {
        // console.log(" clicked from popup")
        select = modifiedAssignments.find(i => i.key === item)
      } else {
        // console.log("clicked from timetable")
        select = modifiedAssignments.find(i => i.id === item)
        // console.log(select)
      }
      setSelectedAssignment({
        key: select.key,
        type: select.type,
        client_key: select.client_key,
        title: select.title,
        group: findMatch(select.group),
        start_time: select.start_time,
        duration: select.duration,
        appointmentName: select.name,
      })
      setDefaultGroupKey(findMatch(select.group))
      setModal(true)
    }
  }

  // Setting selected from click on empty
  const settingAssignmentClick = (groupId, time) => {
    // console.log("setting", groupId, time)

    let select = []
    // console.log("select of", professionals, group, time)
    if (findType == "clients") {
      select = professionals.find(i => i.id === groupId)
      // console.log(select, time, select.client_key, "select clients base")
      setSelectedAssignment({
        title: "",
        start_time: time,
        client_key: select.client_key,
      })
    }
    if (findType == "admins") {
      select = professionals.find(i => i.id === groupId)
      // console.log(select, time, select.id, "select admins base")
      setSelectedAssignment({
        title: props.t("Assign to") + " " + select.label,
        group: select.key,
        start_time: time,
      })
    }

    setDefaultGroupKey("")
    setModal(true)
  }

  // Set selected professionals
  // TODO: this if appointment is moved?
  const handleChange = newSelection => {
    // console.log(newSelection, profsSelection, "profs")
    setProfsSelection(newSelection)
    let filtered = []
    if (newSelection.length != 0) {
      filtered = professionals.filter(p => newSelection.includes(p.key))
      setSelectedProfs(filtered)
    } else {
      let filteredSelected = professionals.filter(o => o.rightTitle !== "00:00")
      setSelectedProfs(filteredSelected)
    }
  }

  const unassigned = () => {
    let shown = 0
    let all = 0
    if (assignments.length > 0) {
      assignments.map(a => {
        if (a.group == 0) {
          shown++
        }
      })
    }
    if (assignmentsOrig.length > 0) {
      assignmentsOrig.map(a => {
        if (a.group == 0) {
          all++
        }
      })
    }
    return (
      <>
        <b className={all == 0 && "text-success"}>{all}</b>
        {all != shown && (
          <span>
            <span className="text-gray font-size-13">
              {" "}
              <span className={shown == 0 && "text-success"}>/{shown}</span>
            </span>
          </span>
        )}
      </>
    )
  }

  const setNewSelection = () => {
    let newEntry = new Object()
    newEntry.key = undefined
    newEntry.type = "video_call"
    newEntry.group = null
    newEntry.start_time = dayjs()
    newEntry.duration = 15
    newEntry.appointmentName = ""
    setSelectedAssignment(newEntry)
    setAddNew(true)
    setModal(true)
  }

  const countingTime = (prof, app) => {
    let startTime = "00:00"
    let minutes = 0

    app.map(a => {
      if (a.group == prof.id) {
        // console.log(a, prof, "check")
        minutes = minutes + a.duration
      }
    })
    let duration = dayjs(startTime, "HH:mm")
      .add(minutes, "minutes")
      .format("HH:mm")
    return duration
  }

  // Matching from profs list to modifying assignment
  const findMatch = value => {
    // console.log(value, "value match")
    if (value == 0) {
      return null
    }
    if (value) {
      let match = professionals.filter(p => value == p.id)
      // console.log(match, value, "1match")
      if (match.length > 0) {
        match = match[0].key
      }
      // console.log(match, "match")
      return match
    }
  }

  const handleEmptyAreaClick = (groupId, time, e) => {
    // if (props.userRole.includes("admin") && !props.modify) {
    if (!modify) {
      // console.log("clicked", groupId, time, e)
      setEmptyClickAssignments(true)
      settingAssignmentClick(groupId, time)
    }
  }

  const handlePageExitAttempt = event => {
    if (modify) {
      event.returnValue = "Are you sure you want to leave?"
      return event.returnValue
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title={title}
            breadcrumbItem={
              <>
                <div className="d-inline-block">
                  <Manual source={"appointments"}></Manual>
                </div>
              </>
            }
          />

          <AppointmentModal
            userRole={userRole}
            validation={validation}
            customizeAssignment={customizeAssignment}
            emptyClickAssignments={emptyClickAssignments}
            defaultGroupKey={defaultGroupKey}
            selectedAssignment={selectedAssignment}
            professionals={professionals}
            selectedGroup={selectedGroup}
            ongoingDelete={ongoingDelete}
            assignments={assignments}
            findType={findType}
            modify={modify}
            loading={loading}
            addNew={addNew}
            modal={modal}
            setModal={setModal}
            deleteAssignment={deleteAssignment}
          />

          {/*  <Card
            outline
            color="primary"
            className="appointments-container border"
          >
            <CardHeader className="bg-transparent">
              <h5 className="my-0 text-primary">
                <i className="mdi mdi-block-helper me-3" />
                Muokattu
              </h5>
            </CardHeader>{" "} */}
          <Card className="appointments-container">
            <CardBody>
              {/* <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                > */}
              {/* <Nav tabs className="nav-tabs-custom nav-justified"> */}
              {/* NOT IN USE YET */}
              {/* {userRole?.includes("admin") && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "0",
                        })}
                        onClick={() => {
                          toggleCustom("0")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-clock-time-eight-outline"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          {props.t("Assigning appointments")}
                        </span>
                      </NavLink>
                    </NavItem>
                  )} */}
              {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-list"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {props.t("Timeline")}
                      </span>
                    </NavLink>
                  </NavItem> */}
              {/* 
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleCustom("2")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-list-alt"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {props.t("Calendar")}
                      </span>
                    </NavLink>
                  </NavItem> */}
              {/*      <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggleCustom("3")
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-calendar-alt"></i>
                      </span>
                      <span className="d-none d-sm-block">
                        {/* TODO: Translation 
                        {props.t("Statistics")}
                      </span>
                    </NavLink>
                  </NavItem> */}
              {/* </ul>
              </div>
            </CardHeader> */}
              {/* <CardBody> */}
              {/* NOT IN USE, 
              in case the appointment functions expand 
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="0">
                  <Row> */}
              <Col sm="12">
                <TimelineCalendar2
                  userRole={userRole}
                  selectedGroup={selectedGroup}
                  assignmentsOrig={assignmentsOrig}
                  assignments={assignments}
                  selectedProfs={selectedProfs}
                  professionals={professionals}
                  differences={differences}
                  modify={modify}
                  loading={loading}
                  findType={findType}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  setSelectedGroup={setSelectedGroup}
                  setAssignments={setAssignments}
                  setUserKey={setUserKey}
                  setDifferences={setDifferences}
                  setModify={setModify}
                  setUserRole={setUserRole}
                  setNewSelection={setNewSelection}
                  unassigned={unassigned}
                  findMatch={findMatch}
                  submitMultipleChanges={submitMultipleChanges}
                  settingSelectedAssignment={settingSelectedAssignment}
                  handleChange={handleChange}
                  handleEmptyAreaClick={handleEmptyAreaClick}
                  getAppointments={getAppointments}
                />
              </Col>
              {/* </Row>
                </TabPane>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12"></Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12"></Col>
                  </Row>
                </TabPane>
              </TabContent>  */}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

Appointments.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Appointments)
