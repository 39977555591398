import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/UserProfile"

// Authentication related pages
//import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Other public pages
import ReleaseNote from "../pages/Public/ReleaseNote"
import DisturbanceNotice from "../pages/Public/DisturbanceNotice"

// LANDING PAGE
import LandingPage from "pages/LandingPage/LandingPage"

// CUSTOMER PROFILE
import CustomerProfile from "pages/CustomerProfile/customer-profile"

// MONITORING
import AlarmMonitoring from "pages/Monitoring/alarm-monitoring/alarm-monitoring"
import OfflineVideoMonitoring from "pages/Monitoring/offline-videos/offline-videos"

// REPORTING
import Reporting from "pages/Reporting/reporting"

// SERVICES CHARTS
import GeneralViewChart from "pages/Services/at-home/general-view-chart"

//SUVANTO SERVICES
import GroupCallServices from "pages/Video/GroupCall/group-call"

// ADMIN TOOLS
import PindoraConfigs from "pages/Developer/Devices/Pindoras/PindoraConfigs"
import GatewayConfigs from "pages/Developer/Devices/Gateways/GatewayConfigs"
import TrackerConfigs from "pages/Developer/Devices/Trackers/TrackerConfigs"
import VideoDevices from "pages/Developer/Devices/VideoDevices/VideoDevices"
import DoseConfigs from "pages/Developer/Devices/Doses/DoseConfigs"
import OrderManagement from "pages/Sales/OrderManagement"
import CarephoneConfigs from "pages/Developer/Devices/Carephones/CarephoneConfigs"
import MeasureDeviceConfigs from "pages/Developer/Devices/MeasureDevices/MeasureDeviceConfigs"
import SystemLogs from "pages/Developer/Logs/SystemLogs"
import ReleaseNotesManagement from "pages/Developer/Messaging/ReleaseNotesManagement"
import DisturbanceNoticesManagement from "pages/Developer/Messaging/DisturbanceNoticesManagement"
import EmailReleasesPage from "pages/Developer/Messaging/EmailReleases/email-releases"
// import EmailReleasesPage from "pages/Developer/email-releases/email-releases"
import ServiceMonitoring from "pages/Sales/ServiceMonitoring"
import VideoRooms from "pages/Developer/Devices/VideoRooms/VideoRooms"
import AdminUsersManagement from "pages/Developer/AdminUsers/AdminUsersManagement"
//import AdminUsersManagement2 from "pages/Developer/admin-users2/admin-users"
import ClientsManagementTable from "pages/Developer/Clients/ClientsManagementTable"

import AdminUsersManagementKeycloak from "pages/Developer/AdminUsers/AdminUsersManagementKeycloak"

//import UserManagement from "pages/Admin/user-management/user-management"
import VideoSystemManagement from "pages/Video/VideoManagement/video-management"
import GroupsManagement from "pages/Developer/Groups/GroupsManagement"

import SmsLogs from "pages/Developer/Logs/SmsLogs"
import GatewayLogs from "pages/Developer/Logs/GatewayLogs"
import AuditLogs from "pages/Developer/Logs/AuditLogs"

import Appointments from "pages/Appointments/Appointments"

import VideoGroupMessage from "pages/Video/VideoGroupMessage/group-message"
import VideoAppSettings from "pages/Video/VideoAppSettings/video-app-settings"

import SupportTicketsReport from "pages/Developer/Misc/SupportTicketsReport"
import Instructions from "pages/Developer/Misc/Instructions"
import OrganizationUserManagement from "pages/Admin/user-management/OrganizationUserManagement"

const authProtectedRoutes = [
  // LANDING PAGE
  { path: "/main", component: <LandingPage /> },

  // CLIENT MANAGEMENT
  // prettier-ignore
  // eslint-disable-next-line react/display-name
  { path: "/clients", exact: true, component: <Navigate to="/clients/select" replace/> },
  // prettier-ignore
  { path: "/clients/:clientId", component: <CustomerProfile/> },

  // MONITORING
  {
    path: "/monitoring/alarms",
    component: <AlarmMonitoring />,
    roles: [],
    permission: "alarm_monitoring",
  },
  {
    path: "/monitoring/offline-videos",
    component: <OfflineVideoMonitoring />,
    roles: [],
    permission: "offline_video_monitoring",
  },

  // PROFILE
  { path: "/profile", component: <UserProfile /> },

  // REPORTING PAGES
  {
    path: "/reporting",
    component: <Reporting />,
    roles: [
      "system_admin",
      "salesperson",
      "order_manager",
      "organisation_admin",
    ],
  },

  // SALES PAGES
  {
    path: "/sales/service-monitoring",
    component: <ServiceMonitoring />,
    roles: ["system_admin", "salesperson", "order_manager"],
  },

  // SERVICES PAGES
  {
    path: "/group-call",
    component: <GroupCallServices />,
    roles: [
      "system_admin",
      "organisation_admin",
      "organisation_user",
      "salesperson",
    ],
  },
  {
    path: "/at-home-chart-full",
    component: <GeneralViewChart />,
  },

  // ADMIN TOOLS
  // Add roles or permissions for access control
  {
    path: "/config/admins",
    component: <AdminUsersManagement />,
    roles: ["system_admin"],
  },
  {
    path: "/config/user-accounts",
    component: <AdminUsersManagementKeycloak />,
    roles: ["system_admin"],
  },
  /*{
    path: "/admin/user-management",
    component: <UserManagement />,
    permission: "admin_management",
  },*/
  {
    path: "/admin/user-management",
    component: <OrganizationUserManagement />,
    permission: "admin_management",
  },
  {
    path: "/instructions",
    component: <Instructions />,
    permission: "instructions",
  },
  // TEST START
  /*{
    path: "/config/admins2",
    component: <AdminUsersManagement2 />,
    roles: ["system_admin"],
  },*/
  // TEST END
  {
    path: "/config/clients",
    component: <ClientsManagementTable />,
    roles: ["system_admin"],
  },
  {
    path: "/config/groups",
    component: <GroupsManagement />,
    roles: ["system_admin"],
  },
  {
    path: "/config/gateways",
    component: <GatewayConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/pindoras",
    component: <PindoraConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/trackers",
    component: <TrackerConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/video-devices",
    component: <VideoDevices />,
    roles: ["system_admin"],
  },
  {
    path: "/config/video-numbers",
    component: <VideoRooms />,
    roles: ["system_admin"],
  },
  {
    path: "/config/doses",
    component: <DoseConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/carephones",
    component: <CarephoneConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/measuringdevices",
    component: <MeasureDeviceConfigs />,
    roles: ["system_admin"],
  },
  {
    path: "/config/video-system",
    component: <VideoSystemManagement />,
    permission: "video_system",
  },
  {
    path: "/sales/orders",
    component: <OrderManagement />,
    roles: ["system_admin", "salesperson", "order_manager"],
  },
  {
    path: "/messaging/releases",
    component: <ReleaseNotesManagement />,
    roles: ["system_admin"],
  },
  {
    path: "/messaging/email-releases",
    component: <EmailReleasesPage />,
    roles: ["system_admin"],
  },
  {
    path: "/messaging/disturbance-notices",
    component: <DisturbanceNoticesManagement />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/system",
    component: <SystemLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/sms",
    component: <SmsLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/gateway",
    component: <GatewayLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/logs/audit-logs",
    component: <AuditLogs />,
    roles: ["system_admin"],
  },
  {
    path: "/reporting/support-tickets",
    component: <SupportTicketsReport />,
    roles: ["system_admin"],
  },
  {
    path: "/appointments",
    component: <Appointments />,
    permission: "appointments",
  },
  {
    path: "/video/group-messages",
    component: <VideoGroupMessage />,
    permission: "groupMessages",
  },
  {
    path: "/video/app-settings",
    component: <VideoAppSettings />,
    roles: ["system_admin"],
  },

  // this route should be at the end of all other routes
  // prettier-ignore
  // eslint-disable-next-line react/display-name
  { path: "*", exact: true, component: <Navigate to="/main" replace/> },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  //{ path: "/login", component: <Login /> }, // KEYCLOAK-LOGIN removed path
  //{ path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/release-note/:id", component: <ReleaseNote /> },
  { path: "/disturbance-notice/:id", component: <DisturbanceNotice /> },
]

export { publicRoutes, authProtectedRoutes }
