import {
  ackAlarms,
  deleteSelectedAlarms,
  getAlarms,
  resolveAlarms,
} from "helpers/backend_helper"
import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Form,
  Label,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Input,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap"
import { Switch } from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import PubSub from "pubsub-js"
import AlarmServicesTable from "./alarm-table"
import AlarmFilter from "./alarm-filter"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { withTranslation } from "react-i18next"
import { showToast } from "utils/general"

const AlarmServices = props => {
  const abortRef = useRef(null)
  const [entries, setEntries] = useState([])
  const [filteredEntries, setFilteredEntries] = useState([])
  const [filteredEntriesAll, setFilteredEntriesAll] = useState([])
  const [showAll, setShowAll] = useState(false)
  const [showAutoClosed, setShowAutoClosed] = useState(true)
  const [selectedAlarms, setSelectedAlarms] = useState([])
  const [selectedAlarmsObj, setSelectedAlarmsObj] = useState([])
  const [closeModal, setCloseModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [comments, setComments] = useState("")
  const [falseAlarm, setFalseAlarm] = useState(false)
  const [dateRange, setDateRange] = useState([
    dayjs().subtract(1, "months"),
    dayjs(),
  ])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      setDateRange(prevState => [prevState[0], dayjs()])
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (dateRange && props.suvantoKey) updateAlarmList()
  }, [dateRange, showAll, showAutoClosed, props.suvantoKey])

  // Update selected objects & alarmkeys
  useEffect(() => {
    setIsLoading(true)
    // console.log(filteredEntries, "filtered")
    if (showAutoClosed) {
      setFilteredEntriesAll(filteredEntries)
    } else {
      let filtered = []
      filtered = filteredEntries.filter(
        f => f.auto_closed == undefined || !f.auto_closed
      )
      // console.log(filtered, "filtered without auto")
      setFilteredEntriesAll(filtered)
    }
    setIsLoading(false)
  }, [filteredEntries])

  // Fetch alarms
  const updateAlarmList = () => {
    if (dateRange && props.suvantoKey) {
      setIsLoading(true)
      getAlarms(
        abortRef.current,
        props.suvantoKey,
        dateRange[0].valueOf(),
        dateRange[1].valueOf(),
        showAll
      )
        .then(data => {
          data.entries.forEach(element => {
            // Map link
            if (element.location?.latitude) {
              element.map_link =
                "https://www.google.com/maps/place/" +
                element.location.latitude +
                "," +
                element.location.longitude
            }

            element.extra_info = ""
            switch (element.alarm_id) {
              case 1:
                if (element.value) element.extra_info = element.value + "%"
                break
              case 15:
              case 16:
                if (element.value)
                  element.extra_info =
                    props.t("over") + " " + element.value + "min"
                break
              case 18:
              case 19:
                if (element.node_name && element.value)
                  element.extra_info =
                    element.node_name + " " + element.value + "°C"
                break
              case 20:
              case 21:
                if (element.node_name && element.value)
                  element.extra_info =
                    element.node_name + " " + element.value + "%"
                break
              case 17:
              case 22:
              case 23:
                if (element.node_name)
                  element.extra_info = "" + element.node_name
                break
              case 27:
              case 47: // Carephone accessory battery alarm
                if (element.string_value)
                  element.extra_info = "" + element.string_value
                break
              case 30: // Abnormal measurement
                if (element.string_value && element.value)
                  element.extra_info =
                    props.t(element.string_value) + " " + element.value
                break
              case 31: // Measurement missed
                if (element.string_value)
                  element.extra_info = props.t(element.string_value)
                break
            }
          })
          setEntries(data.entries)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  // Ack selected alarms
  const ackSelectedAlarms = () => {
    if (selectedAlarms.length > 0) {
      ackAlarms(abortRef.current, selectedAlarms)
        .then(res => {
          updateAlarmList()
        })
        .catch(err => {
          console.log(err)
        })
    } else console.log("not selected")
  }

  // Close selected alarms
  const resolveSelectedAlarms = () => {
    if (selectedAlarms.length > 0) {
      resolveAlarms(abortRef.current, selectedAlarms, comments, falseAlarm)
        .then(res => {
          setComments("")
          setFalseAlarm(false)
          updateAlarmList()
          setCloseModal(false)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  // Delete selected alarms
  const deleteSelections = () => {
    if (selectedAlarms.length > 0) {
      deleteSelectedAlarms(abortRef.current, props.suvantoKey, selectedAlarms)
        .then(result => {
          console.log("Delete alarm result: ", result)
          if (result.statusCode == 200) {
            updateAlarmList()
            setDeleteModal(false)
            showToast(props.t("Deleted"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
    }
  }

  const toggleShow = () => {
    setShowAll(prevState => !prevState)
  }
  const toggleShowAutoClosed = () => {
    setShowAutoClosed(prevState => !prevState)
  }

  // Date changed
  const onDateSelection = value => {
    setDateRange(value)
  }

  return (
    <>
      <Modal
        isOpen={closeModal}
        toggle={() => {
          setOpenRequestModal(!closeModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Close selected alarms")}</ModalHeader>
        <ModalBody>
          <Form className="form-horizontal">
            <FormGroup>
              <Label>{props.t("Comments")}</Label>
              <Input
                name="comments"
                type="textarea"
                rows={6}
                onChange={val => {
                  setComments(val.target.value)
                }}
                value={comments}
              ></Input>
            </FormGroup>
            <FormGroup>
              <Switch
                className="me-2"
                name="falseAlarm"
                checked={falseAlarm}
                onChange={val => {
                  setFalseAlarm(val)
                }}
              ></Switch>
              <Label>{props.t("False alarm")}</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              resolveSelectedAlarms()
            }}
          >
            {props.t("Close alarms")}
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => {
              setCloseModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
      {/** Delete alarms modal */}
      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete selected alarms")}</ModalHeader>
        <ModalBody>
          <p>{props.t("Are you sure you want to delete selected alarms?")}</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            color="danger"
            onClick={() => {
              deleteSelections()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
      <Card className="mt-0">
        <CardBody>
          <Row>
            <Col md="5" className="mb-1">
              {/* HOX */}
              <AlarmFilter
                data={entries}
                setFilteredEntries={setFilteredEntries}
              />
            </Col>
            <Col md="5" className="mb-1">
              {/** 
              <FilterCalendarServices
                setCalendarStartDate={setStartDate}
                setCalendarEndDate={setEndDate}
                range={range}
              />*/}
              <CustomRangePicker
                value={dateRange}
                rangeChanged={onDateSelection}
                showTime={true}
                allowClear={false}
                format={"DD.MM.YYYY HH:mm"}
              />
            </Col>
            <Col md="2">
              <span>
                <FormGroup className="mt-1 mb-1">
                  <Switch
                    className="me-2"
                    name="toggleShow"
                    id="toggleShow"
                    checked={showAll}
                    onChange={toggleShow}
                    checkedChildren={
                      <div
                        className="d-flex"
                        style={{ maxHeight: "22px" }}
                      >
                        <i className="mdi mdi-check-circle-outline font-size-18" />
                      </div>
                    }
                    unCheckedChildren={
                      <div
                        className="d-flex"
                        style={{ maxHeight: "22px" }}
                      >
                        <i className="mdi mdi-check-circle-outline font-size-18" />
                      </div>
                    }
                  ></Switch>
                  <UncontrolledTooltip placement="right" target="toggleShow">
                    {props.t("Show closed alarms")}
                  </UncontrolledTooltip>
                  {/* </FormGroup>
              </span>
              <span>
                <FormGroup className="mt-1 mb-1"> */}
                  <Switch
                    className="me-2"
                    name="toggleShow"
                    id="toggleShowAuto"
                    checked={showAutoClosed}
                    onChange={toggleShowAutoClosed}
                    disabled={!showAll}
                    checkedChildren={
                      <div
                        className="d-flex"
                        style={{ maxHeight: "22px" }}
                      >
                        <i className="mdi mdi-spellcheck font-size-18" />
                      </div>
                    }
                    unCheckedChildren={
                      <div
                        className="d-flex"
                        style={{ maxHeight: "22px" }}
                      >
                        <i className="mdi mdi-spellcheck font-size-18" />
                      </div>
                    }
                  ></Switch>
                  <UncontrolledTooltip
                    placement="right"
                    target="toggleShowAuto"
                  >
                    {props.t("Show automatically closed alarms")}
                  </UncontrolledTooltip>
                </FormGroup>
              </span>
            </Col>
          </Row>
          <Collapse isOpen={selectedAlarmsObj.length != 0}>
            <Row>
              <Col className="py-2">
                {selectedAlarmsObj.some(
                  obj =>
                    obj.status === "UNASSIGNED" || obj.status === "ASSIGNED"
                ) && (
                  <>
                    <Button
                      outline
                      color="primary"
                      className="me-3"
                      // disabled={}
                      onClick={() => {
                        ackSelectedAlarms()
                      }}
                    >
                      <i className="mdi mdi-alert-circle me-2" />
                      {props.t("Ack selected alarms")}
                    </Button>
                    <Button
                      color="primary"
                      className="me-3"
                      // disabled={selectedAlarms.length == 0}
                      onClick={() => {
                        setCloseModal(true)
                      }}
                    >
                      <i className="bx bx-check-circle me-2" />
                      {props.t("Close selected alarms")}
                    </Button>
                  </>
                )}
                {selectedAlarmsObj.length > 0 && (
                  <Button
                    color="danger"
                    className="me-3"
                    // disabled={selectedAlarms.length == 0}
                    onClick={() => {
                      setDeleteModal(true)
                    }}
                  >
                    <i className="mdi mdi-trash-can me-2" />
                    {props.t("Delete selected alarms")}
                  </Button>
                )}

                <span className="text-muted">
                  {selectedAlarmsObj.length} {props.t("selected alarms")}
                </span>
              </Col>
            </Row>
          </Collapse>

          {isLoading && entries.length == 0 ? (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-primary py-4">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </Col>
            </Row>
          ) : (
            <div className="mt-3">
              {filteredEntriesAll.length !== 0 && entries.length !== 0 ? (
                <>
                  <div className="text-muted">
                    {filteredEntriesAll.length} {props.t("found results")}
                  </div>
                  <AlarmServicesTable
                    loading={isLoading}
                    data={filteredEntriesAll}
                    selectedAlarms={selectedAlarms}
                    setSelectedAlarms={setSelectedAlarms}
                    setSelectedAlarmsObj={setSelectedAlarmsObj}
                  ></AlarmServicesTable>
                </>
              ) : (
                <Col xs="12">
                  <div className="text-center my-3">
                    {!showAll && (
                      <h5 to="#" className="py-5 text-success">
                        <i className="bx bx-badge-check font-size-18 align-middle me-2" />
                        {props.t("No unresolved alarms")}
                      </h5>
                    )}
                    {!showAll && (
                      <h5 to="#" className="py-4 ">
                        {props.t("No alarms found")}
                      </h5>
                    )}
                  </div>
                </Col>
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </>
  )
}

AlarmServices.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.any,
}

export default withTranslation()(AlarmServices)
