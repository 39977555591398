import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import Chip from "@material-ui/core/Chip"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"
import { sendEmail } from "helpers/backend_helper"
import {
  Form,
  Input,
  Alert,
  FormFeedback,
  Label,
  FormGroup,
  Button,
  Modal,
  ModalBody,
} from "reactstrap"
import { Steps, TreeSelect } from "antd"

import { useFormik } from "formik"
import * as Yup from "yup"
import { showToast } from "utils/general"
import { insertNewsletterLog } from "helpers/database_helper"

const EmailSendModal = props => {
  const [emailType, setEmailType] = useState(props.modalType)
  const [contentLink, setContentLink] = useState(props.contentLink)
  const [ongoingInsert, setOngoingInsert] = useState(false)
  const [example, setExample] = useState(null)
  const [error, setError] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [treeData, setTreeData] = useState([])
  const [subscribers, setSubscribers] = useState([])
  const [productionDatabase, setProductionDatabase] = useState(false)
  const [subscriberIds, setSubscriberIds] = useState([])
  // Email info
  const [guid, setGuid] = useState(props.guid)
  const [messageTitle, setMessageTitle] = useState(
    emailType === 1
      ? "Versiopäivitystiedote Suvanto Care -selainkäyttöliittymän päivityksestä"
      : "Häiriötiedote Suvanto Care -selainkäyttöliittymään liittyen"
  )
  const [linkTitleRelease, setLinkTitleRelease] = useState(
    emailType === 1 ? "Versiopäivitystiedote" : "Häiriötiedote"
  )

  const [link, setLink] = useState(
    `<a href="https://app.suvanto.care/release-note/${guid}" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://app.suvanto.care/release-note/${guid}&amp;source=gmail&amp;ust=1723553708787000&amp;usg=AOvVaw34kzXryDfsOqThLUFgoiO_">${linkTitleRelease}</a>`
  )
  const [messageReleaseNote, setMessageReleaseNote] = useState(
    `Versiopäivitystiedote Suvanto Care -selainkäyttöliittymän päivityksestä.
      <br><br>${link}
      <br><br>Muutos koskee selainkäyttöliittymää (<a href="https://app.suvanto.care/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://app.suvanto.care/&amp;source=gmail&amp;ust=1723553708787000&amp;usg=AOvVaw34kzXryDfsOqThLUFgoiO_">app.suvanto.care</a>).
      <br><br>Päivitys julkaistaan (päivämäärä). Päivitys ei edellytä käyttäjiltä toimenpiteitä.`
  )
  const [messageErrorNotice, setMessageErrorNotice] = useState(
    `Häiriötiedote Suvanto Care -selainkäyttöliittymään liittyen.
      <br><br>${link}
      <br><br>Muutos koskee selainkäyttöliittymää (<a href="https://app.suvanto.care/" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://app.suvanto.care/&amp;source=gmail&amp;ust=1723553708787000&amp;usg=AOvVaw34kzXryDfsOqThLUFgoiO_">app.suvanto.care</a>).
      <br><br>Häiriö ei edellytä käyttäjiltä toimenpiteitä.`
  )
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUserEmails, setSelectedUserEmails] = useState("")
  const [checkedKeys, setCheckedKeys] = useState([])
  const [filteredUsers, setFilteredUsers] = useState(subscribers)
  const [searchText, setSearchText] = useState("")
  const [selectedTreeValues, setSelectedTreeValues] = useState([])
  const [from, setFrom] = useState("info@suvantocare.fi")
  const [to, setTo] = useState("tiedote@suvantocare.fi")
  const [initialValues, setInitialValues] = useState({
    from: from,
    to: to,
    cc: "",
    bcc: selectedUserEmails,
    title: messageTitle,
    message: emailType === 1 ? messageReleaseNote : messageErrorNotice,
  })
  const [showAll, setShowAll] = useState(false)
  const maxVisibleItems = 3
  const visibleItems = showAll
    ? selectedTreeValues
    : selectedTreeValues.slice(0, maxVisibleItems)
  const hiddenItemsCount = selectedTreeValues.length - maxVisibleItems
  // FILTERS NOT IN USE
  // Show filters
  // const [showFilter, setShowFilter] = useState(false)
  // const [selectedTags, setSelectedTags] = useState([])
  // const [selectedGroups, setSelectedGroups] = useState([])

  // Steps for publication
  const modalSteps = [
    {
      title: props.t("Message"),
    },
    {
      title: props.t("Send"),
    },
  ]

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Email bulletins") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    console.log("SUBS:", props.subscribers)
    if (props.subscribers !== undefined) {
      if (emailType == 1) {
        // Filter releasenotes subscribers
        let releaseNoteSubs = props.subscribers.filter(
          subs => subs.release_notes == true
        )
        setSubscribers(releaseNoteSubs)
      } else {
        // Filter error notices
        let errorNotiSubs = props.subscribers.filter(
          subs => subs.status_reports == true
        )
        setSubscribers(errorNotiSubs)
      }
    }
  }, [props.subscribers])

  useEffect(() => {
    setContentLink(props.contentLink)
  }, [props.contentLink])

  useEffect(() => {
    if (filteredUsers.length > 0) {
      let tree = filteredUsers.map(item => ({
        title: item.name + " (" + item.email + ")",
        value: item.email,
        children: [],
      }))

      let treeData = [
        {
          title: props.t("All"),
          value: "all",
          children: tree,
        },
      ]
      setTreeData(treeData)
    } else {
      setTreeData([])
    }
  }, [filteredUsers])

  useEffect(() => {
    setFilteredUsers(subscribers)
  }, [subscribers])

  useEffect(() => {
    if (searchText.length === 0) {
      setFilteredUsers(subscribers)
    } else {
      const filtered = searchText
        ? subscribers.filter(user =>
            user.email.toLowerCase().includes(searchText.toLowerCase())
          )
        : subscribers.filter(user => selectedUserEmails.includes(user.email))
      setFilteredUsers(filtered)
    }
  }, [searchText])

  // FILTERS NOT IN USE
  // useEffect(() => {
  //   // console.log(filteredUsers, subscribers, selectedTags, selectedGroups)
  //   if (selectedTags.length !== 0 || selectedGroups.length !== 0) {
  //     let filtered = []
  //     let users = subscribers
  //     if (selectedGroups.length > 0) {
  //       users = selectedGroups.some(
  //         group => users.groupname && users.groupname.split(",").includes(group)
  //       )
  //     }
  //     if (selectedTags.length > 0) {
  //       users = selectedTags.some(
  //         tag => users.tags && users.tags.split(",").includes(tag)
  //       )
  //     }
  //     // console.log(users)
  //   } else {
  //     setFilteredUsers(subscribers)
  //   }
  // }, [selectedTags, selectedGroups])
  // FILTERS NOT IN USE

  // Send email
  const sendMessage = vals => {
    setOngoingInsert(true)
    // console.log("sendMessage values:", vals)
    sendEmail(
      abortRef.current,
      vals.from,
      vals.to,
      vals.cc,
      vals.bcc,
      vals.subject,
      vals.body
    )
      .then(result => {
        console.log(result)
        showToast(props.t("Sent"), "success")
        props.settingSelectedGuid("")
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        sendLog()
      })
  }
  const sendLog = () => {
    console.log("Send logs", subscriberIds, emailType, contentLink)
    // if (subscriberIds && emailType && contentLink) {
    let values = {
      recipients: subscriberIds,
      type: emailType,
      content_link: contentLink,
    }
    insertNewsletterLog(abortRef.current, values, productionDatabase)
      .then(result => {
        console.log(result)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {})
    // }
  }
  const changeSteps = value => {
    setCurrentStep(value)
  }
  const nextStep = () => {
    setCurrentStep(currentStep + 1)
  }
  const prevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleChange = value => {
    console.log(value, "value")
    if (value.length > 0) {
      if (value[0].value == "all") {
        const allEmails = filteredUsers.map(user => user.email).join(";")
        // subs ids
        const allIds = filteredUsers.map(user => user.id).join(",")
        console.log("Selected emails:", filteredUsers, allIds)
        setSelectedUserEmails(allEmails)
        setSubscriberIds(allIds)

        const allValues = filteredUsers.map(user => ({
          label: user.name,
          value: user.email,
        }))

        setSelectedUsers(props.filteredUsers)
        setSelectedTreeValues(allValues)
      } else {
        const selectedEmails = value.map(item => item.value).join(";")
        const selectedValues = value.map(item => item.value)
        const filteredIds = filteredUsers
          .filter(user => selectedValues.includes(user.email))
          .map(user => user.id)
          .join(",")
        setSelectedUserEmails(selectedEmails)
        setSubscriberIds(filteredIds)

        const selectedUsers = filteredUsers.filter(user =>
          value.some(selected => selected.value === user.email)
        )
        setSelectedUsers(selectedUsers)
        setSelectedTreeValues(value)
      }
    } else {
      setSelectedUsers("")
      setSelectedTreeValues([])
      setSelectedUserEmails("")
    }
  }

  // FILTERS NOT IN USE
  // const handleUserSelectChange = emails => {
  //   setSelectedUserEmails(emails)
  //   const checkedKeys = subscribers
  //     .filter(user => emails.includes(user.email))
  //     .map(user => user.id.toString())
  //   setCheckedKeys(checkedKeys)
  // }
  // const handleUserSearch = value => {
  //   setSearchText(value)
  //   // console.log("searchtext", searchText, value)
  //   // const filtered = value
  //   //   ? subscribers.filter(user =>
  //   //       user.email.toLowerCase().includes(value.toLowerCase())
  //   //     )
  //   //   : subscribers.filter(user => selectedUserEmails.includes(user.email))
  //   // setFilteredUsers(filtered)
  // }
  // const handleTagSelectChange = value => {
  //   setSelectedTags(value)
  //   // console.log("tags",value)
  // }
  // const handleGroupSelectChange = value => {
  //   setSelectedGroups(value)
  //   // setSelectedGroups(value)
  //   // filterUsersByGroups(value)
  // }
  // const filterUsersByTags = tags => {
  //   if (tags.length === 0) {
  //     setFilteredUsers(subscribers)
  //   } else {
  //     const filtered = subscribers.filter(user =>
  //       tags.some(tag => user.tags && user.tags.split(",").includes(tag))
  //     )
  //     setFilteredUsers(filtered)
  //   }
  // }
  // const filterUsersByGroups = groups => {
  //   if (groups.length === 0) {
  //     setFilteredUsers(subscribers)
  //   } else {
  //     const filtered = filteredUsers.filter(user =>
  //       groups.some(
  //         group => user.groupname && user.groupname.split(",").includes(group)
  //       )
  //     )
  //     setFilteredUsers(filtered)
  //   }
  // }
  // const handleCheck = checkedKeys => {
  //   setCheckedKeys(checkedKeys)
  //   const selectedEmails = subscribers
  //     .filter(user => checkedKeys.includes(user.id.toString()))
  //     .map(user => user.email)
  //   setSelectedUserEmails(selectedEmails)
  // }
  // const allTags = Array.from(
  //   new Set(
  //     subscribers.flatMap(user => (user.tags ? user.tags.split(",") : []))
  //   )
  // )
  // const allGroupnames = Array.from(
  //   new Set(
  //     subscribers.flatMap(user =>
  //       user.groupname ? user.groupname.split(",") : []
  //     )
  //   )
  // )
  // FILTERS NOT IN USE

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: initialValues.from,
      to: initialValues.to,
      cc: initialValues.cc,
      bcc: selectedUserEmails,
      subject: initialValues.title,
      body: initialValues.message,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required(props.t("Required field")),
      body: Yup.string().required(props.t("Required field")),
      to: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      setError(null)
      console.log("Submit values: ", values)

      sendMessage(values)
    },
  })

  useEffect(() => {
    console.log(validation.values)
  }, [validation.values])

  // Selected subs render
  const toggleShowAll = () => {
    setShowAll(!showAll)
  }

  // Custom render for selected subs (only showing email instead of label "Name Surname (email)")
  const customTagRender = ({ value, onClose }) => {
    if (value === "all") {
      return (
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
            margin: "2px",
            padding: "3px 8px",
            background: "#f0f0f0",
            borderRadius: "4px",
            fontSize: "12px",
          }}
        >
          {props.t("All")}
          <Button
            color="transparent"
            onClick={onClose}
            className="m-0 ms-1 p-0"
          >
            <i className="mdi mdi-close font-size-11" />
          </Button>
        </span>
      )
    } else {
      return (
        <span
          style={{
            display: "inline-flex",
            alignItems: "center",
            margin: "2px",
            padding: "3px 8px",
            background: "#f0f0f0",
            borderRadius: "4px",
            fontSize: "12px",
          }}
        >
          {value}
          <Button
            color="transparent"
            onClick={onClose}
            className="m-0 ms-1 p-0"
          >
            <i className="mdi mdi-close font-size-11" />
          </Button>
        </span>
      )
    }
  }
  // Selected subs render end

  return (
    <>
      <Modal isOpen={props.guid.length > 0} centered size="lg">
        <ModalBody>
          <div className="p-2">
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              <Steps
                type="navigation"
                size="small"
                className="pb-3 pt-0"
                onChange={selectedUserEmails.length > 0 ? changeSteps : null}
                current={currentStep}
                items={modalSteps}
              ></Steps>
              {currentStep == 0 && (
                <div>
                  {/* FILTERS NOT IN USE <Col>
                    <Select
                      mode="multiple"
                      style={{ width: "100%", marginBottom: "16px" }}
                      placeholder="Select tags"
                      value={selectedTags}
                      onChange={handleTagSelectChange}
                    >
                      {allTags.map(tag => (
                        <Option key={tag} value={tag}>
                          {tag}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      mode="multiple"
                      style={{ width: "100%", marginBottom: "16px" }}
                      placeholder="Select groupnames"
                      value={selectedGroups}
                      onChange={handleGroupSelectChange}
                    >
                      {allGroupnames.map(group => (
                        <Option key={group} value={group}>
                          {group}
                        </Option>
                      ))}
                    </Select>
                  </Col> */}
                  <FormGroup>
                    <Label>{props.t("Selected subscribers")}</Label>
                    <TreeSelect
                      allowClear
                      treeData={treeData}
                      value={selectedTreeValues}
                      onChange={handleChange}
                      treeCheckable={true}
                      showCheckedStrategy={TreeSelect.SHOW_PARENT}
                      placeholder={props.t("Required field")}
                      style={{ width: "100%" }}
                      labelInValue={true}
                      multiple={true}
                      treeDefaultExpandAll={true}
                      treeNodeFilterProp="label"
                      showSearch={true}
                      searchPlaceholder={props.t("Search")}
                      filterTreeNode={(inputValue, treeNode) =>
                        treeNode.title
                          .toLowerCase()
                          .includes(inputValue.toLowerCase())
                      }
                      tagRender={customTagRender} // Custom tag rendering
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>{props.t("Subject")}</Label>
                    <Input
                      name="subject"
                      type="text"
                      placeholder={props.t("Required field")}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.subject || ""}
                      invalid={
                        !!(
                          validation.touched.subject &&
                          validation.errors.subject
                        )
                      }
                    ></Input>
                    {validation.touched.subject && validation.errors.subject ? (
                      <FormFeedback type="invalid">
                        {validation.errors.subject}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                  <FormGroup>
                    <Label>{props.t("Message")}</Label>
                    <Input
                      name="body"
                      placeholder={props.t("Message")}
                      type="textarea"
                      rows={5}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.body || ""}
                    ></Input>
                  </FormGroup>
                </div>
              )}
              {currentStep == 1 && (
                <>
                  <div>
                    <h6>{props.t("Receivers")}:</h6>
                    <div className="px-4">
                      {visibleItems.map(item => (
                        <Chip
                          className="mt-0 mb-2 font-size-12 me-1"
                          size="small"
                          key={item.value}
                          label={item.label}
                        ></Chip>
                      ))}
                      {hiddenItemsCount > 0 && !showAll && (
                        <Chip
                          className="mt-0 mb-2 font-size-12 me-1"
                          size="small"
                          onClick={toggleShowAll}
                          label={
                            "+" + hiddenItemsCount + " " + props.t("others")
                          }
                        ></Chip>
                      )}
                      {showAll && hiddenItemsCount > 0 && (
                        <Chip
                          className="mt-0 mb-2 font-size-12 me-1"
                          size="small"
                          onClick={toggleShowAll}
                          label={props.t("Show less")}
                        ></Chip>
                      )}
                    </div>
                  </div>
                  <h6 className="pb-1 pt-3">{props.t("Subject")}:</h6>
                  <div className="px-4">{validation.values.subject}</div>
                  <h6 className="pb-1 pt-3">{props.t("Message")}:</h6>
                  <div
                    className="px-4 pb-3"
                    dangerouslySetInnerHTML={{
                      __html: validation.values.body,
                    }}
                  ></div>
                </>
              )}
              <div
                style={{
                  marginTop: 24,
                }}
              >
                <Button
                  color="primary"
                  outline
                  className="ms-2"
                  onClick={() => props.settingSelectedGuid("")}
                >
                  {props.t("Cancel")}
                </Button>

                {currentStep === 1 ? (
                  <Button
                    className="float-end"
                    color="success"
                    onClick={() => validation.submitForm()}
                  >
                    <i className="bx bx-mail-send me-1"></i>
                    {props.t("Send email")}
                  </Button>
                ) : (
                  <Button
                    className="float-end"
                    color="primary"
                    disabled={
                      validation.values.title == "" ||
                      validation.values.message == "" ||
                      selectedUserEmails == ""
                    }
                    onClick={() => nextStep()}
                  >
                    {props.t("Next")}
                  </Button>
                )}

                {currentStep > 0 && (
                  <Button
                    className="float-end"
                    color="primary"
                    style={{
                      margin: "0 8px",
                    }}
                    onClick={() => prevStep()}
                  >
                    {props.t("Previous")}
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

EmailSendModal.propTypes = {
  t: PropTypes.any,
  // selection: PropTypes.any,
  // setSelection: PropTypes.any,
  // productionDatabase: PropTypes.any,
  // modal: PropTypes.any,
  // fetchReleases: PropTypes.any,
  subscribers: PropTypes.any,
  settingSelectedGuid: PropTypes.any,
  modalType: PropTypes.any,
  contentLink: PropTypes.any,
  guid: PropTypes.any,
  filteredUsers: PropTypes.any,
}

export default withRouter(withTranslation()(EmailSendModal))
