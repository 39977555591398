/***********************
 * CUSTOMERS/SERVICES TABLE
 * (TABLE + IMPORT PDF/XLSX)
 ************************/

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Tooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { showToast, sortData } from "utils/general"

// Datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../../datatables.scss"

// Search field
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Translation
import { withTranslation } from "react-i18next"

const TableStats = props => {
  //Table data state
  const [series, setSeries] = useState([])
  // Found items lenght
  const [itemsLength, setItemsLength] = useState(0)
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Tooltip state for download button
  const [tTdownloadstatstable, setTTdownloadstatstable] = useState(false)

  //Excel and pdf name
  const title = props.t("Services")
  //Search bar for table
  const { SearchBar } = Search
  // Sort data in table
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]
  // Show selected number of rows
  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: series.length },
    ],
  }
  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
  ]

  //Setting data state
  useEffect(() => {
    console.log("Data table: " + JSON.stringify(props.data.array))
    setSeries(props.data.array)
    setItemsLength(props.data.stats.total)
  }, [props.data])

  // Table structure
  const columns = [
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "tags",
      text: props.t("Tags"),
      sort: true,
      formatter: (cell, row) => {
        if (!cell || cell.length === 0) return ""
        return cell.join(", ")
      },
      style: {
        textOverflow: "ellipsis",
      },
    },
    {
      dataField: "home",
      text: props.t("Home"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.home_inactive),
      inActive: false,
    },
    {
      dataField: "tracker",
      text: props.t("On the Go"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.tracker_inactive),
      inActive: true,
    },
    {
      dataField: "carephone",
      text: props.t("Safety phone"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.carephone_inactive),
    },
    {
      dataField: "video",
      text: props.t("Video"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.video_inactive),
    },
    {
      dataField: "health",
      text: props.t("Measurements"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.health_inactive),
    },
    {
      dataField: "dose",
      text: props.t("Medication reminder"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.dose_inactive),
    },
    {
      dataField: "smartlock",
      text: props.t("Smartlock"),
      sort: true,
      formatter: (cell, row) => statusFormatter(cell, row.smartlock_inactive),
    },
  ]

  function statusFormatter(cell, inActiveCell) {
    if (inActiveCell) {
      return (
        <span className="badge bg-secondary font-size-12">
          {props.t("Not active")}
        </span>
      )
    } else if (cell) {
      return (
        <span className="badge bg-success font-size-12">
          {props.t("In use")} <i className="bx bx-check"></i>
        </span>
      )
    } else {
      return (
        <span className="badge bg-danger font-size-12"></span> // HOX: Empty span
      )
    }
  }

  function statusFormatterExport(cell) {
    return cell ? props.t("x") : ""
  }

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const sortedData = sortData(series)

    const data = sortedData.map(elt => [
      elt.group,
      elt.name,
      elt.tags,
      statusFormatterExport(elt.home),
      statusFormatterExport(elt.tracker),
      statusFormatterExport(elt.carephone),
      statusFormatterExport(elt.video),
      statusFormatterExport(elt.health),
      statusFormatterExport(elt.dose),
      statusFormatterExport(elt.smartlock),
    ])
    console.log(data, ".pdf function")

    let content = {
      startY: 50,
      head: [
        [
          props.t("Group"),
          props.t("Name"),
          props.t("Tags"),
          props.t("Home"),
          props.t("On the Go"),
          props.t("Safety phone"),
          props.t("Video"),
          props.t("Measurements"),
          props.t("Medication reminder"),
          props.t("Smartlock"),
        ],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const sortedData = sortData(series)

    const Heading = [
      [
        props.t("Group"),
        props.t("Name"),
        props.t("Tags"),
        props.t("Home"),
        props.t("On the Go"),
        props.t("Safety phone"),
        props.t("Video"),
        props.t("Measurements"),
        props.t("Medication reminder"),
        props.t("Smartlock"),
      ],
    ]
    const data = sortedData.map(elt => [
      elt.group,
      elt.name,
      elt.tags ? elt.tags.join(", ") : "",
      statusFormatterExport(elt.home),
      statusFormatterExport(elt.tracker),
      statusFormatterExport(elt.carephone),
      statusFormatterExport(elt.video),
      statusFormatterExport(elt.health),
      statusFormatterExport(elt.dose),
      statusFormatterExport(elt.smartlock),
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success", 5000, "toast-bottom-left")
  }

  return (
    <React.Fragment>
      <div>
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Customers and services")}</h5>
            </span>
          </div>
        </Row>
        <Card className="mt-2">
          <CardBody>
            <Row>
              <Col className="col-12">
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  sizePerPage={SizePerPageDropdownStandalone(sizePerPageList)}
                  keyField="id"
                  columns={columns}
                  data={series}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={series}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Col lg="12">
                            <Row className="mb-1">
                              <Col xs="6">
                                <div className="mt-2">
                                  <p>
                                    {itemsLength} {props.t("found results")}
                                  </p>
                                </div>
                              </Col>
                              <Col xs="6">
                                {/* <div className="mt-2 text-muted"> */}
                                <span className="float-end mt-1">
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={tTdownloadstatstable}
                                    target="downloadstatstable"
                                    toggle={() => {
                                      setTTdownloadstatstable(
                                        !tTdownloadstatstable
                                      )
                                    }}
                                  >
                                    {props.t("Download statistics")}
                                  </Tooltip>
                                  <Dropdown
                                    id="printButtonServicesStats"
                                    isOpen={menu}
                                    toggle={() => setMenu(!menu)}
                                    className="download-btn text-center"
                                    type="button"
                                  >
                                    <DropdownToggle
                                      id="downloadstatstable"
                                      className="text-muted"
                                      tag="i"
                                    >
                                      <i className="mdi mdi-download" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {/* TODO: Can not choose directory file right now */}
                                      <DropdownItem
                                        onClick={() => downloadExcel()}
                                      >
                                        {props.t("Download .xlsx")}
                                      </DropdownItem>
                                      <DropdownItem onClick={() => exportPDF()}>
                                        {props.t("Download .pdf")}
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </span>
                                <Form className="mt-sm-0 float-sm-end d-flex align-items-center">
                                  <div className="search-box me-4">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder={props.t(" ")}
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search search-icon" />
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </Col>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive table-scroll-window">
                                <BootstrapTable
                                  id="services-table"
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  pagination={sizePerPageList}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-3 mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="">
                                <SizePerPageDropdownStandalone
                                  pagination={sizePerPageList}
                                  {...paginationProps}
                                />
                              </div>
                              <Col className="pagination justify-content-end inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

TableStats.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.any,
  searchProps: PropTypes.any,
  csvProps: PropTypes.any,
  baseProps: PropTypes.any,
  MyExportCSV: PropTypes.any,
  onExport: PropTypes.any,
}

export default withTranslation()(TableStats)
