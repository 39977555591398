import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  UncontrolledTooltip,
  ButtonGroup,
  FormGroup,
  Progress,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Accordion,
  AccordionItem,
  AccordionBody,
  Label,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import dayjs from "dayjs"
import {
  getOfflineVideoHistory,
  getOfflineVideos,
  getOfflineVideosChart,
  handleOfflineVideo,
  rebootVideoDevice,
  videoGetClientStatus,
  videoSendPush,
} from "helpers/backend_helper"
import VideoDeviceDrawer from "../../Developer/Devices/VideoDevices/VideoDeviceDrawer"
import {
  dateFormatter,
  defaultPageSizeOptions,
  showToast,
  stringSorter,
} from "utils/general"
import { Table as AntdTable } from "antd"
import OfflineVideosChart from "./offline-videos-chart"
import RangeCalendarServices from "../../Services/filter-calendar-services"
import RangeCalendar from "../../Reporting/filter-calendar"
import OfflineHistoryChart from "./offline-history-chart"
import ReactApexChart from "react-apexcharts"
import GlobalState from "contexts/GlobalState"
import { tableDefaultHeight } from "constants/layout"
import { clientLinksFormatter } from "utils/tables"

const OfflineVideoMonitoring = props => {
  const [state] = useContext(GlobalState)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [modelFilter, setModelFilter] = useState("")
  const [buildFilter, setBuildFilter] = useState("")
  const [videoVersionFilter, setVideoVersionFilter] = useState("")
  const [isSystemAdmin, setIsSystemAdmin] = useState(false)
  const [devices, setDevices] = useState([])
  const [filteredDevices, setFilteredDevices] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [showDrawer, setShowDrawer] = useState(false)
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [action, setAction] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [searchText, setSearchText] = useState("")
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [chartData, setChartData] = useState({
    new: [],
    automatic: [],
    manual: [],
  })
  const [chartLoading, setChartLoading] = useState(false)
  const [chartType, setChartType] = useState("day")
  const [chartDay, setChartDay] = useState(dayjs().valueOf())
  const [firstLoad, setFirstLoad] = useState(true)
  const [chartStats, setChartStats] = useState({
    new: 0,
    automatic: 0,
    manual: 0,
    resolved: 0,
    automaticPercentage: 0,
    manualPercentage: 0,
  })
  const [reasonStats, setReasonStats] = useState([0, 0, 0, 0, 0, 0])
  const [modelStats, setModelStats] = useState([])
  const [modelLabels, setModelLabels] = useState([])

  const [dailyLogs, setDailyLogs] = useState([])
  const [historyChart, setHistoryChart] = useState([])
  const [historyData, setHistoryData] = useState([])
  const [historyId, setHistoryId] = useState("")
  const [historyLoading, setHistoryLoading] = useState(false)

  const [selectedRoomName, setSelectedRoomName] = useState(null)
  const [resolveModal, setResolveModal] = useState(false)
  const [showClientColumn, setShowClientColumn] = useState(false)

  const [startDate, setStartDate] = useState(
    dayjs().startOf("day").add(-1, "months")
  )
  const [endDate, setEndDate] = useState(dayjs().endOf("day"))
  const [selectedChart, setSelectedChart] = useState(0)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Offline videos") + " | Suvanto Care"
    abortRef.current = new AbortController()

    if (state.authUser != null) {
      try {
        const obj = JSON.parse(state.authUser)
        let role = obj.activeRole ? obj.activeRole : obj.role
        if (role === "system_admin") {
          setIsSystemAdmin(true)
          setShowClientColumn(true)
        }
      } catch (err) {}
    }

    getVideos()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, devices])

  // Chart params changed
  useEffect(() => {
    if (!firstLoad) getChart()
  }, [chartType, chartDay])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = devices.filter(
      data =>
        data.serial?.toLowerCase().includes(searchTextLowercase) ||
        data.imei?.toLowerCase().includes(searchTextLowercase) ||
        data.teamViewerId?.toLowerCase().includes(searchTextLowercase) ||
        data.deviceModel?.toLowerCase().includes(searchTextLowercase) ||
        data.buildNumber?.toLowerCase().includes(searchTextLowercase) ||
        data.roomName?.toLowerCase().includes(searchTextLowercase)
    )
    setFilteredDevices(result)
  }

  // Get video entries
  const getVideos = () => {
    setOngoingSearch(true)
    getOfflineVideos(abortRef.current)
      .then(result => {
        if (result.statusCode === 200 && result.entries != null) {
          setDevices(result.entries)
        } else {
          setDevices([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setDevices([])
        setOngoingSearch(false)
      })
  }

  // Handle ticket
  const handleTicket = (roomName, newStatus, reason = "") => {
    if (!action && roomName && newStatus) {
      setAction(true)
      handleOfflineVideo(abortRef.current, roomName, newStatus, reason)
        .then(result => {
          if (result.statusCode == 200) {
            setResolveModal(false)
          } else if (result.reason) {
            showToast(props.t(result.reason), "error", 4000)
          } else {
            showToast(props.t("Error"), "error", 4000)
          }
          getVideos()
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error", 4000)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Get client status from AC
  const getClientStatus = roomName => {
    if (roomName && !action && !ongoingSearch) {
      setAction(true)
      videoGetClientStatus(abortRef.current, roomName)
        .then(result => {
          console.log(result)
          //showToast(props.t("Sent"), "success")
          getVideos()
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Send push command
  const sendPush = (serial, command) => {
    if (serial && command && !action && !ongoingSearch) {
      setAction(true)
      videoSendPush(abortRef.current, serial, command)
        .then(result => {
          console.log("Push result: ", result)
          if (result.statusCode == 200) {
            showToast(props.t("Command sent"), "success", 1500)
          } else {
            showToast(props.t("Error"), "error", 4000)
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error", 4000)
          console.log(err)
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Reboot video device
  const rebootDevice = serial => {
    if (serial && !action && !ongoingSearch) {
      setAction(true)
      rebootVideoDevice(abortRef.current, serial)
        .then(result => {
          console.log(result)
          if (result.statusCode == 200) {
            showToast(props.t("Command sent"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setAction(false)
        })
    }
  }

  // Chart
  const getChart = () => {
    setChartLoading(true)
    setChartData({ new: [], automatic: [], manual: [] })

    let filters = {}
    if (modelFilter) filters.model = modelFilter
    if (buildFilter) filters.build = buildFilter
    if (videoVersionFilter) filters.videoVersion = videoVersionFilter

    getOfflineVideosChart(
      abortRef.current,
      chartType,
      chartDay,
      filters,
      dayjs(chartDay).utcOffset(),
      startDate?.valueOf(),
      endDate?.valueOf()
    )
      .then(result => {
        if (result.statusCode === 200) {
          let temp = new Object()
          if (result.new != null) temp.new = result.new
          if (result.automatic != null) temp.automatic = result.automatic
          if (result.manual != null) temp.manual = result.manual
          setChartData(temp)

          // Add translatable description
          result.entries.forEach(element => {
            switch (element.status) {
              case "open":
                element.statusDescription = "New"
                break
              case "resolved":
                element.statusDescription = "Resolved"
                break
              default:
                element.statusDescription = element.status
                break
            }
            switch (element.reason) {
              case "undefined":
                element.reasonDescription = "Undefined"
                break
              case "other":
                element.reasonDescription = "Other"
                break
              case "camera":
                element.reasonDescription = "Camera issue"
                break
              case "connection":
                element.reasonDescription = "Connection issue"
                break
              case "unresponsive":
                element.reasonDescription = "Unresponsive app"
                break
              case "crashed":
                element.reasonDescription = "Crashed app"
                break
              default:
                element.reasonDescription = element.reason
                break
            }
          })

          setDailyLogs(result.entries)
          setChartStats(result.stats)

          // Reasons
          let reasons = []
          reasons.push(result.reasons.crashed)
          reasons.push(result.reasons.unresponsive)
          reasons.push(result.reasons.camera)
          reasons.push(result.reasons.connection)
          reasons.push(result.reasons.other)
          reasons.push(result.reasons.undefined)
          setReasonStats(reasons)

          // Model stats
          let modCounts = []
          let modLabels = []
          if (result.models) {
            result.models.forEach(model => {
              if (model.label != null && model.count != null) {
                modCounts.push(model.count)
                modLabels.push(props.t(model.label))
              }
            })
          }
          setModelStats(modCounts)
          setModelLabels(modLabels)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setChartLoading(false)
      })
  }

  // Device or room history
  const getHistory = () => {
    setHistoryLoading(true)
    getOfflineVideoHistory(abortRef.current, historyId)
      .then(result => {
        if (result.statusCode === 200) {
          setHistoryChart(result.chart)

          // Add translatable description
          result.entries.forEach(element => {
            switch (element.status) {
              case "open":
                element.statusDescription = "New"
                break
              case "resolved":
                element.statusDescription = "Resolved"
                break
              default:
                element.statusDescription = element.status
                break
            }
            switch (element.reason) {
              case "undefined":
                element.reasonDescription = "Undefined"
                break
              case "other":
                element.reasonDescription = "Other"
                break
              case "camera":
                element.reasonDescription = "Camera issue"
                break
              case "connection":
                element.reasonDescription = "Connection issue"
                break
              case "unresponsive":
                element.reasonDescription = "Unresponsive app"
                break
              case "crashed":
                element.reasonDescription = "Crashed app"
                break
              default:
                element.reasonDescription = element.reason
                break
            }
          })

          setHistoryData(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setHistoryLoading(false)
      })
  }

  // Table structure
  const columns = [
    {
      dataIndex: "status",
      title: props.t("Status"),
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "60px",
      ellipsis: true,
    },
    {
      dataIndex: "actions",
      title: props.t("Actions"),
      align: "center",
      render: actionFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "serial",
      title: props.t("Serial"),
      sorter: (a, b) => {
        return stringSorter(a, b, "serial")
      },
      render: clipboardFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "imei",
      title: props.t("IMEI"),
      sorter: (a, b) => {
        return stringSorter(a, b, "imei")
      },
      render: imeiFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "roomName",
      title: props.t("Number"),
      sorter: (a, b) => {
        return stringSorter(a, b, "roomName")
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "roomLastOnline",
      title: props.t("Last online"),
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        return a.roomLastOnline - b.roomLastOnline
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "teamViewerId",
      title: "TeamViewer ID",
      sorter: (a, b) => {
        return stringSorter(a, b, "teamViewerId")
      },
      render: clipboardFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "lastConnection",
      title: "Monitor " + props.t("connection"),
      sorter: (a, b) => {
        return a.lastConnection - b.lastConnection
      },
      render: dateFormatter,
      width: "140px",
      ellipsis: true,
    },
    ...(showClientColumn
      ? [
          {
            dataIndex: "links",
            title: props.t("Client"),
            render: clientLinksFormatter,
            width: "140px",
            ellipsis: true,
          },
        ]
      : []),
    {
      dataIndex: "videoVersion",
      title: "Video ver.",
      sorter: (a, b) => {
        return stringSorter(a, b, "videoVersion")
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "monitorVersion",
      title: "Monitor ver.",
      sorter: (a, b) => {
        return stringSorter(a, b, "monitorVersion")
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "batteryLevel",
      title: props.t("Battery"),
      sorter: (a, b) => {
        return a.batteryLevel - b.batteryLevel
      },
      width: "100px",
      ellipsis: true,
    },
    {
      dataIndex: "isCharging",
      title: props.t("Charging"),
      render: cell => {
        return String(cell)
      },
      width: "120px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceStarted",
      title: props.t("Device started"),
      sorter: (a, b) => {
        return a.deviceStarted - b.deviceStarted
      },
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "deviceModel",
      title: props.t("Model"),
      sorter: (a, b) => {
        return stringSorter(a, b, "deviceModel")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "buildNumber",
      title: props.t("Build"),
      sorter: (a, b) => {
        return stringSorter(a, b, "buildNumber")
      },
      width: "140px",
      ellipsis: true,
    },
  ]

  const columns2 = [
    {
      dataIndex: "timestamp",
      title: props.t("Time"),
      defaultSortOrder: "descend",
      sorter: (a, b) => {
        return a.timestamp - b.timestamp
      },
      render: dateFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "roomName",
      title: props.t("Room"),
      sorter: (a, b) => {
        return stringSorter(a, b, "roomName")
      },
      render: clipboardFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "statusDescription",
      title: props.t("Status"),
      sorter: (a, b) => {
        return stringSorter(a, b, "status")
      },
      render: translateFormatter,
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "handler",
      title: props.t("Handler"),
      sorter: (a, b) => {
        return stringSorter(a, b, "handler")
      },
      width: "140px",
      ellipsis: true,
    },
    {
      dataIndex: "reasonDescription",
      title: props.t("Reason"),
      sorter: (a, b) => {
        return stringSorter(a, b, "reason")
      },
      render: translateFormatter,
      width: "140px",
      ellipsis: true,
    },
  ]

  function translateFormatter(cell, row) {
    return props.t(cell)
  }

  function editFormatter(cell, row) {
    return (
      <div className="d-inline-flex">
        {(row.ticketStatus == "open" || row.ticketStatus == "") && (
          <>
            <Button
              id={"TT-ack-" + row.key}
              color="primary"
              size="sm"
              disabled={action || ongoingSearch}
              onClick={e => {
                e.preventDefault()
                handleTicket(row.roomName, "acked", "")
              }}
            >
              <i className="mdi mdi-hand font-size-14" />
            </Button>
            <UncontrolledTooltip placement="right" target={"TT-ack-" + row.key}>
              {props.t("Claim")}
            </UncontrolledTooltip>
          </>
        )}
        {row.ticketStatus == "acked" && (
          <>
            {row.isTicketOwner ? (
              <>
                <Button
                  id={"TT-resolve-" + row.key}
                  color="success"
                  size="sm"
                  disabled={action || ongoingSearch}
                  onClick={e => {
                    e.preventDefault()
                    //handleTicket(row.roomName, "resolved")
                    setSelectedRoomName(row.roomName)
                    setResolveModal(true)
                  }}
                >
                  <i className="mdi mdi-check-bold font-size-14" />
                </Button>
                <UncontrolledTooltip
                  placement="right"
                  target={"TT-resolve-" + row.key}
                >
                  {props.t("Set as resolved")}
                </UncontrolledTooltip>
              </>
            ) : (
              <>
                <div
                  id={"TT-acked-" + row.key}
                  className="d-flex"
                  style={{
                    minWidth: "32px",
                    minHeight: "31.5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <i
                    style={{ height: "fit-content" }}
                    className="mdi mdi-hand text-primary"
                  />
                </div>
                <UncontrolledTooltip
                  placement="right"
                  target={"TT-acked-" + row.key}
                >
                  <div className="d-grid">
                    <span>{"" + row.ticketHandler}</span>
                    <span>
                      {"" + dayjs(row.ticketUpdated).format("HH:mm:ss")}
                    </span>
                  </div>
                </UncontrolledTooltip>
              </>
            )}
          </>
        )}
        {row.ticketStatus == "resolved" && (
          <>
            <div
              id={"TT-closed-" + row.key}
              className="d-flex"
              style={{
                minWidth: "32px",
                minHeight: "31.5px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                style={{ height: "fit-content" }}
                className="mdi mdi-check-bold text-success"
              />
            </div>
            <UncontrolledTooltip
              placement="right"
              target={"TT-closed-" + row.key}
            >
              <div className="d-grid">
                <span>{"" + row.ticketHandler}</span>
                <span>{"" + dayjs(row.ticketUpdated).format("HH:mm:ss")}</span>
              </div>
            </UncontrolledTooltip>
          </>
        )}
      </div>
    )
  }

  function actionFormatter(cell, row) {
    return (
      <div className="d-inline-flex">
        <Button
          id={"TT-refreshstatus-" + row.key}
          color="primary"
          outline
          size="sm"
          disabled={action || ongoingSearch}
          onClick={() => {
            getClientStatus(row.roomName)
          }}
        >
          <i className="mdi mdi-cloud-refresh font-size-14" />
        </Button>
        <UncontrolledTooltip
          placement="right"
          target={"TT-refreshstatus-" + row.key}
        >
          {props.t("Refresh video status")}
        </UncontrolledTooltip>

        <Button
          id={"TT-restart-" + row.key}
          className="ms-2"
          color="primary"
          outline
          size="sm"
          disabled={action || ongoingSearch || !row.pushToken}
          onClick={() => {
            sendPush(row.serial, "RestartVideo")
          }}
        >
          <i className="mdi mdi-restart font-size-14" />
        </Button>
        <UncontrolledTooltip placement="right" target={"TT-restart-" + row.key}>
          {props.t("Restart Video app")}
        </UncontrolledTooltip>

        <Button
          id={"TT-launch-" + row.key}
          className="ms-2"
          color="primary"
          outline
          size="sm"
          disabled={action || ongoingSearch || !row.pushToken}
          onClick={() => {
            sendPush(row.serial, "StartVideo")
          }}
        >
          <i className="mdi mdi-launch font-size-14" />
        </Button>
        <UncontrolledTooltip placement="right" target={"TT-launch-" + row.key}>
          {props.t("Start Video app")}
        </UncontrolledTooltip>

        <Button
          id={"TT-monitor-" + row.key}
          className="ms-2"
          color="primary"
          outline
          size="sm"
          disabled={action || ongoingSearch || !row.pushToken}
          onClick={() => {
            sendPush(row.serial, "Connect")
          }}
        >
          <i className="mdi mdi-monitor-eye font-size-14" />
        </Button>
        <UncontrolledTooltip placement="right" target={"TT-monitor-" + row.key}>
          {"Monitor " + props.t("connection")}
        </UncontrolledTooltip>

        {isSystemAdmin && (row.serial?.startsWith("PP") || row.serial?.startsWith("EH")) && (
          <>
            <Button
              id={"TT-reboot-" + row.key}
              className="ms-2"
              color="danger"
              outline
              size="sm"
              disabled={action || ongoingSearch}
              onClick={() => {
                rebootDevice(row.serial)
              }}
            >
              <i className="mdi mdi-exit-to-app font-size-14" />
            </Button>
            <UncontrolledTooltip
              placement="right"
              target={"TT-reboot-" + row.key}
            >
              {props.t("Reboot device")}
            </UncontrolledTooltip>
          </>
        )}
      </div>
    )
  }

  function clipboardFormatter(cell, row) {
    return (
      <Button 
        size="sm" 
        color="transparent"
        className="font-size-13"
        style={{ textDecoration: "underline" }}
        onClick={e => {
          e.preventDefault()
          navigator.clipboard.writeText(cell)
          showToast(props.t("Copied to clipboard") + ": " + cell, "info", 1500)
        }}
      >
        {cell}
      </Button>
    )
  }

  function imeiFormatter(cell, row) {
    if (row.imei && (row.serial.startsWith("PP") || row.serial.startsWith("EH"))) {
      return (
        <a
          href={"https://console.hmdenablepro.com/devices?q=" + row.imei.trim()}
          target="_blank"
          rel="noreferrer"
        >
          {row.imei}
        </a>
      )
    } else return cell
  }

  // Donut chart options for offline reasons
  const options = {
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "reasons-donut",
    labels: [
      props.t("Crashed app"),
      props.t("Unresponsive app"),
      props.t("Camera issue"),
      props.t("Connection issue"),
      props.t("Other"),
      props.t("Undefined"),
    ],
    // https://htmlcolorcodes.com/color-chart/
    colors: [
      "#F1948A", // red
      "#BB8FCE", // violet
      "#85C1E9", // blue
      "#7DCEA0", // green
      "#F8C471", // yellow
      "#BFC9CA", // grey
    ],
    legend: {
      show: true,
      position: "right",
      itemMargin: {
        vertical: 6,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: props.t("Resolved by support"),
              fontSize: "14px",
              color: "#00000063",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
            },
          },
        },
      },
    },
  }

  // Donut chart options for device models
  const modelOptions = {
    tooltip: {
      enabled: true,
      fillSeriesColor: false,
      theme: "dark",
      style: {
        fontSize: "14px",
        fontFamily: undefined,
      },
    },
    id: "models-donut",
    labels: modelLabels,
    // https://htmlcolorcodes.com/color-chart/
    colors: [
      "#F1948A", // red
      "#BB8FCE", // violet
      "#85C1E9", // blue
      "#76d7c4", // blueish green
      "#7DCEA0", // green
      "#F8C471", // yellow
      "#e59866", // orange
      "#BFC9CA", // grey
    ],
    legend: {
      show: true,
      position: "right",
      itemMargin: {
        vertical: 6,
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "400",
        colors: [
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
          "#00000063",
        ],
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 3,
        borderRadius: 2,
        borderWidth: 0,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
    },
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Offline videos")} />
          <Row>
            <Col>
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-flex flex-wrap">
                    <ul className="nav nav-tabs nav-tabs-custom card-header-tabs">
                      <NavItem>
                        <NavLink
                          to="#"
                          active={activeTab === "1"}
                          onClick={() => {
                            setActiveTab("1")
                          }}
                        >
                          {props.t("Table")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "2"}
                          onClick={() => {
                            setActiveTab("2")
                            if (firstLoad) {
                              setFirstLoad(false)
                              getChart()
                            }
                          }}
                        >
                          {props.t("Statistics")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          active={activeTab === "3"}
                          onClick={() => {
                            setActiveTab("3")
                          }}
                        >
                          {props.t("Search")}
                        </NavLink>
                      </NavItem>
                    </ul>
                  </div>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={activeTab}>
                    <TabPane className="show" tabId="1">
                      <Row>
                        <Col>
                          {devices != null && (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          value={searchText}
                                          onChange={event =>
                                            setSearchText(event.target.value)
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    style={{ borderRadius: "100px" }}
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      getVideos()
                                    }}
                                  >
                                    <i className="mdi mdi-refresh me-2" />
                                    {props.t("Refresh")}
                                  </Button>
                                </Col>
                                <Col>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredDevices.length}{" "}
                                      {props.t("found results")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <div className="mt-2">
                                <AntdTable
                                  className="colored-header narrow-header"
                                  bordered
                                  size="small"
                                  sticky
                                  loading={ongoingSearch}
                                  rowKey={"key"}
                                  rowSelection={{
                                    type: "radio",
                                    selectedRowKeys,
                                    onChange: (rowKeys, rows) => {
                                      setSelectedRowKeys(rowKeys)
                                    },
                                  }}
                                  onRow={(record, rowIndex) => {
                                    return {
                                      onClick: event => {
                                        setSelectedRowKeys([record.key])
                                      },
                                    }
                                  }}
                                  columns={columns}
                                  dataSource={filteredDevices}
                                  pagination={{
                                    showSizeChanger: true,
                                    defaultPageSize: 100,
                                    pageSizeOptions: defaultPageSizeOptions,
                                  }}
                                  tableLayout="auto"
                                  scroll={{
                                    x: "max-content",
                                    y: tableDefaultHeight,
                                  }}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane className="show" tabId="2">
                      <Row className="mb-4">
                        <Col className="col-auto">
                          <ButtonGroup size="sm">
                            <Button
                              color="primary"
                              active={chartType === "day"}
                              onClick={() => setChartType("day")}
                              style={{
                                paddingTop: "6px",
                                paddingBottom: "6px",
                              }}
                              className="px-4"
                            >
                              {props.t("1 day")}
                            </Button>
                            <Button
                              color="primary"
                              active={chartType === "month"}
                              onClick={() => {
                                setChartType("month")
                                setChartDay(dayjs().startOf("day").valueOf())
                              }}
                              style={{
                                paddingTop: "6px",
                                paddingBottom: "6px",
                              }}
                              className="px-4"
                            >
                              {props.t("Period of time")}
                            </Button>
                          </ButtonGroup>
                        </Col>
                        <Col className="col-auto">
                          {chartType == "day" && (
                            <RangeCalendarServices
                              startDefault={30}
                              rangePicker={false}
                              setCalendarStartDate={setChartDay}
                              setCalendarEndDate={() => {}}
                            />
                          )}
                          {chartType == "month" && (
                            <RangeCalendar
                              disabled={chartLoading}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                              defaultStart={startDate}
                              defaultEnd={endDate}
                            />
                          )}
                        </Col>
                        <Col className="col-auto">
                          <Button
                            color="primary"
                            className="round-icon-button"
                            id="filter-button"
                            outline
                            onClick={() => {
                              filtersOpen.length > 0
                                ? setFiltersOpen("")
                                : setFiltersOpen("1")
                            }}
                          >
                            <UncontrolledTooltip
                              placement="bottom"
                              target="filter-button"
                            >
                              {props.t("Filters")}
                            </UncontrolledTooltip>
                            {filtersOpen ? (
                              <i className="mdi mdi-filter-minus"></i>
                            ) : (
                              <i className="mdi mdi-filter-menu"></i>
                            )}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            style={{ borderRadius: "100px" }}
                            color="primary"
                            disabled={chartLoading}
                            onClick={() => {
                              getChart()
                            }}
                          >
                            <i className="mdi mdi-refresh me-2" />
                            {props.t("Refresh")}
                          </Button>
                        </Col>
                      </Row>

                      <Accordion
                        flush
                        open={filtersOpen}
                        toggle={id => {
                          id === filtersOpen
                            ? setFiltersOpen("")
                            : setFiltersOpen(id)
                        }}
                      >
                        <AccordionItem>
                          <AccordionBody
                            accordionId="1"
                            className="mb-5 border-top border-bottom"
                            /*style={{
                            backgroundColor: "#e8f0fe",
                            border: "1px solid #ced4da",
                          }}*/
                          >
                            <Row xs={1} sm={2} md={3} xl={4}>
                              {/** 
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Model")}</Label>
                                  <CreatableSelect size="sm"/>

                                </FormGroup>
                              </Col>*/}
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Model")}</Label>
                                  <Input
                                    type="select"
                                    bsSize={"sm"}
                                    name="modelFilter"
                                    value={modelFilter}
                                    onChange={v => {
                                      setModelFilter(v.target.value)
                                    }}
                                  >
                                    <option value="">
                                      {props.t("Undefined")}
                                    </option>
                                    <option value="SM-T515">
                                      {props.t("Galaxy Tab A (SM-T515)")}
                                    </option>
                                    <option value="SM-T505">
                                      {props.t("Galaxy Tab A7 (SM-T505)")}
                                    </option>
                                    <option value="SM-X205">
                                      {props.t("Galaxy Tab A8 (SM-X205)")}
                                    </option>
                                    <option value="Nokia T21">
                                      {props.t("Nokia T21")}
                                    </option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Build")}</Label>
                                  <Input
                                    type="text"
                                    bsSize={"sm"}
                                    name="buildFilter"
                                    value={buildFilter}
                                    onChange={v => {
                                      setBuildFilter(v.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{"Video " + props.t("version")}</Label>
                                  <Input
                                    type="text"
                                    bsSize={"sm"}
                                    name="videoVersionFilter"
                                    value={videoVersionFilter}
                                    onChange={v => {
                                      setVideoVersionFilter(v.target.value)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Button
                              color="clear"
                              className="text-primary"
                              onClick={() => {
                                setModelFilter("")
                                setBuildFilter("")
                                setVideoVersionFilter("")
                              }}
                            >
                              <i className="mdi mdi-close me-1" />
                              {props.t("Clear filters")}
                            </Button>
                          </AccordionBody>
                        </AccordionItem>
                      </Accordion>

                      {chartLoading ? (
                        <>
                          <div className="text-center my-3">
                            <Link to="#" className="text-primary">
                              <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                              {props.t("Loading")}
                            </Link>
                          </div>
                        </>
                      ) : (
                        <>
                          <OfflineVideosChart data={chartData} />

                          <Row xs={1} xl={2} className="mt-4">
                            <Col>
                              <Card>
                                <CardHeader>{props.t("Total")}</CardHeader>
                                <CardBody style={{ height: "300px" }}>
                                  <Row className="mt-2">
                                    <Col>
                                      <div className="d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "4rem",
                                            height: "4rem",
                                          }}
                                        >
                                          <div className="avatar-title avatar-soft-danger rounded-circle font-size-24">
                                            <i className="bx bx-video-off"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center mt-2">
                                        <h4 className="mb-0">
                                          {chartStats.new}
                                        </h4>
                                        <p className="text-muted mb-0 mt-1">
                                          {props.t("New")}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="d-flex justify-content-center">
                                        <div
                                          style={{
                                            width: "4rem",
                                            height: "4rem",
                                          }}
                                        >
                                          <div className="avatar-title avatar-soft-success rounded-circle font-size-24">
                                            <i className="bx bx-video"></i>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="text-center mt-2">
                                        <h4 className="mb-0">
                                          {chartStats.automatic +
                                            chartStats.manual}
                                        </h4>
                                        <p className="text-muted mb-0 mt-1">
                                          {props.t("Resolved")}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>

                                  <ul className="list-group list-group-flush mt-4 border-top">
                                    <li className="list-group-item py-3">
                                      <Row>
                                        <Col>
                                          <span className="text-muted">
                                            {props.t("Resolved automatically")}
                                          </span>
                                        </Col>
                                        <Col xs={2}>
                                          <h5 className="mb-0">
                                            {chartStats.automatic}
                                          </h5>
                                        </Col>
                                        <Col xs={5}>
                                          <Progress
                                            style={{ height: "20px" }}
                                            color="success"
                                            value={chartStats.automatic}
                                            max={chartStats.resolved}
                                          >
                                            {chartStats.automaticPercentage +
                                              "%"}
                                          </Progress>
                                        </Col>
                                      </Row>
                                    </li>

                                    <li className="list-group-item py-3">
                                      <Row>
                                        <Col>
                                          <span className="text-muted">
                                            {props.t("Resolved by support")}
                                          </span>
                                        </Col>
                                        <Col xs={2}>
                                          <h5 className="mb-0">
                                            {chartStats.manual}
                                          </h5>
                                        </Col>
                                        <Col xs={5}>
                                          <Progress
                                            style={{ height: "20px" }}
                                            color="primary"
                                            value={chartStats.manual}
                                            max={chartStats.resolved}
                                          >
                                            {chartStats.manualPercentage + "%"}
                                          </Progress>
                                        </Col>
                                      </Row>
                                    </li>

                                    {/** 
                                    <li className="list-group-item py-3">
                                      <Row>
                                        <Col>
                                          <span className="text-muted">
                                            {props.t("Median")}
                                          </span>
                                        </Col>
                                        <Col xs={2}>
                                          <h5 className="mb-0">
                                            {chartStats.newMedian}
                                          </h5>
                                        </Col>
                                        <Col xs={5}></Col>
                                      </Row>
                                    </li>   */}
                                  </ul>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col>
                              <Card>
                                <CardHeader>
                                  <ButtonGroup size="sm" className="">
                                    <Button
                                      color="clear"
                                      className="font-size-13 text-primary"
                                      style={{
                                        borderWidth: "0 0 1px 0",
                                        borderRadius: "0",
                                      }}
                                      active={selectedChart == 0}
                                      onClick={() => {
                                        setSelectedChart(0)
                                      }}
                                    >
                                      {props.t("Reasons")}
                                    </Button>
                                    <Button
                                      color="clear"
                                      className="font-size-13 text-primary"
                                      style={{
                                        borderWidth: "0 0 1px 0",
                                        borderRadius: "0",
                                      }}
                                      active={selectedChart == 1}
                                      onClick={() => {
                                        setSelectedChart(1)
                                      }}
                                    >
                                      {props.t("Models")}
                                    </Button>
                                  </ButtonGroup>
                                </CardHeader>
                                <CardBody style={{ height: "300px" }}>
                                  {selectedChart == 0 && (
                                    <ReactApexChart
                                      options={options}
                                      series={reasonStats}
                                      type="donut"
                                      height="100%"
                                      className="apex-charts apexcharts-donut"
                                    />
                                  )}
                                  {selectedChart == 1 && (
                                    <ReactApexChart
                                      options={modelOptions}
                                      series={modelStats}
                                      type="donut"
                                      height="100%"
                                      className="apex-charts apexcharts-donut"
                                    />
                                  )}
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>

                          {chartType == "day" && (
                            <div className="mt-3">
                              <AntdTable
                                className="colored-header narrow-header"
                                size="small"
                                sticky
                                rowKey={"timestamp"}
                                columns={columns2}
                                dataSource={dailyLogs}
                                pagination={{
                                  showSizeChanger: true,
                                  defaultPageSize: 500,
                                  pageSizeOptions: defaultPageSizeOptions,
                                }}
                                tableLayout="auto"
                                scroll={{
                                  x: "max-content",
                                  y: tableDefaultHeight,
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </TabPane>

                    <TabPane className="show" tabId="3">
                      <Row className="mb-2">
                        <Col className="col-auto">
                          <FormGroup>
                            <Input
                              id="room"
                              //bsSize="sm"
                              name="room"
                              type="text"
                              placeholder={props.t("Video room")}
                              value={historyId}
                              onChange={v => {
                                setHistoryId(v.target.value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            color="primary"
                            disabled={historyLoading || !historyId}
                            onClick={() => {
                              getHistory()
                            }}
                          >
                            <i className="mdi mdi-database-search me-2" />
                            {props.t("Search")}
                          </Button>
                        </Col>
                      </Row>

                      <OfflineHistoryChart data={historyChart} />

                      <div className="mt-3">
                        <AntdTable
                          className="colored-header"
                          size="small"
                          sticky
                          rowKey={"timestamp"}
                          columns={columns2}
                          dataSource={historyData}
                          pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 500,
                            pageSizeOptions: defaultPageSizeOptions,
                          }}
                          tableLayout="auto"
                          scroll={{
                            x: "max-content",
                            y: tableDefaultHeight,
                          }}
                        />
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={resolveModal}
            toggle={() => {
              setResolveModal(!resolveModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Select reason")}</ModalHeader>
            <ModalBody>
              <ListGroup flush>
                <ListGroupItem
                  disabled={action}
                  action
                  tag={"button"}
                  onClick={() => {
                    handleTicket(selectedRoomName, "resolved", "crashed")
                  }}
                >
                  {props.t("Crashed app")}
                </ListGroupItem>
                <ListGroupItem
                  disabled={action}
                  action
                  tag={"button"}
                  onClick={() => {
                    handleTicket(selectedRoomName, "resolved", "unresponsive")
                  }}
                >
                  {props.t("Unresponsive app")}
                </ListGroupItem>
                <ListGroupItem
                  disabled={action}
                  action
                  tag={"button"}
                  onClick={() => {
                    handleTicket(selectedRoomName, "resolved", "camera")
                  }}
                >
                  {props.t("Camera issue")}
                </ListGroupItem>
                <ListGroupItem
                  disabled={action}
                  action
                  tag={"button"}
                  onClick={() => {
                    handleTicket(selectedRoomName, "resolved", "connection")
                  }}
                >
                  {props.t("Connection issue")}
                </ListGroupItem>
                <ListGroupItem
                  disabled={action}
                  action
                  tag={"button"}
                  onClick={() => {
                    handleTicket(selectedRoomName, "resolved", "other")
                  }}
                >
                  {props.t("Other")}
                </ListGroupItem>
                <ListGroupItem
                  disabled={action}
                  action
                  tag={"button"}
                  onClick={() => {
                    handleTicket(selectedRoomName, "resolved", "")
                  }}
                >
                  {props.t("Undefined")}
                </ListGroupItem>
              </ListGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setResolveModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>

          {/** Device settings drawer */}
          <VideoDeviceDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getDevices={getVideos}
          />
        </Container>
      </div>
    </>
  )
}

OfflineVideoMonitoring.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(OfflineVideoMonitoring)
