/**********************
 * Customers tree
 *********************/
// Expects an array of clients at props.data

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"

import { getLottieLoadingOptions } from "utils/general"

import { Tree, ConfigProvider } from "antd"

import Lottie from "react-lottie"

const SelectCustomersTree = props => {
  const [treeData, setTreeData] = useState([])
  const [loading] = useState(false)

  useEffect(() => {
    setTreeData(transformTreeData(props.data))
  }, [])

  // Transform treeData to a format accepted by the Ant Design Tree component
  const transformTreeData = customers => {
    console.log("treeData:", customers)
    // Group customers by their group property
    const groupedData = customers.reduce((acc, item) => {
      const group = item.group || "" // Fallback for items without a group
      if (!acc[group]) {
        acc[group] = []
      }
      acc[group].push(item)
      return acc
    }, {})

    // Transform the grouped data into the required tree structure
    return [
      {
        title: props.t("All"),
        children: Object.entries(groupedData).map(
          ([groupName, groupItems]) => ({
            title: groupName,
            children: groupItems.map(item => ({
              title: item.name,
              key: item.key,
            })),
          })
        ),
      },
    ]
  }

  // Empty list render
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      {loading ? (
        <p>{props.t("Loading")}</p>
      ) : (
        <p>{props.t("No customers found")}</p>
      )}
    </div>
  )

  return (
    <>
      {treeData.length !== 0 ? ( // Renders tree after data is gotten to allow defaultExpandAll
        <ConfigProvider renderEmpty={customizeRenderEmpty}>
          <Tree
            checkable
            multiple
            defaultExpandAll={props.expandAll}
            selectable={false}
            checkedKeys={props.checkedKeys}
            treeData={treeData}
            onCheck={newCheckedKeys => {
              const filteredKeys = newCheckedKeys.filter(
                key => !/^0-0(-0)?$/.test(key)
              )
              props.setCheckedKeys(filteredKeys)
            }}
          />
        </ConfigProvider>
      ) : (
        <Lottie
          options={getLottieLoadingOptions()}
          height={100}
          width={100}
        ></Lottie>
      )}
    </>
  )
}

SelectCustomersTree.propTypes = {
  t: PropTypes.any,
  checkedKeys: PropTypes.any,
  setCheckedKeys: PropTypes.func,
  expandAll: PropTypes.bool,
  data: PropTypes.any,
}

export default withTranslation()(SelectCustomersTree)
